import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../store/actions/index';
import MediaQuery from 'react-responsive';
import './headerNavbar/HeaderNavbar.scss';
import logo from './../Assets/Logo/locambio-logo.png';
import SearchBar from './SearchBar';
import SearchByCategories from './headerNavbar/SearchByCategories';
import UserBubble from './headerNavbar/UserBubble';
import HeaderNotificationsMessages from './headerNavbar/HeaderNotificationsMessages';
import HeaderNotificationsSaved from './headerNavbar/HeaderNotificationsSaved';
import NotificationsBubble from './NotificationsBubble';
import AvatarUser from './AvatarUser';
import { Alignment, Button, Icon, Menu, Navbar, Popover, Position, Spinner, Tooltip } from '@blueprintjs/core';
import { CHAT_KINDS } from '../utility/chat';

const HeaderNavbar = (props) => {
  const getImagePicture = () => {
    return {
      picture_users: props.imageProfile,
      name: props.fullName.split(' ')[0],
      last_name: props.fullName.split(' ')[1],
      avatar: props.avatar,
    };
  };

  const unreadChats = useSelector(({ chat }) => chat.unreadChats[CHAT_KINDS.proposal] + chat.unreadChats[CHAT_KINDS.inProgress]);

  useEffect(() => {
    props.onGetCategories();
  }, []);

  return (
    <div className="headerNavBar">
      <Navbar
        className="LCColorBackground gradientBackground gradientBackgroundAnimated loCambioNavbar flex flex-row mobile-column"
        fixedToTop
      >
        <div className="flex justify-between align-center">
          <Navbar.Group className="nav-content" align={Alignment.LEFT}>
            <Navbar.Heading className="logo-buttons">
              <Link to="/home">
                <img src={logo} className="App-logo" alt="locambio-logo" />
              </Link>
              <div className="flex row align-center mobile-disappear">
                <Popover
                  usePortal={false}
                  content={
                    <>
                      {props.categories.length > 0 ? (
                        <SearchByCategories
                          categories={props.categories}
                          latitude={props.latitude}
                          longitude={props.longitude}
                        />
                      ) : (
                        <Spinner className="mt-12 mb-12" size={'32'} />
                      )}
                    </>
                  }
                  position={Position.BOTTOM_RIGHT}
                >
                  <Button
                    className="bp3-minimal dep-button first-section-nav-buttons"
                    icon={<Icon icon="properties" iconSize={22} />}
                    text="Categorías"
                  />
                </Popover>
                <span className="vertical-line" />
                <Popover position={Position.BOTTOM_RIGHT}>
                  <Tooltip
                    usePortal={false}
                    content={<p className="margin0">Centro de Ayuda</p>}
                    position={Position.BOTTOM}
                  >
                    <Button
                      className="bp3-minimal first-section-nav-buttons"
                      icon={<Icon icon="help" iconSize={22} />}
                      text="Ayuda"
                    />
                  </Tooltip>
                  <Menu>
                    <p className="bp3-heading bp3-text-small bp3-text-muted marginTop-1em">
                      Información
                    </p>
                    <Link to="/about_locambio">
                      <Menu.Item text="Sobre LoCambio.com" />
                    </Link>
                    <Menu.Divider />
                    <p className="bp3-heading bp3-text-small bp3-text-muted marginTop-1em">
                      Servicios LoCambio.com
                    </p>
                    <Link to="/locambio_seguro">
                      <Menu.Item text="LoCambio.com Seguro" />
                    </Link>
                    <Link to="/publicidad">
                      <Menu.Item text="LoCambio.com Publicidad" />
                    </Link>
                    <Menu.Divider />
                    <p className="bp3-heading bp3-text-small bp3-text-muted marginTop-1em">
                      Enlaces del Sitio
                    </p>
                    <Link to="/consejos_de_seguridad">
                      <Menu.Item text="Consejos de Seguridad" />
                    </Link>
                    <Link to="/politicas-de-privacidad">
                      <Menu.Item text="Políticas de Privacidad" />
                    </Link>
                    <Link to="/terminos-y-condiciones">
                      <Menu.Item text="Términos y Condiciones" />
                    </Link>
                    <Link to="/contactos">
                      <Menu.Item text="Contáctanos" />
                    </Link>
                  </Menu>
                </Popover>
              </div>
            </Navbar.Heading>
          </Navbar.Group>
          <MediaQuery query="(min-device-width: 769px)">
            <SearchBar />
          </MediaQuery>
          <Navbar.Group className="nav-content">
            {props.isAuthenticated ? (
              <div className="flex flex-col third-section-navbar">
                <div className="flex flex-row logged-section">
                  <NotificationsBubble />
                  <Popover
                    minimal={false}
                    usePortal={false}
                    content={
                      <HeaderNotificationsMessages className="chats-width" />
                    }
                    position={Position.BOTTOM_RIGHT}
                  >
                    <Tooltip
                      usePortal={false}
                      content={<p className="margin0">Mensajes</p>}
                      position={Position.BOTTOM}
                    >
                      <Button
                        className="bp3-minimal"
                        icon={
                          <Icon
                            className="white-text"
                            icon="chat"
                            iconSize={22}
                          />
                        }
                      >
                        {unreadChats ? (
                          <span className="bubbleAlert">{unreadChats}</span>
                        ) : null}
                      </Button>
                    </Tooltip>
                  </Popover>
                  <MediaQuery query="(min-device-width: 769px)">
                    <Popover
                      minimal={false}
                      usePortal={false}
                      content={<HeaderNotificationsSaved />}
                      position={Position.BOTTOM_RIGHT}
                    >
                      <Tooltip
                        usePortal={false}
                        content={
                          <p className="margin0">Publicaciones Seguidas</p>
                        }
                        position={Position.BOTTOM}
                      >
                        <Button
                          className="bp3-minimal"
                          icon={
                            <Icon
                              className="white-text"
                              icon="pin"
                              iconSize={22}
                            />
                          }
                        />
                      </Tooltip>
                    </Popover>
                    <Popover
                      content={<UserBubble />}
                      position={Position.BOTTOM_RIGHT}
                    >
                      <Tooltip
                        content={<p className="margin0">Perfil</p>}
                        position={Position.BOTTOM}
                      >
                        <Button minimal className="margin0 padding0">
                          <div className="headerUserCard">
                            <div className="AvatarContainer">
                              <AvatarUser user={getImagePicture()} />
                            </div>
                            <Icon
                              className="white-text"
                              icon="chevron-down"
                              iconSize={26}
                            />
                          </div>
                        </Button>
                      </Tooltip>
                    </Popover>
                  </MediaQuery>
                  <MediaQuery query="(max-device-width: 768px)">
                    <Popover
                      minimal={false}
                      usePortal={false}
                      content={
                        <div className="black">
                          <Popover
                            minimal={false}
                            usePortal={false}
                            content={<HeaderNotificationsSaved />}
                            position={Position.BOTTOM_RIGHT}
                          >
                            <Button
                              className="bp3-minimal"
                              text="Publicaciones guardadas"
                              icon={<Icon icon="pin" iconSize={22} />}
                            />
                          </Popover>
                          <Popover
                            content={<UserBubble />}
                            position={Position.BOTTOM_RIGHT}
                          >
                            <Button minimal className="margin0 padding0">
                              <div className="headerUserCard">
                                <div className="AvatarContainer">
                                  <AvatarUser user={getImagePicture()} />
                                </div>
                                <Icon icon="chevron-down" iconSize={26} />
                              </div>
                            </Button>
                          </Popover>
                        </div>
                      }
                      position={Position.BOTTOM_RIGHT}
                    >
                      <Button
                        className="bp3-minimal"
                        icon={
                          <Icon
                            className="white-text"
                            icon="menu"
                            iconSize={26}
                          />
                        }
                      />
                    </Popover>
                  </MediaQuery>
                </div>
                <div className="flex flex-row third-section-buttons mobile-disappear">
                  <Link to="/new_publication">
                    <div className="publication-button options-buttons flex">
                      <Icon className="white-text" icon="plus" iconSize={22} />
                      <p className="mb-0">Publicar</p>
                    </div>
                  </Link>
                  <Link to="/wall">
                    <div className="mural-button options-buttons flex align-center">
                      <Icon icon="map-marker" className="mr-2" iconSize={22} />
                      <p className="mb-0">Mural</p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex flex-row margin-not-loged">
                <Link to="/registration">
                  <div className="white-text visitor-options flex flex-row justify-evenly align-center">
                    <Icon
                      icon="new-person"
                      className="white-text mr-2"
                      iconSize={22}
                    />
                    <p className="mobile-disappear">Registrarse</p>
                  </div>
                </Link>
                <span className="vertical-line-white" />
                <Link to="/login">
                  <div className="white-text visitor-options flex flex-row justify-evenly align-center">
                    <Icon
                      className="white-text mr-2"
                      icon="user"
                      iconSize={22}
                    />
                    <p className="mobile-disappear">Iniciar sesión</p>
                  </div>
                </Link>
              </div>
            )}
          </Navbar.Group>
        </div>
        <MediaQuery query="(max-device-width: 768px)">
          <div className="flex flex-row justify-evenly">
            <SearchBar />
            {props.isAuthenticated ? (
              <Link to="/new_publication">
                <div className="publication-button options-buttons flex">
                  <Icon className="white-text" icon="plus" iconSize={22} />
                </div>
              </Link>
            ) : null}
          </div>
        </MediaQuery>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.all,
    fullName: state.auth.fullName,
    otherInfo: state.auth.otherInfo,
    imageProfile: state.auth.imageProfile,
    isAuthenticated: state.auth.isAuthenticated,
    sessionVerifed: state.auth.sessionVerifed,
    typeSearch: state.searchbar.typeSearch,
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
    notifications: state.notification,
    avatar: state.auth.avatar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCategories: () => dispatch(actions.getCategories()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HeaderNavbar));
