import React from 'react';
import { FormGroup, InputGroup, Button, TextArea } from '@blueprintjs/core';
import { useContactFormLogic } from './ContactFormLogic';

const ContactForm = () => {
  const {
    formValues,
    onChangeFormValue,
    onSubmit,
    loading,
    errorFormValues,
  } = useContactFormLogic();
  const { lastName, email, subject, message } = formValues;
  const {
    lastName: errorLastName,
    email: errorEmail,
    subject: errorSubject,
    message: errorMessage,
  } = errorFormValues;

  return (
    <div className={'ml-0 md:ml-2'}>
      <div className={'mb-2'}>
        <FormGroup labelInfo="(required)" helperText={errorLastName}>
          <InputGroup
            placeholder="Nombre y Apellido"
            name="lastName"
            value={lastName}
            onChange={onChangeFormValue}
          />
        </FormGroup>
      </div>
      <div className={'mb-2'}>
        <FormGroup labelInfo="(*)" helperText={errorEmail}>
          <InputGroup
            placeholder="E-mail"
            name="email"
            value={email}
            onChange={onChangeFormValue}
          />
        </FormGroup>
      </div>
      <div className={'mb-2'}>
        <FormGroup labelInfo="(*)" helperText={errorSubject}>
          <InputGroup
            placeholder="Asunto:"
            name="subject"
            value={subject}
            onChange={onChangeFormValue}
          />
        </FormGroup>
      </div>
      <div className={'mb-2'}>
        <FormGroup labelInfo="(*)" helperText={errorMessage}>
          <TextArea
            className={'textAreaWhiteStyle'}
            fill={true}
            placeholder="Mensaje:"
            name="message"
            value={message}
            onChange={onChangeFormValue}
          />
        </FormGroup>
      </div>
      <div className={'text-center'}>
        <Button className={'BtnLCPrimary'} onClick={onSubmit} loading={loading}>
          Enviar mensaje
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
