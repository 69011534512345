import * as actionTypes from '../actions/actionTypes';
import { typeNotifications } from '../../const';
import collectionNotifications from '../../const/collectionNotifications';

const initialState = {
  initialLoading: true,
};

Object.values(collectionNotifications).forEach((key) => {
  initialState[key] = []
});

const setNotifications = (state, action) => {
  // Deep clone
  let updateState = JSON.parse(JSON.stringify(state));
  if (updateState.initialLoading === false) {
    updateState = initialState
  }
  /*
   * Insert data in the copy of localState
   * @param {String} keyObjectAPI, Arrangement key that comes from the API.
   * @param {String} keyObjectState, Key of the arrangement of the local state.
   */
  
  const insertData = (keyObjectAPI, keyObjectState) => {
    updateState.initialLoading = false;
    action.data
      .filter(notification => notification.type_notification_id === keyObjectAPI)
      .map(notification => updateState[keyObjectState].push(notification));
  };

  insertData(
    typeNotifications.new_question.name,
    collectionNotifications.commentsQuestion,
  );
  insertData(
    typeNotifications.new_response_comment.name,
    collectionNotifications.commentsAnswer,
  );
  insertData(
    typeNotifications.NEW_PROPOSAL_CHANGE.name,
    collectionNotifications.newProposalChange,
  );
  insertData(
    typeNotifications.accepted_proposal.name,
    collectionNotifications.acceptedProposalChange,
  );
  insertData(
    typeNotifications.rejected_proposal.name,
    collectionNotifications.rejectedPropsalChange,
  );
  insertData(
    typeNotifications.new_change.name,
    collectionNotifications.newChange,
  );
  insertData(
    typeNotifications.exchange_accepted.name,
    collectionNotifications.changeRequestFinalize,
  );
  insertData(
    typeNotifications.exchange_rejected.name,
    collectionNotifications.changeRejected,
  );
  insertData(
    typeNotifications.CHANGE_FINALIZED.name,
    collectionNotifications.changeFinalized,
  );
  insertData(
    typeNotifications.NEW_MESSAGE.name,
    collectionNotifications.newMessage,
  );
  insertData(typeNotifications.SHARE.name, collectionNotifications.share);
  insertData(typeNotifications.ToThirdPartyHaveOwnerInterest.name, collectionNotifications.ToThirdPartyHaveOwnerInterest);
  insertData(typeNotifications.ToOwnerArticleHeWant.name, collectionNotifications.ToOwnerArticleHeWant);
  insertData(typeNotifications.ToOwnerThirdPartyWantArticle.name, collectionNotifications.ToOwnerThirdPartyWantArticle);
  insertData(typeNotifications.ToThirdPartyWantOwnerArticle.name, collectionNotifications.ToThirdPartyWantOwnerArticle);
  insertData(typeNotifications.ToOwnerSameInterest.name, collectionNotifications.ToOwnerSameInterest);
  insertData(typeNotifications.ToThirdPartySameInterest.name, collectionNotifications.ToThirdPartySameInterest);
  insertData(typeNotifications.transaction_completed_by_the_system.name, collectionNotifications.TransactionCompletedByTheSystem);
  insertData(typeNotifications.publication_waiting_approval.name, collectionNotifications.PublicationWaitingApproval);
  return updateState;
};

const updateNotifications = (state, action) => {
  const { data: notification } = action;
  // Deep clone
  const updateState = JSON.parse(JSON.stringify(state));

  /**
   * Update state.
   * @param {String} key, key in the object local state to update
   */
  const saveData = key => {
    const index = updateState[key].findIndex(
      element => element.id === notification.id,
    );
    // if the notification exists, the value in the array is replaced, otherwise a new element is added to the array.
    if (index > -1) {
      updateState[key][index] = notification;
    } else {
      updateState[key].push(notification);
    }
  };

  switch (notification.type_notification_id) {
    case typeNotifications.new_question.name:
      saveData(collectionNotifications.commentsQuestion);
      break;
    case typeNotifications.new_response_comment.name:
      saveData(collectionNotifications.commentsAnswer);
      break;
    case typeNotifications.NEW_PROPOSAL_CHANGE.id:
      saveData(collectionNotifications.newProposalChange);
      break;
    case typeNotifications.accepted_proposal.name:
      saveData(collectionNotifications.acceptedProposalChange);
      break;
    case typeNotifications.rejected_proposal.name:
      saveData(collectionNotifications.rejectedPropsalChange);
      break;
    case typeNotifications.new_change.name:
      saveData(collectionNotifications.newChange);
      break;
    case typeNotifications.exchange_accepted.name:
      saveData(collectionNotifications.changeRequestFinalize);
      break;
    case typeNotifications.exchange_rejected.name:
      saveData(collectionNotifications.changeRejected);
      break;
    case typeNotifications.CHANGE_FINALIZED.id:
      saveData(collectionNotifications.changeFinalized);
      break;
    case typeNotifications.NEW_MESSAGE.id:
      saveData(collectionNotifications.newMessage);
      break;
    case typeNotifications.SHARE.id:
      saveData(collectionNotifications.share);
      break;
    case typeNotifications.ToThirdPartyHaveOwnerInterest.id:
      saveData(collectionNotifications.ToThirdPartyHaveOwnerInterest);
      break;
    case typeNotifications.ToOwnerArticleHeWant.id:
      saveData(collectionNotifications.ToOwnerArticleHeWant);
      break;
    case typeNotifications.ToOwnerThirdPartyWantArticle.id:
      saveData(collectionNotifications.ToOwnerThirdPartyWantArticle);
      break;
    case typeNotifications.ToThirdPartyWantOwnerArticle.id:
      saveData(collectionNotifications.ToThirdPartyWantOwnerArticle);
      break;
    case typeNotifications.ToOwnerSameInterest.id:
      saveData(collectionNotifications.ToOwnerSameInterest);
      break;
    case typeNotifications.ToThirdPartySameInterest.id:
      saveData(collectionNotifications.ToThirdPartySameInterest);
      break;
    case typeNotifications.transaction_completed_by_the_system.name:
      saveData(collectionNotifications.TransactionCompletedByTheSystem);
      break;
    case typeNotifications.publication_waiting_approval.name:
      saveData(collectionNotifications.PublicationWaitingApproval);
      break;
    default:
      console.error(`notification type: ${notification.type_notification_id}`, notification)
      throw new Error('NOTIFICATION TYPE UNHANDLED')
  }

  return updateState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATIONS:
      return setNotifications(state, action);
    case actionTypes.UPDATE_NOTIFICATIONS:
      return updateNotifications(state, action);
    default:
      return state;
  }
};

export default reducer;