import React from 'react'

export default function PaypalButton(props) {
    const [paid, setPaid] = React.useState(false);
    const [error, setError] = React.useState(null);
    const paypalRef = React.useRef();

    React.useEffect(() => {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    description: "Your description",
                    amount: {
                      currency_code: "USD",
                      value: parseFloat(props.amount),
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              setPaid(true);
            },
            onError: (err) => {
            //   setError(err),
              console.error(err);
            },
          })
          .render(paypalRef.current);
      }, []);


      // If the payment has been made
    if (paid) {
        return <div>¡ Pago exitoso !</div>;
    }

      // If any error occurs
    if (error) {
        return <div>Ha ocurrido un error en el proceso de pago. Por favor intente otra vez</div>;
    }  
      

    return (
        <>
            <div>
                <div ref={paypalRef} />
            </div>
        </>
    );
}
