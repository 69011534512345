import React, { Component } from 'react';
import { AnchorButton, Divider } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import Footer from './Footer';

class LinksInfoPages extends Component {
  render() {
    return (
      <>
        <div className="block md:fixed">
          <div className="LinksInfoPages mt-4 sm:mt-10 md:mt-0 lg:mt-0 xl:mt-0 md:pr-12">
            <h4 className="margin0 marginBottom-05em">Información</h4>
            <p className="margin0">
              <Link to="about_locambio">
                <AnchorButton minimal={true} text="Sobre LoCambio.com" />
              </Link>
            </p>
            <Divider className="marginTop-05em marginBottom-05em" />
            <h4 className="margin0 marginBottom-05em">
              Servicios LoCambio.com
            </h4>
            <p className="margin0">
              <Link to="locambio_seguro">
                <AnchorButton minimal={true} text="LoCambio.com Seguro" />
              </Link>
            </p>
            <p className="margin0">
              <Link to="publicidad">
                <AnchorButton minimal={true} text="LoCambio.com Publicidad" />
              </Link>
            </p>
            <Divider className="marginTop-05em marginBottom-05em" />
            <h4 className="margin0 marginBottom-05em">Enlaces del Sitio</h4>
            <p className="margin0">
              <Link to="faq">
                <AnchorButton minimal={true} text="Preguntas Frecuentes" />
              </Link>
            </p>
            <p className="margin0">
              <Link to="consejos_de_seguridad">
                <AnchorButton minimal={true} text="Consejos de Seguridad" />
              </Link>
            </p>
            <p className="margin0">
              <Link to="politicas-de-privacidad">
                <AnchorButton minimal={true} text="Políticas de Privacidad" />
              </Link>
            </p>
            <p className="margin0">
              <Link to="terminos-y-condiciones">
                <AnchorButton minimal={true} text="Términos y Condiciones" />
              </Link>
            </p>
            <p className="margin0">
              <Link to="contactos">
                <AnchorButton minimal={true} text="Contáctanos" />
              </Link>
            </p>
          </div>
          <div className="pr-12">
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default LinksInfoPages;
