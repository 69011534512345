import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import authReducer from './reducers/auth';
import tagsReducer from './reducers/tags';
import chatReducer from './reducers/chats';
import scrollReducer from './reducers/scroll';
import drawerReducer from './reducers/drawers';
import requestReducer from './reducers/request';
import networkReducer from './reducers/network';
import commentReducer from './reducers/comments';
import imageZoomReducer from './reducers/imageZoom';
import searchbarReducer from './reducers/searchbar';
import categoriesReducer from './reducers/categories';
import geolocationReducer from './reducers/geolocation';
import notificationReducer from './reducers/notification';
import notificationsReducer from './reducers/notifications';
import changeSuccessReducer from './reducers/changeSuccess';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  tags: tagsReducer,
  chat: chatReducer,
  scroll: scrollReducer,
  drawers: drawerReducer,
  request: requestReducer,
  network: networkReducer,
  comments: commentReducer,
  imageZoom: imageZoomReducer,
  searchbar: searchbarReducer,
  categories: categoriesReducer,
  geolocation: geolocationReducer,
  notification: notificationReducer,
  notifications: notificationsReducer,
  changeSuccess: changeSuccessReducer,
});

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');

  const logger = createLogger({
    duration: true,
    collapsed: true,
  });

  middlewares.push(logger);
}

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));