import React, { useEffect, useState } from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
import axios from 'axios';
import { config } from '../system/Config';

const InputByCategories = ({ idCategory, updateFields, formValues }) => {
  const [category_id, setCategoryId] = useState(null);
  const [fields, setFields] = useState([]);

  const getFields = async () => {
    try {
      const response = await axios.get(
        `${config.URL_API}/field_categories/${idCategory}`,
      );
      const values = [];
      response.data.forEach(field => {
        const e = formValues.find(c => c.id === field.id);
        const defaultValue = e && e.value;
        values.push({
          id: field.id,
          value: defaultValue,
          label: field.label,
          name: field.name,
        });
      });
      updateFields(values);
      setFields(response.data);
    } catch(err) {
      throw err;
    }
  };

  const dropdowns = fields.filter(field => field.question_type === 'list');
  const texts = fields.filter(field => field.question_type === 'info');

  const onChange = event => {
    const { value, name } = event.target;
    const values = JSON.parse(JSON.stringify(formValues));
    values[values.findIndex(e => e.id.toString() === name)].value = value;
    updateFields(values);
  };

  useEffect(() => {
    if (idCategory !== category_id) {
      setCategoryId(idCategory);
    }
  }, [idCategory]);

  useEffect(() => {
    getFields();
  }, [category_id]);

  if (fields.length === 0 || formValues.length === 0) return null;

  return (
    <FormGroup>
      <Row>
        {dropdowns.map(dropdown => (
          <Col key={dropdown.id} xs={12} sm={6} md={4} lg={4}>
            <div className="bp3-select bp3-large .modifier">
              <select
                value={formValues.find(e => e.id === dropdown.id).value}
                name={dropdown.id}
                onChange={onChange}
              >
                <option value="">{`Seleccionar ${dropdown.label}`}</option>
                {dropdown.settings.options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        ))}
        {texts.map(text => (
          <Col key={text.id} xs={12} sm={6} md={4} lg={4}>
            <FormGroup>
              <InputGroup
                value={formValues.find(e => e.id === text.id).value}
                onChange={onChange}

                name={text.id}
                placeholder={text.label}
              />
            </FormGroup>
          </Col>
        ))}
      </Row>
    </FormGroup>
  );
};

InputByCategories.propTypes = {
  idCategory: PropTypes.number.isRequired,
};

export default InputByCategories;
