import React, { useCallback, useMemo } from 'react';
import { Classes, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Categories = ({ list, onClick, title }) => {
  const categories = useSelector(({ categories: { all } }) => all);
  const categoriesToUse = list || categories;

  const handleClickCategory = useCallback((ancestors) => {
    onClick?.(ancestors);
  }, [onClick]);

  const categoryElement = useCallback((category, ancestorsCategories = []) => {
    const newAncestors = [...ancestorsCategories, category];

    if (category.categories?.length) {
      return (
        <MenuItem
          key={category.id}
          text={category.name}
        >
          {category.categories.map((childCategory) => (
            categoryElement(childCategory, newAncestors)
          ))}
        </MenuItem>
      );
    }

    return (
      <MenuItem
        key={category.id}
        onClick={() => handleClickCategory(newAncestors)}
        text={category.name}
      />
    );
  }, [handleClickCategory]);

  const categoriesElements = useMemo(
    () => categoriesToUse?.map((category) => categoryElement(category)),
    [categoriesToUse, categoryElement],
  );

  return (
    <Menu
      id="list-exchange-categories"
      className={Classes.ELEVATION_0}
    >
      <MenuDivider title={title} />
      {categoriesElements}
    </Menu>
  );
};

Categories.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  list: PropTypes.array,
};

export default Categories;
