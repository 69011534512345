import React, { useMemo } from 'react';
import { Button, Callout, Card, Radio, RadioGroup } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import './step3/Step2.scss';
import { useSelector } from 'react-redux';
import Categories from '../../../components/categories/Categories';
import Breadcrumb from '../../../breadcrumb/Breadcrumb';

const Step2 = (props) => {
  const categories = useSelector(({ categories: { all } }) => all);
  const memoCategories = useMemo(() => {
    let serviceCategories = [];
    const productCategories = categories.filter((category) => {
      if (category.slug !== 'servicios') return true;
      serviceCategories = category.categories;
      return false;
    });
    return { serviceCategories, productCategories };
  }, [categories]);

  const categoriesToShow = props.localState.subCategory === 'product' ?
    memoCategories.productCategories :
    memoCategories.serviceCategories;

  return (
    <Card>
      <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <div className="mb-2">
          <h3 className="text-base">Paso 1</h3>
          <h2 className="text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl">
            Selecciona la categoria
          </h2>
        </div>
        <Card className="CardForm">
          <RadioGroup
            className="ml-1 flex TipeBox"
            label={<h3>Tipo de Publicación:</h3>}
            onChange={props.handlerSubCategory}
            selectedValue={props.localState.subCategory}
          >
            <Radio label="Productos" value="product" />
            <Radio label="Servicios" value="service" checked={props.localState.subCategory === 'service'} />
          </RadioGroup>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Callout
                className="marginBottom-1em"
                icon="info-sign"
                title="Elige la categoría que mejor describa tu artículo"
              />
            </Col>
            <Col xs={12} sm={6} md={5} lg={5}>
              <Categories title="Categorías" list={categoriesToShow} onClick={props.handlerCategorySelect} />
            </Col>
            <Col xs={12} sm={6} md={7} lg={7}>
              <Callout
                className="mt-2 marginBottom-1em"
                icon="plus"
                title="Categoría seleccionada:"
              >
               <Breadcrumb
                 className="my-3 mr-2"
                 data={props.localState.categoryAncestors}
                 emptyText="No hay categoría agregada"
               />
              </Callout>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Button
          className="bp3-fill BtnLCPrimary"
          disabled={props.localState.categorySelected === null}
          rightIcon="arrow-down"
          onClick={props.goToStep3}
          text="Próximo"
        />
      </Col>
    </Row>
  </Card>
  );
};

export default Step2;
