import React, { Component } from "react";
import { Card, Elevation } from "@blueprintjs/core";
import { Row, Col, Container } from "react-grid-system";
import "./publicidadPage/PublicidadPage.scss";
import Footer from "./../components/Footer";
import LinksInfoPages from "./../components/LinksInfoPages";
import locambioAdsLogo from "./../Assets/System/LC-Ads-VerF.png";

class PublicidadPage extends Component {
  render() {
    return (
      <>
        <div className={"PublicidadPage"}>
          <div className={"pt-32 sm:pt-24 md:pt-4 lg:pt-4 xl:pt-4"}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <Card elevation={Elevation.ONE}>
                    <div className={"w-full md:w-1/3 m-4 LoCambioSeguroLogo"}>
                      <div className={"mb-8 text-center"}>
                        <img
                          className={"w-full"}
                          src={locambioAdsLogo}
                          alt={"logo loCambio.com Seguro"}
                        />
                      </div>
                    </div>
                    <h2 className={"margin0 marginBottom-1em"}>Publicidad</h2>
                    <p className={"margin0"}>Texto a desarrollar</p>
                  </Card>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default PublicidadPage;
