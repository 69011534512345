import React, { Component } from "react";
import { Card, Elevation } from "@blueprintjs/core";
import { Row, Col, Container } from "react-grid-system";
import "./privacyPolicies/PrivacyPolicies.scss";
import Footer from "./../components/Footer";
import LinksInfoPages from "./../components/LinksInfoPages";

class PrivacyPolicies extends Component {
  render() {
    return (
      <>
        <div className={"PrivacyPolicies"}>
          <div className={"pt-32 sm:pt-24 md:pt-4 lg:pt-4 xl:pt-4"}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <h2 className={"mb-4"}>Políticas de Privacidad</h2>
                  <Card elevation={Elevation.ONE} className={"mb-8"}>
                    <h2 className={"mb-4"}>Registro de Usuarios</h2>
                    <p className={"justifyText mb-4"}>
                      Para poder hacer uso de los servicios de{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> es necesario que el
                      “Usuario” cree una cuenta en el portal web, para lo cual
                      deberá suministrar toda la información que se solicite
                      como obligatoria de forma precisa y veraz. Asimismo, el
                      “Usuario” será responsable de la información otorgada,
                      garantizando su veracidad y aceptando las consecuencias de
                      su falsedad. Si posterior al registro del “Usuario” surge
                      alguna modificación de la información suministrada al
                      momento de su suscripción en el sistema, el “Usuario” se
                      compromete a actualizar la información lo antes posible.
                    </p>
                    <p className={"justifyText mb-4"}>
                      La cuenta creada por cada “Usuario” en{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> es personal e
                      intransferible, por lo que{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> no se hace responsable
                      por el uso que puedan hacer terceras personas de la misma
                      (con o sin autorización del “Usuario”).
                    </p>
                    <p className={"justifyText mb-4"}>
                      Solo esta permitida la creación de una cuenta por persona,
                      ya sea natural o jurídica. Si se comprueba duplicidad en
                      la información en más de una cuenta,{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> se reserva el derecho de
                      suspenderlas o inhabilitarlas de manera temporal o
                      indefinida.
                    </p>
                    <p className={"justifyText mb-4"}>
                      Cada “Usuario” se responsabiliza por la autenticidad,
                      exactitud, y la veracidad de los datos suministrados por
                      él en <strong>WWW.LOCAMBIO.COM</strong> Asimismo, la
                      plataforma se reserva el derecho de suspender de forma
                      temporal o definitivamente aquel “Usuario” cuyos datos
                      resultaren falsos o fraudulentos en cualquier forma. En
                      estos casos de inhabilitación, se suspenderán todas las
                      operaciones pendientes, sin que ello genere algún derecho
                      a resarcimiento por parte de{" "}
                      <strong>WWW.LOCAMBIO.COM</strong>{" "}
                    </p>
                    <p className={"justifyText mb-4"}>
                      El “Usuario” accederá a su sesión mediante el ingreso de
                      su correo electrónico y clave de seguridad personal
                      elegida ("Contraseña"). En ese sentido, el uso de la
                      cuenta está restringido al uso de la “Contraseña”, que es
                      de conocimiento único y exclusivo del “Usuario”, en
                      consecuencia, se obliga a tomar todas las previsiones como
                      buen pater familia y mantener la confidencialidad de su
                      “Contraseña”. Será de exclusiva responsabilidad del
                      “Usuario” si un tercero lograre acceder a su cuenta, con o
                      sin su consentimiento, y perfeccione o ejecute en su
                      nombre acciones dentro del sitio o viole la
                      confidencialidad de los datos del “Usuario”.{" "}
                    </p>
                    <p className={"justifyText mb-4"}>
                      El “Usuario” se compromete a notificar de forma inmediata,
                      escrita y fehaciente a través de nuestros canales de
                      atención al cliente de <strong>WWW.LOCAMBIO.COM</strong>,
                      de cualquier uso no autorizado de su cuenta, así como el
                      ingreso por terceros no autorizados a la misma.{" "}
                    </p>
                    <p className={"justifyText mb-0"}>
                      <strong>WWW.LOCAMBIO.COM</strong> se reserva el derecho a
                      negar el acceso al servicio, a inhabilitar o a eliminar
                      cualquier cuenta cuando se incumpliera la legislación
                      aplicable, los “Términos y Condiciones” o las Políticas de
                      privacidad, sin que ello genere algún derecho a
                      indemnización o resarcimiento por parte de la plataforma.{" "}
                    </p>
                  </Card>
                  <Card elevation={Elevation.ONE}>
                    <h2 className={"mb-2"}>
                      Privacidad de la Información y Confidencialidad
                    </h2>
                    <p className={"justifyText mb-4"}>
                      Como fue expresado anteriormente, para que un “Usuario”
                      goce de los servicios de <strong>WWW.LOCAMBIO.COM</strong>{" "}
                      debe crear una cuenta ya sea persona natural o jurídica,
                      ingresando datos de información personal que allí se
                      requieren como obligatorios para verificar la autenticidad
                      y veracidad de los usuarios y poder otorgar mayores
                      garantías en la prestación del servicio. La información
                      proporcionada por el “Usuario” al momento de crear su
                      cuenta, será almacenada en nuestros servidores que
                      mantienen altos niveles de seguridad.
                    </p>
                    <p className={"justifyText mb-4"}>
                      No será responsabilidad de{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> las consecuencias que
                      puedan derivarse del uso indebido y no autorizado de un
                      “Usuario” o un tercero, de la información suministrada en
                      la página, ni de la información que el “Usuario” divulgue
                      a terceros por redes sociales, o cualquier otro medio.
                    </p>
                    <p className={"justifyText mb-4"}>
                      <strong>WWW.LOCAMBIO.COM</strong> se compromete a proteger
                      la privacidad de la información; no obstante, puede
                      suceder que, a causa de órdenes judiciales o de
                      regulaciones legales, <strong>WWW.LOCAMBIO.COM</strong> se
                      vea obligado a revelar información a las autoridades o
                      terceras partes dependiendo de las circunstancias, y
                      cuando discrecionalmente se considere necesario.
                    </p>
                    <p className={"justifyText mb-4"}>
                      El “Usuario” puede cancelar, suspender, eliminar o
                      actualizar su cuenta e información de la base de datos de{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> cuando así lo desee; sin
                      embargo, en casos determinados, la información personal
                      del “Usuario” no será eliminada de manera inmediata de los
                      archivos por motivos técnicos o legales.
                    </p>
                    <p className={"justifyText mb-4"}>
                      Si la plataforma <strong>WWW.LOCAMBIO.COM</strong>{" "}
                      modificara los términos en que la información personal es
                      administrada, notificará vía correo electrónico para
                      verificar la conformidad de sus usuarios. Si el “Usuario”
                      no está de acuerdo con las referidas modificaciones,
                      deberá abstenerse de utilizar el sitio y/o los servicios.
                    </p>
                    <p className={"justifyText mb-4"}>
                      Al suscribirse, el “Usuario” de la plataforma{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> reconoce y acepta que{" "}
                      <strong>WWW.LOCAMBIO.COM</strong> podrá utilizar un
                      sistema de seguimiento mediante la utilización de cookies
                      (en lo adelante &quot;Cookies&quot;). Esto es con el fin
                      de otorgar un mejor servicio o proveer información que
                      considere de interés.
                    </p>
                    <p className={"justifyText mb-0"}>
                      <strong>WWW.LOCAMBIO.COM</strong> podrá acceder al sistema
                      de mensajería instantánea de los usuarios dentro de la
                      plataforma o aplicación móvil, ello con el fin de
                      controlar la calidad del servicio, y prevenir violaciones
                      a los “Términos y Condiciones”.
                    </p>
                  </Card>
                  <div className={"whiteSpace"}></div>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default PrivacyPolicies;
