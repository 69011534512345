import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNotifications } from '../store/actions';
import cableApp from '../utility/cableApp';
import { Toast, toastNotificationOptions } from '../utility/toast';

function NotificationCable() {
  const dispatch = useDispatch();

  useEffect(() => {
    const connection = cableApp.subscriptions.create({
      channel: 'NotificationChannel',
      user_id: localStorage.userId,
    }, {
      received: (notification) => {
        console.log('asdasd', notification)
        dispatch(updateNotifications(notification));
        Toast.show(toastNotificationOptions(notification));
      },
    });

    return () => connection.unsubscribe();
  }, []);

  return null;
}

export default NotificationCable;
