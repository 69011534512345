import axios from 'axios';
import { setHeader, getHeader } from './../../system/Sesion';
import { config } from './../../system/Config';
import { getChats } from './index';

import * as actionTypes from './actionTypes';
import { Toast } from '../../utility/toast';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authFail = errorMessage => {
  Toast.show({ intent: 'warning', message: errorMessage, icon: 'warning-sign' })
  return {
    type: actionTypes.AUTH_FAIL,
    error: errorMessage,
  };
};

export const authSuccess = (user, loggued) => {
  const otherInfo = {
    activePublications: 0,
    reputation: user.reputation,
    registration_date: user.registration_date,
    sold: user.sold,
    change: user.change,
    balance: user.balance,
    view_notifications: user.view_notifications,
    address: user.address,
    address_2: user.address_2,
    city: user.city,
    postal_code: user.postal_code,
    state: user.state,
    active: user.active,
    notifications_email: user.notifications_email,
    country: user.country,
    type_suscription: user.type_suscription,
  };

  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: user.token,
    userId: user.id,
    fullName: `${user.name} ${user.last_name}`,
    suscription: user.suscription,
    otherInfo: otherInfo,
    loggued: loggued,
    imageProfile: user.picture_users,
    publications: [],
    avatar: user.avatar,
  };
};

export const setLoggued = value => {
  return {
    type: actionTypes.AUTH_SET_LOGGUED,
    value: value,
  };
};

export const setFollow = data => {
  return {
    type: actionTypes.SET_FOLLOWS,
    followers: data.followers,
    following: data.following,
  };
};

export const setUsersFollowers = followers => {
  return {
    type: actionTypes.SET_USERS_FOLLOWERS,
    usersFollowers: followers,
  };
};

export const setUsersFollowing = following => {
  return {
    type: actionTypes.SET_USERS_FOLLOWING,
    usersFollowing: following,
  };
};

export const setpubShares = pubShares => {
  return {
    type: actionTypes.SET_PUB_SHARES,
    pubShares: pubShares,
  };
};

export const setLikes = likes => {
  return {
    type: actionTypes.SET_LIKES,
    likes,
  };
};

export const setPublicationsSaved = publicationsSaved => {
  return {
    type: actionTypes.SET_PUBLICATIONS_SAVED,
    publicationsSaved,
  };
};

const logOutStorageItems = [
  'tokenId', 'access-token', 'client', 'uid',
  'expiry', 'token-type', 'sesionValid', 'userId',
];

export const logout = () => {
  logOutStorageItems.forEach(key => localStorage.removeItem(key));

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

function ShowWellcomeMessage() {
  Toast.show({ message: 'Te extrañabamos, bienvenido de vuelta. Empecemos a cerrar \nbuenos Cambios!' })
}

export const auth = (email, password) => async (dispatch) => {
  dispatch(authStart());
  try {
    const dataLogin = { email: email, password: password };
    const data = await axios.post(`${config.URL_API}/auth/sign_in`, dataLogin)
    localStorage.setItem('tokenId', data.data.data.token);
    localStorage.setItem('userId', data.data.data.id);

    const response = await axios
      .get(`${config.URL_API}/currency_by_country?country=${data.data.data.country}`, { headers: data.headers })
      .catch((err) => {
        console.log(err);
      });

    if (response.data.errors) {
      localStorage.setItem('currency', 'USD');
    } else {
      localStorage.setItem('currency', response.data.currency);
    }

    setHeader(data.headers);
    await dispatch(getChats());
    dispatch(authSuccess(data.data.data, true));

    ShowWellcomeMessage();

    if (!data.data.data.active) {
      axios.put(
        `${config.URL_API}/active_account`,
        { id: data.data.data.id },
        { headers: data.headers },
      );
    }
  } catch (err) {
    console.error(err)
    err.response?.data?.errors?.forEach(error => {
      if (error === errorCredentials) {
        dispatch(authFail('Credenciales invalidas'));
      }
      if (errorUnconfirmated.test(error)) {
        dispatch(authFail(unconfirmedErrorMessage));
      }
    })
  }
}

export const authWithGoogle = tokenId => {
  return dispatch => {
    dispatch(authStart());
    const dataLogin = { token: tokenId };

    axios
      .post(`${config.URL_API}/validate_login_google`, dataLogin)
      .then(async response => {
        const { data } = response;
        localStorage.setItem('tokenId', data.user.token);
        localStorage.setItem('userId', data.user.id);
        setHeader(data.headers);
        await dispatch(getChats());
        dispatch(authSuccess(data.user, true));
        ShowWellcomeMessage();
      })
      .catch(() => {
        dispatch(authFail(unknowErrorMessage));
      });
  };
};

export const authWithFacebook = (token, email) => {
  return dispatch => {
    dispatch(authStart());
    const dataLogin = { token: token, email: email };

    axios
      .post(`${config.URL_API}/validate_login_facebook`, dataLogin)
      .then(async response => {
        const { data } = response;
        localStorage.setItem('tokenId', data.user.token);
        localStorage.setItem('userId', data.user.id);
        setHeader(data.headers);
        await dispatch(getChats());
        dispatch(authSuccess(data.user, true));
        ShowWellcomeMessage();
      })
      .catch(error => {
        dispatch(authFail(unknowErrorMessage));
      });
  };
};

export const validateSesionUser = token => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/users?token=${token}`, { headers: getHeader() })
      .then(async data => {
        dispatch(authSuccess(data.data, false));
        await dispatch(getChats());
        localStorage.setItem('tokenId', data.data.token);
        localStorage.setItem('userId', data.data.id);
      })
      .catch(error => {
        dispatch(setSesionVerifed());
      });
  };
};

// esto obtiene la cantidad de seguidores y seguidos (cantidad)
export const getFollows = user_id => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/all_cant?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then(data => {
        dispatch(setFollow(data.data));
      })
      .catch(error => {
        console.error(error)
      });
  };
};

// este obtiene los usuarios seguidores y seguidos (informacion)
export const getUsersFollow = user_id => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/followers?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then(data => {
        dispatch(setUsersFollowers(data.data.followers));
      })
      .catch(error => {
        console.error(error)
      });

    axios
      .get(`${config.URL_API}/following?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then(data => {
        dispatch(setUsersFollowing(data.data.following));
      })
      .catch(error => {
        console.error(error)
      });
  };
};

// obtiene las publicaciones que ha compartido un usuario
export const getShares = user_id => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/share_by_user?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then(data => {
        dispatch(setpubShares(data.data));
      })
      .catch(error => {
        console.error(error)
      });
  };
};

export const getLikes = user_id => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/likes_user/${user_id}`, { headers: getHeader() })
      .then(data => dispatch(setLikes(data.data.likes)));
  };
};

export const getPublicationsSaved = userId => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/saved_publications_user?user_id=${userId}`)
      .then(response =>
        dispatch(setPublicationsSaved(response.data.publications_saveds)),
      );
  };
};

export const checkSesion = () => {
  return dispatch => {
    if (
      localStorage.getItem('access-token') &&
      localStorage.getItem('client') &&
      localStorage.getItem('uid') &&
      localStorage.getItem('expiry') &&
      localStorage.getItem('token-type')
    ) {
      dispatch(validateSesionUser(localStorage.getItem('tokenId')));
    } else {
      dispatch(logout());
      dispatch(setSesionVerifed());
    }
  };
};

export const setSesionVerifed = () => {
  return {
    type: actionTypes.SET_SESSION_VERIFED,
  };
};

const errorUnconfirmated = /follow the instructions in the email before your account can be activated/;
const errorCredentials = 'Invalid login credentials. Please try again.';
const unconfirmedErrorMessage = 'Se envió un correo electrónico de confirmación a su correo. Debe seguir las instrucciones en el correo electrónico antes de poder activar su cuenta'
const unknowErrorMessage = 'Lo sentimos, hubo un error';
