import React, { Component } from "react";
import {
  Button,
  ControlGroup,
  InputGroup,
} from "@blueprintjs/core";
import TagsTLC from "../../TagsTLC";

class ChangeOperationMessages extends Component {
  tagsOfChange = new TagsTLC();

  constructor(props) {
    super(props);
    this.state = {
      proposalText: "",
      tag: null,
    };
  }

  changeHandler = (event) => {
    this.setState({ proposalText: event.target.value });
  };

  addProposal = () => {
    //SE RECIBE LA PROPUESTA EN EL COMPONENTE PADRE
    if (this.state.proposalText.length > 0) {
      this.props.addProposal(this.state.proposalText);
      this.setState({ proposalText: "" });
    }
  };

  render() {
    const { proposalText } = this.state;

    return (
      <>
        <h3 className={"margin0 marginBottom-05em"}>
          {`1. - Escribe tu propuesta de cambio`}
        </h3>
        <ControlGroup fill={true}>
          <InputGroup
            className={"whiteInput"}
            
            placeholder="Escribir propuesta"
            id="tagChange"
            value={proposalText}
            onChange={this.changeHandler}
          />
          <Button
            className={"BtnLCPrimary"}
            icon="plus"
            onClick={this.addProposal}
          />
        </ControlGroup>
      </>
    );
  }
}

export default ChangeOperationMessages;
