import React from 'react';
import ProductSwiper from '../../components/productSwiper/ProductSwiper';
import { needOverlay } from '../../utilityFunctions';
import './changeProposalsSent/ChangeProposalsSent.scss';

const RequestPublication = (props) => {
  const {
    // transaction,
    publication_interested,
    publication,
    suscription,
    tags,
  } = props;

  let pub = publication;
  if (publication_interested) {
    pub = publication_interested;
  }

  let content;
  if (pub) {
    content = (
      <>
        <div className="mb-2 PubContainer01">
          <h4 className="PubName">{pub.name}</h4>
          <ProductSwiper
            pictures={pub.pictures_urls || pub.pictures}
            needOverlay={needOverlay(pub.product_category, suscription)}
          />
        </div>
        
      </>
    );
  }

  if (tags) {
    content = (
      <div className="text-center mb-1">
        <h4 className="py-1 text-center PropuestaTitle">Propuesta</h4>
        <div className="bp3-tag tagYellow">{tags}</div>
      </div>
    );
  }

  return <>{content}</>;
};

export default RequestPublication;
