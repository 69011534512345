import React, {Component} from "react";
import {Row, Col} from "react-grid-system";
import { Card, Callout, Icon, Elevation } from "@blueprintjs/core";


class TableCities extends Component{

    constructor(props){
        super(props);
        
        let locations = [];
        
        for(let i in this.props){
            
            for(let key in this.props[i]){
                locations.push({
                    ciudad: key,
                    value: this.props[i][key]
                });
            }
        }
        
        this.state = {
            locations: locations
        };
    }

    render(){
        const {locations} = this.state;
        return (
                <table class="table-auto w-full">
                  <thead>
                    <tr>
                      <th class="px-4 py-2">Ciudad</th>
                      <th class="px-4 py-2">Número de Vistas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        locations.map((element) => (
                            <tr>
                                <td class="border px-4 py-2">{element.ciudad}</td>
                                <td class="border px-4 py-2 text-right">
                                {element.value}
                                </td>
                            </tr>
                        ))
                    }
                  </tbody>
                </table>
        );
    }
}

export default TableCities;