import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './../../utilityFunctions';

const initialState = {
  latitude: null,
  longitude: null
}

const setPosition = (state, action) => {
  return updateObject(state, {
    latitude: action.latitude,
    longitude: action.longitude
  })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POSITION: return setPosition(state, action)
    default: 
      return state;
  }
}

export default reducer;