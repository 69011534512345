import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Position, Tooltip } from '@blueprintjs/core';

import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';
import AvatarUser from '../../components/AvatarUser';
import { datelocambio } from '../../utilityFunctions';
import './changeProposalsSent/ChangeProposalsSent.scss';
import { setCurrentTransaction, setScrollTo } from '../../store/actions';

class ProposalTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: null,
    };
  }

  componentDidMount() {
    this.fetchTransaccion()
      .then((data) => {
        this.setState({
          transaction: data.data,
        }, () => {
          if (this.props.currentTransaction === this.props.name) {
            this.props.setScrollTo(this.props.currentTransaction);
            this.props.setCurrentTransaction('');
          }
        });
      });
  }

  fetchTransaccion() {
    return axios.get(
      `${config.URL_API}/transactions/${this.props.urlTransaction}`,
      { headers: getHeader() },
    );
  }

  render() {
    const { transaction } = this.state;

    if (!transaction) {
      return <></>;
    }

    let card = (
      <div className="headerUserCard flex-col md:flex-row text-left rounded overflow-hidden shadow-md p-2">
        <Link
          to={`/chats/${transaction.chat_id}?scroll=true`}
          className="showProposalLink"
        >
          <div className="w-1/2 text-center md:text-left flex Col1">
            <div className="AvatarContainer mt-10 md:mt-0 float-left">
              <AvatarUser user={transaction.interested_user} />
            </div>
            <div className="Box">
              <div className="mt-2 mr-2 Box1Line1">
                <div className="w-full marginBottom-05em UserName">
                  <h4>
                    {`${transaction.interested_user.name} ${transaction.interested_user.last_name}`}
                  </h4>
                </div>
                <div className="mt-1 mr-1 Date2">
                  <span>{datelocambio(transaction.updated_at)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 Col2">
            <div className="Propuesta">Propuesta</div>
            <div className="mt-1 PubTag">
              {transaction.publication_interested ? (
                <span>{transaction.publication_interested.name}</span>
              ) : (
                <span className="bp3-tag tagYellow mt-1 Proposal">
                  {transaction.tags}
                </span>
              )}
            </div>
          </div>
          <div className="mt-2 Col2">
            <div className="PubTag">
              {transaction.publication_interested ? (
                <img
                  src={transaction.publication_interested.pictures_urls[0]}
                  className="productSwiperCompact"
                />
              ) : null}
            </div>
          </div>
        </Link>
        <div>
        <Tooltip
          content={<p>Rechazar Propuesta</p>}
          position={Position.BUTTOM_RIGHT}
        >
          {this.props.read ? null : (
            <Button
              className="CancelBtn responsiveCancel bp3-small"
              icon="cross"
              onClick={() => this.props.openModal(transaction.id, transaction, 'reject')}
            ></Button>
          )}
        </Tooltip>
        </div>
      </div>
    );

    let result = (
      <>
        <div className="relative pb-2 items-center bg-white">
          {this.props.read ? (
            card
          ) : (
            <div className="Container">
              {card}
              </div>
          )}
        </div>
      </>
    );
    return result;
  }
}

const mapStateToProps = (state) => ({
  currentTransaction: state.request.currentTransaction,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentTransaction: (currentTransaction) => dispatch(setCurrentTransaction(currentTransaction)),
  setScrollTo: (scrollTo) => dispatch(setScrollTo(scrollTo)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProposalTransaction));
