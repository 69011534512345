export default class TagsTLC {

  constructor() {
    this.tags = new Array();
  }

  addTag = (tag) => {
    if (this.tags.indexOf(tag) === -1 && tag) {
      this.tags.push(tag)
    }
  }

  removeTag = tag => {
    const index = this.tags.indexOf(tag);
    this.tags.splice(index, 1)
  }

  getTag = () => {
    return this.tags;
  }

  existTag = tag => {
    if (this.tags.indexOf(tag) !== -1) {
      return true;
    }
    return false;
  }
}
