import React from 'react';
import {
  AnchorButton,
  Button,
  Callout,
  Card,
  Checkbox,
  Collapse,
  ControlGroup,
  Divider,
  FormGroup,
  H4,
  HTMLSelect,
  Icon,
  InputGroup,
  Popover,
  Position,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import './TabsPanel.scss';
import CompleteIndividualRegistration from './../../components/CompleteIndividualRegistration';
import {
  letters,
  minCharacter,
  numbers,
  thereNumber,
  thereSpecialChar,
  thereUpper,
} from './../../utilityFunctions';
import ReactDropzone from 'react-dropzone';
import Recaptcha from 'reaptcha';
import { touchedControlUser, validation } from '../ownProfile/validations';
import {
  countries,
  countriesCode,
  provincesColombia,
  provincesPeru,
  provincesVenezuela,
} from 'const';
import AddImage from '../../components/AddImage';

const countriesOptions = countries.map(({ description, value }) => ({
  label: description,
  value,
}));
countriesOptions.unshift({
  label: 'Seleccionar',
  disabled: true,
  selected: true,
});

const renderLabelProvince = (value) => {
  if (value === countriesCode.VENEZUELA) {
    return '';
  } else if (value === countriesCode.PERU) {
    return '';
  } else if (value === countriesCode.COLOMBIA) {
    return '';
  }
};

const acceptedFiles = 'image/jpeg, image/png, image/gif, image/mba, image/jpg';

class IndividualPanel extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = null;
    this.state = {
      captchaReady: false,
      renderedCaptcha: false,
      states: [
        { label: 'Seleccionar', value: '', disabled: true, selected: true },
      ],
      labelProvince: '',
    };
  }

  componentDidUpdate(prevState) {
    if (this.state.captchaReady && !this.state.renderedCaptcha) {
      this.setState({ renderedCaptcha: true }, () =>
        this.captcha.renderExplicitly(),
      );
    }
  }

  onLoad = () => {
    this.setState({
      captchaReady: true,
    });
  };

  onChangeCountry = (event) => {
    {
      const {
        target: { value },
      } = event;
      let values = [];
      if (value === countriesCode.VENEZUELA) {
        values = provincesVenezuela;
      } else if (value === countriesCode.PERU) {
        values = provincesPeru;
      } else if (value === countriesCode.COLOMBIA) {
        values = provincesColombia;
      }

      const options = values.map((province) => ({
        label: province,
        value: province,
      }));
      const provinceLabel = renderLabelProvince(value);
      this.setState({ provinceLabel });
      options.unshift({
        label: 'Seleccionar',
        value: '',
        disabled: true,
        selected: true,
      });
      this.setState({ states: options });
    }
  };

  inputUserChangeHandler = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    let user = { ...this.state.user };
    user[name] = value;
    if (touchedControlUser.hasOwnProperty(name)) {
      touchedControlUser[event.target.id] = true;
    }
    const valid = validation(user, true);
    this.setState({ user: user, formValid: valid.valid });
  };

  render() {
    return (
      <>
        <div className="individualPanel">
          <H4>Registro para personas</H4>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="marginLeft-1em dragDropDeleteButton">
                <Button onClick={this.props.deleteImage}>
                  <Icon icon="trash" />
                </Button>
              </div>
              <ReactDropzone
                maxSize={5000000}
                accept={acceptedFiles}
                onDrop={(files, pixelCrop) => this.props.onDrop(files)}
              >
                {(dropZoneProps) => (
                  <div className="justify-center h-full w-full">
                    {
                      !this.props.user.image ? <AddImage {...dropZoneProps} className="relative z-20" /> :
                        <div className="image-preview">
                          <div className="croppedImageWrapper" id="image">
                            {this.props.user.image && <img src={this.props.user.image} alt={`image`} />}
                          </div>
                        </div>
                    }
                  </div>
                )}
              </ReactDropzone>
              <div className="marginTop-1em" />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.name}
                label="Nombre"
                labelFor="text-input"
                labelInfo="*"
              >
                <InputGroup
                  placeholder="Nombre"
                  value={this.props.user.name}
                  onChange={this.props.changed}
                  onKeyPress={letters}
                  id="name"
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.last_name}
                label="Apellidos"
                labelFor="text-input"
                labelInfo="*"
              >
                <InputGroup
                  placeholder="Apellidos"
                  value={this.props.user.last_name}
                  onChange={this.props.changed}
                  onKeyPress={letters}
                  id="last_name"
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.email}
                label="Correo electrónico"
                labelFor="text-input"
                labelInfo="*"
              >
                <InputGroup
                  placeholder="Correo Electrónico"
                  value={this.props.user.email}
                  onChange={this.props.changed}
                  id="email"
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.dni}
                label="Cédula de identidad"
                labelInfo="*"
              >
                <ControlGroup>
                  <HTMLSelect
                    id="typeDNI"
                    value={this.props.user.typeDNI}
                    onChange={this.props.changed}
                    options={[
                      { label: 'V', value: 'V' },
                      { label: 'E', value: 'E' },
                    ]}
                  />
                  {/* ----------- agregar E de extrangeros ----------- */}
                  <InputGroup
                    fill={true}
                    placeholder="Cédula de Identidad"
                    value={this.props.user.dni}
                    onChange={this.props.changed}
                    onKeyPress={numbers}
                    id="dni"
                  />
                </ControlGroup>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                label="Fecha de Nacimiento (mayor de 18 años)"
                labelFor="text-input"
                labelInfo="*"
              >
                <HTMLSelect
                  className="marginRight-05em marginBottom-1em"
                  id="year"
                  options={this.props.years}
                  onChange={this.props.changedGeneral}
                  value={this.props.year}
                />
                <HTMLSelect
                  className="marginRight-05em marginBottom-1em"
                  id="month"
                  options={this.props.months}
                  onChange={this.props.changedGeneral}
                  value={this.props.month}
                />
                <HTMLSelect
                  className="marginBottom-1em"
                  id="day"
                  options={this.props.days}
                  onChange={this.props.changedGeneral}
                  value={this.props.day}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup label="Género" labelInfo="*">
                <div id="genderRadio">
                  <RadioGroup
                    selectedValue={this.props.user.gender}
                    onChange={this.props.changed}
                    inline={true}
                  >
                    <Radio label="Masculino" value="1" id="gender" />
                    <Radio label="Femenino" value="0" id="gender" />
                  </RadioGroup>
                </div>
              </FormGroup>
            </Col>

            <Col xs={12} sm={4} md={6} lg={6}>
              <FormGroup label="País" labelFor="text-input" labelInfo="*">
                <HTMLSelect
                  placeholder="Seleccionar"
                  value={this.props.user.country}
                  id="country"
                  onChange={(event) => {
                    this.props.changed(event);
                    this.onChangeCountry(event);
                    this.inputUserChangeHandler(event);
                  }}
                  options={this.props.countries}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={3} md={3} lg={3}>
              <FormGroup
                label={this.state.labelProvince || 'Estado'}
                labelInfo="*"
              >
                <ControlGroup>
                  <HTMLSelect
                    placeholder="Seleccionar"
                    options={this.state.states}
                    id="state"
                    onChange={(event) => {
                      this.props.changed(event);
                      this.inputUserChangeHandler(event);
                    }}
                    value={this.props.user.state}
                  />
                </ControlGroup>
              </FormGroup>
            </Col>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <Col xs={12} sm={12} md={12} lg={12}>
              <Card className="CardForm">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Popover
                      isOpen={true}
                      content={
                        this.props.focusPassword ? (
                          <div className="popOverContent">
                            <Callout
                              className=""
                              icon="key"
                              title="Tu contraseña debe contener"
                            >
                              <ul>
                                <li>
                                  {!minCharacter(this.props.user.password) ? (
                                    <Icon className="iconRed" icon="cross" />
                                  ) : (
                                    <Icon className="iconGreen" icon="tick" />
                                  )}
                                  Al menos seis caracteres
                                </li>
                                <li>
                                  {!thereUpper(this.props.user.password) ? (
                                    <Icon className="iconRed" icon="cross" />
                                  ) : (
                                    <Icon className="iconGreen" icon="tick" />
                                  )}
                                  Al menos una mayúscula
                                </li>
                                <li>
                                  {!thereNumber(this.props.user.password) ? (
                                    <Icon className="iconRed" icon="cross" />
                                  ) : (
                                    <Icon className="iconGreen" icon="tick" />
                                  )}
                                  Al menos un caracter numérica
                                </li>
                                <li>
                                  {!thereSpecialChar(
                                    this.props.user.password,
                                  ) ? (
                                    <Icon className="iconRed" icon="cross" />
                                  ) : (
                                    <Icon className="iconGreen" icon="tick" />
                                  )}
                                  Al menos un caracter especial (!"#@?-_")
                                </li>
                              </ul>
                            </Callout>
                          </div>
                        ) : null
                      }
                      position={Position.TOP_LEFT}
                    >
                      <FormGroup
                        label="Contraseña"
                        labelFor="text-input"
                        labelInfo="*"
                      >
                        <InputGroup
                          className="whiteInput"
                          placeholder="Contraseña"
                          value={this.props.user.password}
                          onChange={this.props.changed}
                          id="password"
                          type="password"
                          onFocus={this.props.onFocusPassword}
                          onBlur={this.props.onBlurPassword}
                        />
                      </FormGroup>
                    </Popover>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <FormGroup
                      helperText={this.props.helperTexts.repeatPassword}
                      label="Confirmar contraseña"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        className="whiteInput"
                        placeholder="Confirmar contraseña"
                        value={this.props.user.repeatPassword}
                        onChange={this.props.changed}
                        id="repeatPassword"
                        type="password"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="marginBottom-1em">
                <Button
                  minimal="true"
                  fill="true"
                  onClick={this.props.toForm2}
                  rightIcon={
                    this.props.showForm2 ? 'chevron-up' : 'chevron-down'
                  }
                >
                  {this.props.showForm2
                    ? 'No completar mi perfil ahora'
                    : 'Completar mi perfil ahora'}
                </Button>
              </div>
            </Col>
          </Row>
          <Collapse isOpen={this.props.showForm2}>
            <CompleteIndividualRegistration
              data={this.props.user}
              changed={this.props.changed}
              helperText={this.props.helperTexts}
            />
          </Collapse>
          <Divider className="marginBottom-1em" />
          <Row className="marginBottom-1em">
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.termsAceppt}
                label="Términos y Condiciones"
                labelInfo="*"
              >
                <Checkbox
                  checked={this.props.user.termsAceppt}
                  id="termsAceppt"
                  onChange={this.props.changed}
                >
                  Acepto los <strong>Términos y Condiciones</strong>
                </Checkbox>
              </FormGroup>
              <AnchorButton
                text="Leer Términos y Condiciones"
                minimal="false"
                icon="eye-open"
                onClick={() => this.props.onOpenTerms()}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.policiesAceppt}
                label="Políticas y Privacidad"
                labelInfo="*"
              >
                <Checkbox
                  checked={this.props.user.policiesAceppt}
                  id="policiesAceppt"
                  onChange={this.props.changed}
                >
                  Acepto las <strong>Políticas y Privacidad</strong>
                </Checkbox>
              </FormGroup>
              <AnchorButton
                text="Leer Políticas y Privacidad"
                minimal="false"
                icon="eye-open"
                onClick={() => this.props.onOpenPolicies()}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Recaptcha
                className="marginTop-1em Recaptcha"
                ref={(e) => (this.captcha = e)}
                sitekey={this.props.sitekey}
                onLoad={this.onLoad}
                onVerify={this.props.onVerify}
                onError={this.props.onErrorCaptcha}
                explicit
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Button
                className="BtnLCYellow marginTop-1em"
                rightIcon="new-person"
                loading={this.props.loading}
                fill={true}
                disabled={!this.props.formValid}
                onClick={this.props.sendRegistration}
              >
                Registrarme
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default IndividualPanel;
