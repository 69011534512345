import React from "react";
import { Divider } from "@blueprintjs/core";
import "./footer/Footer.scss";

const Footer = () => (
  <div className={"Footer"}>
    <Divider className={"marginTop-1em"} />
    <div className={"mt-4 mb-4"}>
      <ul>
        <li>
          <p className="bp3-form-helper-text">
            t-locambio, C.A. 2019 -
            <script>document.write(new Date().getFullYear())</script>RIF:
            J-40865264-1
          </p>
        </li>
        <li>
          <p className="bp3-form-helper-text">
            Todos los Derechos Reservados &#9400; locambio.com
          </p>
        </li>
      </ul>
    </div>
  </div>
);

export default Footer;
