import React, { Component } from 'react';
import { Player } from 'video-react';
import './../../node_modules/video-react/dist/video-react.css';
import { AnchorButton, Card, Elevation } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import './aboutLoCambio/AboutLoCambio.scss';
import LinksInfoPages from './../components/LinksInfoPages';
import locambioLogoDefualt from './../Assets/Logo/locambio-logoDefualt.svg';
import { Link } from 'react-router-dom';
import Faq from './home/components/Faq';

class AboutLoCambio extends Component {
  render() {
    return (
      <>
        <div className={"AboutLoCambio"}> 
          <div className={"pt-32 sm:pt-24 md:pt-4 lg:pt-4 xl:pt-4"}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <Card elevation={Elevation.ONE} className={"mb-4"}>
                    <div className={"mb-8 text-center"}>
                      <img
                        className={"w-1/2 md:w-1/3"}
                        src={locambioLogoDefualt}
                      />
                    </div>
                    <p className={"mb-4"}>
                      <strong>LoCambio.com</strong> es una red social web y
                      aplicación movil para iOs y Android gratuita que ofrece a
                      sus usuarios intercambiar sus bienes y artículos
                      personales a través de una plataforma amigable y sencilla,
                      brindando una experiencia única en su tipo.
                    </p>
                    <p className={"mb-4"}>
                      Sin membrecías, pagos ni comisiones de ningún tipo; los
                      numeros de publicaciones, cambios y propuestas son
                      ilimitados para los usuarios. Es un mercado innovador e
                      ilimitado donde la principal premisa del sitio es el
                      cambio o permuta de bienes.v
                    </p>
                  </Card>
                  {/* <div className={"m-0 mt-4 mb-4"}>
                    <h2 className={"text-center"}>Aqui lo explicamos</h2>
                  </div> */}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Player
                        playsInline
                        poster="https://res.cloudinary.com/dc0xwgwry/image/upload/v1579452694/locambio.com/coverWhatIs_cuhcw0.jpg"
                        src="https://res.cloudinary.com/dc0xwgwry/video/upload/v1579452382/locambio.com/Pre-Lanzamiento_Lo_Cambio-CORTO_j8gfdq.mp4"
                      />
                    </Col>
                  </Row>
                  <div className={"mt-4 mb-4"}>
                    <Card elevation={Elevation.ONE}>
                      <h2 className={"margin0 marginBottom-1em"}>
                        ¿Cómo funciona?
                      </h2>
                      <p className={"mb-4"}>
                        Muy sencillo, los usuarios ubican dentro de la red y
                        cerca de su locacion algun articulo que necesiten y
                        realizan propuestas de intercambio a los otros usuarios,
                        estos ultimos determinan si estan de acuerdo y se pauta
                        o no un intercambio. Cada usuario al realizar una
                        publicacion debe determinar cuestiones como: lugar para
                        el intercambio, valor del producto (referencia para
                        otros usuarios sobre la estimacion de valor que esa
                        personale da) y listar algunos articulos que necesite o
                        por lo que estaria dispuesto a cambiar ese bien; los
                        usuarios interactuan por medio de una mensajeria
                        instantanea sobre detalles adicionales.
                      </p>
                      <p className={"mb-4"}>
                        Unete a la red social donde conviven la oferta y demanda
                        de productos, se satisfacen necesidades y se pautan
                        precios, sin embargo, no se generan egresos en la
                        finanzas de ninguna de las partes involucradas en la
                        transaccion.
                        <br />
                        <br />
                        <strong className={"mr-4"}>
                          ¡Aquí todos tenemos algo que cambiar!
                        </strong>
                        <span classNamew={"w-full"}>
                          <Link to="/registration">
                            <AnchorButton rightIcon="new-person" minimal={true}>
                              Registrarme
                            </AnchorButton>
                          </Link>
                        </span>
                      </p>
                    </Card>
                  </div>
                  <div className={"mt-4 mb-4"}>
                    <Card elevation={Elevation.ONE}>
                      <h2 className={"mb-2"}>Valores</h2>
                      <p className={"mb-8"}>
                        Humanización, innovación, equidad, confianza y
                        tecnología.
                      </p>
                      <h2 className={"mb-2"}>Misión</h2>
                      <p className={"mb-8"}>
                        Conectar personas para intercambiar bienes.
                      </p>
                      <h2 className={"mb-2"}>Visión</h2>
                      <p className={"mb-8"}>
                        Modificar el ciclo de vida de los productos,
                        intercambiar para renovar. Ser las más completa red
                        social de productos.
                      </p>
                      <h2 className={"mb-2"}>Compromiso</h2>
                      <p className={"mb-2"}>
                        <strong>
                          Emprendimiento latino, orientado a las personas y sus
                          cosas.
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <p>
                            Que la gente tenga lo que necesite y pueda dar lo
                            que ya no usa.
                          </p>
                        </li>
                        <li>
                          <p>
                            Que las cosas se re usen y reciclen, reduciendo el
                            consumo de la sociedad.
                          </p>
                        </li>
                        <li>
                          <p>
                            Promover el ahorro, generar bienestar y calidad de
                            vida a la comunidad.
                          </p>
                        </li>
                        <li>
                          <p>
                            Ser un mercado de personas y necesidades, no de
                            comerciantes.
                          </p>
                        </li>
                      </ul>
                    </Card>
                    <div className="mt-4 mb-4 rounded">
                      <Faq />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default AboutLoCambio;
