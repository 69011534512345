import React from 'react';
import {
    Card,
    Elevation,
    Callout,
    Icon,
    Button,
    AnchorButton,
    NonIdealState,
    Spinner,
    Divider
  } from "@blueprintjs/core";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import DetailResultAd from "../detailResultAd/detailResultAd";
import ItemAds from "../itemAds/ItemAds";

class AdsContent extends React.Component{
    render(){
        const {loadingAds, activeCampaña, actives, inactives, initAds, userBalance} = this.props;
        return !loadingAds ? (
            <Col xs={12} sm={6} md={9} lg={9}>
             {activeCampaña ?  <h4 className={"mt-2 mb-2"}>Listado de Campañas Activas</h4>: null}
              {actives.length > 0 && activeCampaña? (
                actives.map((ad, index) => (
                  <ItemAds data={ad} key={index} initAds={initAds} userBalance={userBalance}/>
                ))
              ) : (
                <Callout className={"noActivityDisplay marginBottom-1em"} style={{display: activeCampaña ? 'block' : 'none' }}>
                  <h5 className={"bp3-form-helper-text"}>No tienes Campañas Activas</h5>
                </Callout>
              )}
        
              {!activeCampaña ? <h4 className={"mt-2 mb-2"}>Listado de Campañas Finalizadas</h4> : null}
              {inactives.length > 0 && !activeCampaña ? (
                inactives.map((ad, index) => (
                  <ItemAds data={ad} key={index}/>
                ))
              ) : (
                <Callout className={"noActivityDisplay marginBottom-1em"} style={{display: !activeCampaña ? 'block' : 'none' }}>
                  <h5 className={"bp3-form-helper-text"}>
                    No tienes Campañas Finalizadas
                  </h5> 
                </Callout>
              )}
        
            </Col>
          ) : (
            <Spinner className={"mt-12 mb-12"} size={"32"} />
          );
    }
}

export default AdsContent;