import React, { Component } from 'react';
import {
  Button,
  Callout,
  Card,
  ControlGroup,
  Dialog,
  Elevation,
  FormGroup,
  HTMLSelect,
  Icon,
  InputGroup,
  Popover,
  Position,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import axios from 'axios';
import Footer from './../components/Footer';
import HeaderProfile from './../components/HeaderProfile';
import {
  helperTextsUser,
  touchedControlUser,
  validation,
} from './ownProfile/validations';
import {
  daysInMonth,
  getDateActual,
  getDayActual,
  getMonthActual,
  getYearActual,
  letters,
  minCharacter,
  monthsOptions,
  numbers,
  spaceValidation,
  thereNumber,
  thereSpecialChar,
  thereUpper,
} from '../utilityFunctions';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import moment from 'moment';
import HeaderProfileSkeleton from './../components/HeaderProfileSkeleton';
import * as actions from './../store/actions/index';
import {
  countries,
  countriesCode,
  provincesColombia,
  provincesPeru,
  provincesVenezuela,
} from 'const';
import './ownProfile/OwnProfile.scss';
import { Toast } from '../utility/toast';

const countriesOptions = countries.map(({ description, value }) => ({
  label: description,
  value,
}));
countriesOptions.unshift({
  label: 'Seleccionar',
  disabled: true,
  selected: true,
});

const renderLabelProvince = (value) => {
  if (value === countriesCode.VENEZUELA) {
    return '';
  } else if (value === countriesCode.PERU) {
    return '';
  } else if (value === countriesCode.COLOMBIA) {
    return '';
  }
};

class OwnProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialLoading: true,
      focusPassword: false,
      typeDNI: null,
      day: '',
      month: '',
      year: '',
      years: [{ label: 'Año', value: '', disabled: true, selected: true }],
      months: [{ label: 'Mes', value: '', disabled: true, selected: true }],
      days: [{ label: 'Dia', value: '', disabled: true, selected: true }],
      saving: false,
      formValid: false,
      typeImgWorking: null,
      dataURLImgProfile: null,
      dataURLImgCover: null,
      visibleOldPassword: false,
      states: [{ label: ``, disabled: true, selected: true }],
      labelProvince: '',
    };
    this.TYPES_IMG_WORKING = {
      PROFILE: 'PROFILE',
      COVER: 'COVER',
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem('userId');
    this.getUser(userId);
    this.setDateForm();
  }

  fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async (userId) => {
    try {
      const response = await this.fetchGetUser(userId);
      let user = { ...response.data.user };
      user.dni = response.data.user.dni.split('-')[1].trim('');
      user.gender = response.data.user.gender === 'Masculino' ? '1' : '0';
      user.old_password = '';
      user.password = '';
      user.confirmation_password = '';
      user.picture_data = null;
      user.picture_data_cover = null;
      if (user.picture_users.length > 0) {
        user.picture_data = 'fromAPI';
      }
      // if (user.picture_covers.length > 0) {
      //   user.picture_data_cover = "fromAPI";
      // }

      let dayOfBirth = 1;
      if (user.date_of_birth) {
        const splitDate = user.date_of_birth.split('-');
        const intDay = parseInt(splitDate[2]);
        if (intDay) {
          dayOfBirth = intDay;
        }
      }

      this.setState(
        {
          user,
          initialLoading: false,
          typeDNI: response.data.user.dni.split(' ')[0],
          year: user.date_of_birth.split('-')[0],
          month: user.date_of_birth.split('-')[1],
          day: dayOfBirth + '',
          formValid: validation(user, true).valid,
        },
        () => {
          if (user.country)
            this.onChangeCountry({ target: { value: user.country } });
          this.setDays();
        }
      );
    } catch (err) {
      console.log('error', err);
    }
  };

  setDateForm = () => {
    const months = monthsOptions;
    const date_actual = moment(getDateActual())
      .subtract(18, 'year')
      .format('YYYY');
    let years = [];
    years.push({ label: 'Año', value: '', disabled: true, selected: true });
    for (let i = 0; i < 75; i++) {
      let year = moment(date_actual).subtract(i, 'year').format('YYYY');
      years.push({ label: year, value: year });
    }
    this.setState({ years: years, months: months });
  };

  setDays = () => {
    if (
      !spaceValidation(this.state.month) ||
      !spaceValidation(this.state.year)
    ) {
      return;
    }
    const actualDay = getDayActual();
    const minYear = getYearActual() - 18;
    const actualMonth = getMonthActual();
    let days = [{ label: 'Dia', value: '', disabled: true, selected: true }];
    for (let i = 1; i <= daysInMonth(this.state.month, this.state.year); i++) {
      days.push({
        label: i,
        value: i,
        disabled:
          i > actualDay &&
          this.state.year == minYear &&
          this.state.month == actualMonth,
      });
    }
    this.setState({ days: days });
  };

  inputUserChangeHandler = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    let user = { ...this.state.user };
    user[name] = value;
    if (touchedControlUser.hasOwnProperty(name)) {
      touchedControlUser[event.target.id] = true;
    }
    const valid = validation(user, true);
    this.setState({ user: user, formValid: valid.valid });
  };

  inputChangeHandler = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.setDays();

        if (
          spaceValidation(this.state.year) &&
          spaceValidation(this.state.month) &&
          spaceValidation(this.state.day)
        ) {
          let user = { ...this.state.user };
          user[
            'date_of_birth'
          ] = `${this.state.year}-${this.state.month}-${this.state.day}`;
          this.setState({ user: user });
        }
      }
    );
  };

  onFocusPassword = () => {
    this.setState({ focusPassword: true });
  };

  onBlurPassword = () => {
    this.setState({ focusPassword: false });
  };

  // ENVIO DE DATOS AL SERVER
  fetchUpdateUser = (data) => {
    return axios.put(`${config.URL_API}/users/${this.props.userId}`, data, {
      headers: getHeader(),
    });
  };

  fetchUpdateImgProfile = (data) => {
    return axios.put(`${config.URL_API}/update_profile_photo`, data, {
      headers: getHeader(),
    });
  };

  fetchUpdateImgCover = (data) => {
    return axios.put(`${config.URL_API}/update_cover_photo`, data, {
      headers: getHeader(),
    });
  };

  fetchDeleteImgProfile = (data) => {
    return axios.put(`${config.URL_API}/delete_profile_photo`, data, {
      headers: getHeader(),
    });
  };

  saveData = async () => {
    const { user } = this.state;
    const data = {
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      dni: `${this.state.typeDNI} - ${user.dni}`,
      date_of_birth: user.date_of_birth,
      gender: user.gender,
      address: user.address,
      city: user.city,
      country: user.country,
      postal_code: user.postal_code,
      state: user.state,
      old_password: user.old_password,
    };
    if (user.password !== '' && !spaceValidation(user.old_password)) {
      data.password = user.password;
      this.setState({ visibleOldPassword: true });
      return;
    }

    if (user.password !== '') {
      data.password = user.password;
    }

    this.setState({ saving: true, visibleOldPassword: false });

    try {
      await this.fetchUpdateUser(data);

      let userc = { ...this.state.user };
      userc.old_password = '';
      this.setState({ user: userc, saving: true });

      if (user.picture_data === 'imgDeleted') {
        this.deleteImgProfile();
        return;
      }
      if (
        user.picture_data !== null &&
        user.picture_data !== 'fromAPI' &&
        user.picture_data !== 'imgDeleted'
      ) {
        this.saveImageProfile();
        return;
      }
      this.showMessageSuccess();
    } catch (err) {
      let message = 'Lo sentimos, hubo un error guardando los datos.';
      if (err.response.status === 404) {
        message = 'La contrasena que has introducido es incorrecta';
      }
      const toast = {
        message: message,
        icon: 'tick',
        intent: 'danger',
      };
      Toast.show(toast);
      let userc = { ...this.state.user };
      userc.old_password = '';
      this.setState({ saving: false, user: userc });
    }
  };

  deleteImgProfile = async () => {
    const { user } = this.state;
    const data = {
      id_user: this.props.userId,
    };

    try {
      await this.fetchDeleteImgProfile(data);
      this.showMessageSuccess();
      this.props.onCheckSession();
    } catch (err) {
      const toast = {
        message: 'Lo sentimos, hubo un error eliminando la foto de perfil',
        icon: 'tick',
        intent: 'danger',
      };
      Toast.show(toast);
      this.setState({ saving: false });
    }
  };

  saveImageProfile = async () => {
    const { user } = this.state;
    const data = {
      id_image: user.picture_users.length > 0 ? user.picture_users[0].id : null,
      id_user: this.props.userId,
      picture_data: [user.picture_data],
    };

    try {
      await this.fetchUpdateImgProfile(data);
      // if (
      //   user.picture_data_cover !== null &&
      //   user.picture_data_cover !== "fromAPI"
      // ) {
      //   this.saveImageCover();
      //   return;
      // }
      this.showMessageSuccess();
      this.props.onCheckSession();
    } catch (err) {
      const toast = {
        show: true,
        message: 'Lo sentimos, hubo un error guardando la foto de perfil',
        icon: 'tick',
        intent: 'danger',
      };
      Toast.show(toast);
      this.setState({ saving: false });
    }
  };

  saveImageCover = async () => {
    const { user } = this.state;
    const data = {
      // id_image:
      //   user.picture_covers.length > 0 ? user.picture_covers[0].id : null,
      id_user: this.props.userId,
      picture_data: [user.picture_data_cover],
    };

    try {
      await this.fetchUpdateImgCover(data);
      this.showMessageSuccess();
      this.props.onCheckSession();
    } catch (err) {
      const toast = {
        message: 'Lo sentimos, hubo un error guardando la foto de portada',
        icon: 'tick',
        intent: 'danger',
      };
      Toast.show(toast);
      this.setState({ saving: false });
    }
  };

  showMessageSuccess = () => {
    const toast = {
      message: 'Los cambios han sido guardados exitosamente',
      icon: 'tick',
      intent: 'success',
    };
    Toast.show(toast);
    this.setState({ saving: false });
  };

  hideOldPassword = () => {
    this.setState({ visibleOldPassword: false });
  };

  onChangeCountry = (event) => {
    // eslint-disable-next-line no-lone-blocks
    {
      const {
        target: { value },
      } = event;
      let values = [];
      if (value === countriesCode.VENEZUELA) {
        values = provincesVenezuela;
      } else if (value === countriesCode.PERU) {
        values = provincesPeru;
      } else if (value === countriesCode.COLOMBIA) {
        values = provincesColombia;
      }

      const options = values.map((province) => ({
        label: province,
        value: province,
      }));
      const provinceLabel = renderLabelProvince(value);
      this.setState({ provinceLabel });
      options.unshift({
        label: 'Seleccionar',
        value: '',
        disabled: true,
        selected: true,
      });
      this.setState({ states: options });
    }
  };

  render() {
    const {
      user,
      focusPassword,
      initialLoading,
      typeDNI,
      years,
      months,
      days,
      year,
      month,
      day,
      saving,
      formValid,
      visibleOldPassword,
    } = this.state;

    if (initialLoading) {
      return (
        <>
          <HeaderProfileSkeleton />
        </>
      );
    }

    return (
      <>
        <div className="Profile pt-12">
          <HeaderProfile
            initialLoading={initialLoading}
            user={user}
            TYPES_IMG_WORKING={this.TYPES_IMG_WORKING}
            deleteImgProfile={this.deleteImgProfile}
          />
          <div className="marginTop-1em">
            <Container>
              <Card elevation={Elevation.ONE} className="marginBottom-1em">
                <Callout className="marginBottom-1em">
                  <h3 className="margin0">Datos personales</h3>
                </Callout>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      helperText={helperTextsUser.name}
                      label="Nombre"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        placeholder="Nombre"
                        value={user.name}
                        onChange={this.inputUserChangeHandler}
                        onKeyPress={letters}
                        id="name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      helperText={helperTextsUser.last_name}
                      label="Apellidos"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        placeholder="Apellidos"
                        value={user.last_name}
                        onChange={this.inputUserChangeHandler}
                        onKeyPress={letters}
                        id="last_name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      helperText={helperTextsUser.email}
                      label="Correo electrónico"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        disabled={true}
                        placeholder="xxx@xxx.com"
                        value={user.email}
                        onChange={this.inputUserChangeHandler}
                        id="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      helperText={helperTextsUser.dni}
                      label="Cédula de identidad"
                      labelInfo="*"
                    >
                      <ControlGroup>
                        <HTMLSelect
                          id="typeDNI"
                          value={typeDNI}
                          onChange={this.inputChangeHandler}
                          options={[
                            { label: 'V', value: 'V' },
                            { label: 'E', value: 'E' },
                          ]}
                        />
                        {/* ----------- agregar E de extrangeros ----------- */}
                        <InputGroup
                          fill={true}
                          disabled={true}
                          placeholder={user.dni}
                          value={user.dni}
                          onChange={this.inputUserChangeHandler}
                          onKeyPress={numbers}
                          id="dni"
                        />
                      </ControlGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    {/* -------- Faltan los meses enero, mayo, julio, agosto, octubre y diciembre tiene 31 dias -------- */}
                    <FormGroup
                      label="Fecha de nacimiento (mayor de 18 años)"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <HTMLSelect
                        className="marginRight-05em"
                        id="year"
                        options={years}
                        onChange={this.inputChangeHandler}
                        value={year}
                      />
                      <HTMLSelect
                        className="marginRight-05em"
                        id="month"
                        options={months}
                        onChange={this.inputChangeHandler}
                        value={month}
                      />
                      <HTMLSelect
                        id="day"
                        options={days}
                        onChange={this.inputChangeHandler}
                        value={day}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup label="Género" labelInfo="*">
                      <div id="genderRadio">
                        <RadioGroup
                          selectedValue={user.gender}
                          onChange={this.inputUserChangeHandler}
                          inline={true}
                        >
                          <Radio label="Masculino" value="1" id="gender" />
                          <Radio label="Femenino" value="0" id="gender" />
                        </RadioGroup>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Callout>
                      <h4 className="margin0 marginBottom-1em">
                        Cambiar contraseña
                      </h4>
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Popover
                            isOpen={true}
                            content={
                              focusPassword ? (
                                <div className="popOverContent">
                                  <Callout
                                    icon="key"
                                    title="Tu contraseña debe contener"
                                  >
                                    <ul>
                                      <li>
                                        {!minCharacter(user.password) ? (
                                          <Icon
                                            className="iconRed"
                                            icon="cross"
                                          />
                                        ) : (
                                          <Icon
                                            className="iconGreen"
                                            icon="tick"
                                          />
                                        )}
                                        Al menos seis caracteres
                                      </li>
                                      <li>
                                        {!thereUpper(user.password) ? (
                                          <Icon
                                            className="iconRed"
                                            icon="cross"
                                          />
                                        ) : (
                                          <Icon
                                            className="iconGreen"
                                            icon="tick"
                                          />
                                        )}
                                        Al menos una mayúscula
                                      </li>
                                      <li>
                                        {!thereNumber(user.password) ? (
                                          <Icon
                                            className="iconRed"
                                            icon="cross"
                                          />
                                        ) : (
                                          <Icon
                                            className="iconGreen"
                                            icon="tick"
                                          />
                                        )}
                                        Al menos un caracter numérica
                                      </li>
                                      <li>
                                        {!thereSpecialChar(user.password) ? (
                                          <Icon
                                            className="iconRed"
                                            icon="cross"
                                          />
                                        ) : (
                                          <Icon
                                            className="iconGreen"
                                            icon="tick"
                                          />
                                        )}
                                        Al menos un caracter especial (!"#@?-_")
                                      </li>
                                    </ul>
                                  </Callout>
                                </div>
                              ) : null
                            }
                            position={Position.TOP_LEFT}
                          >
                            <FormGroup
                              label="Nueva contraseña"
                              labelFor="text-input"
                              labelInfo="*"
                            >
                              <InputGroup
                                placeholder="Nueva contraseña"
                                className="whiteInput"
                                value={user.password}
                                onChange={this.inputUserChangeHandler}
                                id="password"
                                type="password"
                                onFocus={this.onFocusPassword}
                                onBlur={this.onBlurPassword}
                              />
                            </FormGroup>
                          </Popover>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Popover
                            isOpen={true}
                            content={
                              focusPassword ? (
                                <div className="popOverContent">
                                  <Callout
                                    icon="key"
                                    title="Tu contraseña debe contener"
                                  >
                                    <ul>
                                      <li>
                                        {user.password ===
                                          user.confirmation_password &&
                                        spaceValidation(user.password) &&
                                        spaceValidation(
                                          user.confirmation_password
                                        ) ? (
                                          <>
                                            <Icon
                                              className="iconGreen"
                                              icon="tick"
                                            />
                                            Coincide
                                          </>
                                        ) : (
                                          <>
                                            <Icon
                                              className="iconRed"
                                              icon="cross"
                                            />
                                            No Coincide
                                          </>
                                        )}
                                      </li>
                                    </ul>
                                  </Callout>
                                </div>
                              ) : null
                            }
                            position={Position.TOP_LEFT}
                          >
                            <FormGroup
                              label="Confirmar nueva contraseña"
                              labelFor="text-input"
                              labelInfo="*"
                            >
                              <InputGroup
                                placeholder="Confirmar nueva contraseña"
                                className="whiteInput"
                                value={user.confirmation_password}
                                onChange={this.inputUserChangeHandler}
                                id="confirmation_password"
                                type="password"
                                onFocus={this.onFocusPassword}
                                onBlur={this.onBlurPassword}
                              />
                            </FormGroup>
                          </Popover>
                        </Col>
                      </Row>
                    </Callout>
                  </Col>
                </Row>
              </Card>
              <Card elevation={Elevation.ONE} className="marginBottom-1em">
                <Callout className="marginBottom-1em">
                  <h3 className="margin0">Dirección personal</h3>
                </Callout>
                <Row>
                  {/* {!props.hideMessageInformative ? */}
                  {/* : null
                   } */}
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      // helperText={props.helperText.address}
                      label="Dirección"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        className="whiteInput"
                        placeholder="Dirección"
                        id="address"
                        value={user.address}
                        onChange={this.inputUserChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup
                      // helperText={props.helperText.city}
                      label="Ciudad"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        className="whiteInput"
                        placeholder="Ciudad"
                        id="city"
                        value={user.city}
                        onChange={this.inputUserChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={4} lg={4}>
                    <FormGroup
                      label="Código postal"
                      // helperText={props.helperText.postal_code}
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        className="whiteInput"
                        placeholder="Código postal"
                        id="postal_code"
                        onKeyPress={numbers}
                        value={user.postal_code}
                        onChange={this.inputUserChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={3}>
                    <FormGroup label="País" labelFor="text-input" labelInfo="*">
                      <HTMLSelect
                        value={user.country}
                        id="country"
                        onChange={(event) => {
                          this.onChangeCountry(event);
                          this.inputUserChangeHandler(event);
                        }}
                        options={countriesOptions}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={3} md={3} lg={3}>
                    <FormGroup
                      // helperText={props.helperText.state}
                      label={this.state.labelProvince || 'Estado'}
                      labelInfo="*"
                    >
                      <ControlGroup>
                        <HTMLSelect
                          options={this.state.states}
                          id="state"
                          onChange={this.inputUserChangeHandler}
                          value={user.state}
                        />
                      </ControlGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Button
                    fill
                    className="BtnLCYellow"
                    loading={saving}
                    onClick={this.saveData}
                    disabled={!formValid}
                  >
                    Guardar cambios
                  </Button>
                </Col>
              </Row>
              <Footer />
              <div className="whiteSpace" />
            </Container>
          </div>
        </div>
        <Dialog isOpen={visibleOldPassword} onClose={this.hideOldPassword}>
          <Col xs={12} sm={6} md={6} lg={6}>
            <FormGroup
              label="Contraseña actual"
              labelFor="text-input"
              helperText={helperTextsUser.old_password}
              labelInfo="*"
            >
              <InputGroup
                placeholder="Contraseña actual"
                className="whiteInput"
                value={user.old_password}
                onChange={this.inputUserChangeHandler}
                id="old_password"
                type="password"
              />
            </FormGroup>

            <Button
              disabled={!spaceValidation(user.old_password)}
              onClick={this.saveData}
            >
              Guardar
            </Button>
          </Col>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckSession: () => dispatch(actions.checkSesion()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnProfile);
