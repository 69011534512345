import React from 'react';
import PropTypes from 'prop-types';
import { datelocambio } from '../../../../../utilityFunctions';
import ProductSwiper from '../../../../../components/productSwiper/ProductSwiper';
import { Callout, Card, Radio, Tag } from '@blueprintjs/core';

/**
 * @param {Array} publications, Records of publications
 * @param {Function} onSelected, It is executed when the button "Select publication pressed.
 */
const PublicationsList = ({ publications, onSelected }) => (
  <div className="Box2 publication-list mt-5 md:mt-0 mb-4">
    {publications.map(publication => (
      <Callout key={publication.id}>
        <Card className="p-2 m-1">
          <div className="flex">
            <div className="relative w-1/2">
              <ProductSwiper pictures={publication.pictures} needOverlay={false} />
            </div>
            <div className="relative pl-4 w-1/2	Half2">
              <div className="FechaPub">
                <h4>
                  <span className="float-right text-xs text-grey-dark mb-0 -mt-4">
                    {datelocambio(publication.publish_date)}
                  </span>
                </h4>
              </div>
              <div className="ProductDetailsContainer marginBottom-05em">
                <h1>{publication.title}</h1>
              </div>

              <div className="mb-2">
                <p>
                  <Tag
                    className="tag marginRight-05em marginBottom-05em"
                    minimal
                  >
                    {
                      publication.publication_type === '0' ?
                        'Servicio' :
                        publication.condition ?
                          'Usado' :
                          'Nuevo'
                    }
                  </Tag>
                </p>
              </div>
              <div className="optionsButton">
                <Radio
                  name="publication_id"
                  label="Elegir publicación"
                  value={publication.id}
                  onChange={(e) => (
                    onSelected({ name: 'publication_id', value: e.currentTarget.value})
                  )}
                />
              </div>
            </div>
          </div>
        </Card>
      </Callout>
    ))}
  </div>
);

PublicationsList.propTypes = {
  publications: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default PublicationsList;
