export const filterResult = (data, filterValues, initialValues) => {
  // si la distancia mayor es menor que 1km o la distance menor y mayor son iguales no habilitare este filtro.
  const disabledDistance =
    initialValues.distanceMax < 1 ||
    initialValues.distanceMax === initialValues.distanceMin;

  let filteredData = data.filter(
    pub =>
      filterByCondition(pub, filterValues.condition, filterValues) &&
      // filterByPrice(pub, filterValues.priceMin, filterValues.priceMax) &&
      filterByAttr(
        pub,
        filterValues.attrCategory.attr,
        filterValues.attrCategory.value
      ) &&
      filterByStatus(pub, filterValues.status) &&
      filterByDistance(pub, filterValues.distance, disabledDistance) &&
      filterByLocation(pub, filterValues.location)
  );

  return filteredData;
};

// const filterByPrice = (element, min, max) => {
//   return parseInt(element.price) >= min && parseInt(element.price) <= max;
// };

const filterByDistance = (element, distance, disabledDistance) => {
  if (disabledDistance) {
    return true;
  }
  return parseInt(parseInt(element.distance) / 1000) <= distance;
};

const filterByStatus = (element, status) => {
  if (status === "") {
    return true;
  }
  return element.product_status === status;
};

const filterByCondition = (element, condition, filterValues) => {
  if (!(filterValues.status === "Usado" && filterValues.condition !== "")) {
    return true;
  }
  return element.condition === condition;
};

const filterByLocation = (element, location) => {
  if (location === "") {
    return true;
  }
  return element.location === location;
};

const filterByAttr = (element, attr, value) => {
  if (attr === "") {
    return true;
  }
  const fieldCategory = element.publications_fields.find(
    field => field.id === attr
  );

  return fieldCategory && fieldCategory.value.toString() === value.toString();
};
