import axios from 'axios';
import { getHeader } from './../../system/Sesion';
import { config } from './../../system/Config';

import * as actionTypes from './actionTypes';

export const setAllData = data => {{
  return {
    type: actionTypes.SET_ALL_DATA,
    allData: data
  }
}}

export const setToastData = data => {
  return {
    type: actionTypes.SET_TOAST_DATA,
    allData: data
  }
}

export const setNotificationData = data => {
  return {
    type: actionTypes.SET_NOTIFICATION_DATA,
    allData: data
  }
}

export const setBubleData = data => {
  return {
    type: actionTypes.SET_BUBBLE_DATA,
    allData: data
  }
}

export const setUpdating = value => {
  return {
    type: actionTypes.SET_UPDATING,
    updating: value
  }
}

export const resetData = () => {
  return {
    type: actionTypes.RESET_DATA
  }
}

export const getAllData = (token) => {
  return dispatch => {
    axios.get(`${config.URL_API}/tlcdata?token=${token}`, {headers: getHeader()})
      .then((data) => {
        dispatch(setAllData(data.data))
        dispatch(setToastData(data.data))
        dispatch(setBubleData(data.data));
        dispatch(setNotificationData(data.data));
        dispatch(setUpdating(false));
      })
      .catch((err) => {
        console.log('error', err);
      })
  }
}