import React, { Component } from 'react';
import { Button, Callout, Drawer, Position } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import MediaQuery from 'react-responsive';
import SearchListResults from './searchList/SearchListResults';
import SearchListFilters from './searchList/SearchListFilter';
import SearchListPagination from './searchList/SearchListPagination';
import Footer from './../components/Footer';
import { TYPESEARCH } from 'utility';
import { config } from './../system/Config';
import { getHeader } from './../system/Sesion';
import { filterResult } from './searchList/searchListFilters/filter';
import { orderAttr, valuesForFilter } from './searchList/aux';
import axios from 'axios';
import { Redirect, withRouter } from 'react-router-dom';
import ProductSearchListSkeleton from './../components/ProductSearchListSkeleton';
import SearchListFilterSkeleton from './../components/SearchListFilterSkeleton';
import NotResults from './searchList/NotResults';
import { connect } from 'react-redux';
import Ads from './../components/ads/Ads';
import { provincesVenezuela, typeAds } from 'const';
import './searchList/SearchList.scss';
import debounce from '../utility/debounce';

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleFilterPanel: false,
      publications: { all: [], print: [], categories: [] },
      loading: true,
      filterInitialValues: {
        // valores iniciales para los filtros
        distanceMax: 10, // valor de la distancia mas lejos de la lista de pub,
        distanceMin: 0, // valor de la distancia menor de la lista de pub
        states: provincesVenezuela,
      },
      filterValues: {
        distanceMax: 100,
        distanceMin: 0,
        status: '',
        condition: '',
        location: '',
        attrCategory: { attr: '', value: '', displayValue: '' },
        filterReset: true,
      },
      incorrectSearch: false,
      pagination: {
        page: 1,
        per_page: 20,
        totalPages: 0,
      },
    };
    this.toSearch = this.toSearch.bind(this);
    this.fetchSearch = this.fetchSearch.bind(this);
    this.setValuesFilter = this.setValuesFilter.bind(this);
    this.slidePriceChangeHandler = this.slidePriceChangeHandler.bind(this);
    this.slideDistanceChangeHandler =
      this.slideDistanceChangeHandler.bind(this);
    this.statusConditionChangeHandler =
      this.statusConditionChangeHandler.bind(this);
    this.locationChangeHandler = this.locationChangeHandler.bind(this);
    this.attrCategoryChangeHandler = this.attrCategoryChangeHandler.bind(this);
    this.filter = this.filter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.getUrlAndData = this.getUrlAndData.bind(this);
    this.filterButtonAction = this.filterButtonAction.bind(this);
  }

  // Panel lateral
  handlerVisibiltyFilterPanel() {
    this.setState({ visibleFilterPanel: !this.state.visibleFilterPanel });
  }

  componentDidMount() {
    this.getUrlAndData(this.props.match.params);
  }

  componentWillReceiveProps(nextProps) {
    this.getUrlAndData(nextProps.match.params);
  }

  debounceSearch = debounce(() => {
    //TO DO add query here
  }, 1000);

  getUrlAndData(params) {
    const typeSearch = params.typeSearch;
    let url = '';
    const { page, per_page } = this.state.pagination;
    if (typeSearch === TYPESEARCH.TAGS || typeSearch === TYPESEARCH.PRICE) {
      url = `${config.URL_API}/search_publications?tags=${params.tags}&location=${params.location}&type=${params.type}&latitude=${params.latitude}&longitude=${params.longitude}&km=null&page=${page}&per_page=${per_page}&search_by=${params.search_by}&condition=${this.state.filterValues.condition}&status=${this.state.filterValues.status}`;
    } else if (typeSearch === TYPESEARCH.INTERST) {
      url = `${config.URL_API}/find_pub_ids?ids=${params.ids}&latitude=${params.latitude}&longitude=${params.longitude}&location=${params.location}&page=${page}&per_page=${per_page}&search_by=${params.search_by}&condition=${this.state.filterValues.condition}&status=${this.state.filterValues.status}`;
    } else if (params.query === 'category') {
      url = `${config.URL_API}/search_publications_by_category?category=${params.category}&latitude=${params.latitude}&longitude=${params.longitude}&page=${page}&per_page=${per_page}&search_by=${params.search_by}&condition=${this.state.filterValues.condition}&status=${this.state.filterValues.status}`;
    }
    this.toSearch(url);
  }

  // busuqeda por precio o etiquetas
  fetchSearch(url) {
    return axios.get(url, { headers: getHeader() });
  }

  async toSearch(url) {
    this.setState({ loading: true });
    try {
      const response = await this.fetchSearch(url);
      let publications = { ...this.state.publications };
      let pagination = { ...this.state.pagination };
      publications.print = response.data.data;
      publications.all = response.data.data;
      pagination.totalPages = response.data.totalPages;
      this.setState({
        publications: publications,
        loading: false,
        pagination: pagination,
      });

      if (response.data.data.length === 0) {
        return;
      }
      let categories = response.data.data.map((pub) => pub.product_category.id);
      publications.categories = categories.reduce(function (a, b) {
        if (a.slice(-1)[0] !== b) a.push(b); // slice(-1)[0] means last item in array without removing it (like .pop())
        return a;
      }, []);

      this.setValuesFilter(response.data.data);
    } catch (err) {
      console.log('error', err);
    }
  }

  setValuesFilter(data) {
    const values = valuesForFilter(data);
    let filterValues = { ...this.state.filterValues };
    filterValues.distance = values.distanceMax;
    filterValues.states = values.states;
    this.setState({ filterInitialValues: values, filterValues: filterValues });
  }

  filterButtonAction() {
    this.filter();
  }

  slidePriceChangeHandler(value) {
    let filterValues = { ...this.state.filterValues };
    filterValues.filterReset = false;
    this.setState({ filterValues: filterValues });
    this.debounceSearch();
  }

  slideDistanceChangeHandler(value) {
    let filterValues = { ...this.state.filterValues };
    filterValues.distance = value;
    filterValues.filterReset = false;
    this.setState({ filterValues: filterValues });
    this.debounceSearch();
  }

  statusConditionChangeHandler(key, value) {
    let filterValues = { ...this.state.filterValues };
    filterValues[key] = value;
    filterValues.filterReset = false;
    this.setState({ filterValues: filterValues });
    this.debounceSearch();
  }

  locationChangeHandler(value) {
    let filterValues = { ...this.state.filterValues };
    filterValues.location = value === 'Todos los estados' ? '' : value;
    filterValues.filterReset = false;
    this.setState({ filterValues: filterValues });
    this.debounceSearch();
  }

  attrCategoryChangeHandler(attr, value, displayValue) {
    let filterValues = { ...this.state.filterValues };
    filterValues.attrCategory.attr = attr;
    filterValues.attrCategory.value = value;
    filterValues.attrCategory.displayValue = displayValue;
    filterValues.filterReset = false;
    this.setState({ filterValues: filterValues });
    this.debounceSearch();
  }

  filter() {
    const publications = { ...this.state.publications };
    const dataFiltered = filterResult(
      publications.all,
      this.state.filterValues,
      this.state.filterInitialValues
    );
    publications.print = dataFiltered;
    this.setState({ publications: publications });
  }

  resetFilter() {
    let filterValues = {
      distance: this.state.filterInitialValues.distanceMax,
      status: '',
      condition: '',
      location: '',
      filterReset: true,
      attrCategory: { attr: '', value: '', displayValue: '' },
    };
    this.setState({ filterValues: filterValues }, () => this.filter());
    this.debounceSearch();
  }

  handlePageChange = (pageNumber) => {
    let pagination = { ...this.state.pagination };
    pagination.page = pageNumber;
    this.setState({ pagination: pagination }, () => {
      this.getUrlAndData(this.props.match.params);
    });
  };

  render() {
    const { visibleFilterPanel } = this.state;
    const {
      loading,
      filterInitialValues,
      filterValues,
      publications,
      incorrectSearch,
    } = this.state;
    const typeSearch = this.props.match.params.typeSearch;
    if (incorrectSearch) {
      return <Redirect to="/" />;
    }
    let attrCategories = [];

    if (publications.categories.length > 0) {
      attrCategories = orderAttr(publications.categories); // dame todas las caracteristicas o atributos de las publicaciones sin repetir
    }
    const publication_ids =
      publications.all.length > 0
        ? publications.all.map((pub) => pub.id).join(',')
        : null;

    return (
      <>
        <div
          className={
            'SearchList mt-16 md:mt-16 pt-12 sm:pt-12 md:pt-12 lg:pt-12 xl:pt-12'
          }
        >
          <div className="customWrapper">
            <Row>
              <Col xs={12} sm={12} md={4} lg={2}>
                <div
                  onClick={() => this.handlerVisibiltyFilterPanel()}
                  className="block md:hidden lg:hidden xl:hidden mt-0 mb-2 sm:mt-0 sm:mb-2 md:mt-0 md:mb-2 lg:mt-0 lg:mb-2 xl:mt-0 xl:mb-2"
                >
                  <Button
                    text="Filtros de búsqueda"
                    rightIcon="filter"
                    minimal
                    fill
                    className="flex items-center BtnLCPrimaryOutline"
                  />
                </div>
                <MediaQuery query="(min-device-width: 768px)">
                  {loading && <SearchListFilterSkeleton />}
                  {!loading && publications.all.length > 0 && (
                    <SearchListFilters
                      filterInitialValues={filterInitialValues}
                      filterValues={filterValues}
                      priceChangeHandler={this.slidePriceChangeHandler}
                      distanceChangeHandler={this.slideDistanceChangeHandler}
                      statusConditionChangeHandler={
                        this.statusConditionChangeHandler
                      }
                      locationChangeHandler={this.locationChangeHandler}
                      publications={publications.all}
                      attrCategoryChangeHandler={this.attrCategoryChangeHandler}
                      resetFilter={this.resetFilter}
                      changePriceMax={this.changePriceMax}
                      changePriceMin={this.changePriceMin}
                    />
                  )}
                </MediaQuery>
              </Col>
              <Col xs={12} sm={12} md={8} lg={10}>
                {typeSearch === TYPESEARCH.TAGS ? (
                  <Callout
                    className="marginBottom-1em"
                    intent="primary"
                    icon="search"
                    title="Búsqueda simple por Artículos o Servicios"
                  />
                ) : null}
                {typeSearch === TYPESEARCH.PRICE ? (
                  <Callout
                    className="marginBottom-1em"
                    intent="primary"
                    icon="search"
                    title="Búsqueda por rango de precios"
                  />
                ) : null}
                {typeSearch === TYPESEARCH.INTERST ? (
                  <Callout
                    className="marginBottom-1em"
                    intent="primary"
                    icon="search"
                    title="Búsqueda por intereses de cambio"
                  />
                ) : null}

                {/* SRP AD */}
                {publications.all.length > 0 && !loading && (
                  <Ads
                    typeAd={typeAds.positioning}
                    publication_ids={publication_ids}
                  />
                )}
                {!loading && publications.all.length > 0 ? (
                  <SearchListResults publications={publications.print} />
                ) : null}
                {!loading && publications.all.length === 0 ? (
                  <NotResults />
                ) : null}
                {loading ? (
                  <>
                    <ProductSearchListSkeleton /> <ProductSearchListSkeleton />
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {publications.print.length > 20 ? (
                <SearchListPagination
                  handlePageChange={this.handlePageChange}
                  totalPages={this.state.pagination.totalPages}
                  activePage={this.state.pagination.page}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="customWrapper">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>

        {/* PANEL FILTROS PARA MOBILE */}
        <Drawer
          title="Filtros de Búsqueda"
          isOpen={visibleFilterPanel}
          canOutsideClickClose
          hasBackdrop
          canEscapeKeyClose
          size={Drawer.SIZE_LARGE}
          position={Position.LEFT}
          autoFocus
          onClose={() => this.handlerVisibiltyFilterPanel()}
        >
          <div className="p-4 overflow-y-scroll">
            {loading ? <SearchListFilterSkeleton /> : null}
            {!loading && publications.all.length > 0 ? (
              <SearchListFilters
                filterInitialValues={filterInitialValues}
                filterValues={filterValues}
                priceChangeHandler={this.slidePriceChangeHandler}
                distanceChangeHandler={this.slideDistanceChangeHandler}
                statusConditionChangeHandler={this.statusConditionChangeHandler}
                locationChangeHandler={this.locationChangeHandler}
                attrCategories={attrCategories}
                publications={publications.all}
                attrCategoryChangeHandler={this.attrCategoryChangeHandler}
                resetFilter={this.resetFilter}
              />
            ) : null}
          </div>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default withRouter(connect(mapStateToProps)(SearchList));
