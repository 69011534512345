import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useHistory, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import PublicationsList from './adForm/step3/FormPositioning/PublicationsList/PublicationsList';
import { DateRangeInput } from '@blueprintjs/datetime';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';
import { numbers, priceToString } from 'utility';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import { Button, Card, Elevation, FormGroup, Icon, InputGroup, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { formatPrice } from '../utilityFunctions';
import PropTypes from 'prop-types';
import { Toast } from '../utility/toast';

const AdPositioningForm = ({ userBalance }) => {
  const date_today = new Date();
  let date_next_week = new Date();
  date_next_week.setDate(date_today.getDate() + 8);
  const { id } = useParams();
  const initialValues = {
    publication_id: id || 0,
    start_date: date_today,
    end_date: date_next_week,
    balance: '',
  };
  const [publications, setPublications] = useState([]);
  const [numberImpresions, setImpresionsNumber] = useState(0);
  const [values, setValues] = useState(initialValues);
  const history = useHistory();

  const save = async values => {
    if (values.publication_id === 0) {
      Toast.show({
        message: 'Elija una publication',
        intent: 'danger',
        icon: 'cross',
      });
      return;
    }

    if (!values.balance) {
      Toast.show({
        message: 'Introduzca un Presupuesto de campaña',
        intent: 'danger',
        icon: 'cross',
      });
      return;
    }

    if (values.balance <= 0) {
      Toast.show({
        message: 'Introduzca un monto superior a cero (0)',
        intent: 'danger',
        icon: 'cross',
        time: 3000,
      });
      return;
    }

    if (parseInt(values.balance) > userBalance) {
      Toast.show({
        message: 'Monto insuficiente',
        intent: 'danger',
        icon: 'cross',
      });
      return;
    }

    try {
      await axios.post(`${config.URL_API}/ads`, values, {
        headers: getHeader(),
      });
      Toast.show({
        message: 'El anuncio ha sido creado exitosamente',
        intent: 'success',
        icon: 'tick',
      });
      history.push('/publicidad_creada');
    } catch (err) {
      Toast.show({
        message: 'Lo sentimos, hubo un error.',
        intent: 'danger',
        icon: 'cross',
      });
    }
  };

  const onChangeDailyBudget = event => {
    const { name, value } = event.target;
    let transformedValue = value;
    if (value) {
      transformedValue = formatPrice(priceToString(value));
    }
    let impresions = value / 0.008;
    setImpresionsNumber(impresions);
    onChangeValue({ name: name, value: transformedValue });
  };

  const getPublications = async () => {
    try {
      const response = await axios.get(`${config.URL_API}/publications_owner_active`, {
        headers: getHeader(),
      });
      const publications = response.data;
      setPublications(publications);
    } catch (err) {
      throw err;
    }
  };

  const onChangeValue = async event => {
    let name, value;
    if (event.target) {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = event.name;
      value = event.value;
    }
    setValues({ ...values, [name]: value });
  };

  const onChangeDuration = value => {
    onChangeValue({ name: 'start_date', value: value[0] });
    onChangeValue({ name: 'end_date', value: value[1] });
  };

  useEffect(() => {
    getPublications();
  }, [id]);

  const {
    start_date,
    end_date,
    balance,
  } = values;

  const onSelectPublication = async event => {
    let name, value;
    if (event.target) {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = event.name;
      value = event.value;
    }
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="mt-32">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card elevation={Elevation.ONE}>
              <h1 className="text-lg sm:text-base">
                <span className="text-4xl sm:text-xl">Campaña de Posicionamiento</span>
              </h1>
              <PublicationsList
                publications={publications}
                onSelected={onSelectPublication}
                values={values}
              />
              <div className="mb-2 w-full inline-flex">
                <Tooltip2
                  content={
                    <p className="margin0">
                      Cuánto tiempo quieres que se publique tu anuncio.
                    </p>
                  }
                  position={Position.RIGHT}
                >
                  <Icon
                    className="mr-2 text-grey-darker"
                    icon="info-sign"
                  />
                </Tooltip2>
                <DateRangeInput
                  formatDate={date => moment(date)
                    .format('DD-MM-YYYY')}
                  value={[start_date, end_date]}
                  parseDate={str => new Date(str)}
                  onChange={onChangeDuration}
                />
              </div>
              <div className="mb-2 w-full inline-flex">
                <Tooltip2
                  content={
                    <p className="margin0">
                      Importe que invertirás en tu anuncio.
                    </p>
                  }
                  position={Position.RIGHT}
                >
                  <Icon
                    className="mr-2 text-grey-darker"
                    icon="info-sign"
                  />
                </Tooltip2>
                <FormGroup
                  label="Presupuesto de campaña"
                  labelFor="text-input"
                  labelInfo="(*)"
                >
                  <div className="w-full inline-flex">
                    <InputGroup
                      className="whiteInput w-full"
                      placeholder="$ USD"
                      value={balance}
                      onChange={onChangeDailyBudget}
                      onKeyPress={numbers}
                      name="balance"
                    />
                    <div className="ml-2">
                      <h5>Saldo:</h5>
                      <h3>USD&nbsp;{formatPrice(userBalance)}</h3>
                    </div>
                  </div>
                  <p className="bp3-form-helper-text">
                    Este saldo te permitirá realizar {numberImpresions} impresiones.
                  </p>
                </FormGroup>
              </div>
              <Button
                className="bp3-intent-primary bp3-fill BtnLCPrimary"
                disabled={false}
                icon="add"
                onClick={() => save(values)}
                rightIcon="tick"
                text="Crear Campaña"
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userBalance: state.auth.otherInfo.balance,
  };
};

AdPositioningForm.propTypes = {
  userBalance: PropTypes.string,
};

export default connect(mapStateToProps)(AdPositioningForm);
