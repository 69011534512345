import React, { Component } from "react";
import AvatarUser from '../../../components/AvatarUser';

import {
  Card,
  Elevation,
  Callout,
  Tag,
  Popover,
  Position,
  Icon,
  Divider
} from "@blueprintjs/core";
import "./activitySuccessfulChange/ActivitySuccessfulChange.scss";
import { Row, Col } from "react-grid-system";
import {
  productNameUrl,
  needOverlay,
  initialValue
} from "./../../../utilityFunctions";
import { config } from "./../../../system/Config";
import { getHeader } from "./../../../system/Sesion";
import ProductSwiper from "../../../components/productSwiper/ProductSwiper";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import OverlayPremium from "./../../../components/OverlayPremium";
import PublicationInterested from "./../activities/activitySuccessfulChange/PublicationInterested";
import flechas from "./img/flechas.png";

class ActivitySuccessfulChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      addressChange: "",
      loading: false,
    };
  }

  componentDidMount() {
    axios.get(
      `${config.URL_API}/transactions/${this.props.tran.id}`, 
      {headers: getHeader()}
    )
      .then(data => data.data)
      .then(data => this.setState({ data: data, loading: true }));
  }

  render() {
    const { loading } = this.state;
    return (
      loading && <> <div className="ActivitySuccessfulChange">
          <Card elevation={Elevation.ONE} className="marginTop-1em">
            <div
              className={
                "changeDesignLabel gradientBackgroundAnimated marginBottom-1em"
              }
              >
              <h2 className={"margin0"}>
                <Icon
                  className={"margin0 marginRight-05em"}
                  icon={"social-media"}
                  />
                Cambio exitoso
              </h2>
            </div>
            <Divider className={"marginTop-1em marginBottom-1em"} />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="headerUserCard">
                  <div className="AvatarContainer">
                    <AvatarUser user={this.state.data.publication.owner} />
                  </div>
                  <div className="UserDetailsContainer marginBottom-05em">
                    <div className={"popOverUserInfoContainer"}>
                      <Popover
                        interactionKind={"hover"}
                        position={Position.LEFT_TOP}
                      >
                        <>
                          <h4>
                            {`${this.state.data.publication.owner.name} ${this.state.data.publication.owner.last_name}`}
                          </h4>
                          <p className="bp3-form-helper-text">
                            Cambió exitosamente su publicación{" "}
                            <span>{`${this.state.data.publication.name}`}</span>{" "}
                          </p>
                        </>
                      </Popover>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={'block'}>
              <div className={'bg-gray-100 mb-2'}>
                <Callout>
                  <div className="NewPublicationTypeContainer">
                    <div className={"flex mb-2"}>
                      <div className={'w-2/5'}>
                        <div className="publicationDetailsContainer">
                          <h1 className={"m-0 pt-2 sm:pt-0 md:pt-0 text-base"}>
                            <Link
                              to={`/${productNameUrl(
                                this.state.data.publication.name
                              )}/${this.state.data.publication.id}`}
                            >
                              {this.state.data.publication.name}
                            </Link>
                          </h1>
                          { this.state.data.publication.publication_type !== "Servicio" && (
                              <div className={"mb-2"}>
                                <p className="bp3-form-helper-text margin0">
                                  Condición del artículo
                                </p>
                                <p>
                                  {(this.state.data.publication.product_status ===
                                  "Usado")  && (
                                    <Tag
                                      className="tag marginRight-05em marginBottom-05em"
                                      minimal={true}
                                    >
                                      Usado
                                    </Tag>
                                  )}
                                  {(this.state.data.publication.product_status ===
                                  "Nuevo")  && (
                                    <Tag
                                      className="tag marginRight-05em marginBottom-05em"
                                      minimal={true}
                                    >
                                      Nuevo
                                    </Tag>
                                  )}
                                  {(this.state.data.publication.product_status ===
                                  "Usado")  && (
                                    <Tag
                                      className="tag marginRight-05em marginBottom-05em"
                                      minimal={true}
                                    >
                                      {this.state.data.publication.condition}
                                    </Tag>
                                  )}
                                </p>
                              </div>
                            )
                          }
                        </div>
                        <div>
                            <div className="productImage">
                              {needOverlay(
                                this.state.data.publication.product_category,
                                this.props.suscription
                              ) ? (
                                <OverlayPremium />
                              ) : null}
                              <ProductSwiper
                                needOverlay={needOverlay(
                                  this.state.data.publication.product_category,
                                  this.props.suscription
                                )}
                                pictures={this.state.data.publication.pictures}  
                                isWall={true}
                              />
                            </div>                            
                        </div>
                        { this.state.data.publication_interested && (
                            <Card className={"padding-05em"}>
                              <div className="headerUserCard">
                                <div className="AvatarContainer">
                                  <AvatarUser user={this.state.data.publication.owner} />
                                </div>
                                <div className="UserDetailsContainer margin0">
                                    <h4
                                      className={"margin0"}
                                      >{`${this.state.data.publication.owner.name} ${this.state.data.publication.owner.last_name}`}</h4>
                                  <p className="bp3-form-helper-text margin0">
                                    {this.state.data.publication.owner.city}
                                  </p>
                                </div>
                              </div>
                            </Card>
                          )
                        }
                      </div>
                      <div className={'w-1/5 md:pl-2 self-center'}>
                        <img src={flechas} alt="cambio"/>
                      </div>
                      <div className={this.state.data.publication_interested ? 'w-2/5':'w-2/5 self-center'}>
                          {this.state.data.publication_interested ? (
                            <PublicationInterested
                              pub={this.state.data.publication_interested}
                              suscription={this.props.suscription}
                            />
                          ) : (
                            <Callout className={"marginBottom-1em"}>
                              <h3 className={"margin0 marginBottom-05em"}>
                                Lo cambió por:
                              </h3>
                              <Tag  className={"tagYellow"}>
                                {this.state.data.tag_change.tag}
                              </Tag> 
                            </Callout>
                          )}
                          <Card className={"padding-05em"}>
                            <div className="headerUserCard">
                              <div className="AvatarContainer">
                                  { this.state.data.publication_interested ? (
                                      <AvatarUser user={this.state.data.publication_interested.owner} />
                                    ) : (
                                      <AvatarUser user={this.state.data.tag_change.owner} />
                                    )
                                  }
                              </div>
                              <div className="UserDetailsContainer margin0">
                                <h4 className={"margin0"}>
                                  { this.state.data.publication_interested ? (
                                      `${this.state.data.publication_interested.owner.name} ${this.state.data.publication_interested.owner.last_name}`
                                    ) : (
                                      `${this.state.data.tag_change.owner.name} ${this.state.data.tag_change.owner.last_name}`
                                    )
                                  }
                                </h4>
                              </div>
                            </div>
                          </Card>
                      </div>
                    </div>
                  </div>
                </Callout>
              </div>
              <div className={'bg-gray-100'}>
                
              </div>
            </div>
          </Card>
        </div> 
      </> || <div>Hola</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    suscription: state.auth.suscription
  };
};

export default connect(mapStateToProps)(ActivitySuccessfulChange);
