import React from 'react';
import { initialValue } from '../utilityFunctions';
import './userStatsInfo/AvatarUser.scss';

const AvatarUser = ({ user, background, skeleton }) => {
  const showAvatar = user?.avatar && user?.avatar !== 'empty';
  const skeletonClass = skeleton ? 'bp3-skeleton' : '';

  // TODO: FIX BACK LOGIC WHEN THE USER REMOVE AVATAR IMAGE

  return (
    <>
      {showAvatar ? (
        background ?
          <div className={`AvatarImage ${skeletonClass}`} style={{ backgroundImage: `url(${user.avatar})` }} /> :
          <div className={`AvatarImage ${skeletonClass}`}>
            <img className="w-12 h-12" src={user.avatar} alt="avatarImage" />
          </div>
      ) : (
        <div className={`AvatarInitials ${skeletonClass}`}>
          <p className="text-xs">
            <span>{initialValue(user?.name)}</span>
            <span>{initialValue(user?.last_name)}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default AvatarUser;
