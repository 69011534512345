// FIXME DANIEL

import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import { Spinner } from '@blueprintjs/core';
import MediaQuery from 'react-responsive';
import BottomScrollListener from 'react-bottom-scroll-listener';
import UserInformation from './activitiesWall/UserInformation';
import Footer from './../components/Footer';
import { VIEW_FILTER_VALUES } from 'utility';
import { config } from './../system/Config';
import axios from 'axios';
import { connect } from 'react-redux';
import Activities from './activitiesWall/Activities';
import { getHeader } from './../system/Sesion';
import withCable from 'hocs/withCable';
import { typeAds, typeFormatsAds, typesActivities } from 'const';
import Ads from 'common-component/ads/Ads.jsx';
import './activitiesWall/ActivitiesWall.scss';

class ActivitiesWall extends Component {
  // cable connection
  cableConnection = null;
  publications = []; // publicaciones
  transactionsCompleted = []; // cambios completados
  shares = []; // las cosas que se comparten
  page = 1; // pagina
  generalPage = 1;
  per_page = 18;
  totalPages; // total de paginas
  todaInfo = {
    all: [],
    print: [],
  };

  timeOutGetData;

  state = {
    allData: {
      all: [],
      print: [],
    }, // en este arreglo guardare toda la data que se muestra en el mural (cambios, publicaciones, compartidas, etc),
    activities: null,
    loadingAllData: true,
    newData: true,
    selectedPubForShare: false, // hay sleccionada una publicacion para compartir?
    filterControl: {
      // valores de cada uno de los filtros
      distance: 1000,
      view: VIEW_FILTER_VALUES.EVERYONE,
      date: '',
    },
    initialFilterValues: {
      distance: { min: 0, max: 0 },
    },
    distance: 1000,
    loadingMoreData: false,
    lastActivities: true,
    pageState: this.page,
    loadScrollEnable: true,
  };

  componentDidMount() {
    this.getData(false);
    this.getUser(this.props.userId);
    this.cableConnection = this.props.cableApp.subscriptions.create(
      { channel: 'WallChannel' },
      {
        received: ({ data, type_activity }) => {
          if (type_activity === 'SHARE') {
            this.shares.push(data);
          }
        },
      },
    );
  }

  componentWillUnmount() {
    this.cableConnection.unsubscribe();
  }

  async orderData() {
    let allData = {
      print: [],
      all: [],
    };

    const pubResp = await this.fetchGetPublications();
    const pubSponResp = await this.fetchGetPublicationsSponsored();
    const transResp = await this.fetchGetTransactions();
    let indexTrans = 0;
    let pubIndex = {}
    pubResp.data.forEach((value, index) => {
      pubIndex[value.id] = index
    })

    let counter = 0
    let pubSponCounterIndicator = [0, 1, 6, 7, 12]
    let transactionCounterIndicator = [5, 11, 18]

    let indexPP = 0
    let pubSpons = pubSponResp.data
    let ad = undefined

    pubResp.data.forEach((value, index) => {
      while (pubSponCounterIndicator.includes(counter) && indexPP < pubSpons.length) {
        let pI = pubIndex[pubSpons[indexPP].publication_id]
        ad = pubSpons[indexPP]
        let pubSpon = {
          id: `${typesActivities.PUBLICATION}${index}`,
          type: typesActivities.PUBLICATION,
          date: pubResp.data[pI].publish_date,
          value: pubResp.data[pI],
          sponsored: ad,
          counter: counter,

        };

        allData.print.push(pubSpon);
        allData.all.push(pubSpon);
        indexPP += 1
        counter += 1
      }

      let tvalue = transResp.data[indexTrans];
      if (transactionCounterIndicator.includes(counter) && tvalue) {
        if (!tvalue) {
          indexTrans = 0;
          tvalue = transResp.data[indexTrans];
        }
        let trans = {
          id: `${typesActivities.CHANGE_SUCCESSFULL}${indexTrans}`,
          type: typesActivities.CHANGE_SUCCESSFULL,
          date: tvalue?.updated_at,
          value: tvalue,
          counter: counter,
        };
        allData.print.push(trans);
        allData.all.push(trans);
        indexTrans++;
        counter += 1
      }
      ad = undefined
      let pub = {
        id: `${typesActivities.PUBLICATION}${index}`,
        type: typesActivities.PUBLICATION,
        date: value.publish_date,
        value: value,
        sponsored: ad,
        counter: counter,
      };

      allData.print.push(pub);
      allData.all.push(pub);
      counter === 18 ? counter = 0 : counter += 1
    });

    if (indexTrans <= transResp.data.length) {
      for (let i = indexTrans; i < transResp.data.length; i++) {
        const value = transResp.data[i];
        let change = {
          id: `${typesActivities.CHANGE_SUCCESSFULL}${i}`,
          type: typesActivities.CHANGE_SUCCESSFULL,
          date: value.updated_at,
          value: value,
        };
        allData.print.push(change);
        allData.all.push(change);
      }

    }

    this.todaInfo.all = [...allData.all];
    this.todaInfo.print = [...allData.print];
    allData.print = allData.print.slice(0, this.per_page);
    allData.all = allData.all.slice(0, this.per_page);
    this.loadNextPage(allData);
  };

  orderByDate = (array) =>
    array.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA < dateB ? 1 : -1;
    });

  /*NUEVO DATOS DE PUBLICACIONES*/
  fetchGetPublications = () => {
    return axios.get(
      `${config.URL_API}/publications?latitude=${localStorage.getItem(
        'latitude',
      )}
        &longitude=${localStorage.getItem('longitude')}
        &distance=${this.state.distance}
        &country=${this.props.userOtherInfo.country}`,
    );
  };

  fetchGetPublicationsSponsored = () => {
    return axios.get(
      `${config.URL_API}/v1/ads_wall?latitude=${localStorage.getItem(
        'latitude',
      )}
        &longitude=${localStorage.getItem('longitude')}
        &distance=${this.state.distance}`,
    );
  };

  /*NUEVO DATOS DE TRANSACCIONES*/
  fetchGetTransactions = () => axios.get(
    `${config.URL_API}/transactions?country=${this.props.userOtherInfo.country}`,
  );


  fetchGetUser = userId => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async userId => {
    try {
      const response = await this.fetchGetUser(userId);
      let user = { ...response.data.user };

      this.setState(
        { user },
      );
    } catch (err) {
      console.log('error', err);
    }
  };


  // espero que el server responda, y cuando responde ordeno la data, luego espero 3s y verifico si hay algo nuevo
  getData = async (newPage) => {
    try {
      if (newPage) {
        const totalPorvista = this.per_page * this.page;

        if (totalPorvista >= this.todaInfo.print.length) {
          this.setState({
            loadingAllData: false,
            loadingMoreData: false,
            loadScrollEnable: false,
          });
          return;
        }

        let allData = {
          print: this.todaInfo.print.slice(0, totalPorvista),
          all: this.todaInfo.all.slice(0, totalPorvista),
        };

        this.loadNextPage(allData);
      } else {
        this.orderData();
      }
    } catch (err) {
      throw err;
    }
  };

  filter = (distance) => {
    this.setState({ distance }, this.orderData);
  };

  sortActivitiesByDate = () => {
    let activities = this.state.activities;

    activities = activities.reverse();


    this.setState({ activities: activities });
  };

  handlerControlChange = (control, value) => {
    let filterControl = { ...this.state.filterControl };
    filterControl[control] = value;
    this.setState({ filterControl: filterControl }, () => this.filter());
  };

  sliderChangeHandler = (value) =>
    this.setState({ distance: value }, this.filter);

  pag = (event) => {
    if (this.state.loadingMoreData || this.state.loadingAllData) {
      return;
    }
    ++this.page;
    this.setState({ loadingMoreData: true }, () => {
      window.scrollTo(0, document.body.scrollHeight);
      setTimeout(() => {
        this.getData(true);
      }, 1000);
    });
  };

  loadNextPage(allData) {
    this.setState({
      allData,
      loadingAllData: false,
      loadingMoreData: false,
      activities: allData.print,
    });
  }

  render() {
    const {
      distance,
      loadingAllData,
      loadingMoreData,
      activities,
      user,
    } = this.state;
    return (
      <>
        <div className="wallContainer p-2 sm:p-2 PubsWall">
          <Row>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="relative">
                <div className="fixed fix-width-UserInformation md:mt-4">
                  <div className="absolute">
                    <MediaQuery query="(min-device-width: 768px)">
                      <div className="marginBottom-1em">
                        <UserInformation user={user}/>
                      </div>
                    </MediaQuery>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              {loadingAllData ? (
                <Spinner
                  className="marginTop-1em marginBottom-1em"
                  size={'32'}
                />
              ) : null}
              {activities !== null ? (
                <Activities
                  activities={activities}
                  typeFormatsAds={typeFormatsAds.wall}
                  typeAd={typeAds.display}
                  distance={distance}
                  history={this.props.history}
                />
              ) : null}
              {loadingMoreData ? (
                <Spinner
                  className="marginTop-1em marginBottom-1em"
                  size={'32'}
                />
              ) : null}
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="relative">
                <div className="fixed">
                  <MediaQuery query="(min-device-width: 768px)">
                    <Ads type={typeFormatsAds.mrec} typeAd={typeAds.display}/>
                    <Footer/>
                  </MediaQuery>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {this.state.loadScrollEnable ? (
          <BottomScrollListener onBottom={this.pag}/>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
    token: state.auth.token,
    userId: state.auth.userId,
    usersFollowing: state.auth.usersFollowing, // usuarios a los que esta siguiendo el usuario logueado.
    userOtherInfo: state.auth.otherInfo,
  };
};

export default connect(mapStateToProps)(withCable(ActivitiesWall));
