export const calculateSize = (value, step) => {
  const cal = value / step;
  if (cal === 0) {
    return 0.1;
  } else {
    return cal;
  }
};

export const valueByAttr = (publications, attrCategories) => {
  let valuesAttrCategories = [];
  publications.forEach(pub => {
    attrCategories.forEach(attr => {
      if (pub[attr.attr]) {
        const index = valuesAttrCategories.findIndex(
          e => e.value === pub[attr.attr]
        );
        if (index === -1) {
          valuesAttrCategories.push({
            value: pub[attr.attr],
            attr: attr.attr,
            displayValue: attr.display
          });
        }
      }
    });
  });
  return valuesAttrCategories;
};
