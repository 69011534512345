import React, { Component } from "react";
import {
  Card,
  Elevation,
  Button,
  Tag,
  Divider,
  Callout,
} from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";
import { Link, withRouter } from "react-router-dom";
import "./changeOperationStep3/ChangeOperationStep3.scss";
import QRCode from "qrcode.react";
import Footer from "./../../components/Footer";
import ProductSwiper from "../../components/productSwiper/ProductSwiper";
import { connect } from "react-redux";
import { needOverlay } from "./../../utilityFunctions";
import OverlayPremium from "./../../components/OverlayPremium";

class ChangeOperationStep3 extends Component {
  render() {
    const {
      nameOwner,
      pubURL,
      idTransaction,
      name,
      pictures,
      idChat,
      product_category,
    } = this.props;
    return (
      <>
        <div
          className={
            "ChangeOperationStep3 w-full md:w-3/5 pt-32 pb-32 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6 m-auto"
          }
        >
          <div className={"marginBottom-1em"}>
            <Button
              className={"marginRight-1em"}
              icon={"chevron-left"}
              minimal={true}
            >
              <Link to={pubURL}>Volver a la publicacion</Link>
            </Button>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Card elevation={Elevation.ONE}>
                <h3 className={"margin0 marginBottom-1em"}>
                Propuesta enviada a {nameOwner}
                </h3>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div className="h-full">
                      {needOverlay(product_category, this.props.suscription) ? (
                        <OverlayPremium />
                      ) : null}
                      <ProductSwiper
                        pictures={pictures}
                        needOverlay={needOverlay(
                          product_category,
                          this.props.suscription
                        )}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Callout>
                      <div className={"publicationName"}>
                        <h1 className={"margin0 marginBottom-1em"}>
                          <Link to={pubURL}>{name}</Link>
                        </h1>
                      </div>

                      <Card>
                        <Tag      
                          className={"marginBottom-1em"}
                          minimal={true}
                        >
                          ID de la transacción: <strong>{idTransaction}</strong>
                        </Tag>
                        <Callout>
                          <h5 className={"margin0 marginBottom-05em"}>
                            Código QR:
                          </h5>
                          <p>
                            <div className={"QRCodeContainer"}>
                              <QRCode value={idTransaction.toString()} />
                            </div>
                          </p>
                          <p
                            className={"bp3-form-helper-text marginBottom-1em mt-4"}
                          >
                            Pide al otro usuario que escanee el código desde su app móvil para finalizar el cambio
                          </p>
                        </Callout>
                      </Card>
                    </Callout>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Button
                      fill={true}
                      className={"BtnLCPrimary marginTop-1em"}
                      onClick={() => {
                        this.props.history.push(
                          `/chats/${idChat}`
                        );
                        // document.location.reload();
                      }}
                    >
                      Escribirle a {nameOwner}
                    </Button>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Link to="/requests">
                      <Button
                        fill={true}
                        className={"BtnLCPrimary marginTop-1em"}
                      >
                        Ir a cambios pendientes
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Footer />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default connect(mapStateToProps)(withRouter(ChangeOperationStep3));
