import React, { Component } from "react";
import { Card, Elevation, Callout, Button } from "@blueprintjs/core";
import "./creditCardList/CreditCardList.scss";
//import creditCardVisa from './../../Assets/System/creditCards/VISA-Light.png';
//import creditCardMasterCard from './../../Assets/System/creditCards/MasterCard-Light.png';
//import creditCardAmex from './../../Assets/System/creditCards/AMERICAN-EXPRESS-LIGHT.png';
import ImageCreditCard from "./ImageCreditCard";

class CreditCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCards: [],
    };
  }

  componentWillMount() {
    let arrayCC = [];
    this.props.creditCards.credit_card.forEach((element) => {
      arrayCC.push(element);
    });

    this.setState({ creditCards: arrayCC }, () => {
      arrayCC = null; //SE BORRA EL AUXILIAR EN MEMORIA
    });
  }

  render() {
    const { showDelete, showPay, disabledPay, payInProcess } = this.props;
    const { creditCards } = this.state;
    return (
      <>
        <div className={"CreditCardList"}>
          <Callout>
            <h3 className={"margin0 marginBottom-05em"}>
              Tarjetas de créditos registradas
            </h3>

            {creditCards.length === 0 ? (
              <Callout className={"noActivityDisplay marginBottom-1em"}>
                <h5 className={"bp3-form-helper-text"}>
                  No hay tarjetas de crédito registradas
                </h5>
              </Callout>
            ) : null}

            {creditCards.length > 0
              ? creditCards.map((card) => (
                  <Card
                    key={card.id_card}
                    interactive={true}
                    elevation={Elevation.ONE}
                    className={"marginBottom-05em"}
                  >
                    <div className="headerUserCard">
                      <div className="logoBrandCC marginRight-1em">
                        <ImageCreditCard type={card.operate} />
                      </div>
                      <div className="UserDetailsContainer marginBottom-05em">
                        <h4>XXXX - XXXX - XXXX - {card.last_digits}</h4>
                        <p className="bp3-form-helper-text">{card.full_name}</p>
                        <p className="bp3-form-helper-text">{card.due_date}</p>
                      </div>
                      <div className="UserDetailsContainer">
                        {showPay ? (
                          <Button
                            className={"marginRight-05em"}
                            
                            intent={"success"}
                            minimal={true}
                            icon={"credit-card"}
                            disabled={disabledPay}
                            loading={payInProcess}
                            onClick={this.props.pay}
                          >
                            Pagar
                          </Button>
                        ) : null}
                        {showDelete ? (
                          <Button
                            
                            intent={"danger"}
                            minimal={true}
                            icon={"trash"}
                            onClick={() =>
                              this.props.confirmDelete(card.id_card)
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  </Card>
                ))
              : null}
          </Callout>
        </div>
      </>
    );
  }
}

export default CreditCardList;

/*
const ImageCreditCard = props  => {
  console.log("imagenes de tarjetas de creditos ",props);
  const {type} = props;
  let img = null;
  switch (type) {
    case "Visa":
      img = <img src={creditCardVisa} alt="creditCard-visa" />;
      break;
    case "Master Card":
      img = <img src={creditCardMasterCard} alt="creditCard-masterCard" />;
      break;
    case "American Express":
      img = <img src={creditCardAmex} alt="creditCard-amex" />;
      break;
  }

  return img;
};

const CreditCardList = (props) => {
  const { creditCards, showDelete, showPay, disabledPay, payInProcess } = props;

  if (!creditCards) return null;

  console.log("tarjetas de creditos registradas ",creditCards);

  return (
    <>
      <div className={"CreditCardList"}>
        <Callout>
          <h3 className={"margin0 marginBottom-05em"}>
            Tarjetas de créditos registradas
          </h3>
          {creditCards.length === 0 ? (
            <Callout className={"noActivityDisplay marginBottom-1em"}>
              <h5 className={"bp3-form-helper-text"}>
                No hay tarjetas de crédito registradas
              </h5>
            </Callout>
          ) : null}

          {creditCards.length > 0 ?
          creditCards.map( card => (
            <Card key={card.id_card} interactive={true} elevation={Elevation.ONE} className={'marginBottom-05em'}>
              <div className="headerUserCard">
                <div className="logoBrandCC marginRight-1em">
                  <ImageCreditCard type={card.operate} />
                </div>
                <div className="UserDetailsContainer marginBottom-05em">
                  <h4>XXXX - XXXX - XXXX - {card.last_digits}</h4>
                  <p className="bp3-form-helper-text">{card.full_name}</p>
                  <p className="bp3-form-helper-text">{card.due_date}</p>
                </div>
                <div className="UserDetailsContainer">
                  {showPay ?
                    <Button 
                      className={'marginRight-05em'} 
                       
                      intent={'success'} 
                      minimal={true} 
                      icon={'credit-card'}
                      disabled={disabledPay}
                      loading={payInProcess}
                      onClick={props.pay}>Pagar</Button>
                    :
                    null
                  }
                  {showDelete ?
                    <Button 
                       
                      intent={'danger'} 
                      minimal={true} 
                      icon={'trash'}
                      onClick={() => props.confirmDelete(card.id_card)} />
                    : null
                  }
                </div>
              </div>
            </Card>
          )) : null}
        </Callout>
      </div>
    </>
  )
} */
