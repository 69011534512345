import React from 'react';
import { Callout, Card, Tag } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import Footer from './../components/Footer';
import axios from 'axios';
import { config } from './../system/Config';
import { getHeader } from './../system/Sesion';
import { connect } from 'react-redux';
import { isNewPublication, needOverlay, productNameUrl } from './../utilityFunctions';
import { Link, withRouter } from 'react-router-dom';
import ProductSwiper from '../components/productSwiper/ProductSwiper';
import OverlayPremium from './../components/OverlayPremium';
import AvatarUser from './../components/AvatarUser';
import RankingGraph from './../components/RankingGraph';
import RankingUserStar from './../components/RankingStar';
import * as actions from './../store/actions/index';
import ModalRanking from './../components/giveRanking/ModalRanking';

class ChangeSuccessfull extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      loading: true,
      change: {}
    };
  }

  componentDidMount() {
    if (!this.props.id && !localStorage.getItem("changeFinalizeId")) {
      this.props.history.push("/home");
    }
    this.getChange();
  }

  fetchGetChange() {
    
    if(localStorage.getItem("changeFinalizeId") !== null){
      return axios.get(`${config.URL_API}/transactions/${localStorage.getItem("changeFinalizeId")}`, {
        headers: getHeader()
      });
    }else{
      return axios.get(`${config.URL_API}/transactions/${this.state.id}`, {
        headers: getHeader()
      });
    }
  }

  async getChange() {
    
    try {
      const change = await this.fetchGetChange();
      this.setState({ change: change.data, loading: false });
    } catch (err) {
      console.log("err", err);
    }
  }

  componentWillUnmount() {
    this.props.onResetChange();
  }

  getPublication(change){
    
    return (
      <>
        <Card>
          <div>
          <div className="productsItemList marginBottom-05em">
                <div className="productAvatarContainer">
                  <div className="AvatarImage">
                    {change.publication_interested.pictures.length > 0 ? (
                      <img
                        src={change.publication_interested.pictures[0]?.url}
                        alt="avatarImage"
                      />
                    ) : null}
                  </div>
                </div>
                <div className="ProductDetailsContainer">
                  <h4 className={"margin0"}>{change.publication_interested.name}</h4>
                </div>
              </div>
          </div>
        </Card>
      </>
    );
  }

  render() {
    const { change, loading } = this.state;
    const { publication, interested_user } = change;

    if (loading) {
      return null;
    }

    return (
      <div className={"pt-24 sm:pt-24 md:pt-4 lg:pt-4 xl:pt-4"}>
        <ModalRanking
          change={change}
          typeRank="recibido"
        />
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Callout className={"mb-4"} intent={"success"} icon={"tick"}>
                  <h3>Cambio realizado exitosamente</h3>
                </Callout>
                <div className={"horizontalThumbanil"}>
                  <Row>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <h1
                        className={
                          "m-0 mb-2 text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl"
                        }
                      >
                        <Link
                          to={`/${productNameUrl(publication.name)}/${
                            publication.id
                          }`}
                        >
                          {publication.name}
                        </Link>
                      </h1>
                      <div className={"positionNewTag"}>
                        {isNewPublication(publication.publish_date) ? (
                          <Tag className="tagNew">Nueva publicación</Tag>
                        ) : null}
                      </div>
                      {needOverlay(
                        publication.product_category,
                        this.props.suscription
                      ) ? (
                        <OverlayPremium />
                      ) : null}
                      <Link
                        to={`/${productNameUrl(publication.name)}/${
                          publication.id
                        }`}
                      >
                        <ProductSwiper
                          pictures={publication.pictures}
                          needOverlay={needOverlay(
                            publication.product_category,
                            this.props.suscription
                          )}
                        />
                      </Link>
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={8}>
                      <div className={"InfoProductContainer"}>
                        <Callout>
                          <div className={"publicationName"}>
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6}>
                                <div className={"infoDetailPublication mb-2"}>
                                  <p className={"bp3-form-helper-text pb-1"}>
                                    Cambiado por:
                                  </p>
                                  {change.tags !== null ? 
                                  <Tag className={"tagYellow"} >
                                    {change.tags}
                                  </Tag> : this.getPublication(change)}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Callout>
                        <Callout
                          className={"mt-4 sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4"}
                        >
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <div className="headerUserCard">
                                <div className="AvatarContainer">
                                  <Link
                                    to={`/publications/third/${interested_user.id}`}
                                  >
                                    <AvatarUser user={interested_user} />
                                  </Link>
                                </div>
                                <div className="UserDetailsContainer marginBottom-05em">
                                  <h4>
                                    <Link
                                      to={`/publications/third/${interested_user.id}`}
                                    >{`${interested_user.name} ${interested_user.last_name}`}</Link>
                                  </h4>
                                  <p className="bp3-form-helper-text">
                                    {interested_user.address}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <RankingGraph user={interested_user} />
                              <RankingUserStar user={interested_user} />
                            </Col>
                          </Row>
                        </Callout>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    id: state.changeSuccess.idChange,
    suscription: state.auth.suscription
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetChange: () => dispatch(actions.resetChange())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangeSuccessfull));
