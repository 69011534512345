import React from "react";
import {
  Tag,
} from "@blueprintjs/core";
import {
  isNewPublication,
  productNameUrl,
  needOverlay,
} from "./../utilityFunctions";
import { Link } from "react-router-dom";
import "./Thumbnail.scss";
import OverlayPremium from "./OverlayPremium";

const Thumbnail = (props) => {
  const { suscription } = props;

  if (!props.pub.pictures[0] || props.pub.publication_status !== "Activo") {
    return null;
  }
  return (
    <Link to={`/${productNameUrl(props.pub.name)}/${props.pub.id}`}>
      <div className={"verticalThumbnail p-2 hover:shadow-lg"}>
        <figure className={"margin0 PubBoxHome"}>
          <div className="productImage">
            {/* <ProductSwiper pictures={props.pub.pictures} /> */}
            {/* <p>LA IMAGEN VA AQUI, ESTA RARO EL CSS, ABAJO ESTA LA ETIQUETA IMG</p> */}
            <img
              src={props.pub.pictures[0].url}
              alt="{props.pub.name}"
            />
            {needOverlay(props.pub.product_category, suscription) ? (
              <OverlayPremium />
            ) : null}
          </div>
          <div className="productImageControls bluredPremiumContent">
            <p className={"margin0 marginBottom-05em"}>
              {isNewPublication(props.pub.publish_date) ? (
                <Tag className="tagNew text-xs">Nueva publicación</Tag>
              ) : null}
            </p>
          </div>

          <div className={"TagBox"}>
            <div className={"block"}>
              <div className={"w-full mb-2 Cond"}>
                <p className="bp3-form-helper-text mb-2">
                  <span className={"ml-1"}>
                    <Tag
                      className="tag marginRight-05em marginBottom-05em text-xs"
                      minimal={true}
                    >
                      {props.pub.product_status}
                    </Tag>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className={"fixHeight--50px"}>
            <h4
              className={
                "VerticalThumbPublicationName margin0 marginBottom-05em text-base"
              }
            >
              {props.pub.name}
            </h4>
          </div>
        </figure>
      </div>
    </Link>
  );
};

export default Thumbnail;
