import React, { Component } from "react";
import {
  Card,
  Button,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import { Container, Row, Col } from "react-grid-system";
import { spaceValidation, emailValidation } from "./../utilityFunctions";
import { config } from "./../system/Config";
import { Link } from "react-router-dom";
import axios from "axios";
import "./forgotMyPassword/ForgotMyPassword.scss";
import Footer from "./../components/Footer";
import { Toast } from '../utility/toast';

class ForgotMyPassword extends Component {
  state = {
    email: "",
    loading: false,
    sendRequest: false
  };

  inputChangeHandler = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  sendToApi = () => {
    if (!this.validations()) {
      return;
    }

    this.setState({ loading: true });
    axios
      .post(`${config.URL_API}/send_mail_recovery_password`, {
        email: this.state.email
      })
      .then(data => {
        this.handlerResponseApi(data.data.response);
      })
      .catch(error => {
        console.error(error);
        Toast.show({message: 'Lo sentimos, hubo un error'})
        this.setState({ loading: false });
      });
  };

  handlerResponseApi = response => {
    switch (response) {
      case "email no exist":
        Toast.show({message: 'Este email no esta registrado.'})
        this.setState({loading: false });
        break;
      case "email sent":
        Toast.show({message: 'Listo, Recibirás un correo electrónico para restablecer la contraseña'})
        this.setState({
          loading: false,
          sendRequest: true,
        });
        break;
    }
  };

  validations = () => {
    let valid = true;
    if (!spaceValidation(this.state.email)) {
      Toast.show({message: 'Por favor escribe tu correo electrónico'});
      valid = false;
    } else if (!emailValidation(this.state.email)) {
      Toast.show({message: 'Por favor escribe un correo electrónico valido'})
      valid = false;
    }
    return valid;
  };

  requestKey = event => {
    if (event.which === 13) {
      this.sendToApi();
    }
  };

  render() {
    return (
      <>
        <section className="forgotPasswordSection mt-48 sm:mt-8 sm:pt-12 md:pt-12 lg:pt-12 xl:pt-12">
          <div className="gradientBackground gradientBackgroundAnimated"></div>
          <div className="forgotPasswordContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1 className="textAlignCenter textColorWhite text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl m-8 sm:m-10 md:m-10 lg:m-10 xl:m-10">
                    Escribe tu correo electrónico
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="marginBottom-1em">
                    <FormGroup
                      label="Correo Electrónico"
                      // helperText="Helper text with details..."
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        
                        placeholder="e-mail"
                        id="email"
                        value={this.state.email}
                        onKeyPress={this.requestKey}
                        onChange={this.inputChangeHandler}
                      />
                    </FormGroup>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          className="BtnLCYellow"
                          large="true"
                          loading={this.state.loading}
                          fill="true"
                          onClick={this.sendToApi}
                        >
                          {!this.state.sendRequest
                            ? "Solicitar cambio de contraseña"
                            : "Solicutud enviada"}{" "}
                        </Button>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Link to="/login">
                          <Button
                            className="BtnLCPrimaryLink marginTop-1em"
                            icon="arrow-left"
                            large="true"
                            fill="true"
                          >
                            Volver a ingresar
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={"mt-32"}>
                    <Footer />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default ForgotMyPassword;
