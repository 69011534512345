import React, { Component } from "react";
import { Card, Elevation } from "@blueprintjs/core";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import "./loCambioSeguro/LoCambioSeguro.scss";
import Footer from "./../components/Footer";
import LinksInfoPages from "./../components/LinksInfoPages";
import LoCambioSeguroLogo from "./../Assets/System/LC-Seguro-VerF.png";

class LoCambioSeguro extends Component {
  render() {
    return (
      <>
        <div className={"LoCambioSeguro pb-8"}>
          <div className={"pt-32 sm:pt-24 md:pt-4 lg:pt-4 xl:pt-4"}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <Card elevation={Elevation.ONE}>
                    <div className={"w-full md:w-1/3 m-4 LoCambioSeguroLogo"}>
                      <img
                        className={"w-full"}
                        src={LoCambioSeguroLogo}
                        alt={"logo loCambio.com Seguro"}
                      />
                    </div>
                    <h2 className={"margin0 marginBottom-1em"}>
                      LoCambio.com Seguro
                    </h2>
                    <p className={"mb-2"}>
                      Cambiar objetos con extraños puede producir
                      intranquilidad. El valor de nuestros artículos, la zona
                      donde se realizara el cambio o el rating del usuario con
                      el que cambiaremos pueden hacernos sentir inseguros
                      respecto a cambiar artículos personalmente, por eso
                      diseñamos LoCambio.com Seguro.
                    </p>
                    <p className={"mb-2"}>
                      Es un servicio opcional de Locambio.com que puedes
                      utilizar para cambiar de forma segura y sin
                      preocupaciones. Consiste en realizar los cambios en
                      ubicaciones designadas ideales, que; además de brindar
                      comodidad, cuentan con personal y equipos de vigilancia.
                      La idea es que los cambios se realicen a satisfacción de
                      ambas partes y según las reglas de Locambio.com.
                    </p>
                    <p className={"mb-2"}>
                      El servicio LoCambio.com Seguro puede ser solicitado por
                      quien publica el artículo o por quien hace la propuesta de
                      cambio. La persona que lo solicita deberá pagar el costo
                      del servicio que puede variar de una ciudad a otra. Al
                      solicitar un CambioSeguro, se le informa a la otra parte
                      interesada la locación a la que debe acudir para realizar
                      el cambio.
                    </p>
                    <p className={"mb-2"}>
                      <Link to="/registration">Regístrate</Link> para empezar a
                      usar LoCambio.com Seguro.
                    </p>
                    <p className={"mb-2"}>
                      Revisa la sección de{" "}
                      <Link to="/consejos_de_seguridad">
                        Consejos de Seguridad
                      </Link>{" "}
                      para las mejores prácticas.
                    </p>
                  </Card>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default LoCambioSeguro;
