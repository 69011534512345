export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_LOGGUED = 'AUTH_SET_LOGGUED';
export const SET_FOLLOWS = 'SET_FOLLOWS';
export const SET_USERS_FOLLOWERS = 'SET_USERS_FOLLOWERS';
export const SET_USERS_FOLLOWING = 'SET_USERS_FOLLOWING';
export const SET_PUB_SHARES = 'SET_PUB_SHARES';
export const GET_LIKES = 'GET_LIKES';
export const SET_LIKES = 'SET_LIKES';
export const SET_SESSION_VERIFED = 'SET_SESSION_VERIFED';
export const SET_PUBLICATIONS_SAVED = 'SET_PUBLICATIONS_SAVED';
export const GET_PUBLICATIONS_SAVED = 'GET_PUBLICATIONS_SAVED';

export const SET_POSITION = 'SET_POSITION';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_START = 'GET_COMMENTS_START';
export const FAIL_GET_COMMENTS = 'FAIL_GET_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const SAVE_COMMENT = 'SAVE_COMMENT';
export const SAVE_COMMENT_START = 'SAVE_COMMENT_START';
export const FAIL_SAVE_COMMENT = 'FAIL_SAVE_COMMENT';

export const SET_MESSAGES = 'SET_MESSAGES';
export const GET_MESSAGES = 'GET_MESSAGES';

export const SET_TAGS = 'SET_TAGS';
export const GET_TAGS = 'GET_TAGS';
export const SET_TRADE_TAGS = 'SET_TRADE_TAGS';
export const GET_TRADE_TAGS = 'GET_TRADE_TAGS';

export const GET_ALL_DATA = 'GET_ALL_DATA';
export const SET_ALL_DATA = 'SET_ALL_DATA';
export const SET_TOAST_DATA = 'SET_TOAST_DATA';
export const SET_BUBBLE_DATA = 'SET_BUBBLE_DATA';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const SET_UPDATING = 'SET_UPDATING';
export const RESET_DATA = 'RESET_DATA';

export const SET_TYPE_SEARCH = 'SET_TYPE_SEARCH';

export const SET_CHANGE_SUCCESS = 'SET_CHANGE_SUCCESS';
export const RESET_CHANGE_SUCCESS = 'RESET_CHANGE_SUCCESS';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const GET_REQUESTS = 'GET_REQUESTS';
export const SET_REQUESTS = 'SET_REQUESTS';
export const UPDATE_REQUESTS = 'UPDATE_REQUESTS';
export const SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION';

export const GET_CHATS = 'GET_CHATS';
export const SET_CHATS = 'SET_CHATS';
export const ADD_CHAT = 'ADD_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const REMOVE_CHAT = 'REMOVE_CHAT';

export const SET_IMAGE_TRANSLATE = 'SET_IMAGE_TRANSLATE';
export const SET_SRC = 'SET_SRC';

export const SET_SCROLL_TO = 'SET_SCROLL_TO';

export const ADD_DRAWER = 'ADD_DRAWER';
export const REMOVE_DRAWER = 'REMOVE_DRAWER';
export const UPDATE_DRAWER = 'UPDATE_DRAWER';

export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';
