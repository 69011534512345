import React, { Component } from 'react';
import { Tab, Tabs, Tag } from '@blueprintjs/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import ActivatedPublications from './myPublications/ActivatedPublications';
import SuccessfulChanges from './myPublications/SuccessfulChanges';
import DesactivatedPublications from './myPublications/DesactivatedPublications';
import MyPublicationsSkeleton from './../components/MyPublicationsSkeleton';
import HeaderProfileSkeleton from './../components/HeaderProfileSkeleton';
import HeaderProfile from './../components/HeaderProfile';
import MediaQuery from 'react-responsive';
import './myPublications/MyPublications.scss';
import { Toast } from '../utility/toast';

class MyPublications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      headerUser: {},
      initialLoading: true,
      publications: {
        actives: [],
        inactives: [],
        finalizeds: [],
      },
      updating: false,
      selectedTabId: 'ActivatedPublications',
    };
    this.PUBLICATION_STATUS = {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
      FINALIZED: 'Finalizado',
    };
  }

  componentDidMount() {
    this.getPublications();
  }

  componentWillMount() {
    const userId = localStorage.getItem('userId');
    this.getUser(userId);
  }

  fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async (userId) => {
    try {
      const response = await this.fetchGetUser(userId);
      let user = { ...response.data.user };
      user.dni = response.data.user.dni.split('-')[1].trim('');
      user.gender = response.data.user.gender === 'Masculino' ? '1' : '0';
      user.old_password = '';
      user.password = '';
      user.confirmation_password = '';
      user.picture_data = null;
      user.picture_data_cover = null;
      if (user.picture_users.length > 0) {
        user.picture_data = 'fromAPI';
      }

      let dayOfBirth = 1;
      if (user.date_of_birth) {
        const splitDate = user.date_of_birth.split('-');
        const intDay = parseInt(splitDate[2]);
        if (intDay) {
          dayOfBirth = intDay;
        }
      }

      this.setState({
        headerUser: user,
        initialLoading: false,
        typeDNI: response.data.user.dni.split(' ')[0],
        year: user.date_of_birth.split('-')[0],
        month: user.date_of_birth.split('-')[1],
        day: dayOfBirth + '',
      });
    } catch (err) {
      console.log('error', err);
    }
  };

  setToast(toast) {
    Toast.show(toast);
  }

  fetchGetPublications = () => {
    return axios.get(
      `${config.URL_API}/publications_owner?token=${localStorage.getItem(
        'tokenId'
      )}`,
      { headers: getHeader() }
    );
  };

  fetchUpdatePublication = (id, status) => {
    return axios.post(
      `${config.URL_API}/publications/update_status/${id}`,
      { publication_status: status },
      { headers: getHeader() }
    );
  };

  getPublications = async () => {
    try {
      const response = await this.fetchGetPublications();
      this.setPublications(response.data.publications);
    } catch (err) {
      this.showToastError();
    }
  };

  setPublications = (pubs) => {
    let publications = { ...this.state.publications };
    let actives = [];
    let inactives = [];
    let finalizeds = [];

    pubs.forEach((pub) => {
      switch (pub.publication_status) {
        case this.PUBLICATION_STATUS.ACTIVE:
          actives.push(pub);
          break;
        case this.PUBLICATION_STATUS.INACTIVE:
          inactives.push(pub);
          break;
        case this.PUBLICATION_STATUS.FINALIZED:
          finalizeds.push(pub);
          break;
        default:
          return [];
      }

      // eslint-disable-next-line no-unused-expressions
      pub.transactions?.forEach((transaction) => {
        if (
          transaction.complete === true &&
          pub.publication_type === 'Servicio'
        ) {
          finalizeds.push(pub);
        }
      });
    });

    publications.actives = actives;
    publications.inactives = inactives;
    publications.finalizeds = finalizeds;
    this.setState({ publications: publications, initialLoading: false });
  };

  desactivePub = async (id) => {
    this.setState({ updating: true });

    try {
      await this.fetchUpdatePublication(id, '1');
      let indexPub = this.state.publications.actives.findIndex(
        (pub) => pub.id === id
      );

      let publications = { ...this.state.publications };
      let actives = [...this.state.publications.actives];
      let inactives = [...this.state.publications.inactives];

      inactives.push(actives[indexPub]);
      actives.splice(indexPub, 1);

      publications.actives = actives;
      publications.inactives = inactives;

      const toast = {
        show: true,
        message: 'La publicacion ha sido inactiva exitosamente',
        icon: 'tick',
        intent: 'success',
      };
      this.setToast(toast);
      this.setState({ publications: publications, updating: false });
    } catch (err) {
      this.showToastError();
    }
  };

  activePub = async (id) => {
    this.setState({ updating: true });

    try {
      await this.fetchUpdatePublication(id, '0');
      let indexPub = this.state.publications.inactives.findIndex(
        (pub) => pub.id === id
      );

      let publications = { ...this.state.publications };
      let actives = [...this.state.publications.actives];
      let inactives = [...this.state.publications.inactives];

      actives.push(inactives[indexPub]);
      inactives.splice(indexPub, 1);

      publications.actives = actives;
      publications.inactives = inactives;

      const toast = {
        message: 'La publicacion ha sido activada exitosamente',
        icon: 'tick',
        intent: 'success',
      };
      this.setToast(toast);
      this.setState({ publications: publications, updating: false });
    } catch (err) {
      this.showToastError();
    }
  };

  showToastError = () => {
    const toast = {
      show: true,
      message: 'Lo sentimos, hubo un error',
      icon: 'cross',
      intent: 'danger',
    };
    this.setToast(toast);
    this.setState({ sendingReport: false });
  };

  tabChangeHandler = (tabId) => this.setState({ selectedTabId: tabId });

  render() {
    const {
      headerUser,
      initialLoading,
      publications,
      selectedTabId,
      updating,
    } = this.state;

    if (initialLoading) {
      return (
        <>
          <HeaderProfileSkeleton />
          <MyPublicationsSkeleton />
        </>
      );
    }

    return (
      <>
        <div className="customWrapper">
          <div className="pt-12">
            <HeaderProfile
              initialLoading={initialLoading}
              user={headerUser}
              editable={false}
            />
            <div className="container m-auto mt-8 mb-4 md:mb-32">
              <Tabs
                id="TabsExample"
                selectedTabId={selectedTabId}
                onChange={this.tabChangeHandler}
              >
                <Tab
                  id="ActivatedPublications"
                  panel={
                    <ActivatedPublications
                      pubs={publications.actives}
                      suscription={this.props.suscription}
                      status={this.PUBLICATION_STATUS.ACTIVE}
                      typesStatus={this.PUBLICATION_STATUS}
                      desactivePub={this.desactivePub}
                      updating={updating}
                    />
                  }
                  panelClassName="overflow-hidden"
                >
                  <p className="margin0 marginTop-05em marginBottom-05em">
                    <Tag minimal={true} className="tagYellow marginRight-1em">
                      {publications.actives.length}
                    </Tag>
                    <MediaQuery query="(min-device-width: 526px)">
                      Publicaciones Activas
                    </MediaQuery>
                    <MediaQuery query="(max-device-width: 525px)">
                      Activas
                    </MediaQuery>
                  </p>
                </Tab>

                <Tab
                  id="DesactivatedPublications"
                  title=""
                  panel={
                    <DesactivatedPublications
                      pubs={publications.inactives}
                      suscription={this.props.suscription}
                      status={this.PUBLICATION_STATUS.INACTIVE}
                      typesStatus={this.PUBLICATION_STATUS}
                      activePub={this.activePub}
                      updating={updating}
                    />
                  }
                  panelClassName="overflow-hidden"
                >
                  <p className="margin0 marginTop-05em marginBottom-05em">
                    <Tag minimal="true" className="tagYellow marginRight-1em">
                      {publications.inactives.length}
                    </Tag>
                    <MediaQuery query="(min-device-width: 526px)">
                      Publicaciones Inactivas
                    </MediaQuery>
                    <MediaQuery query="(max-device-width: 525px)">
                      Inactivas
                    </MediaQuery>
                  </p>
                </Tab>

                <Tab
                  id="SuccessfulChanges"
                  panel={
                    <SuccessfulChanges
                      pubs={publications.finalizeds}
                      suscription={this.props.suscription}
                      status={this.PUBLICATION_STATUS.FINALIZED}
                      typesStatus={this.PUBLICATION_STATUS}
                      updating={updating}
                    />
                  }
                  panelClassName="overflow-hidden"
                >
                  <p className="margin0 marginTop-05em marginBottom-05em">
                    <Tag minimal="true" className="tagYellow marginRight-1em">
                      {publications.finalizeds.length}
                    </Tag>
                    Historial
                  </p>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default connect(mapStateToProps)(MyPublications);
