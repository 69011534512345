import React from "react";
import { Dialog, Callout, Card, Elevation, Icon, Button } from "@blueprintjs/core";
import { Row, Col } from 'react-grid-system';
import axios from "axios";
import { getHeader } from "./../../system/Sesion";
import { config } from "./../../system/Config";
import Rating from "react-rating";
import { Toast } from '../../utility/toast';

class ModalRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueRanking: 0,
      loading: false,
      isOpen: true,
    }
  };
  
  fetchRanking = data => {
    return axios.put(`${config.URL_API}/ranke_user`, data, { headers: getHeader() });
  };
  
  handleClickRating = event => {
    this.setState({ valueRanking: event });
  };
  
  send = () => {
    if (this.props.change.status === 'accepted') {
      this.props.confirmTransaction()
    } else {
      this.props.completeTransaction()
    }
    
    this.setState({ loading: true }, async () => {
      const data = {
        token: localStorage.getItem('tokenId'),
        transaction_id: this.props.change.id,
        rank: this.state.valueRanking,
        qualified_user_id: this.props.typeRank === 'recibido' ? this.props.change.interested_id:this.props.change.publication.owner_id
      }
      try {
        await this.fetchRanking(data);
        this.setState({isOpen: false}, () => {
          if(this.props.successRanked) {
            this.props.successRanked();
          }
        });
      } catch (err) {
        Toast.show({
          message: err.response.data.errors_transaction,
          icon: 'cross',
          intent: 'danger'
        })
        this.setState({ loading: false });
      }
    });
    
  }
  
  render() {
    const { valueRanking, loading, isOpen } = this.state;
    const { change, typeRank, profile } = this.props;
    let owner = change.publication.owner ? change.publication.owner : change.interested_user;
    if(typeRank === 'enviado') {
      // owner = change.publication.owner;
      if(profile) {
        owner = profile.user;
      }
    }
    
    return (
      <>
      <Dialog
        isOpen={isOpen}
        className={'padding0'}
        title={`Último paso danos tu impresión de ${owner.name} ${owner.last_name}`}
        onClose={this.props.closeModal}
      >
        <div className={'padding-1em'}>
          <Callout >
            <h2 className={'margin0 marginBottom-05em'}>Rankea a {`${owner.name} ${owner.last_name}`}</h2>
            <p className={'margin0 marginBottom-1em'}>Califica como fue tu expeciencia de cambio {`${owner.name} ${owner.last_name}`}</p>
            <Card elevation={Elevation.ZERO} className={'padding-1em'}>
              <center>
                <Rating
                  initialRating={valueRanking}
                  stop={5}
                  onClick={this.handleClickRating}
                  emptySymbol={<Icon iconSize={'32'} icon={'star-empty'} />}
                  fullSymbol={<Icon iconSize={'32'} icon={'star'} />}
                />
              </center>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Button
                    className={'BtnLCPrimary marginTop-1em'}
                    fill={true}
                    onClick={this.send}
                    loading={loading}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Callout>
        </div>
      </Dialog>
      </>
    )
  }
}

export default ModalRanking;
