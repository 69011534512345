import React from "react";
import SwiperSkeleton from "./SwiperSkeleton";
import {
  Card,
  Elevation,
  Callout,
  Tag,
  Divider,
  Icon,
  Button,
  AnchorButton,
  ControlGroup,
  InputGroup,
} from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";

const DetailProductSkeleton = (props) => (
  <>
    <div className={"detailProduct pt-32 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6"}>
      <div className={"customWrapper"}>
        <Row>
          <Col xs={12} sm={12} md={9} lg={9}>
            <Card elevation={Elevation.ONE}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className={"positionNewTag"}>
                    <Tag className="bp3-skeleton">Nueva publicación</Tag>
                  </div>
                  <SwiperSkeleton />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className={"InfoProductContainer"}>
                    <Callout>
                      <div className={"publicationName"}>
                        <h1 className={`bp3-skeleton`}></h1>
                        <Divider className={"marginTop-1em marginBottom-1em"} />
                        <Tag className="bp3-skeleton" minimal={true}>
                          ID: <strong></strong>
                        </Tag>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p
                                className={"bp3-form-helper-text bp3-skeleton"}
                              >
                                Disponible para:
                              </p>
                              <Tag className={"bp3-skeleton"}>Cambio</Tag>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p
                                className={"bp3-form-helper-text bp3-skeleton"}
                              >
                                Precio
                              </p>
                              <Tag className={"tag bp3-skeleton"}>
                                <strong>
                                  {localStorage.getItem("currency")}
                                </strong>
                              </Tag>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p
                                className={"bp3-form-helper-text bp3-skeleton"}
                              >
                                Se encuentra a
                              </p>
                              <Tag className={"tag bp3-skeleton"}></Tag>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p
                                className={"bp3-form-helper-text bp3-skeleton"}
                              >
                                Fecha de creación
                              </p>
                              <Tag className={"tag bp3-skeleton"}></Tag>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Callout>
                  </div>
                  <Card elevation={Elevation.ONE} className={"marginTop-1em"}>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <div className={"infoDetailPublication"}>
                          <p className={"bp3-form-helper-text bp3-skeleton"}>
                            Lugar
                          </p>
                          <Tag className={"tag bp3-skeleton"}></Tag>
                        </div>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <div className={"infoDetailPublication"}>
                          <p className={"bp3-form-helper-text bp3-skeleton"}>
                            Condición del artículo
                          </p>
                          <Tag className={"tag marginBottom-05em bp3-skeleton"}>
                            Nuevo
                          </Tag>
                        </div>
                      </Col>
                      <Col xs={6} sm={6} md={12} lg={12}>
                        <div className={"infoDetailPublication"}>
                          <p className={"bp3-form-helper-text bp3-skeleton"}>
                            Categoria del producto
                          </p>
                          <Tag
                            className="marginRight-05em marginBottom-05em bp3-skeleton"
                            minimal={true}
                          >
                            <Icon icon="chevron-right bp3-skeleton" />
                          </Tag>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    elevation={Elevation.ONE}
                    className={"marginTop-1em marginBottom-1em"}
                  >
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <p className={"bp3-form-helper-text bp3-skeleton"}></p>
                        <h4
                          className={"bp3-form-helper-text bp3-skeleton"}
                        ></h4>
                      </Col>
                    </Row>
                  </Card>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="bp3-skeleton"></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card className={"marginTop-1em"}>
              <h3 className="bp3-skeleton">Descripción del producto</h3>
            </Card>
            <Card className={"marginTop-1em"}>
              <h3 className="bp3-skeleton">Hazle a las dudas que tengas</h3>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3}>
            <Card elevation={Elevation.ONE}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={"infoUserDetailPublication"}>
                    <div className={"infoUserDetailPublicationAvatar"}>
                      <div className="headerUserCard">
                        <div className="AvatarContainer">
                          <div className="bp3-skeleton"></div>
                        </div>
                        <div className="UserDetailsContainer marginBottom-05em">
                          <h4 className="bp3-skeleton">ffff</h4>
                          <p className="bp3-form-helper-text bp3-skeleton"></p>
                          <p className="bp3-form-helper-text bp3-skeleton">
                            Caracas
                          </p>
                          <p className={"marginTop-05em"}>
                            <AnchorButton
                              className={"marginRight-1em bp3-skeleton"}
                              minimal={true}
                            >
                              Ver Perfil
                            </AnchorButton>
                          </p>
                        </div>
                      </div>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div
                            style={{ height: "100%", width: "100%" }}
                            className="bp3-skeleton"
                          ></div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Callout className={"marginTop-1em"}>
                            <p
                              className={
                                "bp3-form-helper-text titleNumberOfChanges bp3-skeleton"
                              }
                            >
                              ggg
                            </p>
                            <h4 className={"numberOfChanges bp3-skeleton"}>
                              gggg
                            </h4>
                          </Callout>
                        </Col>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className={"marginTop-1em"}>
                              <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                  <Button
                                    className={"marginTop-1em bp3-skeleton"}
                                    fill={true}
                                    minimal={true}
                                    icon="heart"
                                    text={"Eliminar de Favoritos"}
                                  ></Button>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                  <Button
                                    className={"marginTop-1em bp3-skeleton"}
                                    minimal={true}
                                    fill={true}
                                    icon="chat"
                                    text={"Enviar Mensaje"}
                                  ></Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Button
                                    className={"marginTop-1em bp3-skeleton"}
                                    minimal={true}
                                    fill={true}
                                    icon="ban-circle"
                                    text={"Denunciar"}
                                  ></Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <div className={"marginTop-1em"}>
                        <h3 className="bp3-skeleton">Intereses de cambio</h3>
                        <Callout className={"marginTop-1em"}>
                          <h3 className="bp3-skeleton">
                            Categorias de interés
                          </h3>
                        </Callout>
                        <Callout className={"marginTop-1em"}>
                          <h3 className="bp3-skeleton">Artículos de interés</h3>
                        </Callout>
                        <Callout className={"marginTop-1em"}>
                          <h3 className="bp3-skeleton">
                            Hazle una propuesta de cambio
                          </h3>
                          <ControlGroup fill={true}>
                            <InputGroup
                              className={"whiteInput bp3-skeleton"}
                              placeholder="Enviar una propuesta"
                              id="tagChange"
                            />
                            <Button className={"bp3-skeleton"} icon="plus" />
                          </ControlGroup>
                          <Tag className={"marginTop-05em bp3-skeleton"}>
                            hhh
                          </Tag>
                          <Button
                            className={"marginTop-05em bp3-skeleton"}
                            fill={true}
                          >
                            Enviar propuesta
                          </Button>
                        </Callout>
                        <Callout className={"marginTop-1em"}>
                          <h3 className="bp3-skeleton">Lugar del Articulo</h3>
                          <div
                            id="mapPrincipal"
                            className={"changePointProduct bp3-skeleton"}
                          ></div>
                          <div
                            className={"infoDetailPublication bp3-skeleton"}
                          ></div>
                        </Callout>
                        <Button
                          fill={true}
                          className={"marginTop-1em bp3-skeleton"}
                        >
                          Lo Cambio
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card className={"marginTop-1em marginBottom-1em"}>
              <h3 className={"margin0 marginBottom-1em bp3-skeleton"}>
                Artículos que te pueden interesar
              </h3>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export default DetailProductSkeleton;
