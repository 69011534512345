import React, { Component } from 'react';
import { config } from '../../system/Config';
import {
  Alert,
  Button,
  Callout,
  Card,
  Collapse,
  FormGroup,
  InputGroup,
  Spinner,
} from '@blueprintjs/core';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import pointerMap from './../../Assets/System/pointerMap/pointerMap.png';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import Geocode from 'react-geocode';
import { Col, Row } from 'react-grid-system';
import './formPublication/FormPublication.scss';
import ModalCrop from './../../components/ModalCrop';
import TagsTLC from './../../TagsTLC';
import { connect } from 'react-redux';
import {
  formatPrice,
  getAllCategories,
  getAttr,
  getDateActual,
  getGCSUrl,
  lastElement,
  priceToString,
  productNameUrl,
  spaceValidation,
  typeCategories,
} from '../../utilityFunctions';
import { getHeader } from '../../system/Sesion';
import {
  helperTexts,
  resetStep3AndStep4,
  touchedControl,
  validationsStep3,
  validationsStep4,
} from './formPublication/validations';
import { transformData } from './formPublication/transformData';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import './../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Step2 from './formPublication/Step2';
import Step3 from './formPublication/Step3';
import Step5 from './formPublication/Step5';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CompleteUserData from '../detailProduct/CompleteUserData';
import ReactPixel from 'react-facebook-pixel';
import { Toast } from '../../utility/toast';

// formulario de publicacion (usado en new product y en edit product)
class FormPublication extends Component {
  edit = this.props.edit;
  acceptedFiles = 'image/*';
  tagsChange = new TagsTLC(); // tags de cambio
  tradeTags = new TagsTLC();
  tagsLabel = new TagsTLC(); // etiquetas
  state = {
    initialLoading: true,
    retryFilteredCategories: true,
    isOpen: false,
    crop: {
      unit: '%',
      width: 90,
      height: 90,
      x: 5,
      y: 5,
      disabled: true,
    },
    cropping: false,
    imgCropping: null,
    cropCurrent: null,
    indexImgCropping: null,
    formData: {
      id: 0,
      name: '',
      aditional_info: '',
      location: '',
      locations: [],
      product_categories_id: '',
      product_status: '',
      condition: '',
      address_1: 'Distrito Capital',
      address_2: '',
      article_tags: [],
      trade_tag: '',
      tags: [],
      year: '',
      price: '',
      publish_date: getDateActual(),
      token: window.localStorage.tokenId,
      publication_status: 'Pendiente',
      operation_type: 0,
      ref_price_min: '',
      ref_price_max: '',
      description: 'Descripción del prodcuto.',
      // images: "",
      coordinate: '',
      latitude: '',
      longitude: '',
      images: [],
      interestCategories: [],
      tagsFatherExchange: [],
      fields: [],
      product_id: '',
      new_product: '',
      subCategory: '',
      reference_price: '',
      accept_changes: '1',
    },
    categories: [],
    categorySelected: null,
    categoryAncestors: [],
    categorySelectedExchange: null,
    categorySelectedFatherExchange: null,
    subCategory: 'product',
    validationsStep: {
      three: false,
      for: false,
    },
    useTimeControl: [],
    useTime: '',
    messagesValidation: [],
    tradeTag: '',
    tagLabel: '',
    selectedPlace: {
      name: 'Caracas',
    },
    position: {
      lat: '',
      lng: '',
    },
    collapseControl: {
      isOpenStep2: true,
      isOpenStep3: false,
      isOpenStep4: false,
      isOpenStep5: false,
    },
    showAlert: false,
    alert: '',
    typePublication: null,
    useTimeLabel: {
      DAYS: 'Número de dia/s',
      MONTHS: 'Número de mes/es',
      YEARS: 'Número de año/s',
      '': 'Número de (dia/s,mes/es,año/s)',
    },
    canvasImagesCopy: [],
    showAlertConfirmation: false,
    goToStep: null,
    editorState: EditorState.createEmpty(),
    loading: false,
    publicationSaved: false,
    completeUserInfo: false,
    publication: {},
    loadStep3: false,
  };
  styles = {
    full: { width: '100%' },
    media: { width: '50%' },
    crop: { width: '330px', height: '330px' },
  };

  componentWillReceiveProps(nextProps) {
    const { countryUser } = nextProps;
    if (!countryUser && !this.state.completeUserInfo) {
      this.setState({ completeUserInfo: true });
    }
    this.setCategories(nextProps.categories.all);
    if (this.edit && this.state.retryFilteredCategories) {
      const id = this.props.match.params.id;
      this.getPublication(id, !nextProps.categories.length);
    }
  }

  fetchGetPublication = id => {
    return axios.get(`${config.URL_API}/publications/${id}`, {
      headers: getHeader(),
    });
  };

  getPublication = async (id, wait = !this.props.categories.length) => {
    if (wait) return this.setState({ ...this.state, retryFilteredCategories: true });
    try {
      const response = await this.fetchGetPublication(id);
      await this.setPublication(response.data.publication);
      const {
        data: {
          publication: { publication_status },
        },
      } = response;
      if (publication_status !== 'Activo') {
        return this.props.history.push('/');
      }
      this.setState({ initialLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  getUseTime = (use_time) => {
    const splitUseTime = use_time.split(' ');
    let unit;
    switch (splitUseTime[1]) {
      case 'Mes':
      case 'Meses':
        unit = 'MONTHS';
        break;
      case 'Año':
      case 'Años':
        unit = 'YEARS';
        break;
      default:
        unit = 'DAYS';
        break;
    }
    return { unit: unit, value: splitUseTime[0] };
  };

  findInterestCategories = (interestCategories) => {
    return interestCategories.split('|')
      .map((categories) => categories.split('/'))
      .map?.(
        (categories) => categories.reduce?.(
          (ancestors, category, index) => {
            const categoriesToUse = ancestors[index - 1]?.categories || this.props.categories;
            ancestors[index] = categoriesToUse.find?.((mainCategory) => mainCategory.name === category);
            return ancestors;
          },
          categories,
        ),
      );
  };

  setPublication = async (data) => {
    const {
      id, name, location, locations,
      product_categories_id, product_status, condition, address_1,
      address_2, article_tags, aditional_info,
      trade_tag, tags, product_category,
      year, price, publish_date,
      publication_status, publication_type,
      operation_type, editor_web,
      ref_price_min, ref_price_max, description,
      coordinate, latitude, longitude, pictures,
      tagsFatherExchange, exchange_interest_category, use_time,
      product_id, new_product, subCategory, reference_price, accept_changes,
    } = data;
    const currentPublicationType = publication_type === 'Servicio' ? 'service' : 'product';
    const categories = getAllCategories(product_category);

    data.exchange_interest_category.split(',')
      .forEach(category => {
        this.tagsChange.addTag(category);
      });

    data.article_tags.forEach(articles_tag => {
      this.tradeTags.addTag(articles_tag.name);
    });

    const fields = data.publications_fields.map(field => {
      const value = {};
      value.id = field.field_category_id;
      value.value = field.value;
      value.label = field.field_category.label;
      value.name = field.field_category.name;
      return value;
    });

    const images = await this.setImages(pictures);

    data.tags.forEach(tag => {
      this.tagsLabel.addTag(tag.name);
    });

    let useTime = this.getUseTime(use_time);

    this.setState(prevState => ({
      formData: {                   // object that we want to update
        ...prevState.formData,    // keep all other key-value pairs
        id: id,
        name: name,       // update the value of specific key
        subCategory: currentPublicationType,
        location: location,
        aditional_info: aditional_info,
        locations: locations || [],
        product_categories_id: product_categories_id,
        product_status: product_status,
        condition: condition,
        address_1: address_1,
        address_2: address_2,
        article_tags: article_tags || [],
        trade_tag: trade_tag || '',
        year: year,
        editor_web: editor_web,
        price: price,
        publish_date: publish_date,
        publication_status: publication_status,
        operation_type: operation_type,
        ref_price_min: ref_price_min,
        ref_price_max: ref_price_max,
        description: description,
        coordinate: coordinate,
        latitude: latitude,
        longitude: longitude,
        interestCategories: this.findInterestCategories(exchange_interest_category) || [],
        tagsFatherExchange: tagsFatherExchange || [],
        product_id: product_id,
        new_product: new_product,
        reference_price: reference_price,
        accept_changes: accept_changes,
        fields: fields,
        use_time: useTime.value,
        tags: tags,
        images,
      },
      subCategory: currentPublicationType,
      categorySelected: product_category,
      categoryAncestors: categories,
      retryFilteredCategories: false,
      editorState: editor_web ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(editor_web)),
      ) : EditorState.createEmpty(),
      useTime: useTime.unit,
    }));
  };

  componentDidMount() {
    const { countryUser } = this.props;
    if (!countryUser && !this.state.completeUserInfo) {
      this.setState({ completeUserInfo: true });
    }
    this.setCategories(this.props.categories.all);
    if (this.edit) {
      const id = this.props.match.params.id;
      this.getPublication(id);
    } else {
      this.setState({ initialLoading: false });
    }
  }

  setCategories = (categories) => {
    this.setState({
      categories: categories,
      typePublication: typeCategories.STANDARD,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        let formData = { ...this.state.formData };
        formData.editor_web = JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        );
        this.setState({ formData: formData });
      },
    );
  };

  onLoadDataStep = () => {
    if (!this.state.loadStep3) {

      let copyState = { ...this.state.formData };
      const validStep3 = validationsStep3(copyState, true, this.state);
      let validationsStep = { ...this.state.validationsStep };
      validationsStep['three'] = validStep3.valid;
      this.setState({
        messagesValidation: validStep3.messages,
        validationsStep: validationsStep,
        loadStep3: true,
      });
    }
  };

  getDataImage = async (url) => {
    const result = await fetch(getGCSUrl(url));
    const blob = await result.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  setImages = (images) => {
    return Promise.all(images.map(async (image) => (await this.getDataImage(image.url || image))));
  };

  // cuando el crop es completado pinto la imagen en el canvas
  onCropComplete = (canvas) => {
    if (!canvas) return;
    let publicationState = { ...this.state.formData };
    publicationState.images[this.state.indexImgCropping] = canvas.toDataURL();
    const { valid } = validationsStep3(publicationState, false, this.state);
    const validationsStep = { ...this.state.validationsStep, three: valid };
    this.setState({
      cropping: false,
      imgCropping: null,
      indexImgCropping: null,
      formData: publicationState,
      validationsStep,
    });
  };

  // cuando se cancela el crop
  cancelCrop = () => {
    this.setState({ cropping: false, imgCropping: null, indexImgCropping: null });
  };

  // los otros input
  inputGeneralChangeHandler = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () =>
      validationsStep3(this.state.formData, false, this.state),
    );
  };

  // solo los campos asociados directamente a la "publicacion"
  // me refiero a la publicacion como el "formData" del state
  inputChangeHandler = (event) => {
    let copyState = { ...this.state.formData };
    copyState[event.target.id] = event.target.value;
    this.setState({ formData: copyState });

    if (touchedControl.hasOwnProperty(event.target.id)) {
      touchedControl[event.target.id] = true;
    }
    const validStep3 = validationsStep3(copyState, false, this.state);
    let validationsStep = { ...this.state.validationsStep };
    validationsStep['three'] = validStep3.valid;
    this.setState({
      messagesValidation: validStep3.messages,
      validationsStep: validationsStep,
    });
  };

  inputBlurHandler = (event) => {
    const identifier = event.target.id;
    setTimeout(() => {
      this.state.formData[identifier].split(' ')
        .forEach((value) => {
          if (spaceValidation(value)) {
            this.addTagLabel(value, true);
          }
        });
    }, 3000);
  };

  updateFields = (fields) => {
    const formData = { ...this.state.formData };
    formData.fields = fields;
    this.setState({ formData });
  };

  // manejador para el drag an drop
  onDrop = (files, id = null) => {
    const lastIndex = this.state.formData.images.length;
    const idImageCropping = typeof id === 'number' ? id : lastIndex;
    if (files.length === 1) {
      this.setState({
        cropping: true,
        imgCropping: URL.createObjectURL(files[0]),
        cropCurrent: this.state.crop,
        indexImgCropping: idImageCropping,
      });
    }
  };

  // borra la imagen
  deleteImage = (id) => {
    const images = [...this.state.formData.images];
    images.splice(id, 1);
    const formData = { ...this.state.formData, images };
    const { valid } = validationsStep3(formData, false, this.state);
    const validationsStep = { ...this.state.validationsStep, three: valid };
    this.setState({ formData, validationsStep });
  };

  onDropRejected = (info) => {
    this.setState(
      {
        showAlert: true,
        alert:
          'Los formatos permitidos son: JPEG, PNG. La imagen no debe pesar mas de 15MB',
      },
      () =>
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 2500),
    );
  };

  // maneadjor para la cateogria seleccionada
  handlerCategorySelect = (categories) => {
    this.setState({
      categorySelected: lastElement(categories),
      categoryAncestors: categories,
    });
  };

  // Services
  handlerSubCategory = (event) => {
    const formData = { ...this.state.formData };
    formData.subCategory = event.target.value;
    this.setState({
      subCategory: event.target.value,
      categoryAncestors: [],
      categorySelected: null,
      formData,
    });
  };

  handlerPriceReference = (event) => {
    const copyState = { ...this.state.formData };
    copyState.reference_price = event.target.value;
    this.setState({
      formData: copyState,
    });
  };

  handleLocations = (locations) => {
    const copyState = { ...this.state.formData };
    copyState.locations = [];
    locations.forEach((location) => {
      copyState.locations.push(location.value);
    });

    this.setState({
      formData: copyState,
    });
  };

  // manejador para el tiempo de uso
  handlerUseTime = (event) => {
    let copyState = { ...this.state.formData };
    copyState['use_time'] = '';
    helperTexts['use_time'] = '';
    this.setState({ useTime: event.target.value, formData: copyState });
  };

  // esto son los tags de etiquetas
  addTagLabel = (value, notShowAlert) => {
    this.tagsLabel.addTag(value.split('')
      .splice(0, 25)
      .join(''));
    let formData = { ...this.state.formData };
    formData.tags = this.tagsLabel.getTag();
    this.setState({ formData: formData, tagLabel: '' });
  };

  // estos son los tags de cambio
  addTag = (value) => {
    this.tagsChange.addTag(value.split('')
      .splice(0, 25)
      .join(''));
    let formData = { ...this.state.formData };
    formData.article_tags = this.tagsChange.getTag();
    formData.trade_tag = '';
    this.setState({ formData: formData });
  };

  removeTag = (value, identifier) => {
    let formData = { ...this.state.formData };

    switch (identifier) {
      case 'article_tags':
        this.tagsChange.removeTag(value);
        formData[identifier] = this.tagsChange.getTag();
        break;
      case 'tags':
        this.tagsLabel.removeTag(value);
        formData[identifier] = this.tagsLabel.getTag();
        break;
      default:
        return;
    }
    this.setState({ formData: formData }, () =>
      validationsStep3(this.state.formData, false, this.state),
    );
  };

  removeExchangeTag = (tag) => {
    let formData = { ...this.state.formData };
    let pos = formData.interestCategories.indexOf(tag);
    formData.interestCategories.splice(pos, 1);
    const { valid } = validationsStep3(formData, false, this.state);
    const validationsStep = { ...this.state.validationsStep, three: valid };
    this.setState({ formData, validationsStep });
  };

  // estos son los tags de las categorias interesadas
  addTagExchange = (categories) => {
    let formData = { ...this.state.formData };
    const tagExist = formData.interestCategories.some((category) => {
      if (category.length !== categories.length) return false;
      return (
        Array(3)
          .fill()
          .every((_, i) => category[i]?.slug === categories[i]?.slug)
      );
    });

    if (tagExist) {
      this.setState(
        { showAlert: true, alert: 'No se puede repetir categorías de interés' },
        () =>
          setTimeout(() => {
            this.setState({ showAlert: false });
          }, 2500),
      );
      return;
    }
    formData.interestCategories.push(categories);
    const { valid } = validationsStep3(formData, false, this.state);
    const validationsStep = { ...this.state.validationsStep, three: valid };
    this.setState({ formData, validationsStep });
  };

  // cosas del mapa :)
  getAddress = (latitude, longitude) => {
    const position = { lat: latitude, lng: longitude };
    Geocode.setApiKey(config.KEY_GOOGLE_MAPS);
    Geocode.fromLatLng(latitude, longitude)
      .then(
        (response) => {
          const address = response.results[0].formatted_address;
          let copyState = { ...this.state.formData };
          copyState.address_1 = address;
          copyState.latitude = latitude;
          copyState.longitude = longitude;
          this.setState({ position, formData: copyState });
        },
        (error) => {
          console.error(error);
        },
      );
  };

  mapClicked = (mapProps, map, clickEvent, marker) => {
    // const position = {
    //   lat: clickEvent.latLng.lat(),
    //   lng: clickEvent.latLng.lng()
    // }
    // this.getAddress(position.lat, position.lng)
    // this.setState({ position: position })
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) =>
        this.setState({ position: latLng }, () =>
          this.getAddress(this.state.position.lat, this.state.position.lng),
        ),
      )
      .catch((error) => console.error('Error', error));
  };

  handleChange = (address) => {
    let copyState = { ...this.state.formData };
    copyState['address_1'] = address;
    touchedControl['address_1'] = true;
    this.setState({ formData: copyState });
    if (touchedControl.hasOwnProperty('address_1')) {
      touchedControl['address_1'] = true;
    }
    const validStep4 = validationsStep4(copyState, true);
    this.setState({ messagesValidation: validStep4.messages });
  };

  cancelConfirmationStep = () => {
    this.setState({ showAlertConfirmation: false, goToStep: null });
  };

  confirmationToStep = (goToStep) => {
    this.setState({ showAlertConfirmation: true, goToStep: goToStep });
  };

  confirmStep = () => {
    this.goBackToStep(this.state.goToStep);
    this.setState({ showAlertConfirmation: false, goToStep: null });
  };

  goBackToStep = (step) => {
    let copyState = { ...this.state.collapseControl };
    for (let i = 1; i <= 5; i++) {
      copyState[`isOpenStep${i}`] = false;
    }

    if (step == 1 || step == 2) {
      let formData = { ...this.state.formData };
      formData = resetStep3AndStep4(
        formData,
        this.state.categorySelected ? this.state.categorySelected.id : null,
      );
      this.setState({ formData: formData });
    }

    copyState[`isOpenStep${step}`] = true;
    this.setState({
      collapseControl: copyState,
      publicationSaved: false,
    });
  };

  goToStep3 = () => {
    let copyState = { ...this.state.collapseControl };
    copyState['isOpenStep3'] = true;
    copyState['isOpenStep2'] = false;
    this.setState({ collapseControl: copyState });
    window.scrollTo(0, 0); //POSICIONA LA VISTA ARRIBA CUANDO INICIA EL COMPONENTE
  };

  goToStep4 = () => {
    let copyState = { ...this.state.formData };
    const validStep3 = validationsStep3(copyState, false, this.state);
    let latitude = localStorage.getItem('latitude');
    let longitude = localStorage.getItem('longitude');
    // const setPosition = () => {
    //   this.getAddress(latitude, longitude);
    // };
    this.setState({ messagesValidation: validStep3.messages });
    if (validStep3.valid) {
      let copyState = { ...this.state.collapseControl };
      copyState['isOpenStep4'] = true;
      copyState['isOpenStep3'] = false;
      this.setState({ collapseControl: copyState }, () => this.getAddress(latitude, longitude));
    }
  };

  goToStep5 = () => {
    let map;

    const showMap = () => {
      setTimeout(() => {
        document.getElementById('mapResume')
          .appendChild(map);
      }, 2000);
    };

    let copyState = { ...this.state.formData };
    const validStep4 = validationsStep4(copyState, false);
    this.setState({ messagesValidation: validStep4.messages });
    if (validStep4.messages.length === 0) {
      map = document.getElementById('mapPrincipal');
      let copyState = { ...this.state.collapseControl };
      copyState['isOpenStep4'] = false;
      copyState['isOpenStep5'] = true;
      this.setState({ collapseControl: copyState }, () => showMap());
    }
  };

  fetchUpdatePublication = data => {
    return axios.post(
      `${config.URL_API}/publications/update/${data.id}`,
      data,
      { headers: getHeader() },
    );
  };

  sendToAPI = () => {
    const publication = transformData(this.state, this.props.countryUser);
    this.setState({ loading: true });

    ReactPixel.init('317028946379263', {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    });
    ReactPixel.pageView();
    ReactPixel.track('successful_publication', 'Successful publication');

    axios
      .post(`${config.URL_API}/publications${this.edit ? `/update/${publication.id}` : ''}`, publication, {
        headers: getHeader(),
      })
      .then(({ data }) => {
        Toast.show({
          intent: 'success',
          message: `La publicación ha sido ${this.edit ? 'modificada' : 'creada'} exitosamente`,
        })
        this.setState({
          loading: false,
          publicationSaved: true,
        });
        let publication = data;
        const urlPub = this.edit ? `/${productNameUrl(publication.title)}/${publication.id}` : '/publicacion_creada';
        this.props.history.push(urlPub);
      })
      .catch((error) => {
        Toast.show({
          intent: 'error',
          message: `Lo sentimos, hubo un error`,
        })
        this.setState({ loading: false, publicationSaved: false });
      });
  };

  render() {
    let alertConfirmation = null;
    const { address, city, postal_code, state } = this.props;
    const loading = this.state.initialLoading || !this.props.categories.length;

    if (this.state.completeUserInfo) {
      return (
        <CompleteUserData
          success={() => window.location.reload()}
          data={{ address, city, postal_code, state }}
          title="Pare realizar una publicación debes completar el registro"
        />
      );
    }

    if (this.state.showAlertConfirmation) {
      alertConfirmation = (
        <Alert
          icon="trash"
          intent={'danger'}
          confirmButtonText="Si"
          cancelButtonText="No"
          isOpen={true}
          onConfirm={this.confirmStep}
          onCancel={this.cancelConfirmationStep}
          className={'m-4'}
        >
          <Callout className={'p-4'}>
            <p>
              Si retrocedes, se borrara toda la información
              <br/>
              ¿Estás seguro?
            </p>
          </Callout>
        </Alert>
      );
    }

    if (loading) {
      return (
        <Spinner className="marginTop-1em marginBottom-1em" size="32"/>
      );
    }

    return (
      <div className="p-2 sm:p-2 pt-8 sm:pt-8 sm:pt-4 md:pt-4 lg:pt-4 xl:pt-4">
        {alertConfirmation}
        <ModalCrop
          isOpen={this.state.cropping}
          img={this.state.imgCropping}
          handleComplete={this.onCropComplete}
          handleCancel={this.cancelCrop}
        />
        {/* Fomulario de Publicación */}
        {
          // PRIMER PASO COLLAPSE
          <div className={'container'}>
            {/* SEGUNDO PASO COLLAPSE */}
            <Collapse isOpen={this.state.collapseControl.isOpenStep2}>
              <Step2
                goToStep3={this.goToStep3}
                localState={this.state}
                handlerCategorySelect={this.handlerCategorySelect}
                handlerSubCategory={this.handlerSubCategory}
              />
            </Collapse>
            {/* TERCER PASO COLLAPSE */}
            <Collapse isOpen={this.state.collapseControl.isOpenStep3}>
              <Step3
                localState={this.state}
                confirmationToStep={this.confirmationToStep}
                inputChangeHandler={this.inputChangeHandler}
                handlerUseTime={this.handlerUseTime}
                acceptedFiles={this.acceptedFiles}
                deleteImage={this.deleteImage}
                onDrop={this.onDrop}
                onDropRejected={this.onDropRejected}
                inputBlurHandler={this.inputBlurHandler}
                handlerCategorySelectExchange={this.addTagExchange}
                handlerCategorySelect={this.handlerCategorySelect}
                removeTag={this.removeTag}
                onEditorStateChange={this.onEditorStateChange}
                inputGeneralChangeHandler={this.inputGeneralChangeHandler}
                goToStep4={this.goToStep4}
                addTagExchange={this.addTagExchange}
                addTag={this.addTag}
                addTagLabel={this.addTagLabel}
                tagsLabel={this.tagsLabel}
                tagsChange={this.tagsChange}
                fields={this.state.formData.fields}
                updateFields={this.updateFields}
                countryUser={this.props.countryUser}
                categorySelected={this.state.categorySelected}
                onInit={true}
                removeExchangeTag={this.removeExchangeTag}
                handleLocations={this.handleLocations}
                handlerPriceReference={this.handlerPriceReference}
                handlerAcceptChanges={this.handlerAcceptChanges}
                onLoadDataStep={this.onLoadDataStep}
              />
            </Collapse>
            {/* CUARTO PASO COLLAPSE */}
            <Collapse isOpen={this.state.collapseControl.isOpenStep4}>
              <Card>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button
                      onClick={() => this.confirmationToStep(2)}
                      className="bp3-fill BtnLCPrimary marginBottom-05em"
                      disabled={false}

                      rightIcon="arrow-up"
                      text="Volver al paso 1"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button
                      onClick={() => this.goBackToStep(3)}
                      className="bp3-fill BtnLCPrimary marginBottom-1em"
                      disabled={false}

                      rightIcon="arrow-up"
                      text="Volver al paso 2"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h3>Paso 3</h3>
                    <h2>Punto de encuentro</h2>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <PlacesAutocomplete
                      value={this.state.formData.address_1}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      searchOptions={{
                        componentRestrictions: { country: 've' },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <FormGroup
                            label="Dirección donde te gustaría realizar el intercambio"
                            labelFor="text-input"

                            labelInfo="*"
                          >
                            <InputGroup

                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}
                              placeholder="Buscar direcciones..."
                              id="address_1"
                              className="whiteInput"
                            />
                          </FormGroup>
                          <div className="autocomplete-dropdown-container">
                            {loading && (
                              <div className={'flex items-stretch'}>
                                <div className={'self-center'}>
                                  <h3>Loading...</h3>
                                </div>
                              </div>
                            )}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                }
                                : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <FormGroup
                      // helperText="Helper text with details..."
                      label="Punto de referencia (información opcional)"
                      labelFor="text-input"
                    >
                      <InputGroup

                        id="address_2"
                        onChange={this.inputChangeHandler}
                        value={this.state.formData.address_2}
                        placeholder="Punto de referencia"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <FormGroup
                      // helperText="Helper text with details..."
                      label="Coordenadas del punto de encuentro"
                      labelFor="text-input"
                    >
                      <InputGroup

                        id="text-input"
                        disabled={true}
                        placeholder="Coloca las coordenadas"
                        value={`${this.state.formData.latitude} ${this.state.formData.longitude}`}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div id="mapPrincipal" className="mapPointChange">
                      <div>
                        <Map
                          className="app"
                          {...this.props}
                          google={this.props.google}
                          onClick={this.mapClicked}
                          center={this.state.position}
                          zoom={14}
                        >
                          <Marker
                            icon={{
                              url: pointerMap,
                              scaledSize: { width: 64, height: 64 },
                            }}
                            position={this.state.position}
                          />

                          <InfoWindow onClose={this.onInfoWindowClose}>
                            <div>
                              <h1>{this.state.selectedPlace.name}</h1>
                            </div>
                          </InfoWindow>
                        </Map>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button
                      className="bp3-fill BtnLCPrimary"

                      rightIcon="arrow-down"
                      text="Próximo"
                      onClick={this.goToStep5}
                    />
                  </Col>
                </Row>
              </Card>
            </Collapse>
            {/* CUARTO PASO COLLAPSE */}

            <Collapse isOpen={this.state.collapseControl.isOpenStep5}>
              <Step5
                localState={this.state}
                confirmationToStep={this.confirmationToStep}
                goBackToStep={this.goBackToStep}
                sendToAPI={this.sendToAPI}
                edit={this.edit}
                fieldsCustomCategory={this.state.formData.fields.filter(
                  (field) => field.value !== '',
                )}
                publicationSaved={this.state.publicationSaved}
              />
            </Collapse>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.all,
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
    fullName: state.auth.fullName,
    suscription: state.auth.suscription,
    countryUser: state.auth.otherInfo.country,
    address: state.auth.otherInfo.address,
    city: state.auth.otherInfo.city,
    postal_code: state.auth.otherInfo.postal_code,
    state: state.auth.otherInfo.state,
  };
};

// export default connect(mapStateToProps)(FormPublication);

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: Spinner,
})(connect(mapStateToProps)(withRouter(FormPublication)));
