import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import { Button, Callout, Dialog, Tab, Tabs } from '@blueprintjs/core';

import './changes/Changes.scss';
import withCable from '../hocs/withCable';
import History from './requests/History';
import { config } from '../system/Config';
import Footer from './../components/Footer';
import { getHeader } from '../system/Sesion';
import ChangesTab from './requests/ChangesTab';
import { getQuerystring } from '../utility/url';
import * as actions from './../store/actions/index';
import ModalRanking from '../components/giveRanking/ModalRanking';
import ProposalRequestSend from './requests/ProposalRequestSend';
import ProposalRequestReceived from './requests/ProposalRequestReceived';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';
import { Toast } from '../utility/toast';

class ChangesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: '1',
      loadingAcceptChange: [],
      loadingRejectChange: [],
      dialogCancelTransaction: false,
      deletingTransaction: false,
      changeTemp: null,
      isOpen: false,
      action: [],
      isOpenModalRank: false,
      rankingChange: null,
      transaction: null,
      typeRank: null, // AQUI EMPIEZAN LOS CAMBIOS V2
      profile: null,
      proposals: [],
      changesTrans: [],
      history: [],
    };
    this.userId = parseInt(localStorage.getItem('userId'));
    this.cableConnection = null;
  }

  tabChange = (tabSelected) => (this.props.history.push(`/requests?tab=${tabSelected}`));

  switchDialogCancelTransaction = () => {
    this.setState({
      dialogCancelTransaction: !this.state.dialogCancelTransaction,
    });
  };

  fetchUpdateTransaction = (id, data) => {
    return axios.put(`${config.URL_API}/v1/transactions/${id}`, data, {
      headers: getHeader(),
    });
  };

  showRejectToast = (transaction) => {
    const isOwner = transaction.interested_id !== parseInt(localStorage.userId);

    const { action } = this.state;
    const { interested_user, publication_interested, publication, tag_change, publication: { owner } } = transaction;
    const getInterestedInfo = () => publication_interested ? publication_interested : tag_change;
    const toastInfo = isOwner ?
      { user: interested_user, title: getInterestedInfo() } :
      { user: owner, title: publication };

    const message = <span>
      {action === 'cancel' ? 'Cancelaste' : 'Rechazaste'} el cambio con <b>
      {toastInfo.user.name} {toastInfo.user.last_name}</b> en <b>
      {toastInfo.title.tag ? toastInfo.title.tag : toastInfo.title.name}</b>
    </span>;

    Toast.show({ message });
  };

  cancelTransaction = async (transaction) => {
    this.setState({
      deletingTransaction: true,
      dialogCancelTransaction: false,
    }, async () => {
      try {
        let data = {
          status: 'rejected',
        };
        await this.fetchUpdateTransaction(transaction.id, data);
        this.getTransactionsByTab();

        this.showRejectToast(transaction);
      } catch (err) {
        this.setState({ deletingTransaction: false });
      }
    });
  };

  openModal = (id, transaction = false, action = false) => {
    this.setState({
      dialogCancelTransaction: true,
      changeTemp: id,
      transaction: transaction,
      action: action,
    });
  };

  closeModal() {
    this.setState({ dialogCancelTransaction: false });
  }

  componentDidMount() {
    const tabSelected = getQuerystring(this.props.location.search, 'tab') || '1';
    this.setState({ tabSelected }, () => {
      this.cableConnection = this.props.cableApp.subscriptions.create(
        { channel: 'RequestChannel', user_id: this.userId },
        {
          received: (response) => {
            this.getTransactionsByTab();
          },
        },
      );
      this.getTransactionsByTab();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const tabSelected = getQuerystring(this.props.location.search, 'tab') || '1';
    if (tabSelected !== prevState.tabSelected) {
      this.setState({ tabSelected }, () => this.getTransactionsByTab());
    }
  }

  async getTransactionsByTab() {
    let { tabSelected } = this.state;
    switch (tabSelected) {
      case '1':
        this.fetchRequests('proposals')
          .then(
            (data) => {
              let { data: transactions } = data;
              if (!transactions) {
                transactions = [];
              }

              let transformTransactions = transactions.map((v) => {
                let newTrans = {
                  transactions: v.transactions,
                  type: 'enviada',
                };

                if (v.hasOwnProperty('publication')) {
                  newTrans.type = 'recibida';
                  newTrans.publication = v.publication;
                }
                return newTrans;
              });
              this.setState({
                proposals: [...transformTransactions],
              });
            },
          );
        break;
      case '2':
        this.fetchRequests('in_progress')
          .then(
            (data) => {
              let { data: transactions } = data;
              if (!transactions) {
                transactions = [];
              }
              this.setState({
                changesTrans: [...transactions],
              });
            },
          );
        break;
      case '3':
        this.fetchRequests('history')
          .then(
            (data) => {
              let { transactions } = data.data;
              if (!transactions) {
                transactions = [];
              }
              this.setState({
                history: [...transactions],
              });
            },
          );
        break;
    }
  }

  componentWillUnmount() {
    this.cableConnection.unsubscribe();
  }

  openRankModal = (change, type, profile) => {
    this.setState({
      isOpenModalRank: true,
      rankingChange: change,
      typeRank: type,
      profile,
    });
  };

  closeModalRanking = () => {
    this.setState({
      isOpenModalRank: false,
      rankingChange: null,
      typeRank: null,
      profile: null,
    }, () => this.getTransactionsByTab());
  };

  onSuccessRanked() {
    this.closeModalRanking();
  }

  fetchRequests = (kind) => {
    return axios.get(
      `${config.URL_API}/v1/requests?kind=${kind}`,
      { headers: getHeader() },
    );
  };

  confirmTransaction = async () => {
    const data = {
      status: 'confirmed_interested',
    };
    const { rankingChange: { id, interested_id } } = this.state;
    const isOwner = interested_id !== parseInt(localStorage.getItem('userId'));
    if (isOwner) {
      data.status = 'confirmed_owner';
    }
    try {
      await this.fetchUpdateTransaction(id, data);
    } catch (err) {

    }
  };

  completeTransaction = async () => {
    const data = {
      status: 'complete',
    };
    const { rankingChange: { id, status, interested_id } } = this.state;
    if (status !== 'confirmed_interested' && status !== 'confirmed_owner') {
      return;
    }
    try {
      await this.fetchUpdateTransaction(id, data);
    } catch (err) {

    }
  };

  getChangesTransaction = (transaction) => {
    if (transaction.publication_id) return transaction.transactions[0];
    return transaction.transactions;
  };

  capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0)
      .toUpperCase() + lower.slice(1);
  }

  render() {
    const {
      tabSelected,
      dialogCancelTransaction,
      transaction,
      proposals,
      changesTrans,
      history,
    } = this.state;

    const changes = proposals.concat(changesTrans, history);

    return (
      <>
        <div>
          <div className="customWrapper">
            <div className="RequestsContainer">
              <Tabs
                id="requestsTabs"
                horizontal={true}
                selectedTabId={tabSelected}
                onChange={this.tabChange}
              >
                <Tab
                  id="1"
                  panel=
                    {tabSelected === '1' ?
                      <>
                        {
                          proposals.map((v, i) => (
                            v.type === 'enviada' ? (
                              <div key={`proposal-${i}`} className="container mx-auto mb-3 PubBox">
                                <div
                                  className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">PROPUESTA
                                  ENVIADA
                                </div>
                                <ScrollToElement name={v.transactions} highlight>
                                  <ProposalRequestSend
                                    value={v}
                                    suscription={this.props.suscription}
                                    indexProposal={i}
                                    openModal={this.openModal}
                                    currentTransaction={this.props.requests.currentTransaction}
                                    name={v.transactions}
                                  />
                                </ScrollToElement>
                              </div>
                            ) : (
                              <div key={`proposal-${i}`} className="container mx-auto mb-3 PubBox">
                                <div
                                  className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">PROPUESTA
                                  RECIBIDA
                                </div>
                                <ProposalRequestReceived
                                  proposal={v}
                                  suscription={this.props.suscription}
                                  indexProposal={i}
                                  openModal={this.openModal}
                                />
                              </div>
                            )
                          ))
                        }
                      </>
                      : null
                    }
                >
                  <p className="margin0 marginTop-05em marginBottom-05em TopHeader">
                    Propuestas
                  </p>
                </Tab>

                <Tab
                  id="2"
                  panel={
                    <>
                      {tabSelected === '2' ? changesTrans.map((v, i) => (
                        Array.isArray(v.transactions) ? 
                        (v.transactions.map((v2, i2) => (
                          <ScrollToElement key={`changes-${i}`} name={v2} highlight>
                            <ChangesTab
                              value={v}
                              indexProposal={i}
                              suscription={this.props.suscription}
                              userId={this.userId}
                              openModal={this.openModal}
                              openRankModal={this.openRankModal}
                              currentTransaction={v2}
                              name={v2}
                            />
                          </ScrollToElement>
                        ))) :
                        <ScrollToElement key={`changes-${i}`} name={this.getChangesTransaction(v)} highlight>
                        <ChangesTab
                          value={v}
                          indexProposal={i}
                          suscription={this.props.suscription}
                          userId={this.userId}
                          openModal={this.openModal}
                          openRankModal={this.openRankModal}
                          currentTransaction={this.props.requests.currentTransaction}
                          name={this.getChangesTransaction(v)}
                        />
                      </ScrollToElement>
                        )
                      ) : null
                      }
                    </>
                  }
                >
                  <p className="margin0 marginTop-05em marginBottom-05em TopHeader">
                    Cambios
                  </p>
                </Tab>

                <Tab
                  id="3"
                  panel={
                    <>
                      {tabSelected === '3' ? history.map((v, i) => (
                        <div key={v} className="mb-3">
                          <History
                            urlTransaction={v}
                            indexProposal={i}
                            suscription={this.props.suscription}
                            userId={this.userId}
                            openModal={this.openModal}
                            completeTransaction={this.completeTransaction}
                          />
                        </div>
                      )) : null
                      }
                    </>
                  }
                >
                  <p className="margin0 marginTop-05em marginBottom-05em TopHeader">
                    Historial
                  </p>
                </Tab>
              </Tabs>
            </div>
            {changes.length === 0 ? (
              <Callout className="noActivityDisplay marginBottom-1em">
                <h5 className="bp3-form-helper-text">
                  Sin Transacciones
                </h5>
              </Callout>
            ) : null}
            <Dialog
              isOpen={dialogCancelTransaction}
              className="paddingBottom-0"
              icon="info-sign"
              title="Cancelar Propuesta"
              onClose={() => {
                this.closeModal();
              }}
            >
              <div className="padding-1em">
                <Callout title="¿Estás seguro que deseas cancelar la Propuesta?">
                  <p>Si cancelas se eliminará la transacción</p>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Button
                        className="BtnLCPrimary margin0 marginTop-1em"
                        minimal={true}
                        fill={true}
                        onClick={() => this.cancelTransaction(transaction)}
                      >
                        Si
                      </Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Button
                        className="BtnLCPrimary margin0 marginTop-1em"
                        minimal={true}
                        fill={true}
                        onClick={() => this.switchDialogCancelTransaction()}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </Callout>
              </div>
            </Dialog>
            {this.state.isOpenModalRank ? (
              <ModalRanking
                change={this.state.rankingChange}
                typeRank={this.state.typeRank}
                closeModal={this.closeModalRanking}
                successRanked={this.onSuccessRanked.bind(this)}
                profile={this.state.profile}
                confirmTransaction={this.confirmTransaction}
                completeTransaction={this.completeTransaction}
              />) : null
            }
            <Footer/>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.request,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetRequests: () => dispatch(actions.getRequests()),
    onUpdateRequests: (data, typeRequest) =>
      dispatch(actions.updateRequest(data, typeRequest)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCable(ChangesPage));
