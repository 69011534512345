import React, { Component } from "react";
import { Callout, Card, Dialog } from '@blueprintjs/core';
//import ModalCrop from "./../../components/ModalCrop";
import ModalCropOld from "./../../components/ModalCropOld";
import { connect } from "react-redux";
import {
  formatPrice,
  initialValue,
  needSkeletonClass,
} from "./../../utilityFunctions";
import { config } from "./../../system/Config";
import { getHeader } from "./../../system/Sesion";
import axios from "axios";
import { Link } from "react-router-dom";
import UserStatsInfo from "../../components/UserStatsInfo";
import "./userInformation/UserInformation.scss";

class UserInformation extends Component {
  state = {
    crop: {
      x: 10,
      y: 10,
      width: 50,
      aspect: 1,
      disabled: true,
    },
    isOpenCrop: false,
    imgCroping: null,
    cropping: false,
    cropCurrent: null,
    user: {
      picture_data_profile: "",
      picture_data_cover: "",
    },
    imageEditing: null,
    haveImage: null, // el usuario tiene imagen?
    publicationsActive: [],
  };

  componentDidUpdate() {
    if (
      
      this.props.pictureData &&
      this.props.pictureData.length > 0 &&
      this.state.haveImage === null
    ) {
      this.setState({ haveImage: true });
    }
  }

  componentDidMount() {
    if (
      
      this.props.pictureData &&
      this.props.pictureData.length > 0 &&
      this.state.haveImage === null
    ) {
      this.setState({ haveImage: true });
    }
  }

  componentWillMount() {
    this.props.userPublications.forEach((item) => {
      if (item.publication_status == "Activo") {
        this.state.publicationsActive.push(item);
      }
    });
  }

  // cuando el crop es completado pinto la imagen en el canvas
  onCropComplete = (crop, pixelCrop) => {
    const canvas = document.createElement("canvas"); // canvas para el tercer paso del formulari
    const imageEditing = this.state.imageEditing;
    canvas.width = "300";
    canvas.height = "300";

    const ctx = canvas.getContext("2d");

    const image = document.createElement("img");
    image.setAttribute("src", this.state.imgCroping);
    image.setAttribute("height", "100%");
    image.setAttribute("width", "100%");
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      300,
      300
    );
    canvas.setAttribute("style", "width: 100%; height: 100%");
    //this.insertCanvasInDiv(`image-${imageEditing}-user`, canvas)

    let copyState = { ...this.state.user };
    if (imageEditing === "profile") {
      copyState["picture_data_profile"] = canvas.toDataURL();
    } else {
      copyState["picture_data_cover"] = canvas.toDataURL();
    }
    this.setState({ user: copyState });
  };

  // manejador para el crop
  onCropChange = (crop, pixelCrop) => {
    this.setState({ cropCurrent: crop });
  };

  uploadProfilePhoto = () => {
    const dataAPI = {
      id_user: this.props.userId,
      id_image:
        this.props.pictureData.length > 0 ? this.props.pictureData[0].id : -0,
      picture_data: [this.state.user.picture_data_profile],
    };
    axios
      .put(`${config.URL_API}/update_profile_photo`, dataAPI, {
        headers: getHeader(),
      })
      .catch((error) => {
        alert("hubo un error cambiando la imagen");
      });
  };

  updateCoverPhoto = () => {
    const dataAPI = {
      id_user: this.props.userId,
      id_image:
        this.props.pictureCover.length > 0 ? this.props.pictureCover[0].id : -0,
      picture_data: [this.state.user.picture_data_cover],
    };

    axios
      .put(`${config.URL_API}/update_cover_photo`, dataAPI, {
        headers: getHeader(),
      })
      .catch((error) => {
        alert("hubo un  error cambiando la imagen");
      });
  };

  // inserta en un contenedor, un canvas
  insertCanvasInDiv = (idDiv, canvas) => {
    document.getElementById(idDiv).innerHTML = "";
    document.getElementById(idDiv).appendChild(canvas);
  };

  // cierra el modal del crop
  closeModal = () => {
    let copyState = { ...this.state.user };
    this.setState({ croping: false, imgCroping: null, user: copyState });
    if (this.state.imageEditing === "profile") {
      this.setState({ haveImage: true });
      document
        .querySelector("#user-profile-image")
        .setAttribute("src", this.state.user.picture_data_profile);
      this.uploadProfilePhoto();
    } else {
      document
        .querySelector("#user-cover-image")
        .setAttribute("src", this.state.user.picture_data_cover);
      this.updateCoverPhoto();
    }
  };

  // cuando se cancela el crop
  cancelCrop = () => {
    //document.getElementById(`image-profile-user`).innerHTML = ''
    this.setState({ croping: false, imgCroping: null });
  };

  // manejador para el drag an drop
  onDrop = (files, imageEditing) => {
    if (files.length === 1) {
      this.setState({
        croping: true,
        imgCroping: URL.createObjectURL(files[0]),
        cropCurrent: this.state.crop,
        imageEditing: imageEditing,
      });
    }
  };

  // esta funcion me devuelve la URL de la imagen, como la foto de perfil y la foto de portada
  // se guardan en carpetas diferentes entonces por eso esta esta logica aqui
  // el nombre de la imagen tambien es diferente porque en las tablas no son iguales :)

  render() {
    let otherInfoUser = { ...this.props.otherInfoUser };
    let crop = null;
    const {user} = this.props
    if (this.state.croping) {
      crop = (
        <Dialog
          canOutsideClickClose={false}
          isOpen={true}
          icon="widget"
          onClose={this.cancelCrop}
          title="Recorte de imagen"
        >
          <ModalCropOld
            crop={this.state.cropCurrent}
            img={this.state.imgCroping}
            onImageLoaded={this.onImageLoaded}
            onComplete={(crop, pixelCrop) =>
              this.onCropComplete(crop, pixelCrop)
            }
            onChange={(crop, pixelCrop) => this.onCropChange(crop, pixelCrop)}
            isCropped={this.closeModal}
            cancelCrop={this.cancelCrop}
          />
        </Dialog>
      );
    }

    return (
      
      <>
        <div className="UserInformationContainer">
          {crop}
          <Card className="padding0">
            <div className={"WallActivityUserInformation"}>
              <div className="">
                <figure className={""}>
                </figure>
              </div>
              <div className="User-Avatar-Name">
                <figure className="m-auto">
                  {this.props.pictureData ? (
                    <Callout className="avatarContainer bp3-elevation-0 m-auto">
                      {
                        this.state.haveImage ? (
                          <Link to="/profile">
                            <div
                              id={`image-profile-user`}
                              className="dragDropDisclaimerContenainer"
                            >
                              <img
                                className="rounded shadow w-full h-full"
                                id={`user-profile-image`}
                                src={user?.avatar}
                                alt="profile"
                              />
                            </div>
                          </Link>
                        ) : (
                          <div className="initialsName">
                            <p>
                              <span>
                                {this.props.userFullName
                                  ? initialValue(
                                      this.props.userFullName.split(" ")[0]
                                    )
                                  : null}
                              </span>
                              <span>
                                {this.props.userFullName
                                  ? initialValue(
                                      this.props.userFullName.split(" ")[1]
                                    )
                                  : null}
                              </span>
                            </p>
                          </div>
                        )
                      }
                    </Callout>
                  ) : null}
                </figure>
                <div
                  className={needSkeletonClass(
                    this.props.otherInfoUser,
                    "userName"
                  )}
                >
                  <Link to="/profile">
                    <h1 className={"mt-4 mb-2"}>{this.props.userFullName}</h1>
                  </Link>
                  <p>
                    <span className="balanceTxt">Saldo: </span>{" "}
                    <span className="balanceMoney">
                      {localStorage.getItem("currency")}{" "}
                      {otherInfoUser.balance
                        ? formatPrice(otherInfoUser.balance.toString())
                        : null}
                    </span>
                  </p>
                </div>
              </div>
              <div className="padding-1em">
                <UserStatsInfo user={user}/>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userFullName: state.auth.fullName,
    userSuscription: state.auth.suscription,
    userPublications: state.auth.publications,
    otherInfoUser: state.auth.otherInfo,
    followers: state.auth.followers,
    following: state.auth.following,
    pictureData: state.auth.imageProfile,
    pictureCover: state.auth.imageCover,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(UserInformation);
