import React, { Component } from 'react';
import { Button, Callout, Card, Divider, Elevation, Radio, RadioGroup, Spinner, Tag } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import ProductSwiper from '../components/productSwiper/ProductSwiper';
import ChangeOperationStep2 from './changeOperationStep1/ChangeOperationStep2';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import AddressPubInfo from './../components/AddressPubInfo';
import PubsAsociatedUser from './changeOperationStep1/PubsAsociatedUser';
import pointerMap from './../Assets/System/pointerMap/pointerMap.png';
import './changeOperationStep1/ChangeOperation.scss';
import { needOverlay, productNameUrl } from '../utilityFunctions';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import { connect } from 'react-redux';
import Footer from './../components/Footer';
import OverlayPremium from './../components/OverlayPremium';
import { ELEVATION_1 } from '@blueprintjs/core/lib/esm/common/classes';
import ChangeOperationMessages from './changeOperationStep1/ChangeOperationMessages';

class ChangeOperationStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      tradeTags: [], // el arreglo con todos los tags disponibles para cambio
      pointChange: true, // es true cuando es el punto definido por el anuncianto, y false cuando es un punto de TLC SEGURO.,
      tradeTag: null, // el tag que eligio el usuario,
      showNextStep: false, // muestro el siguiente paso?,
      pointSecure: { address_1: '', address_2: '' }, // punto personalizado o telocambio seguro,
      pub: {},
      pubsAsociatedUser: [],
      pubAsociatedUserSelected: null,
      loading: false,
      backUrl: '',
      proposal: null,
    };
    this.checkTag = this.checkTag.bind(this);
    this.pointChangeHandler = this.pointChangeHandler.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.pointSecureChangeHandler = this.pointSecureChangeHandler.bind(this);
    this.backStep = this.backStep.bind(this);
  }

  getInfoUser = () => {
    axios.get(`${config.URL_API}/users/${this.props.id_user}`, {
      headers: getHeader(),
    });
  };

  PRINCIPAL_POINTS = {
    GRAN_CARACAS: 'Gran Caracas',
    OCCIDENTE: 'Occidente',
    CENTRO: 'Centro',
    ORIENTE: 'Oriente',
  };

  fetchGetPublication = (id) => {
    return axios.get(
      `${config.URL_API}/publications/${id}?latitude=${localStorage.getItem(
        'latitude',
      )}&longitude=${localStorage.getItem('longitude')}`,
      { headers: getHeader() },
    );
  };

  fetchGetPublicationsAsociatedUser = (id) => {
    return axios.get(`${config.URL_API}/pubs_owner?owner_id=${id}`, {
      headers: getHeader(),
    });
  };

  componentDidMount() {
    this.setState({
      backUrl: `/${productNameUrl(this.props.match.params.name)}/${
        this.props.match.params.id
      }`,
    });
    this.getPub(this.props.match.params.id);
    this.getPubsAsociatedUser();
  }

  getPub = async (id) => {
    try {
      let response = await this.fetchGetPublication(id);

      const tradeTags = response.data.publication.tags
        .map((value) => {
          return { name: value.name, checked: false };
        });

      // hago forceUpdate para que se vea el pointer del mapa
      this.setState(
        { pub: response.data.publication, loading: true, tradeTags: tradeTags },
        () => {
          this.forceUpdate();
        },
      );
    } catch(err) {
      console.log('err', err);
    }
  };

  // obtener publicaciones asociadas al usuario logueado
  getPubsAsociatedUser = async () => {
    try {
      const response = await this.fetchGetPublicationsAsociatedUser(
        localStorage.getItem('userId'),
      );
      this.setPubsAsociatedUser(response.data);
    } catch(err) {
      console.log('err', err);
    }
  };

  setPubsAsociatedUser = (data) => {
    this.setState({ pubsAsociatedUser: data });
  };

  selectPubAsociatedUser = (id) => {
    this.setState({ pubAsociatedUserSelected: id, proposal: null }, () =>
      this.desCheckAllTags(),
    );
  };

  desSelectPubAsociatedUser = () => {
    this.setState({ pubAsociatedUserSelected: null });
  };

  searchPub = (id, array) => {
    let pub = null;
    array.forEach((value) => {
      if (value.id === id) {
        pub = value;
      }
    });
    return pub;
  };

  checkTag(i) {
    let copyTradeTags = [...this.state.tradeTags];
    let tradeTag = null;
    const tradeTags = copyTradeTags.map((value, index) => {
      if (index === i) {
        tradeTag = value.name;
        value.checked = true;
      } else {
        value.checked = false;
      }
      return value;
    });
    this.setState({
      tradeTags: tradeTags,
      tradeTag: tradeTag,
      pubAsociatedUserSelected: null,
      pubSelected: null,
      proposal: null,
    });
  }

  desCheckAllTags = () => {
    let tradeTags = [...this.state.tradeTags];
    this.setState({ proposal: null });
    tradeTags.map((value, index) => {
      value.checked = false;
      return value;
    });
    this.setState({ tradeTags: tradeTags, tradeTag: null });
  };

  pointChangeHandler() {
    this.setState({ pointChange: !this.state.pointChange }, () => {
      if (this.state.pointChange) {
        let pointSecure = { ...this.state.pointSecure };
        pointSecure.address_1 = '';
        this.setState({ pointSecure: pointSecure });
      }
    });
  }

  pointSecureChangeHandler(event, principalPoint) {
    let pointSecure = { ...this.state.pointChange };
    pointSecure.address_1 = principalPoint;
    pointSecure.address_2 = event.target.value;
    this.setState({ pointSecure: pointSecure, pointChange: false });
  }

  nextStep() {
    this.setState({ showNextStep: true });
  }

  backStep() {
    this.setState({ showNextStep: false });
  }

  addProposal = (value) => {
    this.setState({ proposal: value });

    //SE DESACTIVAN LOS TAGS
    this.state.tradeTags.map((item) => {
      item.checked = false;
    });

    //SE DESACTIVAN LAS PUBLICACIONES
    this.setState({
      pubAsociatedUserSelected: null,
      pubSelected: null,
    });
  };
  removeTag = () => {
    this.setState({ proposal: null });
  };

  render() {
    const { user, pub, loading, backUrl } = this.state;
    const {
      tradeTags,
      pointChange,
      tradeTag,
      showNextStep,
      pointSecure,
      pubsAsociatedUser,
      pubAsociatedUserSelected,
      proposal,
    } = this.state;

    const position = {
      lat: this.props.match.params.latitude || pub.coordinate.latitude,
      lng: this.props.match.params.longitude || pub.coordinate.longitude,
    }
    
    const pubSelected =
      pubAsociatedUserSelected !== null
        ? this.searchPub(pubAsociatedUserSelected, pubsAsociatedUser)
        : null;

    if (!loading) {
      return <Spinner className={'mt-32 marginBottom-1em'} size={'32'} />;
    }

    // si es el propietrio de la publicacion o no es el id del usuario logueado es redirigido al home
    if (this.props.userId === pub.owner.id) {
      return <Redirect to="/" />;
    }

    if (!showNextStep) {
      return (
        <>
          <div className={'pt-32 pb-32 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6'}>
            <Container>
              <div className={'ChangeOperationContainer'}>
                <div className={'marginBottom-1em'}>
                  <Button
                    className={'marginRight-1em'}
                    icon={'chevron-left'}
                    minimal={true}
                  >
                    <Link to={backUrl}>
                      {this.props.match.params.name.replace(/-/g, ' ')}
                    </Link>
                  </Button>
                </div>
                <Card elevation={Elevation.ONE}>
                  <RadioGroup>
                    <Callout>
                      <h3>
                        ¿Qué le ofreces a
                        <Link to={`/publications/third/${pub.owner.id}`}>
                          <span className={'mr-1 ml-1'}>{`${pub.owner.name} ${pub.owner.last_name}`}</span>
                        </Link>
                        a cambio de su {pub.name}?
                      </h3>
                    </Callout>

                    <div className={'marginTop-1em marginBottom-1em'}>
                      <Callout>
                        <Row>
                          <Col xs={12} sm={4} md={4} lg={4}>
                            <div className={'SwiperContainer'}>
                              {needOverlay(
                                pub.product_category,
                                this.props.suscription,
                              ) ? (
                                <OverlayPremium />
                              ) : null}
                              <ProductSwiper
                                pictures={pub.pictures}
                                needOverlay={needOverlay(
                                  pub.product_category,
                                  this.props.suscription,
                                )}
                              />
                            </div>
                            <div className={'mt-2 mb-2'}>
                              {pubSelected !== null ? (
                                <Callout
                                  icon={'tick'}
                                  intent={'primary'}
                                  className={'marginTop-1em padding-1em'}
                                >
                                  <h3 className={'margin0 marginBottom-1em'}>
                                    Publicación seleccionada para proponer el
                                    cambio:
                                  </h3>
                                  <Card
                                    elevation={ELEVATION_1}
                                    interactive={true}
                                    className={'padding-05em'}
                                  >
                                    <div className="productsItemList marginBottom-05em">
                                      <div className="productAvatarContainer">
                                        <div className="AvatarImage">
                                          {pubSelected.pictures.length > 0 ? (
                                            <img
                                              src={pubSelected.pictures[0]?.url}
                                              alt="avatarImage"
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="ProductDetailsContainer">
                                        <h4 className={'margin0'}>
                                          {pubSelected.name}
                                        </h4>
                                      </div>
                                    </div>
                                  </Card>
                                </Callout>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12} sm={8} md={8} lg={8}>
                            <div className={'marginBottom-1em'}>
                              <Card
                                elevation={ELEVATION_1}
                                className={'padding-1em'}
                              >
                                <h3 className={'margin0 marginBottom-05em'}>
                                  {`Intereses de ${pub.owner.name} ${pub.owner.last_name}`}
                                </h3>
                                {tradeTags.map((value, i) =>
                                  <Tag
                                    key={i}
                                    className={
                                      'tag marginRight-05em marginBottom-05em'
                                    }
                                  >
                                    {value.name}
                                  </Tag>,
                                )}
                              </Card>
                            </div>
                            <Callout
                              intent={'primary'}
                              className={'marginBottom-1em'}
                            >
                              <h3 className={'margin0 marinBottom-05em'}>
                                Selecciona entre enviar una propuesta escrita o una de tus publicaciones como solicitud
                                de cambio a
                                <Link to={`/publications/third/${pub.owner.id}`}>
                                  <span className={'mr-1 ml-1'}>{`${pub.owner.name} ${pub.owner.last_name}`}</span>
                                </Link>
                              </h3>
                            </Callout>
                            <Card
                              elevation={ELEVATION_1}
                              className={'padding-1em mb-4'}
                            >
                              <ChangeOperationMessages
                                addProposal={this.addProposal}
                              ></ChangeOperationMessages>
                              {this.state.proposal != null && (
                                <Tag
                                  className={'tag mt-4 mb-4'}

                                  onRemove={() =>
                                    this.setState({ proposal: null })
                                  }
                                >
                                  {this.state.proposal}
                                </Tag>
                              )}
                            </Card>
                            <div className={'marginBottom-1em'}>
                              <PubsAsociatedUser
                                pubs={pubsAsociatedUser}
                                selectPub={this.selectPubAsociatedUser}
                                desSelectPub={this.desSelectPubAsociatedUser}
                                idPubSelected={
                                  this.state.pubAsociatedUserSelected
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Callout>
                    </div>
                    <Divider className={'marginTop-1em marginBottom-1em'} />
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Callout
                          title={`Punto de cambio sugerido por ${pub.owner.name} ${pub.owner.last_name}`}
                        />
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4}>
                        <Callout className={'marginTop-1em'}>
                          <Radio
                            label={`Punto de encuentro propuesto por ${pub.owner.name} ${pub.owner.last_name}`}
                            checked={pointChange}
                            onChange={this.pointChangeHandler}
                          />
                          <Divider
                            className={'marginTop-1em marginBottom-1em'}
                          />
                          <div
                            className={'mapChangePoint'}
                            id="mapChangeOperationStep"
                          >
                            <Map
                              className="app mapDetailProduct"
                              {...this.props}
                              google={this.props.google}
                              onClick={this.mapClicked}
                              center={position}
                              zoom={14}
                            >
                              <Marker
                                icon={{
                                  url: pointerMap,
                                  scaledSize: { width: 64, height: 64 },
                                }}
                                position={position}
                              />

                              <InfoWindow onClose={this.onInfoWindowClose}>
                                <div>
                                  <h1>{pub.address_1}</h1>
                                </div>
                              </InfoWindow>
                            </Map>
                          </div>
                        </Callout>

                      </Col>
                      <Col xs={12} sm={8} md={8} lg={8}>
                        <Callout className={'marginTop-1em'}>
                          <div className={'infoDetailPublication'}>
                            <AddressPubInfo
                              address_1={pub.address_1}
                              address_2={pub.address_2}
                              coordinate={pub.coordinate}
                            />
                          </div>
                          <h5 className={'mt-8'}>Atención</h5>
                          <p className={'bp3-form-helper-text mt-2'}>
                            El punto de cambio lo podrás modificar más adelante en la negociacion por chat privado.
                            Considera la reputación de otros usuarios y siempre elige un sitio público y seguro para
                            realizar el encuentro. Te recomendamos que sigas nuestros <Link to="/consejos_de_seguridad">Consejos
                            de seguridad</Link> para que tu experiencia LoCambio.com sea exitosa.
                          </p>
                        </Callout>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          fill={true}

                          className={'BtnLCPrimary marginTop-1em'}
                          disabled={
                            (tradeTag === null &&
                              pubSelected === null &&
                              proposal === null) ||
                            (!pointChange && pointSecure.address_2 === '')
                          }
                          onClick={this.nextStep}
                        >
                          Siguiente
                        </Button>
                      </Col>
                    </Row>
                  </RadioGroup>
                </Card>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Footer />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </>
      );
    }

    return (
      <ChangeOperationStep2
        pictures={pub.pictures}
        name={pub.name}
        product_category={pub.product_category}
        nameOwner={`${pub.owner.name} ${pub.owner.last_name}`}
        tradeTag={tradeTag}
        pubSelected={pubSelected}
        publication_interested_id={pubAsociatedUserSelected}
        pointChange={pointChange}
        address_1={pointChange ? pub.address_1 : pointSecure.address_1}
        address_2={pointChange ? pub.address_2 : pointSecure.address_2}
        coordinate={pub.coordinate}
        id_pub={pub.id}
        backStep={this.backStep}
        proposal={proposal}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    suscription: state.auth.suscription,
  };
};

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: Spinner,
})(withRouter(connect(mapStateToProps, null)(ChangeOperationStep1)));

//export default connect(mapStateToProps)(ChangeOperationStep1);
