import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Link } from 'react-router-dom';

const settings = {
  modules: [Navigation],
  navigation: true,
  initialSlide: 0,
  slidesPerView: 1.5,
  spaceBetween: 20,
  centeredSlides: true,
};

const ServicesLC = ({ services, title, type }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const { normal, highlight } = title;

  const currentStatus = {
    preview: 'Demo gratis',
    available: 'Ya disponible',
    unavailable: 'En desarrollo',
  };

  const allServices = services.map((service) => {
    let unavailable = service.status === 'unavailable';

    const content = (
      <div className="servicesLC__service" key={`serviceLC-${service.title}`}>
        <div
          className={`servicesLC__status-Bar ${unavailable && 'servicesLC__status-Bar--grey'}`}
        >
          {currentStatus[service.status]}
        </div>
        <img
          className={`servicesLC__logo ${unavailable && 'servicesLC__logo--grey'}`}
          src={service.image}
          alt={service.description}
        />
        <div className="servicesLC__info">
          <p
            className={`servicesLC__title ${unavailable && 'servicesLC__title--accent'}`}
          >
            {service.title}
          </p>
          <p className="servicesLC__description">{service.description}</p>
        </div>
        <div className="servicesLC__button-Container">
          {unavailable ? (
            <p className="servicesLC__button servicesLC__button--grey">
              Proximamente
            </p>
          ) : (
            <Link to={service.route}>
              <p className="servicesLC__button">Saber más</p>
            </Link>
          )}
        </div>
      </div>
    );

    if (isNotMobile) return content;

    return (
      <SwiperSlide key={`serviceLC-slide-${service.title}`}>
        {content}
      </SwiperSlide>
    );
  });

  return (
    <div className="servicesLC section section--with-max-width">
      <p className="section__title">
        <span>{normal || title} </span>
        <span className={`section__title--${type} section__title--bolder`}>
          {highlight}
        </span>
      </p>
      {isNotMobile ? (
        <div className="servicesLC__container">{allServices}</div>
      ) : (
        <Swiper {...settings}>{allServices}</Swiper>
      )}
    </div>
  );
};

ServicesLC.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      normal: PropTypes.string.isRequired,
      highlight: PropTypes.string,
    }),
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'accent']),
};

ServicesLC.defaultProps = {
  services: [
    {
      status: 'preview',
      title: 'Cambia artículos de alto valor',
      description:
        'Cambia artículos como vehículos e inmuebles, y recibe beneficios como: Contrato Digital, Asesoría Legal y mucho más',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-1.jpg',
      route: '',
    },
    {
      status: 'available',
      title: 'Cambia seguro',
      description:
        'Usa nuestras instalaciones para cambiar de manera fácil, cómoda y segura',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-2.jpg',
      route: '',
    },
    {
      status: 'unavailable',
      title: 'Inspira confianza',
      description:
        'Solicita una verificación de identidad para aumentar la confianza en los usuarios interesados en contactarte',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-3.jpg',
      route: '',
    },
  ],
  title: {
    normal: 'Pregunta por los servicios de',
    highlight: 'LoCambio.com',
  },
  type: 'primary',
};

export default ServicesLC;
