import React, { Component } from 'react';
import { Callout, Card, Elevation, Tag } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import ProductSwiper from '../../components/productSwiper/ProductSwiper';
import AvatarUser from './../../components/AvatarUser';
import RankingGraph from './../../components/RankingGraph';
import './searchListResults/SearchListResults.scss';
import { distanceFormat, formatPrice, isNewPublication, needOverlay, productNameUrl } from './../../utilityFunctions';
import ArticlesOfInterest from './../../components/ArticlesOfInterest';
import CategoriesOfInterest from './../../components/CategoriesOfInterest';
import RankingUserStar from './../../components/RankingStar';
import { connect } from 'react-redux';
import OverlayPremium from './../../components/OverlayPremium';

class SearchListResults extends Component {
  render() {
    const { publications } = this.props;

    return publications.map((pub, i) => (
      <Card
        elevation={Elevation.ONE}
        interactive
        key={i}
        className="marginBottom-1em"
      >
        <Link to={`/${productNameUrl(pub.name)}/${pub.id}`}>
          <div className="horizontalThumbanil">
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="positionNewTag">
                  {isNewPublication(pub.publish_date) ? (
                    <Tag className="tagNew">Nueva publicación</Tag>
                  ) : null}
                </div>
                {needOverlay(pub.product_category, this.props.suscription) ? (
                  <OverlayPremium />
                ) : null}
                <Link to={`/${productNameUrl(pub.name)}/${pub.id}`}>
                  <ProductSwiper
                    pictures={pub.pictures}
                    needOverlay={needOverlay(
                      pub.product_category,
                      this.props.suscription,
                    )}
                  />
                </Link>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="InfoProductContainer">
                  <Callout>
                    <div className="publicationName">
                      <h1 className="m-0 pb-2 text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl">
                        <Link to={`/${productNameUrl(pub.name)}/${pub.id}`}>
                          {pub.name}
                        </Link>
                      </h1>
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div className="info-publication">
                            <div className="infoDetailPublication mb-2">
                              <p className="bp3-form-helper-text pb-1">
                                Disponible para:
                              </p>
                              <Tag className="tagYellow">Cambio</Tag>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div className="info-publication">
                            <div className="infoDetailPublication mb-2">
                              <p className="bp3-form-helper-text pb-1">
                                Valor del articulo
                              </p>
                              <Tag className="tag">
                                <strong>
                                  {localStorage.getItem('currency')}{' '}
                                  {formatPrice(pub.price)}
                                </strong>
                              </Tag>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div className="info-publication">
                            <div className="infoDetailPublication mb-2">
                              <p className="bp3-form-helper-text pb-1">
                                Se encuentra a
                              </p>
                              <Tag className="tag">
                                {`${formatPrice(
                                  distanceFormat(pub.distance)
                                    .split(' km')[0],
                                )
                                  .toString()} km`}
                              </Tag>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div className="info-publication">
                            <div className="infoDetailPublication">
                              <p className="bp3-form-helper-text pb-1">
                                Condicion del Articulo
                              </p>
                              <Tag className="tag marginRight-05em mb-2 sm:mb-2 md:mb-1 lg:mb-1 xl:mb-1">
                                {pub.product_status}
                              </Tag>
                              {pub.product_status === 'Usado' ? (
                                <Tag className="tag mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0">
                                  {pub.condition}
                                </Tag>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Callout>
                  <Callout className="mt-4 sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4">
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="headerUserCard">
                          <div className="AvatarContainer">
                            <Link to={`/publications/third/${pub.owner.id}`}>
                              <AvatarUser user={pub.owner} />
                            </Link>
                          </div>
                          <div className="UserDetailsContainer marginBottom-05em">
                            <h4>
                              <Link
                                to={`/publications/third/${pub.owner.id}`}
                              >{`${pub.owner.name} ${pub.owner.last_name}`}</Link>
                            </h4>
                            <p className="bp3-form-helper-text">
                              {pub.owner.address}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <RankingGraph user={pub.owner} />
                        <RankingUserStar user={pub.owner} />
                      </Col>
                    </Row>
                  </Callout>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <Callout className="marginBottom-1em mt-4 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                  <div className="interestsForChange">
                    <h4 className="marginBottom-05em pb-2">
                      Categorías de interés
                    </h4>
                    <CategoriesOfInterest
                      categories={pub.exchange_interest_category}
                    />
                  </div>
                </Callout>
                {pub.article_tags.length > 0  && (
                  <Callout>
                    <div className="interestsForChange">
                      <h4 className="marginBottom-05em pb-2">
                        Artículos de interés
                      </h4>
                      <ArticlesOfInterest
                        articles={pub.article_tags.slice(0, 5)}
                        tagsHides={pub.article_tags.length > 5}
                      />
                    </div>
                  </Callout>
                )}
              </Col>
            </Row>
          </div>
        </Link>
      </Card>
    ));
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default connect(mapStateToProps)(SearchListResults);
