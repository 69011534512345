const collectionNotifications = {
    commentsQuestion: "commentsQuestion",
    commentsAnswer: "commentsAnswer",
    newProposalChange: "newProposalChange",
    acceptedProposalChange: "acceptedProposalChange",
    rejectedPropsalChange: "rejectedPropsalChange",
    TransactionCompletedByTheSystem: "TransactionCompletedByTheSystem",
    PublicationWaitingApproval: "PublicationWaitingApproval",
    newChange: "newChange",
    changeRequestFinalize: "changeRequestFinalize",
    changeRejected: "changeRejected",
    changeFinalized: "changeFinalized",
    newMessage: "newMessage",
    share: "share",
    ToThirdPartyHaveOwnerInterest: "ToThirdPartyHaveOwnerInterest",
    ToOwnerArticleHeWant: "ToOwnerArticleHeWant",
    ToOwnerThirdPartyWantArticle: "ToOwnerThirdPartyWantArticle",
    ToThirdPartyWantOwnerArticle: "ToThirdPartyWantOwnerArticle",
    ToOwnerSameInterest: "ToOwnerSameInterest",
    ToThirdPartySameInterest: "ToThirdPartySameInterest",
}

export default collectionNotifications
