import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AvatarUser from '../AvatarUser';
import { formatPrice } from '../../utilityFunctions';
import {
  Menu,
  Callout,
  Icon,
  Button,
  Position,
  Spinner,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import SearchByCategories from './SearchByCategories';

const UserBubble = (props) => {
  const getImagePicture = () => {
    return {
      picture_users: props.imageProfile,
      name: props.fullName.split(' ')[0],
      last_name: props.fullName.split(' ')[1],
      avatar: props.avatar,
    };
  };

  return (
    <Menu className="scroll-80 pop-perfil padding0">
      <Callout className="remove-rounded-corners padding-popover-user gradientBackgroundAnimated">
        <div className="headerUserCard margin0">
          <div className="AvatarContainer">
            <AvatarUser user={getImagePicture()} />
          </div>
          <div className="user-details-container">
            <h4 className="margin0 white-text">
              {props.fullName ? props.fullName : null}
            </h4>
            <div className="flex flex-row fund-row">
              <Link to="/payment_method">
                <Icon
                  className="white-text funds-button"
                  icon="plus"
                  iconSize={22}
                />
              </Link>
              <p className="bp3-form-helper-text margin0">
                Saldo: {localStorage.getItem('currency')}{' '}
                {props.otherInfo.balance
                  ? formatPrice(props.otherInfo.balance)
                  : null}
              </p>
            </div>
          </div>
        </div>
      </Callout>
      <Link to="/profile">
        <Menu.Item
          icon={
            <Icon
              className="remove-margin-top"
              icon="id-number"
              iconSize={22}
            />
          }
          className="user-menu-option"
          text="Perfil"
        />
      </Link>
      <Link to="/my-publications">
        <Menu.Item
          icon={
            <Icon
              className="remove-margin-top"
              icon="applications"
              iconSize={22}
            />
          }
          className="user-menu-option"
          text="Mis Publicaciones"
        />
      </Link>
      <Link to="/ads-dashboards">
        <Menu.Item
          icon={
            <Icon
              className="remove-margin-top"
              icon="star-empty"
              iconSize={22}
            />
          }
          className="user-menu-option"
          text="Publicaciones destacadas"
        />
      </Link>
      <Menu.Divider />
      <Link to="/wall">
        <strong>
          <Menu.Item
            icon={
              <Icon className="remove-margin-top" icon="map-marker" iconSize={22} />
            }
            className="user-menu-option"
            text="Mural"
          />
        </strong>
      </Link>
      {/* Link provisional */}
      <Link to="/requests">
        <Menu.Item
          icon={<Icon className="remove-margin-top" icon="pin" iconSize={22} />}
          className="user-menu-option"
          text="Publicaciones seguidas"
        />
      </Link>
      <Link to="/requests">
        <Menu.Item
          icon={
            <Icon
              className="remove-margin-top"
              icon="swap-horizontal"
              iconSize={22}
            />
          }
          className="user-menu-option"
          text="Propuestas de intercambio"
        />
      </Link>
      <Link to="/requests?tab=2">
        <Menu.Item
          icon={
            <Icon
              className="remove-margin-top"
              icon="arrows-horizontal"
              iconSize={22}
            />
          }
          className="user-menu-option"
          text="Intercambios en proceso"
        />
      </Link>
      <Link to="/requests?tab=3">
        <Menu.Item
          icon={
            <Icon className="remove-margin-top" icon="history" iconSize={22} />
          }
          className="user-menu-option"
          text="Historial de intercambios"
        />
      </Link>
      <Link to="/new_publication">
        <strong>
          <Menu.Item
            className="user-menu-publish"
            text="Publicar"
            icon={
              <Icon
                className="user-menu-publish remove-margin-top"
                icon="plus"
                iconSize={22}
              />
            }
          />
        </strong>
      </Link>

      <Menu.Divider />
      <Link to="/configurations">
        <Menu.Item
          className="user-menu-option"
          text="Configuración y privacidad"
          icon={<Icon className="remove-margin-top" icon="cog" iconSize={22} />}
        />
      </Link>
      <Menu.Divider />
      <Link to="/logout">
        <Menu.Item
          className="user-menu-option"
          icon={
            <Icon className="remove-margin-top" icon="log-out" iconSize={22} />
          }
          text="Cerrar sesión"
        />
      </Link>
    </Menu>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.all,
    fullName: state.auth.fullName,
    otherInfo: state.auth.otherInfo,
    imageProfile: state.auth.imageProfile,
    avatar: state.auth.avatar,
  };
};

export default connect(mapStateToProps)(withRouter(UserBubble));
