import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * @param {Object} props
 * @param {Number} props.idPub Id publication
 * @param {Boolean} props.updating If is true, button es showed with a loading
 * @param {Function} props.desactivePub  It is trigerred when button is pressed
 */
const DesactivePublication = ({ idPub, updating, desactivePub }) => (
  <div className="buttonContainer">
    <Button
      className="orangeButton"
      onClick={() => desactivePub(idPub)}
      loading={updating}
    >
      <Icon className="icon" icon="stop" />
      Archivar
    </Button>
    <Link to="/adForm">
      <Button className="blackButton">
        <Icon className="icon" icon="stop" />
        Promocionar
      </Button>
    </Link>
  </div>
);

DesactivePublication.propTypes = {
  idPub: PropTypes.number.isRequired,
  updating: PropTypes.bool,
  desactivePub: PropTypes.func.isRequired,
};

export default DesactivePublication;
