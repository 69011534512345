import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  Button,
  FormGroup,
  InputGroup,
  Position,
  Popover,
  Icon,
  Callout
} from "@blueprintjs/core";
import { Container, Row, Col } from "react-grid-system";
import "./recoverPassword/RecoverPassword.scss";
import {
  minCharacter,
  thereUpper,
  thereNumber,
  thereSpecialChar,
  spaceValidation,
  addClassElement,
  removeClassElement
} from "../utilityFunctions";
import { config } from "../system/Config";
import axios from "axios";
import Footer from "../components/Footer";
import { Toast } from '../utility/toast';

class RecoverPassword extends Component {
  state = {
    password: "",
    repeatPassword: "",
    loading: false,
    token: "",
    focusPassword: false,
    passwordComplete: false,
    helperTexts: {
      password: "",
      repeatPassword: ""
    },
    success: false
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    this.setState({ token: token });
  }

  inputChangeHandler = event => {
    const validationsPassword = () => {
      let removeClassInfo = () => {
        ["errorInput", "checkInput"].forEach(value => {
          removeClassElement("password", value);
          removeClassElement("repeatPassword", value);
        });
      };
      removeClassInfo();
      let valid = true;
      const password = this.state.password;
      const repeatPassword = this.state.repeatPassword;
      let helperTexts = { ...this.state.helperTexts };

      if (
        !minCharacter(password) ||
        !thereUpper(password) ||
        !thereNumber(password) ||
        !thereSpecialChar(password) ||
        !spaceValidation(password)
      ) {
        addClassElement("password", "errorInput");
        valid = false;
      } else {
        addClassElement("password", "checkInput");
      }

      if (!spaceValidation(repeatPassword) || password !== repeatPassword) {
        addClassElement("repeatPassword", "errorInput");
        helperTexts["repeatPassword"] = "Las contraseñas deben ser iguales";
        valid = false;
      } else {
        helperTexts["repeatPassword"] = "";
        addClassElement("repeatPassword", "checkInput");
      }

      this.setState({ passwordComplete: valid, helperTexts });
    };

    this.setState({ [event.target.id]: event.target.value }, () =>
      validationsPassword()
    );
  };

  focusPassword = () => {
    this.setState({ focusPassword: true });
  };

  blurPassword = () => {
    this.setState({ focusPassword: false });
  };

  sendToApi = () => {
    this.setState({ loading: true });
    let dataAPI = { token: this.state.token, password: this.state.password };
    axios
      .post(`${config.URL_API}/update_password`, dataAPI)
      .then(data => {
        if (data.data.response === "password update") {
          Toast.show({ message: 'La contraseña ha sido cambiada exitosamente', intent: 'warning' });
          this.setState({ loading: false, success: true });
        } else {
          Toast.show({ message: 'Lo sentimos, ha habido un error', intent: 'danger' });
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        Toast.show({ message: 'Lo sentimos, ha habido un error', intent: 'danger' });
        this.setState({ loading: false });
      });
  };

  requestKey = event => {
    if (event.which === 13) {
      if (this.state.passwordComplete) {
        this.sendToApi();
      }
    }
  };

  render() {
    return (
      <>
        <section className="recoverPasswordSection mt-20 sm:mt-8 sm:pt-2 md:pt-2 lg:pt-0 xl:pt-0">
          <div className="gradientBackground gradientBackgroundAnimated"/>
          <div className="recoverPasswordContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1 className="textAlignCenter textColorWhite text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl m-8 sm:m-10 md:m-10 lg:m-10 xl:m-10">
                    Restablecer nueva contraseña
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="marginBottom-1em">
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Popover
                          isOpen="true"
                          content={
                            this.state.focusPassword ? (
                              <div className="popOverContent">
                                <Callout
                                  className=""
                                  icon="key"
                                  title="Tu contraseña debe contener"
                                >
                                  <ul>
                                    <li>
                                      {!minCharacter(this.state.password) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos seis caracteres
                                    </li>
                                    <li>
                                      {!thereUpper(this.state.password) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos una mayúscula
                                    </li>
                                    <li>
                                      {!thereNumber(this.state.password) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos un caracter numérica
                                    </li>
                                    <li>
                                      {!thereSpecialChar(
                                        this.state.password
                                      ) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos un caracter especial (!"#@?-_")
                                    </li>
                                  </ul>
                                </Callout>
                              </div>
                            ) : null
                          }
                          position={Position.TOP}
                        >
                          <FormGroup
                            label="Nueva Contraseña"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <InputGroup
                              type="password"
                              
                              fill={true}
                              disabled={this.state.success}
                              placeholder="Nueva Contraseña"
                              id="password"
                              onFocus={this.focusPassword}
                              onBlur={this.blurPassword}
                              value={this.state.password}
                              onChange={this.inputChangeHandler}
                            />
                          </FormGroup>
                        </Popover>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup
                          label="Confirmar contraseña"
                          helperText={this.state.helperTexts["repeatPassword"]}
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <InputGroup
                            type="password"
                            
                            fill={true}
                            placeholder="Confirmar contraseña"
                            disabled={this.state.success}
                            id="repeatPassword"
                            onKeyPress={this.requestKey}
                            value={this.state.repeatPassword}
                            onChange={this.inputChangeHandler}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!this.state.success ? (
                      <Button
                        className="BtnLCYellow"
                        large="true"
                        loading={this.state.loading}
                        fill="true"
                        onClick={this.sendToApi}
                        disabled={
                          !this.state.passwordComplete || this.state.success
                        }
                      >
                        Restaurar contraseña
                      </Button>
                    ) : null}

                    {this.state.success ? (
                      <Link to="/login">
                        <Button
                          className="BtnLCYellow"
                          large="true"
                          fill="true"
                        >
                          Ir a Iniciar Sesion
                        </Button>
                      </Link>
                    ) : null}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Footer />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(RecoverPassword);
