import * as actionTypes from '../actions/actionTypes';

import { updateObject, AUTOMATIC_MESSAGE } from "utility";
import { notificationStatus } from "const";

const getInitialState = () => {
  let state = {
    allData: {
      proposal_changes: {pending: [], accepted: [], rejected: []},
      chats: [],
      comments: [],
      shares: [],
      changes: {
        trans_pub_owner: { pending: [], finalized: [], rejected: [] },
        trans_interested: { pending: [], finalized: [], rejected: [], unRanked: [] }
      },
      likes: [],
      good_deals: [],
      changes_canceled: []
    },
    toastData: {
      proposal_changes: {pending: [], accepted: [], rejected: []},
      chats: [],
      comments: [],
      shares: [],
      changes: {
        trans_pub_owner: { pending: [], finalized: [], rejected: [], requestFinalized: [] },
        trans_interested: { pending: [], finalized: [], rejected: [] }
      },
      likes: [],
      good_deals: [],
      changes_canceled: []
    },
    notificationsData: {
      proposal_changes: {pending: [], accepted: [], rejected: []},
      chats: [],
      comments: [],
      shares: [],
      changes: {
        trans_pub_owner: { pending: [], finalized: [], rejected: [] },
        trans_interested: { pending: [], finalized: [], rejected: [] }
      },
      likes: [],
      good_deals: [],
      changes_canceled: []
    },
    bubbleData: {
      proposal_changes: {pending: 0, accepted: 0, rejected: 0},
      chats: 0,
      comments: 0,
      shares: 0,
      changes: {
        trans_pub_owner: { pending: 0, finalized: 0, rejected: 0 },
        trans_interested: { pending: 0, finalized: 0, rejected: 0 }
      },
      likes: 0,
      good_deals: 0,
      changes_canceled: 0
    },
    initialLoad: true,
    updating: true
  }
  return state;
}

const initialState = getInitialState();

const resetData = (state, action) => {
  return updateObject(state, getInitialState());
}

const setAllData = (state, action) => {
  const userId = localStorage.getItem('userId');
  const comments = action.allData.comments.filter( comment => comment.user_to_id.toString() !== userId);
  let allData = {...state.allData};
  // comentarios
  allData.comments = comments;

  // shares
  allData.shares = allData.shares;
  
  // propuestas de cambio
  let proposal_changes = {pending: [], accepted: [], rejected: []};
  action.allData.proposal_changes.forEach( proposal => {
    switch(proposal.status){
      case notificationStatus.PENDING:
        proposal_changes.pending.push(proposal);
        break;
      case notificationStatus.APPROVED:
        proposal_changes.accepted.push(proposal);
        break;
      case notificationStatus.REJECTED:
        proposal_changes.rejected.push(proposal);
        break;
    }
  });
  allData.proposal_changes = proposal_changes;
  // cambios
  let trans_pub_owner = {pending: [], finalized: [], rejected: [], requestFinalize: []};
  let trans_interested = {pending: [], finalized: [], rejected: [], requestFinalize: [], unRanked: []};
  action.allData.changes.trans_pub_owner.forEach( change => {
    if (!change.complete && !change.rejected){
      trans_pub_owner.pending.push(change);
    } 
    else if (change.complete && !change.rejected){
      trans_pub_owner.finalized.push(change);
    } 
    else if (change.rejected && !change.complete){
      trans_pub_owner.rejected.push(change);
    }
    // si es una transaccion con solicitud de finalizacion
    if (change.request_finalize && !change.complete && !change.rejected){
      trans_pub_owner.requestFinalize.push(change);
    }
  })

  action.allData.changes.trans_interested.forEach( change => {
    if (!change.complete && !change.rejected){
      trans_interested.pending.push(change);
    } 
    else if (change.complete  && !change.rejected){
      trans_interested.finalized.push(change);
    } 
    else if (change.rejected && !change.complete){
      trans_interested.rejected.push(change);
    }
    // si es una transaccion con solicitud de finalizacion
    if (change.request_finalize && !change.complete && !change.rejected){
      trans_interested.requestFinalize.push(change);
    }
    // si es una transaccion sin rankear
    if (change.complete && !change.rejected && !change.ranked) {
      trans_interested.unRanked.push(change);
    }
  })
  allData.changes.trans_pub_owner = trans_pub_owner;
  allData.changes.trans_interested = trans_interested;
  allData.chats = action.allData.chats;

  return updateObject(state, {
    allData: allData,
    initialLoad: false,
    updating: false
  })
}

const setToastData = (state, action) => {
  const userId = localStorage.getItem('userId');
  const comments = action.allData.comments.filter(comment => !comment.toast_notification && comment.user_to_id.toString() !== userId);
  let proposal_changes = {pending: [], accepted: [], rejected: []};

  // propuestas de cambio
  action.allData.proposal_changes.forEach( proposal => {
    switch(proposal.status){
      // si es una propuesta de cambio pendiente, me interesa que le aparezca la notificacion
      // al dueno de la publicacion, ej: "Mira Daniel, Mirna te hizo una publicacion"
      case notificationStatus.PENDING:
        if (proposal.owner_publication_id.toString() === userId && !proposal.toast_notification_create) {
          proposal_changes.pending.push(proposal);
        }
        break;
      // si es una propuesta que se acepto me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.APPROVED:
        if (proposal.interested_user_id.toString() === userId && !proposal.toast_notification_update) {
          proposal_changes.accepted.push(proposal);
        }
        break;
      // si es una propuesta que se rechazo me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.REJECTED:
        if (proposal.interested_user_id.toString() === userId && !proposal.toast_notification_update) {
          proposal_changes.rejected.push(proposal);
        }
        break;
    }
  });

  let trans_pub_owner = {pending: [], finalized: [], rejected: [], requestFinalized: []};
  let trans_interested = {pending: [], finalized: [], rejected: []};
  action.allData.changes.trans_pub_owner.forEach( change => {

    // si es una transaccion pendiente, me interesa que la aparezca la notificacion al propietario de la publicacion
    if (!change.complete && !change.rejected && !change.toast_notification_create) {
      trans_pub_owner.pending.push(change);
    }

    if (!change.complete && !change.rejected && !change.toast_notification_update && change.request_finalize){
      trans_pub_owner.requestFinalized.push(change)
    }
  });

  action.allData.changes.trans_interested.forEach( change => {
    // si es una transaccion finalizada me intresa que la salga la notifacion al interesado
    if (change.complete && !change.rejected && !change.toast_notification_update) {
      trans_interested.finalized.push(change);
    }
    // si es una transaccion rechazada me interesa que la salga la notificacion al interesado
    else if (change.rejected && !change.complete && !change.toast_notification_update) {
      trans_interested.rejected.push(change);
    } 
  });

  // mensaajes
  let copyChats = [...action.allData.chats]
  let chats = [];
  copyChats.forEach( chat => {
    chat.messagesToast = [];
    let messageUnRead = false;
    chat.messages.forEach( (message) => {
      if (message.user_from.toString() !== userId && !message.toast_notification){
        chat.messagesToast.push(message);
        messageUnRead = true;
      }
    })
    if (messageUnRead){
      chats.push(chat)
    }
  });

  // cambios cancelados
  let changes_canceled = [];
  action.allData.changes_canceled.forEach( change => {
    if (change.finalized_user_id.toString() !== userId && !change.toast){
      changes_canceled.push(change);
    }
  });

  let toastData = {...state.toastData};
  toastData.shares = action.allData.shares;
  toastData.comments = comments;
  toastData.proposal_changes = proposal_changes;
  toastData.chats = chats;
  toastData.changes_canceled = changes_canceled;
  toastData.changes.trans_pub_owner = trans_pub_owner;
  toastData.changes.trans_interested = trans_interested;

  return updateObject(state, {
    toastData: toastData
  })
}

const setNotificationsData = (state, action) => {
  const userId = localStorage.getItem('userId');
  const comments = action.allData.comments.filter(comment => comment.user_to_id.toString() !== userId);
  let proposal_changes = {pending: [], accepted: [], rejected: []};

  // propuestas de cambio
  action.allData.proposal_changes.forEach( proposal => {
    switch(proposal.status){
      // si es una propuesta de cambio pendiente, me interesa que le aparezca la notificacion
      // al dueno de la publicacion, ej: "Mira Daniel, Mirna te hizo una publicacion"
      case notificationStatus.PENDING:
        if (proposal.owner_publication_id.toString() === userId ) {
          proposal_changes.pending.push(proposal);
        }
        break;
      // si es una propuesta que se acepto me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.APPROVED:
        if (proposal.interested_user_id.toString() === userId) {
          proposal_changes.accepted.push(proposal);
        }
        break;
      // si es una propuesta que se rechazo me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.REJECTED:
        if (proposal.interested_user_id.toString() === userId) {
          proposal_changes.rejected.push(proposal);
        }
        break;
    }
  });


  // cambios
  let trans_pub_owner = {pending: [], finalized: [], rejected: [], requestFinalize: []};
  let trans_interested = {pending: [], finalized: [], rejected: [], requestFinalize: []};
  action.allData.changes.trans_pub_owner.forEach( change => {

    // si es una transaccion pendiente, me interesa que la aparezca la notificacion al propietario de la publicacion
    if (!change.complete && !change.rejected) {
      trans_pub_owner.pending.push(change);
    }

    // si es una transaccion con solicitud de finalizacion
    if (change.request_finalize && !change.complete && !change.rejected){
      trans_pub_owner.requestFinalize.push(change);
    }
  });

  action.allData.changes.trans_interested.forEach( change => {
    // si es una transaccion finalizada me intresa que la salga la notifacion al interesado
    if (change.complete && !change.rejected) {
      trans_interested.finalized.push(change);
    }
    // si es una transaccion rechazada me interesa que la salga la notificacion al interesado
    else if (change.rejected && !change.complete) {
      trans_interested.rejected.push(change);
    } 

    // si es una transaccion con solicitud de finalizacion
    if (change.request_finalize && !change.complete && !change.rejected){
      trans_interested.requestFinalize.push(change);
    }
  });

  // mensajes
  let copyChats = [...action.allData.chats]
  let chats = [];
  copyChats.forEach( (chat, index) => {
    let messagesFiltered = [];
    chat.messages.forEach( message => {
      if (message.user_from.toString() !== userId){
        messagesFiltered.push(message);
      }
    })
    chats.push(chat)
    chats[index].messagesFiltered = messagesFiltered;
  })

  // buenos negocios
  let good_deals = action.allData.good_deals.filter( value => value.user_id.toString() !== userId);

  let notificationsData = {};
  notificationsData.shares = action.allData.shares;
  notificationsData.comments = comments;
  notificationsData.chats = chats;
  notificationsData.good_deals = good_deals;
  notificationsData.proposal_changes = proposal_changes;
  notificationsData.changes.trans_pub_owner = trans_pub_owner;
  notificationsData.changes.trans_interested = trans_interested;

  return updateObject(state, {
    notificationsData: notificationsData
  })
}

const setBubbleData = (state, action) => {
  const userId = localStorage.getItem('userId');
  let comments = 0
  action.allData.comments.forEach(comment => {
    if (comment.user_to_id.toString() !== userId && !comment.read){
      ++comments;
    }
  })
  let proposal_changes = {pending: 0, accepted: 0, rejected: 0};

  // propuestas de cambio
  action.allData.proposal_changes.forEach( proposal => {
    switch(proposal.status){
      // si es una propuesta de cambio pendiente, me interesa que le aparezca la notificacion
      // al dueno de la publicacion, ej: "Mira Daniel, Mirna te hizo una publicacion"
      case notificationStatus.PENDING:
        if (proposal.owner_publication_id.toString() === userId && !proposal.read_create) {
          ++proposal_changes.pending;
        }
        break;
      // si es una propuesta que se acepto me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.APPROVED:
        if (proposal.interested_user_id.toString() === userId && !proposal.read_update) {
          ++proposal_changes.accepted;
        }
        break;
      // si es una propuesta que se rechazo me interesa que le salga la notificacion al usuario interesado
      case notificationStatus.REJECTED:
        if (proposal.interested_user_id.toString() === userId && !proposal.read_update) {
          ++proposal_changes.rejected;
        }
        break;
    }
  });

  // cambios
  let trans_pub_owner = {pending: 0, finalized: 0, rejected: 0};
  let trans_interested = {pending: 0, finalized: 0, rejected: 0};
  action.allData.changes.trans_pub_owner.forEach( change => {

    // si es una transaccion pendiente, me interesa que la aparezca la notificacion al propietario de la publicacion
    if (!change.complete && !change.rejected && !change.read_create) {
      ++trans_pub_owner.pending
    }
  });

  action.allData.changes.trans_interested.forEach( change => {
    // si es una transaccion finalizada me intresa que la salga la notifacion al interesado
    if (change.complete && !change.rejected && !change.read_update) {
      ++trans_interested.finalized
    }
    // si es una transaccion rechazada me interesa que la salga la notificacion al interesado
    else if (change.rejected && !change.complete &&  !change.read_update) {
      ++trans_interested.rejected
    } 
  });

  // mensajes
  let chats = 0;
  let copyChats = [...action.allData.chats]
  copyChats.forEach( chat => {
    chat.messages.forEach(message => {
      if (message.user_from.toString() !== userId && !message.read){
        ++chats;
      } 
    })
  });

  const isRead = good_deal => {
    if (userId === good_deal.transaction.interested_id.toString()){
      return good_deal.read_interested;
    } else {
      return good_deal.read_owner;
    }
  }

  // buenos negocios
  let good_deals = 0;
  action.allData.good_deals.forEach( good_deal => {
    if (good_deal.user_id.toString() !== userId && !isRead(good_deal)){
      ++good_deals;
    }
  })

  let bubbleData = {...state.bubbleData};
  bubbleData.comments = comments;
  bubbleData.proposal_changes = proposal_changes;
  bubbleData.changes.trans_pub_owner = trans_pub_owner;
  bubbleData.good_deals = good_deals;
  bubbleData.chats = chats;
  bubbleData.changes.trans_interested = trans_interested;
  return updateObject( state, {
    bubbleData: bubbleData
  });
}

const setUpdating = (state, action) => {
  return updateObject(state, {
    updating: action.updating
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_ALL_DATA: return setAllData(state, action);
    case actionTypes.SET_TOAST_DATA: return setToastData(state, action);
    case actionTypes.SET_NOTIFICATION_DATA: return setNotificationsData(state, action);
    case actionTypes.SET_BUBBLE_DATA: return setBubbleData(state, action);
    case actionTypes.SET_UPDATING: return setUpdating(state, action);
    case actionTypes.RESET_DATA: return resetData(state, action)
    default:
      return state;
  }
}

export default reducer;
