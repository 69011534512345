import React, { Component } from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import LinksInfoPages from './../components/LinksInfoPages';
import Faq from './faq/Faq';

class HelpCenter extends Component {
  render() {
    return (
      <>
        <div className="HelpCenter">
          <div className="pt-16 sm:pt-16 md:pt-16 lg:pt-16 xl:pt-16">
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <Card elevation={Elevation.ONE}>
                    <h2 className="margin0">Preguntas Frecuentes</h2>
                  </Card>
                  <Faq />
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default HelpCenter;
