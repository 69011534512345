import React, { Component } from "react";
import FollowAction from "./../../../../components/FollowAction";
import { Callout, Tag, Button } from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  needSkeletonClass,
} from "./../../../../utilityFunctions";
import { config } from "./../../../../system/Config";
import { getHeader } from "./../../../../system/Sesion";
import "./ownerInfo/OwnerInfo.scss";
import AvatarUser from "./../../../../components/AvatarUser";
import RankingGraph from "./../../../../components/RankingGraph";
import RankingUserStar from "./../../../../components/RankingStar";

class OwnerInfo extends Component {
  state = {
    user: {},
    loading: true,
    publicationsActive: []
  };



  getInfoUser = () => {
    axios
      .get(`${config.URL_API}/users/${this.props.idUser}`, {
        headers: getHeader(),
      })
      .then((data) => {

        if(data.status == 200){
          this.setState({ user: data.data.user, loading: false });

          let auxArray = [];

          this.state.user.publication_owners.forEach(item => {
            if(item.publication_status == "Activo"){
              auxArray.push(item);
            }
          });
          this.setState({
            publicationsActive: auxArray
          });
        }

      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  componentWillMount() {
    this.getInfoUser();
  }

  render() {
    return (
      <>
        <div className="popOverOwnerInfo padding-05em">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="headerUserCard">
                <div
                  className={needSkeletonClass(
                    !this.state.loading,
                    "AvatarContainer"
                  )}
                >
                  <AvatarUser user={this.state.user} />
                </div>
                <div
                  className={needSkeletonClass(
                    !this.state.loading,
                    "UserDetailsContainer marginBottom-05em"
                  )}
                >
                  <h4 className={needSkeletonClass(!this.state.loading, "")}>
                    {this.state.user.name} {this.state.user.last_name}
                  </h4>
                  <p className="bp3-form-helper-text">{this.state.user.city}</p>
                </div>
              </div>
              <div>
                <p className={needSkeletonClass(!this.state.loading, "")}></p>
              </div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Callout className="marginBottom-1em">
                    <div
                      className={needSkeletonClass(
                        !this.state.loading,
                        "UserChangeDetail"
                      )}
                    >
                      <div
                        className={
                          "pt-4 pb-2 sm:pt-4 sm:pb-0 md:pt-4 md:pb-0 lg:pt-4 lg:pb-0 xl:pt-4 xl:pb-0"
                        }
                      >
                        <RankingGraph user={this.state.user} />
                      </div>
                      <div
                        className={
                          "pt-4 pb-2 sm:pt-4 sm:pb-0 md:pt-4 md:pb-0 lg:pt-4 lg:pb-0 xl:pt-4 xl:pb-0"
                        }
                      >
                        <RankingUserStar user={this.state.user} />
                      </div>
                    </div>
                  </Callout>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Callout className="marginBottom-1em">
                    <div
                      className={needSkeletonClass(
                        !this.state.loading,
                        "UserChangeDetail"
                      )}
                    >
                      <h2>
                        {this.state.user.publication_owners
                          ? this.state.publicationsActive.length
                          : null}
                      </h2>
                      <p className="bp3-form-helper-text">Publicaciones</p>
                    </div>
                  </Callout>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Callout className="marginBottom-1em">
                    <div
                      className={needSkeletonClass(
                        !this.state.loading,
                        "UserChangeDetail"
                      )}
                    >
                      <h2>{this.state.user.change}</h2>
                      <p className="bp3-form-helper-text">Cambios</p>
                    </div>
                  </Callout>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Callout className={"marginBottom-1em"}>
                    <div
                      className={needSkeletonClass(
                        !this.state.loading,
                        "UserChangeDetail"
                      )}
                    >
                      <h2>
                        {this.state.user.following
                          ? this.state.user.following.length
                          : null}
                      </h2>
                      <p className="bp3-form-helper-text">Seguidos</p>
                    </div>
                  </Callout>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Callout className={"marginBottom-1em"}>
                    <div
                      className={needSkeletonClass(
                        !this.state.loading,
                        "UserChangeDetail"
                      )}
                    >
                      <h2>
                        {this.state.user.followers
                          ? this.state.user.followers.length
                          : null}
                      </h2>
                      <p className="bp3-form-helper-text">Seguidores</p>
                    </div>
                  </Callout>
                </Col>
              </Row>
              <FollowAction
                updateInfoUser={this.getInfoUser}
                userId={this.props.idUser}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Link to={`/publications/third/${this.state.user.id}`}>
                <Button
                  className={"BtnLCYellow marginTop-05em"}
                  fill={true}
                  icon={"user"}
                  text={"Ver publicaciones"}
                />
              </Link>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default OwnerInfo;
