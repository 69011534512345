import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Card,
  Elevation,
  Callout,
  AnchorButton,
  Button,
  Dialog,
} from "@blueprintjs/core";
import { Link } from "react-router-dom";
import TypeSuscription from "./../components/TypeSuscription";
import RankingGraph from "./../components/RankingGraph";
import RankingStar from "./../components/RankingStar";
import {
  initialValue,
  typeSuscription,
  dateMonthString,
} from "./../utilityFunctions";
import { config } from "./../system/Config";
import { connect } from "react-redux";
import "./userStatsInfo/UserStatsInfo.scss";

class UserStatsInfo extends Component {
  publicationsActive = [];

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    let user;
    if (this.props.user && !this.publicationsActive.length) {
      user = this.props.user;
      this.props.user.publication_owners.forEach((item) => {
        if (item.publication_status === "Activo") {
          this.publicationsActive.push(item);
        }
      });
    } else {
      
      user = {};
      user.activePublications = this.props.otherInfoUser.activePublications;
      user.change = this.props.otherInfoUser.change;
      // user.following = this.props.following ? new Array(this.props.following):[];
      // user.followers = this.props.followers ? new Array(this.props.followers):[];
      user.reputation = this.props.otherInfoUser.reputation;
      user.registration_date = this.props.otherInfoUser.registration_date;
      this.props.userPublications.forEach((item) => {
        if (item.publication_status === "Activo") {
          this.publicationsActive.push(item);
        }
      });
    }

    return (
      <>
        {/* <h4>Reputación</h4>
          <RankingGraph user={user} />
          <RankingStar user={user} /> */}
        <div className={"marginTop-1em"}>
          <Row>
            <Col xs={6} sm={12} md={6} lg={6}>
              <Callout className="marginBottom-1em">
                <div className="UserChangeDetail">
                  <div className="PubCount">
                    {this.publicationsActive.length === 0 ? (
                      <h2>0</h2>
                    ) : (
                      <h2>{this.publicationsActive.length}</h2>
                    )}
                  </div>
                  <p className="bp3-form-helper-text margin0">Publicaciones Activas</p>
                </div>
              </Callout>
            </Col>
            <Col xs={6} sm={12} md={6} lg={6}>
              <Callout className="marginBottom-1em">
                <div className="UserChangeDetail">
                  <h2 className={"ChangeCount"}>{user.change}</h2>
                  <p className="bp3-form-helper-text margin0">Cambios Exitosos</p>
                </div>
              </Callout>
            </Col>
            {/* <Col xs={6} sm={6} md={6} lg={6}>
              <Callout>
                <div className="UserChangeDetail">
                  <h2 className={"margin0"}>{user.following.length}</h2>
                  <p className="bp3-form-helper-text margin0">Seguidos</p>
                </div>
              </Callout>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <Callout>
                <div className="UserChangeDetail">
                  <h2 className={"margin0"}>{user.followers.length}</h2>
                  <p className="bp3-form-helper-text margin0">Seguidores</p>
                </div>
              </Callout>
            </Col> */}
          </Row>
        </div>
      </>
    );
  }
}

// export default UserStatsInfo;
const mapStateToProps = (state) => {
  return {
    userFullName: state.auth.fullName,
    userSuscription: state.auth.suscription,
    userPublications: state.auth.publications,
    otherInfoUser: state.auth.otherInfo,
    followers: state.auth.followers,
    following: state.auth.following,
    pictureData: state.auth.imageProfile,
    pictureCover: state.auth.imageCover,
    userId: state.auth.userId,
    activePubs: state.publicationsActive,
  };
};

export default connect(mapStateToProps)(UserStatsInfo);
