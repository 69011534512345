import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button as BPButton } from '@blueprintjs/core';

import './Button.scss';

const Button = ({ className, bold, minWidth, text, hideTextOnMobile, ...props }) => {
  const intentClass = props.intent ? `button--${props.intent}` : '';
  const boldClass = bold ? 'button--bold' : '';
  const minWidthClass = minWidth ? 'button--min-with' : '';
  const classNames = `button ${className || ''} ${intentClass} ${boldClass} ${minWidthClass}`;
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const BPText = hideTextOnMobile && isMobile ? '' : text;

  return (
    <BPButton className={classNames} text={BPText} {...props} />
  );
};
export default Button;