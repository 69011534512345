import React from 'react';
import { rankingUserClass } from '../utilityFunctions';
import '../components/utility.css';

const RankingGraph = props => {
  const user = props.user;
  const classesRanking = rankingUserClass(
    user.registration_date,
    user.reputation,
  );

  return (
    <div className="UserChangeDetail marginTop-1em marginBottom-1em">
      <div className="rankingGraphContent">
        <div className={classesRanking.new}>
          <p className="LeyendaRank">Malo</p>
        </div>
        <div className={classesRanking.regular}>
          <p className="LeyendaRank">Regular</p>
        </div>
        <div className={classesRanking.good}>
          <p className="LeyendaRank">Bueno</p>
        </div>
        <div className={classesRanking.excelent}>
          <p className="LeyendaRank">Excelente</p>
        </div>
      </div>
    </div>
  );
};

export default RankingGraph;
