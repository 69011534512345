import React from 'react';
import { Button, ButtonGroup, Callout, Divider, RangeSlider, Slider } from '@blueprintjs/core';

const SearchListFilterSkeleton = (props) => (
  <>
    <Callout>
      <h4 className="margin0 marginBottom-05em bp3-skeleton">
        Filtros de búsqueda
      </h4>
      <Button className="bp3-skeleton" minimal fill>
        Ver todo
      </Button>
    </Callout>

    <Divider className="marginTop-05em marginBottom-05em" />

    <Divider className="marginTop-05em marginBottom-05em" />

    <Callout>
      <div className="marginBottom-1em ">
        <h4 className="margin0 marginBottom-05em bp3-skeleton">
          Se encuentra a
        </h4>
        <Slider className="bp3-skeleton" />
      </div>
    </Callout>

    <Divider className="marginTop-05em marginBottom-05em" />

    <Callout>
      <div className="marginBottom-1em">
        <h4 className="margin0 marginBottom-05em bp3-skeleton">Precio</h4>
        <RangeSlider className="bp3-skeleton" />
      </div>
    </Callout>

    <Divider className="marginTop-05em marginBottom-05em" />

    <Callout>
      <div className="marginBottom-1em">
        <ButtonGroup minimal fill vertical>
          <div>
            <h4 className="margin0 bp3-skeleton">Condicion del articulo</h4>
            <Button className="bp3-skeleton">Todos</Button>
            <Button className="bp3-skeleton">Nuevo</Button>
            <Button className="bp3-skeleton">Usado</Button>
          </div>
          <div>
            <h4 className="margin0 bp3-skeleton">Condicion</h4>
            <Button className="bp3-skeleton">Excelente</Button>
            <Button className="bp3-skeleton">Bueno</Button>
            <Button className="bp3-skeleton">Con Detalles</Button>
            <Button className="bp3-skeleton">Para Respuesto</Button>
          </div>
        </ButtonGroup>
      </div>
    </Callout>

    <Divider className="marginTop-05em marginBottom-05em" />

    <Callout>
      <div className="marginBottom-1em">
        <ButtonGroup minimal fill vertical>
          <h4 className="margin0 marginBottom-05em bp3-skeleton">
            Ubicacion
          </h4>
          <Button className="bp3-skeleton" />
        </ButtonGroup>
      </div>
    </Callout>

    <Divider className="marginTop-05em marginBottom-05em" />

    <Callout>
      <div className="marginBottom-1em">
        <h4 className="margin0 marginBottom-05em bp3-skeleton">
          Caracteristicas
        </h4>
        <ButtonGroup minimal vertical>
          <Button className="bp3-skeleton">Lorem</Button>
          <Divider className="marginTop-1em marginBottom-1em" />
        </ButtonGroup>
      </div>
    </Callout>
  </>
);

export default SearchListFilterSkeleton;
