import React, { Component } from 'react';
import {
  Card,
  Button,
  FormGroup,
  InputGroup,
  Divider,
  Checkbox,
} from '@blueprintjs/core';
import { Container, Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from './../store/actions/index';
import { spaceValidation, emailValidation } from './../utilityFunctions';
import { config } from './../system/Config';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Footer from './../components/Footer';
import Recaptcha from 'reaptcha';
import MediaQuery from 'react-responsive';
import { Toast } from '../utility/toast';
import './login/Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      verifyCaptcha: false,
      captchaReady: false,
      renderedCaptcha: false,
    };
  }

  /**
   * It fires when the captcha is ready.
   */
  onLoad = () => this.setState({ captchaReady: true });

  /**
   * It fires when the captcha is verified.
   */
  onVerify = () => this.setState({ verifyCaptcha: true });

  /**
   * It fires when an error occurs in the captcha.
   */
  onErrorCaptcha = () => {
    Toast.show({ message: 'Por favor verifica tu conexion a internet', intent: 'warning', icon: 'warning-sign' });
  }

  componentDidUpdate() {
    /**
     * If in the reducer this property is "true" the user is redirected to the home.
     */
    if (this.props.loggued) {
      this.props.onSetLoggued(false);
      this.props.history.push(`/wall`);
    }

    /**
     * To avoid an error with the captcha library.
     */
    if (this.state.captchaReady && !this.state.renderedCaptcha) {
      this.setState({ renderedCaptcha: true }, () =>
        this.captcha.renderExplicitly(),
      );
    }
  }

  /**
   * Validations of form
   * @returns {Boolean}
   */
  validations = () => {
    let valid = true;
    if (!spaceValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escribe el email', icon: 'warning-sign' });
      valid = false;
    } else if (!emailValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escriba un email valido', icon: 'warning-sign' });
      valid = false;
    } else if (!spaceValidation(this.state.formData.password)) {
      Toast.show({ intent: 'warning', message: 'Por favor escribe la contraseña', icon: 'warning-sign' });
      valid = false;
    }
    return valid;
  };

  /**
   * Handle el change of the inputs.
   */
  inputChangeHandler = (event) => {
    const { value, id } = event.target;
    const formData = { ...this.state.formData };
    formData[id] = value;
    this.setState({ formData });
  };

  /**
   * It fires when the Login button is pressed.
   */
  login = () => {
    if (this.validations()) {
      // If the captcha has not been completed, we do NOT make the API request.
      if (!this.state.verifyCaptcha && !process.env.REACT_APP_LOCAL_ENV) {
        Toast.show({ message: 'Debe completar el Captcha', intent: 'warning', icon: 'warning-sign' });
        return;
      }
      this.props.onAuth(
        this.state.formData.email,
        this.state.formData.password,
      );
    }
  };

  /**
   * Google response
   * @param {Object} response
   */
  responseGoogle = (response) => {
    this.props.onAuthWithGoogle(response.tokenId);
  };

  /**
   * Facebook response
   * @param {Object} response
   */
  responseFacebook = (response) =>
    this.props.onAuthWithFacebook(response.accessToken, response.email);

  /**
   * If the pressed key is enter then login is done.
   * @param {Object} event
   * @returns {Function}
   */
  loginKey = (event) => {
    if (event.which === 13) {
      this.login();
    }
  };

  render() {
    const { formData } = this.state;
    const { email, password } = formData;
    const { loading } = this.props;

    return (
      <div>
        <section className="loginSection mt-20 sm:mt-8 sm:pt-2 md:pt-2 lg:pt-0 xl:pt-0">
          <div className="gradientBackground gradientBackgroundAnimated"></div>
          <div className="loginContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1
                    className="textAlignCenter textColorWhite text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl m-8 sm:m-10 md:m-10 lg:m-10 xl:m-10">
                    ¡Ingresa a tu cuenta aquí!
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="marginBottom-1em">
                    <FormGroup
                      label="Correo Electrónico"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        placeholder="Correo Electrónico"
                        value={email}
                        onChange={this.inputChangeHandler}
                        id="email"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Contraseña"
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        placeholder="contraseña"
                        onKeyPress={this.loginKey}
                        value={password}
                        onChange={this.inputChangeHandler}
                        id="password"
                        type="password"
                      />
                    </FormGroup>
                    <Row className="marginTop-1em">
                      <Col xs={12} sm={6} md={6} lg={6}>
                        ¿Ya tienes cuenta?
                        <Link to="registration"> Registrate</Link>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="mt-4">
                          <Checkbox
                            className="BtnLCPrimaryLink marginTop-05em"
                            checked={this.state.isEnabled}
                            label="Mantener la sesión abierta"
                            onChange={this.handleEnabledChange}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <div className="mt-2">
                          <Link to="fotgot_password">¿Olvidaste tu clave?</Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <MediaQuery query="(min-device-width: 348px)">
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="w-min">
                            <Recaptcha
                              className="Recaptcha marginTop-1em marginBottom-1em"
                              ref={(e) => (this.captcha = e)}
                              sitekey={config.KEY_CAPTCHA}
                              onLoad={this.onLoad}
                              onVerify={this.onVerify}
                              onError={this.onErrorCaptcha}
                              explicit
                            />
                          </div>
                        </Col>
                      </MediaQuery>
                      <MediaQuery query="(max-device-width: 347px)">
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Recaptcha
                            className="Recaptcha marginTop-1em marginBottom-1em"
                            ref={(e) => (this.captcha = e)}
                            sitekey={config.KEY_CAPTCHA}
                            onLoad={this.onLoad}
                            onVerify={this.onVerify}
                            onError={this.onErrorCaptcha}
                            explicit
                            size={'compact'}
                          />
                        </Col>
                      </MediaQuery>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          className="BtnLCYellow"
                          rightIcon="log-in"
                          large="true"
                          loading={loading}
                          fill="true"
                          onClick={this.login}
                        >
                          Entrar
                        </Button>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Divider className="marginTop-1em marginBottom-1em"/>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FacebookLogin
                          appId="787380315071516"
                          autoLoad={false}
                          callback={this.responseFacebook}
                          fields="name,email,picture"
                          isProcessing={false}
                          reAuthenticate={true}
                          textButton="Inicia sesión con Facebook"
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <GoogleLogin
                          className="buttonLoginGoogle marginTop-1em"
                          clientId={config.KEY_GOOGLE_LOGIN}
                          autoLoad={false}
                          isSignedIn={false}
                          onSuccess={this.responseGoogle}
                          onFailure={(err) => {
                            console.log('err', err);
                          }}
                          buttonText="Inicia sesión con Google"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Footer/>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggued: state.auth.loggued,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetLoggued: (value) => dispatch(actions.setLoggued(value)),
    onAuthWithGoogle: (tokenId) => dispatch(actions.authWithGoogle(tokenId)),
    onAuthWithFacebook: (tokenId, email) =>
      dispatch(actions.authWithFacebook(tokenId, email)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
