import React from "react";
import { Callout, AnchorButton, Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import AvatarUser from "./../../components/AvatarUser";
import FollowAction from "./../../components/FollowAction";
import RankingGraph from "./../../components/RankingGraph";
import RankingUserStar from "./../../components/RankingStar";
import { Row, Col } from "react-grid-system";
import axios from "axios";
import { getHeader } from "./../../system/Sesion";
import { connect } from "react-redux";
import { config } from "./../../system/Config";
import MediaQuery from "react-responsive";
import "../detailProduct/OwnerInfo.scss";

class OwnerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      publicationsActive: [],
    };
  }

  getInfoUser = () => {
    axios
      .get(`${config.URL_API}/users/${this.props.id_user}`, {
        headers: getHeader(),
      })
      .then((data) => {
        //this.setState({ user: data.data.user, loading: false });
        if (data.status == 200) {
          this.setState({ user: data.data.user, loading: false });

          let auxArray = [];

          this.state.user.publication_owners.forEach((item) => {
            if (item.publication_status == "Activo") {
              auxArray.push(item);
            }
          });
          this.setState({
            publicationsActive: auxArray,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  componentDidMount() {
    this.getInfoUser();
  }

  render() {
    const { user, loading } = this.state;
    const { isAuth } = this.props;

    if (loading) {
      return null;
    }

    return (
      <Callout>
        <div className="headerUserCard">
          <div className="AvatarContainer">
            <AvatarUser user={user} />
          </div>
          <div className="UserDetailsContainer">
            <h4 className={"margin0"}>{`${user.name} ${user.last_name}`}</h4>
            <p className="bp3-form-helper-text margin0">{user.city}</p>
          </div>
        </div>
        {isAuth && (
          <div className={"marginTop-1em marginBottom-1em"}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Link to={`/publications/third/${user.id}`}>
                  <Button
                  icon={"person"}
                    fill={true}
                    className="bp3-button bp3-fill BtnLCPrimary"
                  >
                    Ver Perfil
                  </Button>
                </Link>
              </Col>
            </Row>
            {/*<Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FollowAction
                  updateInfoUser={this.getInfoUser}
                  userId={user.id}
                />
              </Col>
            </Row> */}
          </div>
        )}
        {/* <Row>
          <MediaQuery query="(min-device-width: 768px)">
            <Col xs={6} sm={6} md={6} lg={6}>
              <Callout className="marginBottom-1em">
                <div className="UserChangeDetail">
                  <h2 className={"margin0 text-center"}>
                    {this.state.user.publication_owners
                      ? this.state.publicationsActive.length
                      : null}
                  </h2>
                  <p className="bp3-form-helper-text margin0 text-center">
                    Publicaciones
                  </p>
                </div>
              </Callout>
            </Col>
          </MediaQuery>

          <Col xs={6} sm={6} md={6} lg={6}>
            <Callout className="marginBottom-1em">
              <div className="UserChangeDetail bp3-text-overflow">
                <h2 className={"margin0 text-center"}>{user.change}</h2>
                <p className="bp3-form-helper-text margin0 text-center">
                  Cambios
                </p>
              </div>
            </Callout>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Callout>
              <div className="UserChangeDetail">
                <h2 className={"margin0 text-center"}>
                  {user.following.length}
                </h2>
                <p className="bp3-form-helper-text margin0 text-center">
                  Seguidos
                </p>
              </div>
            </Callout>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Callout>
              <div className="UserChangeDetail">
                <h2 className={"margin0 text-center"}>
                  {user.followers.length}
                </h2>
                <p className="bp3-form-helper-text margin0 text-center">
                  Seguidores
                </p>
              </div>
            </Callout>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div
              className={
                "pt-4 pb-2 sm:pt-4 sm:pb-0 md:pt-4 md:pb-0 lg:pt-4 lg:pb-0 xl:pt-4 xl:pb-0"
              }
            >
              <RankingGraph user={user} />
            </div> */}
            <div
              className={
                "pt-4 pb-2 sm:pt-4 sm:pb-0 md:pt-4 md:pb-0 lg:pt-4 lg:pb-0 xl:pt-4 xl:pb-0"
              }
            >
              <RankingUserStar user={user} />
            </div>
          </Col>
        </Row>
      </Callout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(OwnerInfo);
