import React, { Component } from 'react';
import axios from 'axios';
import { getHeader } from '../../system/Sesion';
import { datelocambio } from '../../utilityFunctions';
import { config } from '../../system/Config';

import RequestPublication from './RequestPublication';
import flechaCompleted from './images/flechacomplete.png';
import flechaRejectInt from './images/flecharejectint.png';
import flechaRejectProp from './images/flecharejectprop.png';
import flechaAwaitInt from './images/betado-gris-amarillo-.png';
import flechaAwaitProp from './images/betado-gris.png';
import flechasChange from './images/flechas.png';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: null,
      status: null,
      is_owner: null,
      profile: null,
    };
  }

  componentDidMount() {
    this.fetchTransaccion().then(async (data) => {
      let isOwner = data.data.interested_id != this.props.userId;
      let profile = null;
      if (!isOwner) {
        profile = (await this.fetchUserProfile(data.data.publication.owner_id))
          .data;
      }

      if (data.data.status === "complete") {
        let dataRanking = {
          owner_id: data.data.publication.owner_id,
          transaction_id: data.data.id,
          interested_id: data.data.interested_id,
        };
        let ranking = (await this.fetchRanking(dataRanking)).data;
        if (ranking) {
          data.data.interested_rank = ranking.interested_rank;
          data.data.owner_rank = ranking.owner_rank;
        }
      }

      this.setState({
        transaction: data.data,
        status: data.data.status,
        is_owner: isOwner,
        profile,
      });
    });
  }

  fetchTransaccion() {
    return axios.get(
      `${config.URL_API}/transactions/${this.props.urlTransaction}`,
      { headers: getHeader() }
    );
  }

  fetchUserProfile(idProfile) {
    return axios.get(`${config.URL_API}/users/${idProfile}`, {
      headers: getHeader(),
    });
  }

  fetchRanking(body) {
    let headers = getHeader();
    headers.ownerid = body.owner_id;
    headers.interestedid = body.interested_id;
    headers.transactionid = body.transaction_id;
    return axios.get(`${config.URL_API}/v1/transactions/show_ranking`, {
      headers,
    });
  }

  render() {
    const { transaction, status, is_owner, profile } = this.state;
    const { suscription } = this.props;

    let result = <></>;
    if (!transaction) {
      return result;
    }

    let divPub = (
      <>
        <RequestPublication
          publication={transaction.publication}
          suscription={suscription}
        />
      </>
    );
    let divInterested = (
      <>
        <RequestPublication
          transaction={transaction}
          publication_interested={transaction.publication_interested}
          tags={transaction.tags}
          suscription={suscription}
        />
      </>
    );
    switch (status) {
      case "complete":
        result = (
          <div className="container mx-auto relative PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              CAMBIO EXITOSO
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? (
                    <>
                      {divInterested}
                      <h4 className="mb-1">Recibido</h4>
                      {transaction.owner_rank ? (
                        <p className="acceptedText">
                          Calificacion recibida {transaction.owner_rank}/5
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {divPub}
                      <h4 className="mb-1">Recibido</h4>
                      {transaction.owner_rank ? (
                        <p className="acceptedText">
                          Calificacion recibida {transaction.owner_rank}/5
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
                <div className="w-1/5 text-center">
                  <img
                    src={flechaCompleted}
                    className="w-full md:w-3/4"
                    alt="Completo"
                  />
                </div>
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? (
                    <>
                      {divPub}
                      <h4 className="mb-2">
                        Entregado a:{" "}
                        {transaction.interested_user.name +
                          " " +
                          transaction.interested_user.last_name}
                      </h4>
                      {transaction.interested_rank ? (
                        <p className="acceptedText">
                          Calificacion otorgada {transaction.interested_rank}
                          /5
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {divInterested}
                      <h4 className="mb-2">
                        Entregado a:{" "}
                        {profile.user.name + " " + profile.user.last_name}
                      </h4>
                      {transaction.interested_rank ? (
                        <p className="acceptedText">
                          Calificacion otorgada {transaction.interested_rank}
                          /5
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "rejected":
        result = (
          <div className="container mx-auto relative PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              PROPUESTA CANCELADA
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? (
                    <>{divPub}</>
                  ) : (
                    <>
                      {divInterested}
                      <h4>Propuesta Enviada</h4>
                    </>
                  )}
                </div>
                <div className="w-1/5 text-center">
                  {is_owner ? (
                    <img
                      src={flechaRejectProp}
                      className="w-full md:w-3/4"
                      alt="rechazado"
                    />
                  ) : (
                    <img
                      src={flechaRejectInt}
                      className="w-3/4"
                      alt="Rechazado"
                    />
                  )}
                </div>
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? (
                    <>
                      {divInterested}
                      <h4>
                        Recibida de:{" "}
                        {transaction.interested_user.name +
                          " " +
                          transaction.interested_user.last_name}
                      </h4>
                    </>
                  ) : (
                    <>
                      {divPub}
                      <h4>
                        Propietario{" "}
                        {profile.user.name + " " + profile.user.last_name}
                      </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "await":
        result = (
          <div className="container mx-auto relative PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              PROPUESTA EN ESPERA
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? divPub : divInterested}
                </div>
                <div className="w-1/5 text-center">
                  {is_owner ? (
                    <img
                      src={flechaAwaitProp}
                      className="w-full md:w-3/4"
                      alt="Espera"
                    />
                  ) : (
                    <img src={flechaAwaitInt} className="w-3/4" alt="Espera" />
                  )}
                </div>
                <div className="w-2/5 p-1 text-center">
                  {!is_owner ? divPub : divInterested}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "confirmed_interested":
        result = (
          <div className="container mx-auto relative PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              PENDIENTE DE CONFIRMACION
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center PubContainerConfirmed">
                  {!is_owner && (
                    <>
                      {divInterested}
                      <div className="acceptedText">
                        <h4 className="mb-1">{`${profile.user.name} ${profile.user.last_name}`}</h4>
                        todavia no ha confirmado el cambio exitoso
                      </div>
                    </>
                  )}
                </div>
                <div className="w-1/5 text-center">
                  <img
                    src={flechasChange}
                    className="w-full md:w-1/2"
                    alt="Cambio"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div className="w-2/5 p-1 text-center">
                  {!is_owner ? divPub : divInterested}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "confirmed_owner":
        result = (
          <div className="container mx-auto relative PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              PENDIENTE DE CONFIRMACION
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 -mt-5 text-center PubContainerConfirmed">
                  {is_owner ? (
                    <>
                      {divPub}
                      <div className="mb-2">
                        <h4 className="mb-1">
                          {`${transaction.interested_user.name} ${transaction.interested_user.last_name}`}{" "}
                        </h4>
                        todavia no ha confirmado el cambio exitoso
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="w-1/5 text-center">
                  <img
                    src={flechasChange}
                    className="w-full md:w-1/2"
                    alt="Cambio"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div className="w-2/5 p-1 text-center">
                  {!is_owner ? divPub : divInterested}
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
    return result;
  }
}

export default History;
