import { spaceValidation, addClassElement, removeClassElement } from './../../../utilityFunctions';

export const validations = (data, withTouched) => {
  let aux = true;
  let messages = [];

  // address validation
  if (!spaceValidation(data.address) || data.address.length <= 1) {
    if (!withTouched || touchedControl['address']) {
      addClassElement('address', 'errorInput');
      helperText.address = 'La direccion no puede estar en blanco';
      messages.push('Direccion')
    }
    aux = false;
  } else {
    helperText.address = '';
    removeClassElement('address', 'errorInput')
  }

  // city
  if (!spaceValidation(data.city) || data.city.length <= 1) {
    if (!withTouched || touchedControl['city']) {
      addClassElement('city', 'errorInput');
      helperText.city = 'La ciudad no puede estar en blanco';
      messages.push('Direccion')
    }
    aux = false;
  } else {
    helperText.city = '';
    removeClassElement('city', 'errorInput')
  }

  // postal code
  if (!spaceValidation(data.postal_code) || data.postal_code.length <= 1) {
    if (!withTouched || touchedControl['postal_code']) {
      addClassElement('postal_code', 'errorInput');
      helperText.postal_code = 'El codigo postal no puede estar en blanco';
      messages.push('Direccion')
    }
    aux = false;
  } else {
    helperText.postal_code = '';
    removeClassElement('postal_code', 'errorInput')
  }

  // state
  if (!spaceValidation(data.state)) {
    if (!withTouched || touchedControl["state"]) {
      addClassElement('state', 'errorInput');
      helperText.state = 'El estado no puede estar en blanco';
      messages.push('Estado')
    }
    aux = false;
  } else {
    helperText.state = '';
    removeClassElement('state', 'errorInput')
  }
  return {valid: aux, messages: messages};
}

export const touchedControl = {
  address: false,
  city: false,
  postal_code: false,
  state: false
}

export const helperText = {
  address: '',
  city: '',
  postal_code: '',
  state: ''
}