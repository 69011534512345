import React from 'react';
import { Icon, Divider } from '@blueprintjs/core';

const addImage = ({ open, getInputProps, getRootProps }) => {
  let container = null;
  container = (
    <div className="image-preview" onClick={open} {...getRootProps?.()}>
      <input {...getInputProps?.()} />
      <div className="dragDropDisclaimer">
        <div className="dragDropDisclaimerText">
          <p>
            <Icon iconSize="24" icon="plus" />
          </p>
          <h5>Subir imagen</h5>
          <Divider />
          <p>
            Arrastra tu imagen o presiona aquí para seleccionar una imagen de tus
            archivos
          </p>
        </div>
      </div>
    </div>

  );
  return container;
};

export default addImage;
