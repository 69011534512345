import React from 'react';
import PropTypes from 'prop-types';

class AddressPubInfo extends React.Component {
  render() {

    const { address_1, address_2, coordinate } = this.props;
    return (
      <>
        <p className={'bp3-form-helper-text mb-2'}>Dirección</p>
        <h3 className={'margin0 marginBottom-1em'}>{address_1}</h3>
        {address_2 ?
          <>
            <p className={'bp3-form-helper-text mt-4 mb-2'}>Dirección complementaria</p>
            <h3 className={'margin0 marginBottom-1em'}>{address_2}</h3>
          </>
          : null
        }
        <p className={'bp3-form-helper-text margin0 marginBottom-05em'}>Coordenadas</p>
        <h3 className={'margin0'}>{`${coordinate.latitude} ${coordinate.longitude}`} </h3>
      </>
    )
  }
}


AddressPubInfo.propTypes = {
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string,
  coordinate: PropTypes.object.isRequired
}

export default AddressPubInfo;
