import React, { Component } from 'react';
import {
  Alert,
  AnchorButton,
  Button,
  ButtonGroup,
  Callout,
  Card,
  Icon,
  Position,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import './headerNotificationsSaved/HeaderNotificationsSaved.scss';
import { productNameUrl, dateMonthStringShort } from '../../utilityFunctions';
import { config } from '../../system/Config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as actions from '../../store/actions';
import AvatarUser from '../AvatarUser';
import { Toast } from '../../utility/toast';

class HeaderNotificationsSaved extends Component {
  state = {
    pub: [],
    loading: true,
    removeConfirmation: false,
    index: null,
    viewAllList: false,
    destroySuccess: false,
    deleting: false,
  };

  componentDidMount() {
    this.getPub();
  }

  fetchPub = () => {
    return axios.get(
      `${config.URL_API}/saved_publications_user?user_id=${localStorage.getItem(
        'userId'
      )}`
    );
  };

  fetchDestroyPubSave = (id) => {
    return axios.delete(`${config.URL_API}/publications_saveds/${id}`);
  };

  getPub = async () => {
    this.setState({ loading: true });
    try {
      let response = await this.fetchPub();
      this.setState({ pub: response.data, loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  publicationOwner = (id) => id === this.props.userId;

  switchShowRemoveConf = (index) => {
    this.setState({ index: index }, () =>
      this.setState({ removeConfirmation: !this.state.removeConfirmation })
    );
  };

  switchViewAllList = () => {
    this.setState({ viewAllList: !this.state.viewAllList });
  };

  destroy = async () => {
    let pub = [...this.state.pub];
    this.setState({ deleting: true });
    await this.fetchDestroyPubSave(pub[this.state.index].id);
    pub.splice(this.state.index, 1);
    this.props.onGetPublicationsSaved(this.props.userId);
    this.setState({
      pub: pub,
      index: null,
      removeConfirmation: false,
      deleting: false,
    });

    Toast.show({
      intent: 'success',
      icon: 'tick',
      message: 'La publicación ha sido eliminada de la lista.',
    });
  };

  render() {
    const { loading, removeConfirmation, deleting } = this.state;

    if (loading) {
      return <Spinner className="pt-4 pr-8 pb-4 pl-8" size="24" />;
    }
    let pub = [];
    if (!this.state.viewAllList) {
      pub = [...this.state.pub].splice(0, 9);
    } else {
      pub = [...this.state.pub];
    }
    return (
      <>
        <div className="headerNotificationsSaved">
          <div className="padding-05em">
            <Callout title="Publicaciones Seguidas" />
          </div>

          <ul>
            {pub.map((element, i) => (
              <li key={i}>
                <figure className="interactiveFigure m-1 ">
                  <div className="headerUserCard">
                    <div className="AvatarContainer">
                      <AvatarUser user={element.publication.owner} />
                    </div>
                    <div className="UserDetailsContainer marginBottom-05em">
                      <div className="flex flex-row name-row">
                        <div className="pr-3 UserName">
                          <h4>{`${element.publication.owner.name} ${element.publication.owner.last_name}`}</h4>
                        </div>
                        <div className="NotDate2">
                          {dateMonthStringShort(element.publication.updated_at)}
                        </div>
                      </div>
                      <h4
                        className={
                          this.publicationOwner(element.publication.owner.id)
                            ? 'bp3-text-overflow-ellipsis article-text purple-text'
                            : 'bp3-text-overflow-ellipsis article-text orange-text'
                        }
                      >
                        {element.publication.name}
                      </h4>
                    </div>
                  </div>
                </figure>
              </li>
            ))}
            {pub.length === 0 ? (
              <div className="p-2">
                <Callout>No hay publicaciones guardadas</Callout>
              </div>
            ) : null}
          </ul>
          <div className="padding-05em">
            <Link to="/notifications_page" className="button-chats">
              <AnchorButton className="purple-text" minimal fill>
                Ver Todas
              </AnchorButton>
            </Link>
          </div>

          <Alert
            className="p-0"
            title="Borrar Publicación Guardada"
            confirmButtonText="Borrar"
            cancelButtonText="Cancelar"
            isOpen={removeConfirmation}
            onCancel={this.switchShowRemoveConf}
            onConfirm={this.destroy}
          >
            <Card className="mt-4 mr-4 ml-4">
              <div className="flex">
                <div>
                  <Icon icon="trash" intent="danger" iconSize="32" />
                </div>
                <div>
                  <h>Estas seguro de eliminar esta publicación de la lista?</h>
                </div>
              </div>
            </Card>
          </Alert>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPublicationsSaved: (user_id) =>
      dispatch(actions.getPublicationsSaved(user_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNotificationsSaved);
