import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import {
  Alignment,
  Button,
  Callout,
  Card,
  ControlGroup,
  Drawer,
  Elevation,
  InputGroup,
  Position,
  Tag,
} from '@blueprintjs/core';
import MediaQuery from 'react-responsive';
import CategoriesOfInterest from './../components/CategoriesOfInterest';
import ArticlesOfInterest from './../components/ArticlesOfInterest';
import pointerMap from './../Assets/System/pointerMap/pointerMap.png';
import axios from 'axios';
import * as actions from './../store/actions/index';
import Comments from './../components/Comments';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import { getAttr, needOverlay, productNameUrl, spaceValidation } from './../utilityFunctions';
import { config } from './../system/Config';
import { getHeader } from './../system/Sesion';
import TagsTLC from './../TagsTLC';
import Footer from './../components/Footer';
import Thumbnail from './../components/Thumbnail';
import Report from './../components//Report';
import AddressPubInfo from './../components/AddressPubInfo';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import DetailProductSkeleton from './../components/DetailProductSkeleton';
import { userDataComplete } from './detailProduct/aux';

import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import './../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CompleteUserData from './detailProduct/CompleteUserData';
import OwnerInfo from './detailProduct/OwnerInfo';
import Ads from 'common-component/ads/Ads.jsx';
import { typeAds, typeFormatsAds } from 'const';
import ProductDetailMainSection from './detailProduct/ProductDetailMainSection';
import './detailProduct/DetailProduct.scss';
import { Toast } from '../utility/toast';

class DetailProduct extends React.Component {
  tagsOfChange = new TagsTLC();

  state = {
    // Panel userdetail
    visibleUserDetailPanel: false,
    pub: null,
    tagChange: '', // valor del input del tag de cambio
    tagsChange: [], // propuestas de cambio,
    sendingProposal: false, // envaindo propuesta (loading),
    publicationSaved: false, // el usuario le ha dado like a esta publicacion?,
    pubRelated: [],
    isOpenReport: false, // esta abierto el modal para Denunciar?
    comments: false, // la publicacion tiene comentarios?,
    editorState: EditorState.createEmpty(),
    pubInTransaction: false, // el usuario tiene esta publicacion en transaccion?,
    chatIdTransaction: null, // chat asociado a la transaccion pendiente
    showUserCompleteData: false,
  };

  params = {
    grabCursor: true,
    centeredSlides: false,
    slidesPerGroup: 1,
    slidesPerView: 4,
    spaceBetween: 30,
    rebuildOnUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  fetchGetTransactionForUser(idUser, idPub) {
    axios
      .get(
        `${config.URL_API}/transaction_for_user?user_id=${idUser}&publication_id=${idPub}`,
      )
      .then((response) => {
        this.setState({ pubInTransaction: response.data });
      })
      .catch((err) => console.log(err));
  }

  // Panel userdetail
  handlerVisibiltyUserDetailPanel() {
    this.setState({ UserDetailPanel: !this.state.UserDetailPanel });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const id = this.props.match.params.id;
      this.setState({ pub: null });
      this.getPub(id);
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getPub(id);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  fetchAPIGet = (id) =>
    axios.get(
      `${config.URL_API}/publications/${id}?latitude=${localStorage.getItem(
        'latitude',
      )}&longitude=${localStorage.getItem(
        'longitude',
      )}&user_id=${localStorage.getItem('userId')}`,
      { headers: getHeader() },
    );

  fetchChangeProposal = (data) =>
    axios.post(`${config.URL_API}/notification_trades`, data, {
      headers: getHeader(),
    });

  fetchRelatedProduct = (category) =>
    axios.get(
      `${
        config.URL_API
      }/search_publications_by_category?category=${category}&latitude=${localStorage.getItem(
        'latitude',
      )}&longitude=${localStorage.getItem('longitude')}`,
      { headers: getHeader() },
    );

  fetchUpdateViewsCategory = (id) =>
    axios.put(
      `${config.URL_API}/categories/increment_view`,
      { id: id },
      { headers: getHeader() },
    );

  fetchUpdateViewsPub = (id) =>
    axios.put(
      `${config.URL_API}/pubs/increment_view`,
      { id: id },
      { headers: getHeader() },
    );

  getPub = async (id) => {
    try {
      this.fetchGetTransactionForUser(localStorage.getItem('userId'), id);
      let response = await this.fetchAPIGet(id);
      const getChatId = (chat) => {
        return chat ? chat.id : null;
      };

      if (response.data.publication.publication_status !== 'Activo') {
        this.props.history.push('/home');
      }

      this.setState(
        {
          pub: response.data.publication,
          comments: response.data.publication.comments_publications.length > 0,
          chatIdTransaction: response.data.transactions_asociated_user
            ? getChatId(response.data.transactions_asociated_user.chat)
            : null,
        },
        () => {
          this.verifyIfPublicationSaved();
          this.getPubRelated(response.data.publication.product_category.name);

          this.updateViewsCategory(
            response.data.publication.product_category.product_category.id,
          );
          this.updateViewsPub(response.data.publication.id);
          if (this.state.pub.editor_web) {
            this.setState({
              editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(this.state.pub.editor_web)),
              ),
            });
          }
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  getPubRelated = async (category) => {
    try {
      let response = await this.fetchRelatedProduct(category);
      this.setState({ pubRelated: response.data.data });
    } catch (err) {
      console.log(err);
    }
  };

  updateViewsCategory = async (id) => {
    await this.fetchUpdateViewsCategory(id);
  };

  updateViewsPub = async (id) => {
    await this.fetchUpdateViewsPub(id);
  };

  getPosition = () => {
    let position;
    if (this.state.pub) {
      const { pub } = this.state;
      position = {
        lat: pub.coordinate.latitude,
        lng: pub.coordinate.longitude,
      };
    }
    return position;
  };

  inputChangeHandler = (event) => {
    const id = event.target.id;
    this.setState({ [id]: event.target.value });
  };

  addTagChange = () => {
    const index = this.state.pub.tags.findIndex(
      (value) => this.state.tagChange.toLowerCase() === value.name.toLowerCase(),
    );
    if (index !== -1) {
      Toast.show({ message: `Vaya! ${this.state.tagChange} ya es un articulo de interés` })
      return;
    }
    this.tagsOfChange.addTag(this.state.tagChange);
    this.setState({ tagsChange: this.tagsOfChange.getTag(), tagChange: '' });
  };

  removeTagChange = (value) => {
    this.tagsOfChange.removeTag(value);
    this.setState({ tagsChange: this.tagsOfChange.getTag() });
  };

  changeProposal = async () => {
    const data = {
      token: localStorage.getItem('tokenId'),
      publication_id: this.state.pub.id,
      trade_tags: this.state.tagsChange,
    };
    this.setState({ sendingProposal: true });

    try {
      await this.fetchChangeProposal(data);
      Toast.show({ message: `Se ha enviado exitosamente la propuesta a ${this.state.pub.owner.name} ${this.state.pub.owner.last_name}, espera su respuesta` })
      this.setState({ tagsChange: [], sendingProposal: false });
    } catch (err) {
      Toast.show({ message: 'Lo sentimos, ha habido un error' })
      this.setState({ sendingProposal: false });
    }
  };

  like = async () => {
    try {
      this.setState({ publicationSaved: true });
      const dataAPI = {
        user_id: this.props.userId,
        publication_id: this.state.pub.id,
      };
      await axios.post(`${config.URL_API}/publications_saveds`, dataAPI, {
        headers: getHeader(),
      });
      this.props.onGetPublicationsSaved(this.props.userId);
    } catch (err) {
      this.setState({ publicationSaved: false });
    }
  };

  dislike = async () => {
    try {
      this.setState({ publicationSaved: false });
      const p = this.props.publicationsSaved.find(
        (item) => item.publication_id === this.state.pub.id,
      );
      await axios.delete(`${config.URL_API}/publications_saveds/${p.id}`);
      this.props.onGetPublicationsSaved(this.props.userId);
    } catch (err) {
      this.setState({ publicationSaved: true });
    }
  };

  // verificar si el usuario el usuario ha dado like a esta publicacion
  verifyIfPublicationSaved = () => {
    if (this.props.publicationsSavedLoaded) {
      const saved = this.props.publicationsSaved?.find(
        ({ publication_id }) => publication_id === this.state.pub.id,
      );
      if (saved) {
        this.setState({ publicationSaved: true });
      }
    } else {
      setTimeout(() => {
        this.verifyIfPublicationSaved();
      }, 1000);
    }
  };

  switchOpenReport = () => {
    this.setState({ isOpenReport: !this.state.isOpenReport });
  };

  setCommentsTrue = () => {
    if (this.state.comments) {
      return;
    }
    this.setState({ comments: true });
  };

  toChange = (link) => {
    if (userDataComplete(this.props.otherInfoUser)) {
      this.props.history.push(link);
    } else {
      this.setState({ showUserCompleteData: true });
    }
  };

  hideModalUserCompleteData = () => {
    this.setState({ showUserCompleteData: false });
  };

  completeDataUserSuccess = () => {
    this.setState({ showUserCompleteData: false });
    const { pub } = this.state;
    const link = `/change/operation/${productNameUrl(pub.name)}/${pub.id}/${
      pub.owner.id
    }/${pub.coordinate.latitude}/${pub.coordinate.longitude}`;
    this.props.history.push(link);
  };

  render() {
    // Panel userDetails
    const { UserDetailPanel } = this.state;
    const {
      pub,
      tagsChange,
      tagChange,
      sendingProposal,
      isOpenReport,
      pubInTransaction,
      showUserCompleteData,
    } = this.state;
    const { otherInfoUser } = this.props;

    if (pub === null) {
      return (
        <React.Fragment>
          <DetailProductSkeleton/>
        </React.Fragment>
      );
    }
    if (!pub.owner.active) {
      return <Redirect to="/user/inactive/tmp"/>;
    }

    const position = this.getPosition();
    const attr = getAttr(pub.product_category.id);
    let attrCategory = [];
    attr.attr.forEach((value, index) => {
      if (pub[value]) {
        attrCategory.push({ attr: value, display: attr.display[index] });
      }
    });
    const pubRelated = this.state.pubRelated.filter((e) => e.id !== pub.id);

    let modalUserCompleteData = null;

    if (showUserCompleteData) {
      modalUserCompleteData = (
        <CompleteUserData
          data={otherInfoUser}
          onClose={this.hideModalUserCompleteData}
          success={this.completeDataUserSuccess}
        />
      );
    }

    return (
      <>
        <div className="detailProduct pt-32 pb-20 sm:pt-12 md:pt-16 lg:pt-16 xl:pt-16">
          <div className="customWrapper">
            {pub.owner.id === this.props.userId ? (
              <Callout
                className="marginBottom-1em"
                intent="primary"
                title="Estas viendo una publicación tuya"
              >
                <Link to={`/edit/publication/${pub.id}`}>
                  <Button
                    className="BtnLCPrimary marginTop-1em marginRight-05em"
                    align={Alignment.RIGHT}
                    icon="edit"
                  >
                    Modificar publicación
                  </Button>
                </Link>
              </Callout>
            ) : null}
            <Row>
              <Col xs={12} md={7} lg={8} xl={9}>
                <ProductDetailMainSection
                  pubInTransaction={pubInTransaction}
                  pub={pub}
                  toChange={this.toChange}
                  isAuthenticated={this.props.isAuthenticated}
                  userId={this.props.userId}
                  suscription={this.props.suscription}
                  handlerVisibiltyUserDetailPanel={
                    this.handlerVisibiltyUserDetailPanel
                  }
                />
                {pub.editor_web ? (
                  spaceValidation(pub.editor_web) ? (
                    <>
                      <Card elevation={Elevation.ONE} className="marginTop-1em">
                        {/* En el caso de haber descripcion */}
                        <h3>Descripción del producto</h3>
                        <Editor
                          editorState={this.state.editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          readOnly={true}
                          toolbar={{ options: [], inline: {} }}
                        />
                      </Card>
                    </>
                  ) : (
                    <Card elevation={Elevation.ONE} className="marginTop-1em">
                      <Callout className="noActivityDisplay marginBottom-1em">
                        <h5 className="bp3-form-helper-text">
                          Artículo sin descripción
                        </h5>
                      </Callout>
                    </Card>
                  )
                ) : (
                  <Card elevation={Elevation.ONE} className="marginTop-1em">
                    <Callout className="noActivityDisplay marginBottom-1em">
                      <h5 className="bp3-form-helper-text">
                        Artículo sin descripción
                      </h5>
                    </Callout>
                  </Card>
                )}
                <Card className="marginTop-1em">
                  <h3>Preguntas</h3>
                  <Comments
                    isVisible={true}
                    pub={pub}
                    setCommentsTrue={{ comments: true }}
                  />
                </Card>

                {/* Articulos que te pueden interesar  */}
                <div className="md:px-4 mt-8 mb-4">
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h3 className="mb-4">
                        Artículos que te pueden interesar
                      </h3>
                      {pubRelated.length > 0 ? (
                        <>
                          <Row>
                            <Swiper {...this.params}>
                              {pubRelated.map((element, i) => (
                                <SwiperSlide>
                                  <span className="w-full">
                                    <Thumbnail
                                      suscription={this.props.suscription}
                                      key={i}
                                      pub={element}
                                    />
                                  </span>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </Row>
                        </>
                      ) : (
                        <Callout className="noActivityDisplay">
                          <h5 className="bp3-form-helper-text">
                            No hay artículos
                          </h5>
                        </Callout>
                      )}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Footer/>
                    </Col>
                  </Row>
                </div>
                {/* Articulos que te pueden interesar  */}
              </Col>
              <MediaQuery query="(min-device-width: 768px)">
                <Col xs={12} sm={12} md={5} lg={4} xl={3}>
                  <Card
                    elevation={Elevation.ONE}
                    className="mt-4 sm:mt-4 md:mt-0 lg:mt-0 xl:mt-0"
                  >
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="infoUserDetailPublication">
                          <Card
                            elevation={Elevation.ONE}
                            className="mt-4 sm:mt-4 md:mt-0 lg:mt-0 xl:mt-0"
                          >
                            <div className="infoUserDetailPublicationAvatar">
                              {pub.owner ? (
                                <OwnerInfo id_user={pub.owner.id}/>
                              ) : null}
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  {this.props.isAuthenticated ? (
                                    <div className="marginTop-1em">
                                      <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                          {this.props
                                            .publicationsSavedLoaded ? (
                                            this.state.publicationSaved ? (
                                              <Button
                                                className="marginBottom-05em"
                                                onClick={this.dislike}
                                                fill={true}
                                                minimal={true}
                                                icon="heart"
                                                text={
                                                  'Eliminar de los Guardados'
                                                }
                                              />
                                            ) : (
                                              <Button
                                                className="marginBottom-05em"
                                                onClick={this.like}
                                                fill={true}
                                                icon="bookmark"
                                                minimal={true}
                                                text={'Guardar Publicación'}
                                              />
                                            )
                                          ) : null}
                                        </Col>
                                        {pubInTransaction ? (
                                          <Col xs={12} sm={12} md={12} lg={12}>
                                            <Link
                                              to={`/chats/${this.state.chatIdTransaction}`}
                                            >
                                              <Button
                                                className="marginBottom-05em"
                                                minimal={true}
                                                fill={true}
                                                icon="chat"
                                                text={'Enviar Mensaje'}
                                              />
                                            </Link>
                                          </Col>
                                        ) : null}
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                          <Button
                                            className="marginBottom-1em"
                                            minimal={true}
                                            fill={true}
                                            icon="ban-circle"
                                            intent="danger"
                                            text={'Reportar'}
                                            onClick={this.switchOpenReport}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          </Card>
                          <div className="pt-5">
                            <h3>Intereses de cambio</h3>
                            <Callout className="marginTop-1em">
                              <h4 className="marginBottom-05em pb-2">
                                Categorías de interés
                              </h4>
                              <CategoriesOfInterest
                                categories={pub.exchange_interest_category}
                              />
                            </Callout>
                            <Callout className="marginTop-1em">
                              <h4 className="marginBottom-05em pb-2">
                                Artículos de interés
                              </h4>
                              <ArticlesOfInterest articles={pub.article_tags}/>
                            </Callout>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Ads
                    className="mt-4 mb-4"
                    type={typeFormatsAds.mrec}
                    typeAd={typeAds.display}
                  />

                  <Ads
                    className="mt-4 mb-4"
                    type={typeFormatsAds.halfPage}
                    typeAd={typeAds.display}
                  />
                </Col>
              </MediaQuery>
            </Row>
          </div>
        </div>

        {/* PANEL userdetail */}
        <Drawer
          title={'Detalles de la Publicación'}
          isOpen={UserDetailPanel}
          canOutsideClickClose={true}
          hasBackdrop={true}
          canEscapeKeyClose={true}
          size={Drawer.SIZE_LARGE}
          position={Position.LEFT}
          autoFocus={true}
          onClose={() => this.handlerVisibiltyUserDetailPanel()}
        >
          <div className="p-4 overflow-y-scroll">
            <Card elevation={Elevation.ONE}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="infoUserDetailPublication">
                    <div className="marginBottom-1em">
                      {!needOverlay(
                        pub.product_category,
                        this.props.suscription,
                      ) ? (
                        <>
                          {!pubInTransaction ? (
                            <Button
                              onClick={() =>
                                this.toChange(
                                  `/change/operation/${productNameUrl(
                                    pub.name,
                                  )}/${pub.id}/${pub.owner.id}/${
                                    pub.coordinate.latitude
                                  }/${pub.coordinate.longitude}`,
                                )
                              }
                              fill={true}
                              className="BtnLCYellow"
                              disabled={
                                !this.props.isAuthenticated ||
                                pub.owner.id === this.props.userId ||
                                pubInTransaction
                              }
                            >
                              Enviar Propuesta
                            </Button>
                          ) : (
                            <Button
                              fill={true}
                              className="BtnLCYellow"
                              disabled={true}
                            >
                              Cambio pendiente
                            </Button>
                          )}
                        </>
                      ) : null}
                    </div>
                    <div className="infoUserDetailPublicationAvatar">
                      {pub.owner ? <OwnerInfo id_user={pub.owner.id}/> : null}
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          {this.props.isAuthenticated ? (
                            <div className="marginTop-1em">
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  {this.props.publicationsSavedLoaded ? (
                                    this.state.publicationSaved ? (
                                      <Button
                                        className="marginBottom-05em"
                                        onClick={this.dislike}
                                        fill={true}
                                        minimal={true}
                                        icon="heart"
                                        text={'Eliminar de los Guardados'}
                                      />
                                    ) : (
                                      <Button
                                        className="marginBottom-05em"
                                        onClick={this.like}
                                        fill={true}
                                        icon="bookmark"
                                        minimal={true}
                                        text={'Guardar'}
                                      />
                                    )
                                  ) : null}
                                </Col>
                                {pubInTransaction ? (
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <Link
                                      to={`/chats/${this.state.chatIdTransaction}`}
                                    >
                                      <Button
                                        className="marginBottom-05em"
                                        minimal={true}
                                        fill={true}
                                        icon="chat"
                                        text={'Enviar Mensaje'}
                                      />
                                    </Link>
                                  </Col>
                                ) : null}
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Button
                                    className="marginBottom-1em"
                                    minimal={true}
                                    fill={true}
                                    icon="ban-circle"
                                    intent="danger"
                                    text={'Denunciar esta publicación'}
                                    onClick={this.switchOpenReport}
                                  />
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <div className="marginTop-1em">
                        <h3>Intereses de cambio</h3>
                        <Callout className="marginTop-1em">
                          <h4 className="marginBottom-05em pb-2">
                            Categorías de interés
                          </h4>
                          <CategoriesOfInterest
                            categories={pub.exchange_interest_category}
                          />
                        </Callout>
                        <Callout className="marginTop-1em">
                          <h4 className="marginBottom-05em pb-2">
                            Categorías de interés
                          </h4>
                          <ArticlesOfInterest articles={pub.article_tags}/>
                        </Callout>
                        {!needOverlay(
                          pub.product_category,
                          this.props.suscription,
                        ) ? (
                          <Callout className="marginTop-1em">
                            <h4 className="marginBottom-05em pb-2">
                              Artículos de interés
                            </h4>
                            <h4 className="marginBottom-05em">
                              Hazle una propuesta de cambio
                            </h4>
                            <ControlGroup fill={true}>
                              <InputGroup
                                className="whiteInput"
                                disabled={
                                  pub.owner.id === this.props.userId ||
                                  !this.props.isAuthenticated
                                }
                                placeholder="Escribir propuesta"
                                value={tagChange}
                                onChange={this.inputChangeHandler}
                                id="tagChange"
                              />
                              <Button
                                onClick={this.addTagChange}
                                className="BtnLCPrimary"
                                icon="plus"
                                disabled={
                                  !spaceValidation(tagChange) ||
                                  tagsChange.indexOf(tagChange) !== -1 ||
                                  !this.props.isAuthenticated
                                }
                              />
                            </ControlGroup>
                            {tagsChange.map((tag, i) => (
                              <Tag
                                className="tagYellow marginTop-05em"
                                key={i}
                                onRemove={() => this.removeTagChange(tag)}
                              >
                                {tag}
                              </Tag>
                            ))}

                            {tagsChange.length === 0 ? (
                              <div className="marginBottom-05em">
                                <p className="bp3-form-helper-text text-center pt-4 pb-1">
                                  No hay propuestas para enviar
                                </p>
                              </div>
                            ) : null}
                            <Button
                              disabled={tagsChange.length === 0}
                              className="BtnLCPrimary mt-4"
                              fill={true}
                              onClick={this.changeProposal}
                              loading={sendingProposal}
                            >
                              Enviar propuesta
                            </Button>
                          </Callout>
                        ) : null}
                        <Callout className="marginTop-1em">
                          <h4 className="marginBottom-05em pb-2">
                            Lugar del articulo
                          </h4>
                          <div id="mapPrincipal" className="changePointProduct">
                            <Map
                              disabled={true}
                              readOnly={true}
                              className="app mapDetailProduct"
                              {...this.props}
                              google={this.props.google}
                              onClick={this.mapClicked}
                              center={position}
                              zoom={14}
                            >
                              <Marker
                                readOnly={true}
                                disabled={true}
                                icon={{
                                  url: pointerMap,
                                  scaledSize: { width: 64, height: 64 },
                                }}
                                position={position}
                              />
                              <InfoWindow onClose={this.onInfoWindowClose}>
                                <div>
                                  <h1>{pub.address_1}</h1>
                                </div>
                              </InfoWindow>
                            </Map>
                          </div>
                          <div className="infoDetailPublication">
                            <AddressPubInfo
                              address_1={pub.address_1}
                              address_2={pub.address_2}
                              coordinate={pub.coordinate}
                            />
                          </div>
                        </Callout>
                        {!needOverlay(
                          pub.product_category,
                          this.props.suscription,
                        ) ? (
                          <>
                            {!pubInTransaction ? (
                              <Button
                                onClick={() =>
                                  this.toChange(
                                    `/change/operation/${productNameUrl(
                                      pub.name,
                                    )}/${pub.id}/${pub.owner.id}/${
                                      pub.coordinate.latitude
                                    }/${pub.coordinate.longitude}`,
                                  )
                                }
                                fill={true}
                                className="BtnLCYellow marginTop-1em"
                                disabled={
                                  !this.props.isAuthenticated ||
                                  pub.owner.id === this.props.userId ||
                                  pubInTransaction
                                }
                              >
                                Enviar Propuesta
                              </Button>
                            ) : (
                              <Button
                                fill={true}
                                className="BtnLCYellow marginTop-1em"
                                disabled={true}
                              >
                                Cambio pendiente
                              </Button>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="mt-2">
                <>
                  {!pubInTransaction ? (
                    <Button
                      onClick={() =>
                        this.toChange(
                          `/change/operation/${productNameUrl(pub.name)}/${
                            pub.id
                          }/${pub.owner.id}/${pub.coordinate.latitude}/${
                            pub.coordinate.longitude
                          }`,
                        )
                      }
                      fill={true}
                      className="BtnLCYellow"
                      disabled={
                        !this.props.isAuthenticated ||
                        pub.owner.id === this.props.userId ||
                        pubInTransaction
                      }
                    >
                      Lo Cambio
                    </Button>
                  ) : (
                    <Button fill={true} className="BtnLCYellow" disabled={true}>
                      Cambio pendiente
                    </Button>
                  )}
                </>
              </div>
            </Card>
          </div>
        </Drawer>

        <Report
          isOpen={isOpenReport}
          onClose={this.switchOpenReport}
          owner={`${pub.owner.name} ${pub.owner.last_name}`}
          name={pub.name}
          id={pub.id}
        />
        {modalUserCompleteData}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    publicationsSavedLoaded: state.auth.publicationsSavedLoaded,
    publicationsSaved: state.auth.publicationsSaved,
    isAuthenticated: state.auth.isAuthenticated,
    otherInfoUser: state.auth.otherInfo,
    suscription: state.auth.suscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPublicationsSaved: (user_id) =>
      dispatch(actions.getPublicationsSaved(user_id)),
  };
};

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: DetailProductSkeleton,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailProduct)));
