import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { Callout, Card, Elevation, Tag } from '@blueprintjs/core';
import './publicationItem/PublicationItem.scss';
import ProductSwiper from '../../components/productSwiper/ProductSwiper';
import { datelocambio, productNameUrl } from '../../utilityFunctions';
import ActivePublication from './ActivePublication';
import DesactivePublication from './DesactivePublication';

const PublicationItem = (props) => {
  const { pub, typesStatus, status, updating } = props;
  const publicationTag = props.pub.publication_type || 'Standard';
  return (
    <div className="PublicationItem">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card
            elevation={Elevation.ONE}
            className="marginBottom-1em"
          >
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <ProductSwiper
                  pictures={pub.pictures}
                  needOverlay={false}
                />
              </Col>
              <Col xs={12} sm={8} md={8} lg={8}>
                <Callout>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <p className="margin0 marginBottom-05em">
                        Publicación:
                      </p>
                      <h1 className="margin0 marginTop-05em marginBottom-05em">
                        <Link
                          to={`${productNameUrl(pub.name)}/${pub.id}`}
                        >
                          {pub.name}
                        </Link>
                      </h1>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      Tipo de Publicación:
                      <Tag
                        className="tag marginRight-05em marginBottom-05em ml-1 text-xs"
                        minimal
                      >
                        {publicationTag}
                      </Tag>
                      <p className="margin0 marginBottom-05em">
                        Fecha en que se creó la
                        Publicación:
                      </p>
                      <h1 className="margin0 marginBottom-05em">
                        {datelocambio(pub.publish_date)}
                      </h1>
                    </Col>
                  </Row>
                  <Card
                    elevation={Elevation.ONE}
                    className="padding-1em"
                  >
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <p className="margin0 marginBottom-05em">
                            Intereses de cambio:
                        </p>
                        {pub.article_tags.map(
                          (article_tag) => (
                            <Tag
                              key={article_tag.id}
                              onRemove
                              className="tagYellow marginRight-05em marginBottom-05em"
                            >
                              {article_tag.name}
                            </Tag>
                          ),
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Callout>
                {status === typesStatus.ACTIVE ? (
                  <DesactivePublication
                    desactivePub={props.desactivePub}
                    idPub={pub.id}
                    updating={updating}
                  />
                ) : null}
                {status === typesStatus.INACTIVE ? (
                  <ActivePublication
                    activePub={props.activePub}
                    idPub={pub.id}
                    updating={updating}
                  />
                ) : null}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PublicationItem;
