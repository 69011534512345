import React, { useState } from 'react';
import { Button, Callout, Card, Collapse } from '@blueprintjs/core';

import './Faq.scss';

const helpFaq = [
  {
    title: 'Registro de Nuevos Usuarios',
    questions: [
      {
        question: '¿Cómo me Registro?',
        answer: (
          <div>
            <p>• Ingresa en LoCambio.com</p>
            <p>• Haz clic en “Regìstrarse”</p>
            <p>• Llena los campos con los datos solicitados</p>
            <p>• Crea una contraseña.</p>
            <p>
              • Haz clic en “Registrarme” para recibir un correo de confirmación
            </p>
            <p>• Haz clic en el link del correo para confirmar tu cuenta</p>
            <p>• ¡LISTO! Bienvenido a LoCambio.com</p>
          </div>
        ),
      },
      {
        question: '¿Cómo Debe ser mi Contraseña?',
        answer: (
          <div>
            <p>Para su mayor seguridad se recomienda:</p>
            <p>• Evitar usar el nombre de usuario u otro dato de acceso público.</p>
            <p>• Utilizar una contraseña con 6 caracteres o más.</p>
            <p>• Usar mayúsculas, números y caracteres especiales.</p>
          </div>
        ),
      },
      {
        question: '¿Si soy menor de edad puedo registrarme?',
        answer: (
          <p>
            LoCambio.com ofrece la posibilidad de intercambiar bienes y/o servicios
            entre los usuarios, por lo que los menores de edad no pueden registrarse
            ni usar la plataforma.
          </p>
        ),
      },
      {
        question: '¿Si estoy en otro país puedo registrarme?',
        answer: (
          <p>
            LoCambio.com busca abordar varios países de latinoamerica comenzando por
            Venezuela. Próximamente se contemplan Colombia y Perú.
          </p>
        ),
      },
      {
        question: '¿Obligatoriamente debo colocar mi foto para hacer el Registro?',
        answer: (
          <p>
            No es obligatorio colocar la foto de perfil, pero se recomienda
            colocarla para generar más confianza a los otros usuarios y aumentar las
            posibilidades de recibir propuestas de intercambio.
          </p>
        ),
      },
      {
        question:
          '¿Para realizar el registro, debo colocar mi cédula obligatoriamente?',
        answer: (
          <p>
            Si, para realizar el registro es obligatorio colocar su cédula de
            identidad, ya que esta permite identificarse como usuario único y evita
            la duplicidad de cuentas.
          </p>
        ),
      },

    ],
  },
  {
    title: '¿Olvidaste Algún Dato?',
    questions: [
      {
        question: '¿Cómo puedo recuperar mi contraseña?',
        answer: (
          <div>
            <p>Si olvidaste tu contraseña la puedes recuperar fácilmente:</p>
            <p>• Selecciona la opción donde dice “¿Olvidaste tu clave?”</p>
            <p>• Ingresa tu correo</p>
            <p>
              • Haz clic donde dice “Solicitar cambio de contraseña” para recibir un
              correo con el siguiente paso.
            </p>
            <p>
              • Haz clic en el link recibido al correo para restablecer tu
              contraseña
            </p>
          </div>
        ),
      },
      {
        question: 'No recuerdo el correo con el que me registré. ¿Cómo hago?',
        answer: (
          <p>
            Primero, asegúrate de haber escrito bien el correo. Si el problema
            persiste, contacta a nuestro equipo de soporte en línea para recibir
            apoyo.
          </p>
        ),
      },
      {
        question:
          'Estoy intentando registrarme y me dice que ya el correo existe. ¿Qué debo hacer?',
        answer: (
          <p>
            Primero, asegúrate de haber escrito bien el correo. Si el problema
            persiste, contacta a nuestro equipo de soporte en línea para recibir
            apoyo.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Acerca de LoCambio.com',
    questions: [

      {
        question: '¿Qué puede ofrecerme LoCambio.com?',
        answer: (
          <p>
            Es una plataforma web gratuita que ofrece a sus usuarios intercambiar
            sus bienes, artículos personales y servicios, con un interfaz amigable y
            sencilla. Los números de publicación, propuestas e intercambios son
            ilimitados.
          </p>
        ),
      },
      {
        question: '¿Cuál es el propósito de LoCambio.com?',
        answer: (
          <div>
            <p>
              • Que las personas tengan lo que necesitan y puedan dar lo que ya no
              usan.
            </p>
            <p>
              • Que las cosas se reusen o reciclen, reduciendo el consumismo de la
              sociedad y apoyar al medio ambiente.
            </p>
            <p>
              • Promover el ahorro, generar bienestar y calidad de vida a la
              comunidad.
            </p>
            <p>• Ser un mercado de personas y necesidades, no de comerciantes.</p>
            <p>
              • Ser la mayor red social de intercambio que abarque toda
              latinoamérica.
            </p>
          </div>
        ),
      },
      {
        question: '¿LoCambio.com está disponible en Google Play?',
        answer: (
          <p>
            En estos momentos estamos trabajando en eso, esperamos pronto tener
            disponible nuestra Aplicaciòn en Google Play.
          </p>
        ),
      },
      {
        question: '¿Se puede usar dinero para comprar cosas en LoCambio.com?',
        answer: (
          <p>
            El propósito de la plataforma es estimular intercambios, pero también si
            se desea y ambas partes lo acuerdan, se puede adicionar algo de dinero
            como parte de la propuesta de intercambio.
          </p>
        ),
      },
      {
        question: '¿Para utilizar la plataforma debo registrarme?',
        answer: (
          <p>
            No exactamente, cualquier visitante de LoCambio.com puede usar nuestro
            buscador para conseguir bienes y servicios cerca de él. Sin embargo,
            para poder proponer propuestas de intercambio, debe registrarse para la
            seguridad de todos los involucrados.
          </p>
        ),
      },
      {
        question: '¿LoCambio.com siempre será gratis?',
        answer: (
          <p>
            El número de publicaciones, propuestas e intercambios siempre serán
            ilimitadas y totalmente gratis. Por otro lado, durante nuestra etapa de
            lanzamiento Beta, nos hemos tomado la libertad de brindar gratuitamente
            algunos beneficios, entre otros que tenemos en desarrollo para
            sorprender a nuestros usuarios.
          </p>
        ),
      },
      {
        question: '¿Por qué medios me puedo comunicar con ustedes?',
        answer: (
          <div>
            <p>
              LoCambio.com cuenta con un chat para Soporte en línea, donde podrás
              ser atendido por nuestro equipo de operadores. De igual forma, puedes
              comunicarte con nosotros por otras vías como:
            </p>
            <p>• Correo electrónico: info@locambio.com</p>
            <p>• Whatsapp: +58 412-2022631</p>
            <p>• Telegram: +58 412-2022631</p>
            <p>• También puedes seguirnos en nuestras redes sociales @locambiove</p>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Acerca de las Publicaciones',
    questions: [

      {
        question: '¿Qué cosas puedo publicar en LoCambio.com?',
        answer: (
          <p>
            Puedes publicar cualquier cosa legal como accesorios de vestir, ropa,
            zapatos, teléfonos, televisores, muebles, neveras, lavadoras,
            computadoras, juguetes, libros, películas, joyas, maquillaje, hasta
            carros, motos e incluso inmuebles, entre otras. Además, en LoCambio.com
            también pueden publicarse servicios de las profesiones u oficios de
            nuestros usuarios. Las posibilidades de intercambio son infinitas.
          </p>
        ),
      },
      {
        question: '¿Existe un límite de publicaciones?',
        answer: (
          <p>
            El número de publicaciones que pueden hacer nuestros usuarios son
            ilimitadas y totalmente gratis.
          </p>
        ),
      },
      {
        question: '¿Se pueden publicar cosas usadas o nuevas?',
        answer: (
          <p>
            LoCambio.com está preparada para publicar bienes usados o nuevos. Sin
            embargo, queremos recalcar la honestidad a nuestros usuarios sobre las
            condiciones de los bienes durante las propuestas de intercambio, para
            que las partes involucradas queden satisfechas con el mismo.
          </p>
        ),
      },
      {
        question:
          '¿Cuando vaya a publicar mis artículos debo poner foto real del artículo o puedo sacarla de internet?',
        answer: (
          <p>
            Nuestros usuarios pueden usar fotos de internet a la hora de hacer una
            publicación. Sin embargo, en LoCambio.com consideramos una buena
            práctica que sugerimos a todos nuestros usuarios el uso de fotos reales
            para las publicaciones principalmente de bienes, que permita ver el
            estado o condiciones de los mismos.
          </p>
        ),
      },
      {
        question:
          '¿Cuáles son los requisitos básicos que se necesitan para publicar?',
        answer: (
          <div>
            <p>
              • Ser dueño y tener a la mano el bien o servicio que se va a publicar
            </p>
            <p> Tener una imagen JPG o PNG para la publicación</p>
            <p>
              {' '}
              Describir bien lo que tienes y lo que quieres de la publicación con
              ayuda de nuestro formulario
            </p>
            <p>• Definir un lugar para hacer el trueque o intercambio</p>
          </div>
        ),
      },
      {
        question: '¿Qué tamaño debe de tener la imagen para poder ser publicada?',
        answer: (
          <p>
            Las imágenes de las publicaciones pueden ser archivos JPG o PNG, con un
            peso máximo de 15 mb. Lo importante es asegurarse de que sean fotos
            nítidas y visibles y/o imágenes claras con buena resolución.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Acerca del Intercambio',
    questions: [
      {
        question: '¿Dónde se realizan los intercambios?',
        answer: (
          <div>
            <p>
              Los lugares donde se realicen los intercambios se definen en la
              publicación con ayuda de Google Map. En Locambio.com sugerimos por
              medidas de seguridad pautar en zonas con cámaras y/o servicio de
              seguridad como centros comerciales u otros establecimientos.
            </p>
            <p>
              Pensando en su seguridad, LoCambio.com espera ofrecerles muy pronto
              espacios seguros donde los usuarios podrán encontrarse para concretar
              sus intercambios
            </p>
          </div>
        ),
      },
    ],
  },
  {
    title: 'Acerca de la Seguridad',
    questions: [
      {
        question: '¿Qué debo hacer si fui víctima de fraude?',
        answer: (
          <p>
            Presentar una denuncia inmediatamente a las autoridades de tu país y
            enviar un correo a asuntoslegales@locambio.com con la denuncia y
            cualquier otra información pertinente. Nuestro equipo de asuntos legales
            lo atenderá.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Chat de Intercambio, Explorador y Notificaciones',
    questions: [
      {
        question: '¿Cómo funciona el explorador?',
        answer: (
          <p>
            Sólo debes escribir lo que quieres en la barra de búsqueda de
            Locambio.com, por ejemplo “Ropa de bebe” y el explorador de LoCambio.com
            te mostrará las opciones más cerca de tu ubicación. Incluso puedes usar
            los filtros del explorador para hacer más precisa tu búsqueda.
          </p>
        ),
      },
      {
        question: '¿Cómo funciona el sistema de mensajería?',
        answer: (
          <p>
            El chat de intercambios permite conocer a los usuarios y conversar sobre
            los detalles de sus propuestas y ponerse de acuerdo para concluir los
            intercambios.
          </p>
        ),
      },
      {
        question: '¿Cómo funciona el sistema de notificaciones en LoCambio.com?',
        answer: (
          <div>
            <p>
              Hay varias razones para recibir notificaciones en LoCambio.com, las
              principales son:
            </p>
            <p>• Cuando un usuario hace una pregunta a una publicación</p>
            <p>
              • Cuando un usuario hace una propuesta de intercambio a una
              publicación
            </p>
            <p>• Cuando un usuario envía un mensaje por el chat de intercambios</p>
            <p>• Cuando una propuesta de intercambio es aceptada o rechazada</p>
            <p>• Cuando un intercambio se confirma o se cancela</p>
          </div>
        ),
      },
    ],
  },
];

const Faq = () => {
  const [isOpen, setIsOpen] = useState(null);

  const handleOpen = (i) => {
    if (i === isOpen) return setIsOpen(null);
    setIsOpen(i);
  };

  const faqElements = (questions, questionsId) => questions.map((element, i) => {
    const questionId = `${questionsId} ${i}`;
    const currentOpen = questionId === isOpen;
    const className = currentOpen ? 'active' : '';

    return (
      <div className="mt-2">
        <Button
          text={element.question}
          className={`bold-purple text-center leading-normal ${className}`}
          rightIcon="chevron-down"
          fill
          onClick={() => handleOpen(questionId)}
        />
        <Collapse className="purple" isOpen={currentOpen}>
          <Callout className="text-center">{element.answer}</Callout>
        </Collapse>
      </div>
    );
  });

  return (
    <div className="faq">
      {
        helpFaq.map((section, i) => (
          <Card className="my-4">
            <h3 className="mb-5">{section.title}</h3>
            {faqElements(section.questions, i)}
          </Card>
        ))
      }
    </div>
  );
};

export default Faq;
