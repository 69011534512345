import React, { useState } from 'react';
import { connect } from 'react-redux';
import './searchBar/SearchBar.scss';
import { withRouter } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import * as actions from '../store/actions/index';

const SearchBar = (props) => {
  const [valueSearch, setValueSearch] = useState('');
  const [searching, setSearching] = useState('name');
  const [clickedInput, setClickedInput] = useState(false);
  const [location, setLocation] = useState('all');

  const handleTagChangeSearching = () => {
    setSearching('name');
  };

  const handleClickedInput = () => {
    setClickedInput(true);
  };

  const handleBlur = () => {
    if (valueSearch.length === 0) setClickedInput(false);
  };

  const handleTagChangeHaving = () => {
    setSearching('trade');
  };

  const handleChange = (event) => {
    setValueSearch(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.history.push(
      `/search_list/${valueSearch.replaceAll(' ', ',')}/${location}/0/10/${
        props.latitude
      }/${props.longitude}/false/${props.typeSearch}/${searching}`
    );
  };
  const getText = () => {
    return searching === 'name' ? (
      <p onClick={handleClickedInput} className="search-bar-text">
        Busca lo que{' '}
        <span className="active-lo-quiero-text">quieres conseguir </span>a
        cambio{' '}
      </p>
    ) : (
      <p onClick={handleClickedInput} className="search-bar-text">
        Busca <span className="active-lo-tengo-text">quien quiere </span>lo que
        tienes
      </p>
    );
  };

  return (
    <div className="middle-navbar flex flex-col">
      <div className="flex flex-row">
        <span
          className={`search-tab search-tab-left ${
            searching === 'name' ? 'active-lo-quiero' : 'disabled'
          }`}
          onClick={handleTagChangeSearching}
        >
          Lo quiero
        </span>
        <span
          className={`search-tab search-tab-right ${
            searching === 'trade' ? 'active-lo-tengo' : 'disabled'
          }`}
          onClick={handleTagChangeHaving}
        >
          Lo tengo
        </span>
      </div>
      <div
        class="flex flex-row justify-between search-bar"
        onClick={handleClickedInput}
      >
        {!clickedInput ? (
          getText()
        ) : (
          <form className="full-width" onSubmit={handleSubmit}>
            <input
              value={valueSearch}
              onBlur={handleBlur}
              id="searchInput"
              autoFocus
              onChange={handleChange}
              className="header-search-bar padding-search-bar"
              type="search"
            />
          </form>
        )}
        <Icon icon="search" className="padding-search-bar" iconSize={22} onClick={handleSubmit}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.tags.tags,
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
    typeSearch: state.searchbar.typeSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetTypeSearch: (typesSearch) =>
      dispatch(actions.setTypeSearch(typesSearch)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
