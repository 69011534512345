import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';

import './App.scss';
import Routes from './components/Routes';
import { SimpleToast } from './utility/toast';
import ChatCable from 'common-component/ChatCable';
import GiveRanking from './components/GiveRanking';
import HeaderNavbar from './components/HeaderNavbar';
import InitialLoading from './components/InitialLoading';
import { setNetworkStatus } from './store/actions/network';
import NotificationCable from 'common-component/NotificationCable';
import { checkPosition, checkSesion, getPosition, getTradeTags } from './store/actions/index';

import 'react-image-crop/dist/ReactCrop.css';
import 'normalize.css';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

library.add(faStroopwafel);

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkPosition());
    dispatch(getPosition());
    dispatch(checkSesion());
    dispatch(getTradeTags());
  }, [dispatch]);

  useEffect(() => {
    let toast;

    const handleOnline = () => {
      SimpleToast.dismiss(toast);
      dispatch(setNetworkStatus(true));
    };

    const handleOffline = () => {
      toast = SimpleToast.show({
        className: 'offline-toast',
        timeout: 0,
        intent: 'danger',
        icon: 'warning-sign',
        message: 'No tienes conexión a internet',
      });
      dispatch(setNetworkStatus(false));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const loading = (
    !props.sessionVerifed ||
    !props.latitude ||
    !props.longitude
  );

  if (loading) return <InitialLoading />;

  return (
    <>
      <header>
        <HeaderNavbar />
      </header>
      <div>
        <Routes
          isAuthenticated={props.isAuthenticated}
          sessionVerifed={props.sessionVerifed}
        />

        {
          props.isAuthenticated && (
            <>
              <NotificationCable />
              <ChatCable />
            </>
          )
        }
      </div>
      <GiveRanking />
    </>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    sessionVerifed: state.auth.sessionVerifed,
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
  };
};

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(App));
