import React from "react";
import { Dialog, Callout } from "@blueprintjs/core";
import {
  validations,
  touchedControl,
  helperText
} from "./completeUserData/validations";
import { getHeader } from "./../../system/Sesion";
import { config } from "./../../system/Config";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "./../../store/actions/index";
import CompleteIndividualRegistration from "./../../components/CompleteIndividualRegistration";
import PropTypes from "prop-types";

class CompleteUserData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        address: "",
        city: "",
        postal_code: "",
        state: ""
      },
      saving: false
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentWillReceiveProps() {
    this.setInitialState();
  }

  setInitialState = () => {
    const { data } = this.props;
    const formData = {
      address: data.address,
      city: data.city,
      postal_code: data.postal_code,
      state: data.state
    };
    this.setState({ formData: formData });
  };

  inputChangeHandler = event => {
    const name = event.target.id;
    const value = event.target.value;
    let formData = { ...this.state.formData };
    formData[name] = value;
    validations(formData, true);
    if (touchedControl.hasOwnProperty(name)) {
      touchedControl[name] = true;
    }
    this.setState({ formData: formData });
  };

  fetchUpdateData = data => {
    const userId = localStorage.getItem("userId");
    return axios.put(`${config.URL_API}/users/${userId}`, data, {
      headers: getHeader()
    });
  };

  send = async () => {
    let data = { ...this.state.formData };
    const validation = validations(data, true).valid;

    if (!validation) {
      return;
    }

    this.setState({ saving: true });
    data["password_required"] = false;

    try {
      await this.fetchUpdateData(data);
      this.setState({ saving: false });
      this.props.onUpdateDataUser(localStorage.getItem("tokenId"));
      this.props.success();
    } catch (err) {
      this.setState({ saving: false });
    }
  };

  render() {
    const data = this.state.formData;
    return (
      <>
        <Dialog
          icon={"info-sign"}
          className={"paddingBottom-0"}
          isOpen={true}
          onClose={this.props.onClose}
          title="Completa el registro"
        >
          <div className={"padding-1em"}>
            <Callout intent={"warning"} className={"marginBottom-1em"}>
              <h3 className={"margin0 marginBottom-05em"}>
                Para enviar la solicitud de cambio debes completar el registro
              </h3>
            </Callout>
            <CompleteIndividualRegistration
              data={data}
              changed={this.inputChangeHandler}
              hideMessageInformative={true}
              buttonSend={true}
              helperText={helperText}
              send={this.send}
              saving={this.state.saving}
              formValid={validations(data, true).valid}
            />
          </div>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateDataUser: token => dispatch(actions.checkSesion(token))
  };
};

CompleteUserData.propTypes = {
  onSucess: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(CompleteUserData);
