import React from 'react';
import { productNameUrl } from '../utilityFunctions';
import history from '../utility/history';

const typeNotifications = {
  new_question: {
    id: 1,
    name: 'new_question',
    url: 'comments_publications',
    kind: 'comment',
    logo: (notification) => notification.model.user.avatar,
    message: (notification) => <span>Preguntó en tu publicación: <b>{notification.model.publication.title}</b></span>,
    title: (notification) => `${notification.model.user.name} ${notification.model.user.last_name}`,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  new_response_comment: {
    id: 2,
    name: 'new_response_comment',
    url: 'comments_publications',
    kind: 'comment',
    title: (notification) => `${notification.model.user.name} ${notification.model.user.last_name}`,
    logo: (notification) => notification.model.user.avatar,
    message: (notification) => <span>Respondió tu pregunta en <b>{notification.model.publication.title}</b></span>,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  NEW_PROPOSAL_CHANGE: { id: 3, name: 'NEW PROPOSAL CHANGE' },
  accepted_proposal: {
    id: 4,
    url: 'transactions',
    kind: 'change',
    name: 'accepted_proposal',
    user: (notification) => {
      if (Number(localStorage.userId) === Number(notification.model.publication.owner_id)) {
        return notification.model.interested_user;
      }
      return notification.model.publication.owner;
    },
    message: (notification) => <span>Aceptó tu propuesta en <b>{notification.model.publication.title}</b></span>,
    title: (notification) => {
      const user = typeNotifications.accepted_proposal.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => typeNotifications.accepted_proposal.user(notification).avatar,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  rejected_proposal: {
    id: 5,
    name: 'rejected_proposal',
    url: 'transactions',
    kind: 'change',
    user: (notification) => {
      if (Number(localStorage.userId) === Number(notification.model.publication.owner_id)) {
        return notification.model.interested_user;
      }
      return notification.model.publication.owner;
    },
    message: (notification) => <span>Rechazó tu propuesta en <b>{notification.model.publication.title}</b></span>,
    title: (notification) => {
      const user = typeNotifications.rejected_proposal.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => typeNotifications.rejected_proposal.user(notification).avatar,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  new_change: {
    id: 6,
    name: 'new_change',
    url: 'transactions',
    kind: 'change',
    title: (notification) => `${notification.model.interested_user.name} ${notification.model.interested_user.last_name}`,
    logo: (notification) => notification.model.interested_user.avatar,
    message: (notification) => <span>Te envió una propuesta en <b>{notification.model.publication.title}</b></span>,
    onClick: () => () => {
      history.push('/requests');
    },
  },
  exchange_accepted: {
    id: 7,
    name: 'exchange_accepted',
    url: 'transactions',
    kind: 'change',
    user: (notification) => {
      if (Number(localStorage.userId) === Number(notification.model.publication.owner_id)) {
        return notification.model.interested_user;
      }
      return notification.model.publication.owner;
    },
    message: (notification) =>
      <span>Confirmó y calificó un cambio contigo en <b>{notification.model.publication.title}</b></span>,
    title: (notification) => {
      const user = typeNotifications.exchange_accepted.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => typeNotifications.exchange_accepted.user(notification).avatar,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  exchange_rejected: {
    id: 8,
    name: 'exchange_rejected',
    url: 'transactions',
    kind: 'change',
    user: (notification) => {
      if (Number(localStorage.userId) === Number(notification.model.publication.owner_id)) {
        return notification.model.interested_user;
      }
      return notification.model.publication.owner;
    },
    message: (notification) => <span>Rechazó tu propuesta en <b>{notification.model.publication.title}</b></span>,
    title: (notification) => {
      const user = typeNotifications.exchange_rejected.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => typeNotifications.exchange_rejected.user(notification).avatar,
    onClick: (notification) => () => {
      history.push(`/${productNameUrl(notification.model.publication.name)}/${notification.model.publication.id}`);
    },
  },
  CHANGE_FINALIZED: { id: 9, name: 'CHANGE FINALIZED' },
  NEW_MESSAGE: { id: 10, name: 'NEW MESSAGE' },
  SHARE: { id: 11, name: 'SHARE' },
  ToThirdPartyHaveOwnerInterest: { id: 12, name: 'ToThirdPartyHaveOwnerInterest' },
  ToOwnerArticleHeWant: { id: 13, name: 'ToOwnerArticleHeWant' },
  ToOwnerThirdPartyWantArticle: { id: 14, name: 'ToOwnerThirdPartyWantArticle' },
  ToThirdPartyWantOwnerArticle: { id: 15, name: 'ToThirdPartyWantOwnerArticle' },
  ToOwnerSameInterest: { id: 16, name: 'ToOwnerSameInterest' },
  ToThirdPartySameInterest: { id: 17, name: 'ToThirdPartySameInterest' },
  transaction_completed_by_the_system: {
    id: 18,
    name: 'transaction_completed_by_the_system',
    url: 'transactions',
    kind: 'change',
    title: (notification) => {
      const user = typeNotifications.transaction_completed_by_the_system.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => typeNotifications.transaction_completed_by_the_system.user(notification).avatar,
    message: (notification) => <span>Ya paso una semana desde que se confirmo, intuimos que ya fue completado el cambio de <b>{notification.model.publication.title}</b></span>,
    user: (notification) => {
      if (Number(localStorage.userId) === Number(notification.model.publication.owner_id)) {
        return notification.model.interested_user;
      }
      return notification.model.publication.owner;
    },
  },
  publication_waiting_approval: {
    id: 19,
    name: 'publication_waiting_approval',
    url: 'publications',
    kind: 'publication',
    title: (notification) => {
      const user = typeNotifications.publication_waiting_approval.user(notification);
      return `${user.name} ${user.last_name}`;
    },
    logo: (notification) => notification.model.owner.avatar,
    message: (notification) => <span>Tu publicación <b>{notification.model.title}</b> esta pendiente por aprobación</span>,
    user: (notification) => notification.model.owner,
    publicationName: (notification) => notification.model.name,
  },
}

export default typeNotifications;
