import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { config } from './../system/Config';
import axios from 'axios';
import { Spinner }from "@blueprintjs/core";

class ConfirmateUser extends Component {

  componentDidMount() {
    const token = this.props.match.params.token 
    
    axios.get(`${config.URL_API}/active_user/${token}`)
      .then((data) => {
        if (data.data.response) {
          this.props.history.push(`/login`)
        } else {
          this.props.history.push(`/login`)
        }
      })
      .catch((error) => {
        this.props.history.push(`/login`)
      })
  }

  render() {
    return(
      <Spinner className={'marginTop-1em marginBottom-1em'} size={'32'} />
    )
  }
}

export default withRouter(ConfirmateUser);