import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';

const Images = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const intervalRef = useRef();

  useEffect(() => () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (images.length > 1) {
      intervalRef.current = setInterval(() => {
        setImageIndex((imageIndex) => imageIndex >= images.length - 1 ? 0 : imageIndex + 1);
      }, 4000);
    }
    setImageIndex(0);
  }, [images]);

  const imageStyle = {
    backgroundImage: `url(${images[imageIndex]?.url || images[imageIndex]})`,
  };

  if (!images || !images.length) return null;

  return (
    <div className="images" style={imageStyle} />
  );
};

Images.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      PropTypes.string.isRequired,
    ]),
  ),
};

const areEqual = ({ images: prevImages }, { images: nextImages }) => {
  return (prevImages.every((image, i) => image === nextImages[i]));
};

export default memo(Images, areEqual);