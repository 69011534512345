import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Card, Dialog, Elevation, Tag } from '@blueprintjs/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import ProducDetailsMobileActions from './ProducDetailsMobileActions';
import { isNewPublication, needOverlay } from '../../utilityFunctions';
import OverlayPremium from '../../components/OverlayPremium';
import ProductDetailSection from './ProductDetailSection';
import ProductSwiper, { swiperParams } from '../../components/productSwiper/ProductSwiper';
import WrapperImageZoom from '../../components/wrapperImageZoom/WrapperImageZoom';
import './ProductDetailMainSection.scss';

const modalStyle = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  width: '60%',
};

export default function ProductDetailMainSection({
  pubInTransaction,
  pub,
  toChange,
  isAuthenticated,
  userId,
  handlerVisibiltyUserDetailPanel,
  suscription,
}) {
  const [isOpenCarouselModal, setIsOpenCarouselModal] = useState(false);

  const handleOpenCarouselModal = () => {
    setIsOpenCarouselModal(true);
  };

  return (
    <Card elevation={Elevation.ONE}>
      <Dialog
        isOpen={isOpenCarouselModal}
        onClose={() => setIsOpenCarouselModal(false)}
        style={modalStyle}
      >
        <Swiper {...swiperParams}>
          {pub.pictures.map((image) => (
            <SwiperSlide>
              <img
                className="product-modal-img"
                alt="product"
                src={image?.url || image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Dialog>
      <Row>
        <Col xs={12} className="md:hidden">
          <ProducDetailsMobileActions
            pubInTransaction={pubInTransaction}
            pub={pub}
            toChange={toChange}
            isAuthenticated={isAuthenticated}
            userId={userId}
            handlerVisibiltyUserDetailPanel={handlerVisibiltyUserDetailPanel}
          />
        </Col>
        <Col xs={12} lg={6}>
          <div className="positionNewTag">
            {isNewPublication(pub.publish_date) ? (
              <Tag className="tagNew">Nueva publicación</Tag>
            ) : null}
          </div>
          <ProductSwiper
            zoom
            onClick={handleOpenCarouselModal}
            imgClass="zoom-in"
            pictures={pub.pictures}
            needOverlay={needOverlay(
              pub.product_category,
              suscription,
            )}
          />
          {needOverlay(pub.product_category, suscription) && (
            <OverlayPremium />
          )}
        </Col>
        <Col xs={12} lg={6}>
          <WrapperImageZoom />
          <ProductDetailSection
            pub={pub}
            pubInTransaction={pubInTransaction}
            toChange={toChange}
            suscription={suscription}
            isAuthenticated={isAuthenticated}
            userId={userId}
          />
        </Col>
      </Row>
    </Card>
  );
}
