import React from 'react';
import Logo from '../../../Assets/Logo/logoB&W.png';
import { Link } from 'react-router-dom';

const directory = [
  {
    title: 'Acerca de',
    destiny: [
      { route: '/about_locambio', page: 'Locambio.com' },
      { route: '/', page: 'Blog.locambio.com' },
      { route: '/contactos', page: 'Contacto' },
    ],
  },
  {
    title: 'Ayuda',
    destiny: [
      { route: '/faq', page: 'Preguntas frecuentes' },
      { route: '/consejos_de_seguridad', page: 'Consejos de Seguridad' },
      {
        route: '/politicas-de-privacidad',
        page: 'Políticas de privacidad',
      },
      { route: '/terminos-y-condiciones', page: 'Términos y Condiciones' },
    ],
  },
  {
    title: 'Cuenta',
    destiny: [
      { route: '/profile', page: 'Perfil' },
      { route: '/wall', page: 'Mural' },
      { route: '/requests', page: 'Cambios' },
      { route: '/chats', page: 'Mensajes' },
      { route: '/my-publications', page: 'Publicaciones guardadas' },
      { route: '/new_publication', page: 'Crear publicacion' },
    ],
  },
  {
    title: 'Redes Sociales',
    destiny: [
      { route: '/', page: 'Instagram' },
      { route: '/', page: 'Facebook' },
      { route: '/', page: 'Twitter' },
      { route: '/', page: 'Tiktok' },
      { route: '/', page: 'Youtube' },
      { route: '/', page: 'Whatsapp' },
    ],
  },
];

const displayDirectory = directory.map((section, index) => (
  <div key={`section${index}`} className="footer__section">
    <h2 className="footer__section-title">{section.title}</h2>
    {
      section.destiny.map((destiny, index) => (
        <Link key={`destiny${index}`} to={destiny.route}>
          <p className="footer__section-destiny">{destiny.page}</p>
        </Link>
      ))
    }
  </div>
));

const greyBar = (
  <div className="footer__grey-bar">
    <p className="footer__disclaimer">
      t-locambio, C.A. 2019 -RIF: J-40865264-1
    </p>
    <img className="footer__logo" src={Logo} />
    <p className="footer__disclaimer">
      Todos los Derechos Reservados Ⓒ locambio.com
    </p>
  </div>
);
const Footer = () => {

  return (
    <footer>
      <div className="footer__directory">
        {displayDirectory}
      </div>
      {greyBar}
    </footer>
  );
};

export default Footer;
