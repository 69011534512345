import React from 'react';
import { Button, Card } from '@blueprintjs/core';
import { ELEVATION_1 } from '@blueprintjs/core/lib/esm/common/classes';
import './pubAsociatedUser/PubsAsociatedUser.scss';
import { getGCSUrl } from '../../utilityFunctions';

const PubsAsociatedUser = (props) => {
  const { pubs, idPubSelected } = props;

  return (
    <div className="PubsAsociatedUser">
      <Card elevation={ELEVATION_1} className="padding-1em">
        <h3 className="margin0 marginBottom-05em">2.- Tus publicaciones</h3>
        <div className="SelectionPublicationsList">
          {pubs.map((pub, i) => (
            <Card
              interactive
              elevation={ELEVATION_1}
              className={`padding-05em ${
                idPubSelected === pub.id ? 'selectedPub' : null
              }`}
              key={`pubAsociated${i}`}
            >
              <div className="productsItemList marginBottom-05em text-center">
                <div className="flex justify-center">
                  <div className="avatarWrapper">
                    {pub.pictures.length && (
                      <img
                        src={pub.pictures[0]?.url}
                        alt="avatarImage"
                      />
                    )}
                  </div>
                </div>
                <div className="ProductDetailsContainer">
                  <h4 className="mb-3">{pub.name}</h4>
                </div>
                <div className="optionsButton">
                  <Button
                    className="BtnLCPrimaryOutline"
                    text="Elegir esta"
                    onClick={() => props.selectPub(pub.id)}
                    minimal
                    fill
                  />
                </div>
              </div>
            </Card>
          ))}
        </div>

        {idPubSelected !== null ? (
          <Button
            className="BtnLCPrimary"
            onClick={props.desSelectPub}
            fill
          >
            Eliminar seleccion
          </Button>
        ) : null}
      </Card>
    </div>
  );
};

export default PubsAsociatedUser;
