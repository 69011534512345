import React from "react";
import ProductSwiper from "../../../../components/productSwiper/ProductSwiper";
import { Tag } from "@blueprintjs/core";
import { needOverlay } from "./../../../../utilityFunctions";
import { Link } from "react-router-dom";
import { productNameUrl } from "./../../../../utilityFunctions";

const PublicationInterested = props => {
  const { pub, suscription } = props;
  return (
    <>
      <div className={'block'}>
        <div>

          <h1
            className={
              "m-0 pt-2 sm:pt-0 md:pt-0"
            }
          >
            {
              <Link to={`/${productNameUrl(pub.name)}/${pub.id}`}>
                {pub.name}
              </Link>
            }
          </h1>
          <p className="bp3-form-helper-text margin0">Condición del artículo</p>
          <p>
            {pub.product_status === "Usado" ? (
              <Tag
                className="tag marginRight-05em marginBottom-05em"
                minimal={true}
              >
                Usado
              </Tag>
            ) : null}
            {pub.product_status === "Nuevo" ? (
              <Tag
                className="tag marginRight-05em marginBottom-05em"
                minimal={true}
              >
                Nuevo
              </Tag>
            ) : null}
            {pub.product_status === "Usado" ? (
              <Tag
                className="tag marginRight-05em marginBottom-05em"
                minimal={true}
              >
                {pub.condition}
              </Tag>
            ) : null}
          </p>
        </div>
        <div>
          <ProductSwiper
            needOverlay={needOverlay(pub.product_category, suscription)}
            pictures={pub.pictures_urls}
            isWall={true}
          />
        </div>
      </div>
      {/* <div>
        <p className="bp3-form-helper-text margin0">Lo cambio por:</p>
        <p className={"marginBottom-1em"}>
          {pub.trade_tag.split(",").map((tag, i) => (
            <Tag
              key={`successchangetradetag${i}`}
              className={"tag marginRight-05em"}
            >
              {tag}
            </Tag>
          ))}
        </p>
      </div> */}
    </>
  );
};

export default PublicationInterested;
