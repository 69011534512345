import React from 'react';
import { dateMonthStringShort } from '../../utilityFunctions';
import { Divider } from '@blueprintjs/core';
import AvatarUser from '../AvatarUser';
import './NotificationHeaderElement.scss';
import { typeNotifications } from '../../const';

class NotificationHeaderElement extends React.Component {
  getUser(notification, value) {
    const tn = notification.type_notification_id;
    if (
      tn === typeNotifications.new_question.name ||
      tn === typeNotifications.new_response_comment.name    
    ) {
      return value.user;
    } else if (tn === typeNotifications.accepted_proposal.name) {
      return typeNotifications.rejected_proposal.user(notification);
    } else if (tn === typeNotifications.rejected_proposal.name) {
      return typeNotifications.rejected_proposal.user(notification);
    } else if (tn === typeNotifications.new_change.name) {
      return value.interested_user;
    } else if (tn === typeNotifications.exchange_accepted.name
      || tn === typeNotifications.exchange_rejected.name
      || tn === typeNotifications.transaction_completed_by_the_system.name) {
      return typeNotifications.exchange_rejected.user(notification);
    } else if (tn === typeNotifications.publication_waiting_approval.name) {
      return typeNotifications.publication_waiting_approval.user(notification);
    }
    return null;
  }

  publicationUserClass = () => {
    return this.props.value.publication?.owner_id === this.props.notification.user_id ?
      'bp3-text-overflow-ellipsis article-text purple-text' :
      'bp3-text-overflow-ellipsis article-text orange-text'
  }

  render() {
    const { value, notification } = this.props;
    const date = this.props.notification.created_at;
    const user = this.getUser(this.props.notification, value);
    const onClick = typeNotifications[notification.type_notification_id].onClick?.(notification);

    return (
      <>
        <div className={`headerUserCard ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
          <div className="AvatarContainer">
            <AvatarUser user={user} />
          </div>
          <div className="UserDetailsContainer marginBottom-05em">
            <div className="flex flex-row name-row">
              <div className="pr-3 UserName">
                <h4>{`${user.name} ${user.last_name}`}</h4>
              </div>
              <div className="NotDate2">{dateMonthStringShort(date)}</div>
            </div>
            <p className="bp3-form-helper-text message-text">{typeNotifications[notification.type_notification_id].message(notification)}</p>
            <h4
              className={this.publicationUserClass()}
            >
              {this.props.value.publication?.name || typeNotifications[notification.type_notification_id].publicationName(notification) }
            </h4>
          </div>
        </div>
        <Divider />
      </>
    );
  }
}

export default NotificationHeaderElement;
