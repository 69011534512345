import React, { useEffect } from "react";
import { Button, Card, Elevation, Callout, Tag } from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./adResults.scss";
import {
  isNewPublication,
  needOverlay,
  productNameUrl,
  formatPrice
} from "utility";
import OverlayPremium from "common-component/OverlayPremium";
import AvatarUser from "common-component/AvatarUser";
import RankingGraph from "common-component/RankingGraph";
import RankingUserStar from "common-component/RankingStar";
import CategoriesOfInterest from "common-component/CategoriesOfInterest";
import ArticlesOfInterest from "common-component/ArticlesOfInterest";
import ProductSwiper from '../../productSwiper/ProductSwiper';
import { connect } from "react-redux";
import { segmentationAds } from "const";

/**
 * @param data, Data of ad
 * @param {Function} onUpdateView, It runs when the ad is viewed or pressed or the ad is seen.
 * @param {Function} onUpdateClick, It runs when ad is pressed.
 */
const AdResults = ({ data, suscription, onUpdateView, onUpdateClick }) => {
  const { publication } = data;

  useEffect(() => {
    const { id, segmentation } = data;
    if (segmentation === segmentationAds.impresion) onUpdateView(id);
  }, [data]);

  const onClicked = () => {
    const { id, segmentation } = data;
    if (segmentation === segmentationAds.click) onUpdateClick(id);
  };

  return (
    <>
      <div>
        <p className={"text-xs text-grey-dark mb-2"}>Publicidad</p>
      </div>
      <div className={"bg-white rounded shadow p-2 mb-4"}>
        <h3>Resultado patrocinado</h3>
        <Card
          elevation={Elevation.ONE}
          interactive={true}
          className={"marginBottom-1em"}
        >
          <Link
            to={`/${productNameUrl(publication.name)}/${publication.id}`}
            onClick={() => onClicked()}
          >
            <div className={"horizontalThumbanil"}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className={"positionNewTag"}>
                    {isNewPublication(publication.publish_date) ? (
                      <Tag className="tagNew">Nueva publicación</Tag>
                    ) : null}
                  </div>
                  {needOverlay(publication.product_category, suscription) ? (
                    <OverlayPremium />
                  ) : null}
                  <Link
                    to={`/${productNameUrl(publication.name)}/${
                      publication.id
                    }`}
                  >
                    <ProductSwiper
                      pictures={publication.pictures}
                      needOverlay={needOverlay(
                        publication.product_category,
                        suscription
                      )}
                    />
                  </Link>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className={"InfoProductContainer"}>
                    <Callout>
                      <div className={"publicationName"}>
                        <h1
                          className={
                            "m-0 pb-2 text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl"
                          }
                        >
                          <Link
                            to={`/${productNameUrl(publication.name)}/${
                              publication.id
                            }`}
                          >
                            {publication.name}
                          </Link>
                        </h1>
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <div className={"infoDetailPublication mb-2"}>
                              <p className={"bp3-form-helper-text pb-1"}>
                                Disponible para:
                              </p>
                              <Tag className={"tagYellow"} >
                                Cambio
                              </Tag>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <div className={"infoDetailPublication mb-2"}>
                              <p className={"bp3-form-helper-text pb-1"}>
                                Precio
                              </p>
                              <Tag className={"tag"} >
                                <strong>
                                {localStorage.getItem("currency")} {formatPrice(publication.price)}
                                </strong>
                              </Tag>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p className={"bp3-form-helper-text pb-1"}>
                                Condición del artículo
                              </p>
                              <Tag
                                className={
                                  "tag marginRight-05em mb-2 sm:mb-2 md:mb-1 lg:mb-1 xl:mb-1"
                                }
                                
                              >
                                {publication.product_status}
                              </Tag>
                              {publication.product_status === "Usado" ? (
                                <Tag
                                  className={
                                    "tag mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0"
                                  }
                                  
                                >
                                  {publication.condition}
                                </Tag>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Callout>
                    <Callout className={"mt-4 sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4"}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="headerUserCard">
                            <div className="AvatarContainer">
                              <Link
                                to={`/publications/third/${publication.owner.id}`}
                              >
                                <AvatarUser user={publication.owner} />
                              </Link>
                            </div>
                            <div className="UserDetailsContainer marginBottom-05em">
                              <h4>
                                <Link
                                  to={`/publications/third/${publication.owner.id}`}
                                >{`${publication.owner.name} ${publication.owner.last_name}`}</Link>
                              </h4>
                              <p className="bp3-form-helper-text">
                                {publication.owner.address}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <RankingGraph user={publication.owner} />
                          <RankingUserStar user={publication.owner} />
                        </Col>
                      </Row>
                    </Callout>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <Callout
                    className={
                      "marginBottom-1em mt-4 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
                    }
                  >
                    <div className={"interestsForChange"}>
                      <h4 className={"marginBottom-05em pb-2"}>
                        Categorias de interés para Cambio
                      </h4>
                      <CategoriesOfInterest
                        categories={publication.exchange_interest_category}
                      />
                    </div>
                  </Callout>
                  <Callout>
                    <div className={"interestsForChange"}>
                      <h4 className={"marginBottom-05em pb-2"}>
                        Productos de interés para Cambio
                      </h4>
                      <ArticlesOfInterest
                        articles={publication.article_tags.slice(0, 5)}
                        tagsHides={publication.article_tags.length > 5}
                      />
                    </div>
                  </Callout>
                </Col>
              </Row>
            </div>
          </Link>
        </Card>

        {/* ============================== */}
        {/* <div className={"p-2"}>aqui va el serachResult</div> */}
        <div className={"w-full bg-white rounded-b p-2"}>
          <div className={"flex"}>
            <div className={"w-full sm:w-full md:w-2/3 lg:w-2/3 xl:w-2/3"}>
              <h5 className={"text-sm mb-0 flex-center"}>{data.title}</h5>
              <p className={"text-xs text-grey-darker mb-0 flex-center"}>
                {data.text}
              </p>
            </div>
            <div className={"w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3"}>
              <div className={"text-right flex-right"}>
                <Button className={"BtnLCPrimary text-center"} minimal={true}>
                  "text"
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AdResults.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdateView: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    suscription: state.auth.suscription
  };
};

export default connect(mapStateToProps)(AdResults);
