import { spaceValidation, addClassElement, removeClassElement, emailValidation, thereNumber, thereSpecialChar, thereUpper } from './../../utilityFunctions';

export const validationsUser = (data, withTouched) => {
  let aux = true;
  let messages = []

  // name validation
  if (!spaceValidation(data.name) || data.name.length <= 1) {
    if (!withTouched || touchedControlUser['name']) {
      addClassElement('name', 'errorInput')
      helperTextsUser.name = 'El nombre no puede ser menor de 2 caracateres'
      messages.push('Nombre')
    }
    aux = false;
  } else {
    helperTextsUser.name = ''
    removeClassElement('name', 'errorInput')
  }

  // lastname validation
  if (!spaceValidation(data.last_name) || data.last_name.length <= 1) {
    if (!withTouched || touchedControlUser['last_name']) {
      addClassElement('last_name', 'errorInput')
      helperTextsUser.last_name = 'El apellido no puede ser menor de 2 caracteres'
      messages.push('Apellido')
    }
    aux = false;
  } else {
    helperTextsUser.last_name = ''
    removeClassElement('last_name', 'errorInput')
  }

  // email validation
  if (!spaceValidation(data.email)) {
    if (!withTouched || touchedControlUser['email']) {
      addClassElement('email', 'errorInput')
      messages.push('Email')
    }
    aux = false 
  } else {
    if (!emailValidation(data.email)) {
      helperTextsUser.email = 'Debe ser un email valido'
      addClassElement('email', 'errorInput')
      aux = false;
    } else {
      helperTextsUser.email = ''
      removeClassElement('email', 'errorInput')
    }
  }

  // dni validation
  if (!spaceValidation(data.dni) || !spaceValidation(data.typeDNI)) {
    if (!withTouched || touchedControlUser['dni']) {
      addClassElement('dni', 'errorInput')
      messages.push('DNI')
    }
    aux = false;
  } else {
    removeClassElement('dni', 'errorInput')
  }

  if (spaceValidation(data.dni)) {
    if (data.typeDNI == 'V') {
      if (data.dni.length < 6 || data.dni.length > 8) {
        helperTextsUser.dni = 'Debe ser un numero de cédula de Identidad valido'
      } else {
        helperTextsUser.dni = ''
      }
    }
    if (data.typeDNI == 'E') {
      if (data.dni.length < 8 || data.dni.length > 10) {
        helperTextsUser.dni = 'Debe ser un numero de cédula de Identidad valido'
      } else {
        helperTextsUser.dni = ''
      }
    }
  }

  // password validation
  if (!spaceValidation(data.password)) {
    if (!withTouched || touchedControlUser['password']) {
      addClassElement('password', 'errorInput')
      messages.push('Password')
    }
    aux = false;
  } else {
    removeClassElement('password', 'errorInput')
  }

  if (spaceValidation(data.password)) {
    if (!thereNumber(data.password) || !thereUpper(data.password) || !thereSpecialChar(data.password)) {
      addClassElement('password', 'errorInput')
      aux = false;
    } else {
      removeClassElement('password', 'errorInput')
    }
  }

  // repeat password validation
  if (!spaceValidation(data.repeatPassword)) {
    if (!withTouched || touchedControlUser['repeatPassword']) {
      addClassElement('repeatPassword', 'errorInput')
      messages.push('repeatPassword')
    }
    aux = false;
  } else {
    removeClassElement('repeatPassword', 'errorInput')
  }

  if (spaceValidation(data.password) && spaceValidation(data.repeatPassword)) {
    if (data.password !== data.repeatPassword) {
      addClassElement('password', 'errorInput')
      addClassElement('repeatPassword', 'errorInput')
      helperTextsUser.repeatPassword = 'La Contraseña deben ser iguales'
      messages.push('Las contrasenas no coinciden')
      aux = false;
    } else {
      helperTextsUser.repeatPassword = ''
      removeClassElement('password', 'errorInput')
      removeClassElement('repeatPassword', 'errorInput')
    }
  }

  // fecha de nacimiento  validation
  if (!spaceValidation(data.date_of_birth)) {
    if (!withTouched || touchedControlUser['date_of_birth']) {
      messages.push('Fecha de Nacimiento')
      addClassElement('year', 'errorInput')
      addClassElement('month', 'errorInput')
      addClassElement('day', 'errorInput')
    }
    aux = false;
  } else {
    removeClassElement('year', 'errorInput')
    removeClassElement('month', 'errorInput')
    removeClassElement('day', 'errorInput')
  }

  // gender validation
  if (!spaceValidation(data.gender)) {
    if (!withTouched || touchedControlUser['gender']) {
      addClassElement('genderRadio', 'errorInput')
      messages.push('Genero')
    }
    aux = false;
  } else {
    removeClassElement('genderRadio', 'errorInput')

   // country validation
  if (!spaceValidation(data.country)) {
    if (!withTouched || touchedControlUser['country']) {
      messages.push('País')
      addClassElement('country', 'errorInput')
    }
    aux = false;
  } else {
    removeClassElement('country', 'errorInput')
  }

  }
  if (!data.termsAceppt) {
    messages.push('Aceptar terminos y condiciones')
    helperTextsUser.termsAceppt = 'Debe aceptar los terminos y condiciones'
    aux = false;
  } else {
    helperTextsUser.termsAceppt = ''
  }
  if (!data.policiesAceppt) {
    messages.push('Aceptar politicas y Privacidad')
    helperTextsUser.policiesAceppt = 'Debe aceptar las politicas y privacidad'
    aux = false;
  } else {
    helperTextsUser.policiesAceppt = ''
  }
  return {
    valid: aux,
    messages: messages
  }
};

const initialTouched = {
  name: false,
  last_name: false,
  email: false,
  dni: false,
  password: false,
  repeatPassword: false
};

const initialHelperTextsUser = {
  name: '',
  last_name: '',
  email: '',
  dni: '',
  password: '',
  repeatPassword: '',
  termsAceppt: '',
  policiesAceppt: ''
};

export let touchedControlUser = {...initialTouched};

export let helperTextsUser = {...initialHelperTextsUser};

export const resetTouchedAndTextHelper = () => {
  touchedControlUser = initialTouched;
  helperTextsUser = initialHelperTextsUser;
}