import {
  addClassElement,
  daysValidation,
  getAttr,
  monthsValidation,
  removeClassElement,
  spaceValidation,
  useTime,
  yearsValidation,
} from '../../../utilityFunctions';

export const validationsStep3 = (data, withTouched, state) => {
  let aux = true;
  let messages = [];

  if (!spaceValidation(data.product_status) && data.subCategory !== 'service') {
    removeClassElement("use_time", "checkInput");
    addClassError("use_time");
    helperTexts["condition"] = "Por favor selecciona el estado del articulo";
    aux = false;
    
  } else {
    helperTexts["condition"] = "";
  }

  if (data.name.length >= 50) {
    addClassError("name");
    removeClassElement("name", "checkInput");
    helperTexts["name"] = "No debe tener mas de 50 caracteres";
    aux = false;
  } else if (data.name.length > 0 ) {
    removeClassError("name");
    addClassElement("name", "checkInput");
    helperTexts["name"] = "";
  } else if (data.name.length == 0 ) {
    addClassError("name");
    removeClassElement("name", "checkInput");
    aux = false;
  }
  
  if (data.product_status === "Usado") {
    if (!spaceValidation(data.condition)) {
      if (!withTouched || touchedControl.condition) {
        helperTexts["condition"] =
          "Por favor selecciona el estado del articulo";
      }
      aux = false;
    } else {
      helperTexts["condition"] = "";
    }
    if (!spaceValidation(data.use_time)) {
      if (!withTouched || touchedControl.use_time) {
        removeClassElement("use_time", "checkInput");
        addClassError("use_time");
      }
      aux = false;
    } else {
      addClassElement("use_time", "checkInput");
      removeClassError("use_time");
    }
    if (!spaceValidation(data.aditional_info)) {
      if (!withTouched || touchedControl.aditional_info) {
        removeClassElement("aditional_info", "checkInput");
        addClassError("aditional_info");
      }
      aux = false;
    } else {
      removeClassError("aditional_info");
      addClassElement("aditional_info", "checkInput");

      if (data.aditional_info.length < 60) {
        helperTexts["aditional_info"] = "Minimo de 60 caracteres";
        addClassError("aditional_info");
        removeClassElement("aditional_info", "checkInput");
        aux = false;
      } else {
        helperTexts["aditional_info"] = "";
        removeClassError("aditional_info");
        addClassElement("aditional_info", "checkInput");
        aux = true;
      }
    }

    if (spaceValidation(state.useTime)) {
      if (state.useTime === useTime.DAYS) {
        if (!daysValidation(data.use_time)) {
          helperTexts["use_time"] =
            "Por favor escriba un dia valido (entre 1 a 31)";
          addClassError("use_time");
          removeClassElement("use_time", "checkInput");
        } else {
          helperTexts["use_time"] = "";
          removeClassError("use_time");
          addClassElement("use_time", "checkInput");
        }
      }
      if (state.useTime === useTime.MONTHS) {
        if (!monthsValidation(data.use_time)) {
          helperTexts["use_time"] =
            "Por favor escriba un mes valido (entre 1 a 12)";
          addClassError("use_time");
          removeClassElement("use_time", "checkInput");
        } else {
          helperTexts["use_time"] = "";
          removeClassError("use_time");
          addClassElement("use_time", "checkInput");
        }
      }
      if (state.useTime === useTime.YEARS) {
        if (!yearsValidation(data.use_time)) {
          helperTexts["use_time"] = "Por favor escriba un año valido";
          addClassError("use_time");
          removeClassElement("use_time", "checkInput");
        } else {
          helperTexts["use_time"] = "";
          removeClassError("use_time");
          addClassElement("use_time", "checkInput");
        }
      }
    }
  }

  if (data.interestCategories.length < 1 && !withTouched) {
    addClassError("list-exchange-categories");
    removeClassElement("list-exchange-categories", "checkInput");
    helperTexts.interestCategories = "Debes agregar por lo menos una categoría";
    aux = false;
  } else if (data.interestCategories.length >= 1) {
    helperTexts.interestCategories = "";
    removeClassError("list-exchange-categories");
    addClassElement("list-exchange-categories", "checkInput");
  }

  // subCategory

  if (!spaceValidation(data.price) || data.price <= 0) {
    removeClassElement("price", "checkInput");
    addClassElement("price", "errorInput");
    helperTexts.price = "El precio de la publicacion no puede estar en blanco";
    aux = false;
  } else {
    helperTexts.price = "";
    removeClassElement("price", "errorInput");
    addClassElement("price", "checkInput");
  }


  if (data.images.length > 0) {
    removeClassError("fotoPrincipal");
    addClassElement("fotoPrincipal", "checkInput");
  } else {
    addClassError("fotoPrincipal");
    removeClassElement("fotoPrincipal", "checkInput");
    aux = false;
  }
  
  return {
    valid: aux,
    messages: messages
  };
};

export const validationsStep4 = (data, withTouched) => {
  let aux = true;
  let messages = [];
  if (!spaceValidation(data.address_1)) {
    if (!withTouched || touchedControl.address_1) {
      addClassError("address_1");
    }
    aux = false;
  } else {
    removeClassError("address_1");
  }
  return {
    valid: aux,
    messages: messages
  };
};

const addClassError = id => {
  if (document.getElementById(id)) {
    document.getElementById(id).classList.add("errorInput");
  }
};

const removeClassError = id => {
  if (document.getElementById(id)) {
    document.getElementById(id).classList.remove("errorInput");
  }
};

export const isThereFeatureValue = (idCategory, publicacion) => {
  let aux = false;
  const attr = getAttr(idCategory).attr;
  attr.forEach(value => {
    if (
      publicacion[value] !== null &&
      publicacion[value] &&
      publicacion[value] !== undefined
    ) {
      if (spaceValidation(publicacion[value])) {
        aux = true;
        return;
      }
    }
  });
  return aux;
};

export const resetStep3AndStep4 = (data, idCategory) => {
  data.name = "";
  data.price = "";
  data.location = "";
  data.locations = [];
  data.product_status = "";
  data.article_tags = [];
  data.tags = [];
  data.interestCategories = [];
  data.tagsFatherExchange = [];
  data.condition = "";
  data.use_time = "";
  data.aditional_info = "";
  data.address_1 = "";
  data.address_2 = "";
  data.latitude = "";
  data.longitude = "";
  data.images = [];
  const featuresCategory = getAttr(idCategory).attr;
  featuresCategory.forEach(value => {
    if (data[value]) {
      data[value] = "";
    }
  });
  return data;
};

export const touchedControl = {
  name: false,
  condition: false,
  product_status: false,
  location: true,
  price: false,
  year: false,
  address_1: false,
  address_2: false,
  description: false,
  brand: false,
  quantity: false,
  material: false,
  school_cycle: false,
  gender: false,
  editor: false,
  number_edition: false,
  laboratory: false,
  type_medication: false,
  watss: false,
  color: false,
  potencia_kw: false,
  potencia_watss: false,
  screen_size: false,
  storage: false,
  megapixels: false,
  race: false,
  old: false,
  type_animal: false,
  mg_ml: false,
  number_tablet: false,
  displacement: false,
  number_door: false,
  number_posts: false,
  km: false,
  type_gas: false,
  type_transmission: false,
  type_ship: false,
  band: false,
  processor: false,
  memory_ram: false,
  resolution: false,
  power: false,
  multifunctional: false,
  lens: false,
  zoom: false,
  liters: false,
  oz: false,
  type_sofa: false,
  name_club: false,
  urbanization: false,
  meters_2: false,
  number_room: false,
  number_bath: false,
  number_pool_parking_lot: false,
  living_room: false,
  dinning_room: false,
  meters_2_construction: false,
  meters_ground: false,
  type_build: false,
  number_areas: false,
  type_industry: false,
  number_cubicles: false,
  number_ropes: false,
  autor: false,
  director: false,
  producer: false,
  season: false,
  kind_care: false,
  name_comic: false,
  name_movie_or_book: false,
  name_gender: false,
  number_pads: false,
  state: false,
  date: false,
  due_date: false,
  weightFilter: false,
  category: false,
  type_aux: false,
  type_general: false,
  name_aux: false,
  model_2: false,
  discipline: false,
  use_time: false,
  aditional_info: false,
  size: false
};

export const validationsControl = {
  name: false,
  condition: false,
  product_status: false,
  use_time: false,
  location: false,
  price: false,
  year: false,
  address_1: false,
  address_2: false,
  description: false,
  brand: false,
  quantity: false,
  material: false,
  school_cycle: false,
  gender: false,
  editor: false,
  number_edition: false,
  laboratory: false,
  type_medication: false,
  watss: false,
  color: false,
  potencia_kw: false,
  potencia_watss: false,
  screen_size: false,
  storage: false,
  megapixels: false,
  race: false,
  old: false,
  type_animal: false,
  mg_ml: false,
  number_tablet: false,
  displacement: false,
  number_door: false,
  number_posts: false,
  km: false,
  type_gas: false,
  type_transmission: false,
  type_ship: false,
  band: false,
  processor: false,
  memory_ram: false,
  resolution: false,
  power: false,
  multifunctional: false,
  lens: false,
  zoom: false,
  liters: false,
  oz: false,
  type_sofa: false,
  name_club: false,
  urbanization: false,
  meters_2: false,
  number_room: false,
  number_bath: false,
  number_pool_parking_lot: false,
  living_room: false,
  dinning_room: false,
  meters_2_construction: false,
  meters_ground: false,
  type_build: false,
  number_areas: false,
  type_industry: false,
  number_cubicles: false,
  number_ropes: false,
  autor: false,
  director: false,
  producer: false,
  season: false,
  kind_care: false,
  name_comic: false,
  name_movie_or_book: false,
  name_gender: false,
  number_pads: false,
  state: false,
  date: false,
  due_date: false,
  weightFilter: false,
  category: false,
  type_aux: false,
  type_general: false,
  name_aux: false,
  model_2: false,
  discipline: false,
  aditional_info: false,
  size: false
};

export const helperTexts = [{ use_time: "", condition: "" }];
