import React from 'react';
import { config } from '../../system/Config';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeader } from '../../system/Sesion';
import NotificationHeaderElement from './NotificationHeaderElement.js';
import NotificationPageElement from './NotificationPageElement.js';
import '../../pages/notificationsPage/NotificationsPage.scss';
import { typeNotifications } from '../../const';

class BaseNotificationGet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      textElements: {},
      stopper: false,
    };
  }

  async componentDidMount() {
    const textElements = typeNotifications[this.props.value.type_notification_id];
    if (!textElements.url) return;

    if (this.props.value.model) {
      this.setState({ data: this.props.value.model, textElements });
      return;
    }

    const { data } = await axios.get(
      `${config.URL_API}/${textElements.url}/${this.props.value.model_id}`,
      { headers: getHeader() },
    );
    this.setState({ data, textElements });
  }

  render() {
    const { typeNotification } = this.props;
    const value = this.state.data;
    if (typeNotification === 'Notification') {
      return (
        (
          value.data !== [] && value.id !== undefined &&
          <NotificationHeaderElement
            key={value.id}
            value={value}
            textElements={this.state.textElements}
            notification={this.props.value}
          />
        ) || null
      );
    }

    if (typeNotification === 'Page') {
      return (
        (
          value.data !== [] && value.id !== undefined &&
          <NotificationPageElement
            key={value.id}
            value={value}
            markNotificationAsRead={this.props.markNotificationAsRead}
            textElements={this.state.textElements}
            notification={this.props.value}
          />
        ) || null
      );
    }
  }
}

BaseNotificationGet.propTypes = {
  value: PropTypes.object.isRequired,
  typeNotification: PropTypes.string.isRequired,
}

export default BaseNotificationGet;
