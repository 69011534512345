import React from 'react';
import { Button, Callout, Card, Tag } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { spaceValidation } from '../../../utilityFunctions';
import Breadcrumb from '../../../breadcrumb/Breadcrumb';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';

const priceReferences = [
  ' por mes',
  ' por semana',
  ' por día',
  ' por hora',
  ' por servicio',
];

const step5 = (props) => {
  const isProduct = props.localState.subCategory === 'product';

  return (
    <Card>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            onClick={() => props.confirmationToStep(2)}
            className="bp3-fill BtnLCPrimary marginBottom-05em"
            disabled={false}
            rightIcon="arrow-up"
            text="Volver al paso 1"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            onClick={() => props.confirmationToStep(3)}
            className="bp3-fill BtnLCPrimary marginBottom-05em"
            disabled={false}
            rightIcon="arrow-up"
            text="Volver al paso 2"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            onClick={() => props.goBackToStep(4)}
            className="bp3-fill BtnLCPrimary marginBottom-1em"
            disabled={false}
            rightIcon="arrow-up"
            text="Volver al paso 3"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h3 className="text-base">Paso 3</h3>
          <h2 className="text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl">
            Resumen de la Nueva Publicación
          </h2>
        </Col>
      </Row>
      <div className="fifthStep">
        <Card className="marginBottom-1em">
          <Row>
            {/* RESUMEN PASO 1 */}
            <Col xs={12} sm={12} md={12} lg={12}>
              <h3 className="mb-4">Paso 1 - Tipo de publicación</h3>
            </Col>
          </Row>
          <Row>
            {/* Categoria Seleccionada */}
            <Col xs={12} sm={12} md={6} lg={6}>
              <Row>
                <Callout
                  className="marginBottom-1em"
                  title="Categoria y subcategria seleccionadas:"
                >
                  <Breadcrumb data={props.localState.categoryAncestors} />
                </Callout>
              </Row>
            </Col>
            {/* Condición */}
            {isProduct && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Callout className="marginBottom-1em" title="Estado:">
                      {props.localState.formData.product_status === 'Nuevo' && (
                        <Tag className="tag">Nuevo</Tag>
                      )}
                      {props.localState.formData.product_status === 'Usado' && (
                        <Tag className="tag">Usado</Tag>
                      )}
                    </Callout>
                  </Col>
                </Row>
              </Col>
            )}
            {isProduct && props.localState.formData.product_status === 'Usado' && (
              <Col>
                <Callout className="marginTop-1em" title="Condición:">
                  {props.localState.formData.condition === 'Excelente' && (
                    <Tag className="tag">Excelente</Tag>
                  )}
                  {props.localState.formData.condition === 'Bueno' && (
                    <Tag className="tag">Poco Uso</Tag>
                  )}
                  {props.localState.formData.condition === 'Detalles' && (
                    <Tag className="tag">Con Detalles</Tag>
                  )}
                  {props.localState.formData.condition === 'Repuesto' && (
                    <Tag className="tag">Para Repuesto</Tag>
                  )}
                </Callout>
              </Col>
            )}
            {/* Tiempo de Uso */}
            {props.localState.formData.product_status === 'Usado' && (
              <Col>
                <Callout className="marginTop-1em" title="Tiempo de Uso:">
                  <Tag className="tag">{props.localState.formData.use_time}</Tag>
                </Callout>
              </Col>
            )}
            {/* Tiempo de Uso */}
            {props.localState.formData.product_status === 'Usado' && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <Callout
                  className="marginTop-1em"
                  title="Descripción de la condición del artículo:"
                >
                  <p>
                    <strong>{props.localState.formData.aditional_info}</strong>
                  </p>
                </Callout>
              </Col>
            )}
          </Row>
        </Card>
        <Card className="marginBottom-1em">
          <Row>
            {/* RESUMEN PASO 2 */}
            <Col xs={12} sm={12} md={12} lg={12}>
              <h3>Paso 2 - Categoria correspondiente a la publicación</h3>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Callout
                className="marginBottom-1em"
                title={isProduct ? 'Imagenes del Artículo:' : 'Imagenes del Servicio:'}
              >
                <Row>
                  {props.localState.formData.images.map((value, i) => (
                    <Col xs={6} sm={3} md={3} lg={3} key={`resume-image-${i}`}>
                      <div
                        className="croppedImageWrapper"
                        id={`resume-image-${i}`}
                      >
                        {value && <img src={value} alt={`publication-${i}`} />}
                      </div>
                    </Col>
                  ))}
                </Row>
              </Callout>
            </Col>

            {/* Nombre de la Publicación */}
            <Col xs={12} sm={12} md={6} lg={6}>
              <Callout
                className="marginTop-1em"
                title="Nombre de la Publicación:"
              >
                <p>
                  <strong>{props.localState.formData.name}</strong>
                </p>
              </Callout>
            </Col>
            {/* Valor de mi artículo */}
            <Col xs={12} sm={12} md={6} lg={6}>
              {
                <Callout className="marginTop-1em" title={`Valor del ${isProduct ? 'Producto' : 'Servicio'}`}>
                  <p>
                    <strong>
                      {props.localState.formData.price + ' ' + localStorage.getItem('currency')}
                      {!isProduct && priceReferences[props.localState.formData.reference_price]}
                    </strong>
                  </p>
                </Callout>
              }
            </Col>
            {/* Lugar en donde se encuentra el Artículo */}
            {isProduct ? (
              <Col xs={12} sm={12} md={6} lg={6}>
                <Callout
                  className="marginTop-1em"
                  title="Lugar donde se encuentra el artículo"
                >
                  <p>
                    <strong>{props.localState.formData.location}</strong>
                  </p>
                </Callout>
              </Col>
            ) : (
              props.localState.formData.locations.length !== 0 && (
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Callout
                    className="marginTop-1em"
                    title="Lugar donde presta el servicio"
                  >
                    <Tag className="marginRight-05em marginBottom-05em" minimal>
                      {props.localState.formData.locations.join(', ')}
                    </Tag>
                  </Callout>
                </Col>
              )
            )}
            {props.localState.formData.editor_web && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <Callout
                  className="marginTop-1em"
                  title={`Descripción del ${isProduct ? 'producto' : 'servicio'}`}
                >
                  <Editor
                    toolbarHidden
                    editorState={EditorState.createWithContent(
                      convertFromRaw(
                        JSON.parse(props.localState.formData.editor_web),
                      ),
                    )}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    readOnly
                  />
                </Callout>
              </Col>
            )}
            <Col xs={12} sm={12} md={6} lg={6}>
              <Callout
                className="marginTop-1em"
                title="Categorias que te interesa obtener en cambio:"
              >
                {props.localState.formData.interestCategories.map((tag, i) => (
                  <Breadcrumb key={`breadcrumb-summary-${i}`} data={tag} />
                ))}
              </Callout>
            </Col>
            {/* Artículos que te interesa obtener en cambio */}
            {props.localState.formData.article_tags.length !== 0 && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <Callout
                  className="marginTop-1em"
                  title="Artículos o servicios que te interesa obtener en cambio:"
                >
                  {props.localState.formData.article_tags.map((tag, i) => (
                    <Tag
                      key={`resumeTradeTag${i}`}
                      className="marginRight-05em marginBottom-05em"
                      minimal={true}
                    >
                      {tag.name || tag}
                    </Tag>
                  ))}
                </Callout>
              </Col>
            )}
          </Row>
        </Card>
        <Card className="marginBottom-1em">
          <Row>
            {/* RESUMEN PASO 1 */}
            <Col xs={12} sm={12} md={12} lg={12}>
              <h3 className="text-base">Paso 4</h3>
              <h2 className="text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl mb-4">
                Punto de encuentro
              </h2>
            </Col>
            {/* MAPA */}
            <Col xs={12} sm={4} md={6} lg={6}>
              <Callout className="marginTop-1em" title="Punto de encuentro:">
                <div id="mapResume" className="mapResume" />
              </Callout>
            </Col>
            {/* Detalles del punto de encuentro */}
            <Col xs={12} sm={12} md={6} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  {/* Dirección donde te gustaría realizar el intercambio */}
                  <Callout
                    className="marginTop-1em"
                    title="Dirección donde te gustaría realizar el intercambio:"
                  >
                    <p>
                      <strong>{props.localState.formData.address_1}</strong>
                    </p>
                  </Callout>
                </Col>
                {spaceValidation(props.localState.formData.address_2) && (
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {/* Punto de referencia (información opcional) */}
                    <Callout
                      className="marginTop-1em"
                      title="Punto de referencia (información opcional):"
                    >
                      <p>
                        <strong>{props.localState.formData.address_2}</strong>
                      </p>
                    </Callout>
                  </Col>
                )}
                <Col xs={12} sm={12} md={12} lg={12}>
                  {/* Coordenadas del punto de encuentro */}
                  <Callout
                    className="marginTop-1em"
                    title="Coordenadas del punto de encuentro:"
                  >
                    <p>
                      <strong>
                        {props.localState.formData.latitude}
                        {props.localState.formData.longitude}
                      </strong>
                    </p>
                  </Callout>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            className="bp3-fill BtnLCYellow"
            loading={props.localState.loading}
            onClick={props.sendToAPI}
            icon="tick"
            text={
              props.edit ?
                'Actualizar publicación' :
                isProduct ?
                  ' Publicar artículo' :
                  ' Publicar servicio'
            }
            disabled={props.publicationSaved}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default step5;
