import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Callout,
  Card,
  ControlGroup,
  Divider,
  FormGroup,
  Icon,
  InputGroup,
  Radio,
  RadioGroup,
  Tag,
  TextArea,
} from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { numbers, spaceValidation, thereSpecialChar, useTime } from '../../../utilityFunctions';
import { helperTexts } from './validations';
import ReactDropzone from 'react-dropzone';
import { Editor } from 'react-draft-wysiwyg';
import AddImage from './../../../components/AddImage';
import InputByCategories from './../../../components/InputyByCategories';
import { countriesCode, provincesColombia, provincesPeru, provincesVenezuela } from 'const';
import MultiSelect from 'react-multi-select-component';
import Categories from '../../../components/categories/Categories';
import Breadcrumb from '../../../breadcrumb/Breadcrumb';

const optionsEditor = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const Step3 = (props) => {
  let stateValues = [];
  const { countryUser, localState: { subCategory } } = props;

  if (countryUser === countriesCode.VENEZUELA) {
    stateValues = provincesVenezuela;
  } else if (countryUser === countriesCode.PERU) {
    stateValues = provincesPeru;
  } else if (countryUser === countriesCode.COLOMBIA) {
    stateValues = provincesColombia;
  }

  const multiSelectSettings = {
    allItemsAreSelected: 'Todos los estados han sido seleccionados',
    clearSearch: 'Limpiar la Busqueda',
    noOptions: 'Sin Opciones',
    search: 'Busqueda',
    selectAll: 'Seleccionar Todos',
    selectSomeItems: 'Seleccionar...',
  }

  useEffect(() => {
    props.onLoadDataStep()
  });

  const handleClickExchangeCategory = useCallback((ancestors) => {
    props.handlerCategorySelectExchange(ancestors);
  }, []);

  return (
    <Card>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            onClick={() => props.confirmationToStep(2)}
            className="bp3-fill BtnLCPrimary marginBottom-1em"
            disabled={false}
            rightIcon="arrow-up"
            text="Volver al paso 1"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="mb-2">
            <h3 className="text-base">Paso 2</h3>
            <h2
              className={
                'text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl'
              }
            >
              {subCategory === 'product' ? 'Especificaciones del artículo' : 'Especificaciones del servicio'}
            </h2>
          </div>
        </Col>
      </Row>
      <Card className="CardForm">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FormGroup
              helperText={helperTexts['name']}
              label="Título de la Publicación"
              labelFor="text-input"
              labelInfo="*"
            >
              <InputGroup
                id="name"
                placeholder="Título de la Publicación"
                value={props.localState.formData.name}
                onChange={props.inputChangeHandler}
                onBlur={props.inputBlurHandler}
                className="whiteInput"
              />
            </FormGroup>
            <Divider className="marginTop-1em marginBottom-1em" />
            <Row>
              {/* Lugar en donde se encuentra el Artículo  */}
              {props.localState.subCategory === 'service' ?
                <Col xs={12} sm={6} md={4} lg={4}>
                  <FormGroup label="Ubicación" labelInfo="">
                    <MultiSelect
                      options={
                        stateValues.map((state) => {
                          return { label: state, value: state }
                        })}
                      value={
                        props.localState.formData.locations.map((state) => {
                            return { label: state, value: state }
                          },
                        )}
                      onChange={props.handleLocations}
                      labelledBy="Estados"
                      overrideStrings={multiSelectSettings}
                    />
                  </FormGroup>
                </Col>
                :
                <Col xs={12} sm={6} md={4} lg={4}>
                  <FormGroup label="Ubicación" labelInfo="">
                    <div className="bp3-select bp3-large .modifier">
                      <select
                        id="location"
                        value={props.localState.formData.location}
                        onChange={props.inputChangeHandler}
                      >
                        <option value="" disabled={true}>
                          Seleccionar
                        </option>
                        {stateValues.map((state) => (
                          <option value={state} key={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                  </FormGroup>
                </Col>
              }
              {/* Valor de mi Artículo */}
              <Col xs={12} sm={6} md={4} lg={4}>
                <FormGroup
                  label="Valor estimado"
                  labelFor="text-input"
                >
                  <InputGroup
                    placeholder={props.localState.formData.subCategory === 'service' ? 'A convenir / ' + localStorage.getItem('currency') :
                      localStorage.getItem('currency')}
                    id="price"
                    type="number"
                    onChange={props.inputChangeHandler}
                    value={props.localState.formData.price}
                    onKeyPress={(event) => numbers(event)}
                    className="whiteInput"
                  />
                </FormGroup>
              </Col>
              <Col>
                {props.localState.formData.price > 0 && props.localState.formData.subCategory === 'service' ?
                  <FormGroup>
                    <RadioGroup
                      className="TipeBox"
                      onChange={props.handlerPriceReference}
                      selectedValue={props.localState.formData.reference_price}
                    >
                      <Radio label=" Al mes" value="0" />
                      <Radio label=" Por semana" value="1" />
                      <Radio label=" Por día" value="2" />
                      <Radio label=" Por hora" value="3" />
                      <Radio label=" Por servicio" value="4" />
                      <Radio label=" Otro" value="5" />
                    </RadioGroup>
                  </FormGroup>
                  : null
                }
              </Col>
            </Row>
            <Divider className="marginTop-1em marginBottom-1em" />
            {props.localState.categorySelected !== null ? (
              <InputByCategories
                idCategory={props.localState.categorySelected.id}
                updateFields={props.updateFields}
                formValues={props.fields}
              />
            ) : null}
          </Col>
        </Row>
      </Card>


      <Divider className="marginTop-1em marginBottom-1em" />
      <Card className="CardForm">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label className="bp3-label" htmlFor="form-group-input">
              {props.localState.subCategory === 'service' ? 'Imágenes referenciales (Logo, Local, Equipos, etc)' : 'Imágenes del artículo'}
              <span className="bp3-text-muted"> *</span>
              <div className="bp3-form-helper-text marginBottom-1em">
                Puedes subir un archivo JPG, GIF o PNG. El límite del tamaño del
                archivo es de 15 MB.
              </div>
            </label>
            <div className="imagesBoxes">
              {props.localState.formData.images.length < 5 && (
                <div className="imageDragZone">
                  <ReactDropzone
                    multiple={false}
                    maxSize={15000000}
                    accept={props.acceptedFiles}
                    onDrop={(files) => props.onDrop(files)}
                    onDropRejected={props.onDropRejected}
                  >
                    {(dropzoneState) => <AddImage className="relative z-20" {...dropzoneState} />}
                  </ReactDropzone>
                </div>
              )}
              {props.localState.formData.images.map((value, i) => (
                <div key={`contImage${i}`} className="imageDragZone">
                  <div className="dragDropDeleteButton">
                    <Button>
                      <Icon icon="trash" onClick={() => props.deleteImage(i)} />
                    </Button>
                  </div>
                  <ReactDropzone
                    multiple={false}
                    maxSize={15000000}
                    accept={props.acceptedFiles}
                    onDrop={(files) => props.onDrop(files, i)}
                    onDropRejected={props.onDropRejected}
                  >
                    {() => (
                      <div className="justify-center h-full w-full">
                        {!value && <AddImage className="relative z-20" />}
                        <div className="image-preview">
                        <div className="croppedImageWrapper" id={`image-${i}`}>
                          {value && <img src={value} alt={`image-${i}`} />}
                        </div>
                        </div>
                      </div>
                    )}
                  </ReactDropzone>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
      <Divider className="marginTop-1em marginBottom-1em" />
      {
        props.localState.subCategory !== 'service' &&
        <Card className="CardForm">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {/* NUEVO O USADO */}
              <div id="product_status">
                <RadioGroup
                  inline={true}
                  label="Condición del artículo *"
                  onChange={props.inputChangeHandler}
                  selectedValue={props.localState.formData.product_status}
                >
                  <Radio label="Nuevo" value="Nuevo" id="product_status" />
                  <Radio label="Usado" value="Usado" id="product_status" />
                </RadioGroup>
              </div>
              {props.localState.formData.product_status === 'Usado' ? (
                <Row>
                  {/* DETALLES ESTADO USADO */}
                  <Col xs={12} sm={12} md={4} lg={4}>
                    <Divider className="marginTop-1em marginBottom-1em" />
                    <FormGroup
                      helperText={helperTexts['condition']}
                      label="Condición del Artículo"
                      labelInfo="*"
                    >
                      <div id="contCondition">
                        <RadioGroup
                          id="contCondition"
                          onChange={props.inputChangeHandler}
                          selectedValue={props.localState.formData.condition}
                        >
                          <Radio
                            id="condition"
                            label="Excelente"
                            value="Excelente"
                          />
                          <Radio
                            id="condition"
                            label="Con Detalles"
                            value="Con Detalles"
                          />
                          <Radio
                            id="condition"
                            label="Poco Uso"
                            value="Poco Uso"
                          />
                          <Radio
                            id="condition"
                            label="Para Respuesto"
                            value="Para Respuesto"
                          />
                        </RadioGroup>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8}>
                    <Divider className="marginTop-1em marginBottom-1em" />
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4}>
                        <FormGroup label="Tiempo de Uso" labelInfo="*">
                          <RadioGroup
                            onChange={props.handlerUseTime}
                            selectedValue={props.localState.useTime}
                          >
                            <Radio label="dia/s" value={useTime.DAYS} />
                            <Radio label="mes/es" value={useTime.MONTHS} />
                            <Radio label="año/s" value={useTime.YEARS} />
                          </RadioGroup>
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={8}>
                        <FormGroup
                          helperText={helperTexts.use_time}
                          label={
                            props.localState.useTimeLabel[
                              props.localState.useTime
                              ]
                          }
                          labelInfo="*"
                        >
                          <ControlGroup>
                            <InputGroup
                              fill={true}
                              className="whiteInput"
                              id="use_time"
                              onChange={props.inputChangeHandler}
                              value={props.localState.formData.use_time}
                              placeholder="Números de dias, meses o años"
                              onKeyPress={numbers}
                              disabled={
                                !spaceValidation(props.localState.useTime)
                              }
                            />
                          </ControlGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Divider className="marginTop-1em marginBottom-1em" />
                    <FormGroup
                      helperText={helperTexts['aditional_info']}
                      label="Descripción del estado del Artículo"
                      labelInfo="*"
                    >
                      <TextArea
                        className="textAreaWhiteStyle"
                        fill={true}
                        placeholder="Haz una descripción detallada sobre el estado de tu artículo"
                        id="aditional_info"
                        value={props.localState.formData.aditional_info}
                        onChange={props.inputChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Card>
      }
      <Divider className="marginTop-1em marginBottom-1em" />
      <Card className="CardForm">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <label className="bp3-label" htmlFor="form-group-input">
                  <h3>¿Qué categorías, artículos o servicios te
                    interesan? </h3>
                  <br />
                </label>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4}>
                    <Categories title="Categorías" onClick={handleClickExchangeCategory} />
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8}>
                    <Card>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="marginBottom-1em">
                            {props.localState.categorySelectedExchange !==
                            null ? (
                              <Tag minimal={true} onRemove={true} multiline="">
                                {
                                  props.localState
                                    .categorySelectedFatherExchange.name
                                }
                              </Tag>
                            ) : null}
                            {props.localState.categorySelectedExchange ? (
                              <Icon icon="chevron-right" />
                            ) : null}
                            {props.localState.categorySelectedExchange !==
                            null ? (
                              <Tag
                                className="marginRight-05em"
                                minimal={true}
                                onRemove={true}
                                multiline=""
                              >
                                {props.localState.categorySelectedExchange.name}
                              </Tag>
                            ) : null}
                          </div>
                          <div className="bp3-form-helper-text marginBottom-1em">
                            {helperTexts.interestCategories}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Callout
                            id="category-enchange"
                            icon="plus"
                            title="Categorías de interés"
                          >
                            <div className="calloutNoActivity"> {/*paso dos*/}
                              {!props.localState.formData.interestCategories.length && (
                                <p>
                                  <Icon icon="issue" /> No hay categorias
                                  agregadas
                                </p>
                              )}
                            </div>
                            {props.localState.formData.interestCategories.map(
                              (tag, i) => (
                                <Breadcrumb
                                  key={`breadcrumb-${i}`}
                                  data={tag}
                                  onRemove={() => props.removeExchangeTag(tag)}
                                />
                              ),
                            )}
                          </Callout>
                        </Col>
                      </Row>
                    </Card>
                    <Divider className="marginTop-1em marginBottom-1em" />
                    <Card>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <FormGroup
                                label="Especifica artículos o servicios que te interesen"
                                // labelInfo="*"
                              >
                                <ControlGroup className="mt-2">
                                  <InputGroup
                                    className="whiteInput bp3-fill"
                                    fill={true}
                                    placeholder="Escribe aquí los artículos o servicios que te interesan…"
                                    value={props.localState.formData.trade_tag}
                                    id="trade_tag"
                                    onChange={props.inputChangeHandler}
                                  />
                                  <Button
                                    onClick={() =>
                                      props.addTag(
                                        props.localState.formData.trade_tag,
                                      )
                                    }
                                    className="BtnLCPrimary"
                                    icon="add"
                                    text="Agregar"
                                    disabled={
                                      !spaceValidation(
                                        props.localState.formData.trade_tag,
                                      ) ||
                                      props.tagsChange.existTag(
                                        props.localState.formData.trade_tag,
                                      ) ||
                                      props.localState.formData.trade_tag
                                        .length > 25 ||
                                      !/^(?:(\w)(?!\1\1))+$/.test(
                                        props.localState.formData.trade_tag,
                                      ) ||
                                      thereSpecialChar(
                                        props.localState.formData.trade_tag,
                                      )
                                    }
                                  />
                                </ControlGroup>
                                <div className="bp3-form-helper-text marginBottom-1em">
                                  {helperTexts['article_tags']}
                                </div>
                              </FormGroup>
                              <Callout
                                icon="plus"
                                title="Artículos o Servicios de interés"
                              >
                                <div className="calloutNoActivity">
                                  {props.localState.formData.article_tags
                                    .length === 0 ? (
                                    <p>
                                      <Icon icon="issue" /> No hay artículos o servicios
                                      agregados
                                    </p>
                                  ) : null}
                                </div>
                                {props.localState.formData.article_tags.map(
                                  (tag, i) => (
                                    <Tag
                                      className="marginBottom-05em marginRight-05em"
                                      key={`trade_tag${i}`}
                                      minimal={true}
                                      onRemove={() =>
                                        props.removeTag(tag, 'article_tags')
                                      }
                                      interactive={true}
                                      round={false}
                                    >
                                      {tag.name || tag}
                                    </Tag>
                                  ),
                                )}
                              </Callout>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                    <Divider className="marginTop-1em marginBottom-1em" />
                    <Card className="CardForm">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Callout
                            title="¿Estas abierto a recibir cualquier tipo de propuestas?"
                          >
                            <RadioGroup
                              inline
                              className="TipeBox"
                              onChange={props.inputChangeHandler}
                              selectedValue={props.localState.formData.accept_changes}
                            >
                              <Radio label="Si" value="1" id="accept_changes" />
                              <Radio label="No" value="0" id="accept_changes" />
                            </RadioGroup>
                          </Callout>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Divider className="marginTop-1em marginBottom-1em" />
                <Callout title={props.localState.subCategory === 'service' ? 'Descripción del servicio' : 'Descripción del artículo'}>
                  <label
                    className="bp3-label maginBottom-1em"
                    htmlFor="form-group-input"
                  >
                    {props.localState.subCategory === 'service' ? 'Explica detalladamente toda la información relevante de tu servicio; que incluye, que no incluye, etc.' : 'Describe detalladamente tu artículo'}
                  </label>
                  <Editor
                    editorState={props.localState.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={props.onEditorStateChange}
                    toolbar={optionsEditor}
                  />
                </Callout>
              </Col>
              <Divider className="marginTop-1em marginBottom-1em" />
              <Callout
                className="marginFixOptimazerPublication"
                title="Optimiza el alcance de tu publicación:"
              >
                <label
                  className="bp3-label maginBottom-1em"
                  htmlFor="form-group-input"
                >
                  Puedes agregar palabras claves relacionadas a tu {props.localState.subCategory === 'service' ? 'servicio ' : 'artículo '}
                  , esto ayuda a que tu publicación este mejor posicionada en
                  nuestro motor de búsqueda.
                </label>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Card className="marginBottom-1em">
                      <FormGroup
                        label={props.localState.subCategory === 'service' ? 'Palabras clave del Servicio ' : 'Palabras clave del artículo '}
                        labelInfo="*"
                      >
                        <ControlGroup>
                          <InputGroup
                            className="whiteInput bp3-fill"
                            fill={true}
                            placeholder={props.localState.subCategory === 'service' ? 'Palabras clave del Servicio ' : 'Palabras clave del artículo '}
                            id="tagLabel"
                            onChange={props.inputGeneralChangeHandler}
                            value={props.localState.tagLabel}
                          />
                          <Button
                            className="BtnLCPrimary"
                            icon="add"
                            text="Agregar"
                            onClick={() =>
                              props.addTagLabel(props.localState.tagLabel)
                            }
                            disabled={
                              !spaceValidation(props.localState.tagLabel) ||
                              !/^(?:(\w)(?!\1\1))+$/.test(
                                props.localState.tagLabel,
                              ) ||
                              props.tagsLabel.existTag(
                                props.localState.tagLabel,
                              ) ||
                              props.localState.tagLabel.length > 25 ||
                              thereSpecialChar(props.localState.tagLabel)
                            }
                          />
                        </ControlGroup>
                        <div className="bp3-form-helper-text marginBottom-1em">
                          {helperTexts['tagLabel']}
                        </div>
                      </FormGroup>
                      <Callout icon="plus" title="Palabras clave agregadas">
                        <div className="calloutNoActivity">
                          {props.localState.formData.tags.length === 0 ? (
                            <p>
                              <Icon icon="issue" /> No hay palabras clave del
                              {props.localState.subCategory === 'service' ? ' servicio ' : ' artículo '} agregadas
                            </p>
                          ) : null}
                        </div>
                        {props.localState.formData.tags.map((tag, i) => (
                          <Tag
                            key={`tagLabel${i}`}
                            className="marginBottom-05em marginRight-05em"
                            minimal={true}
                            onRemove={() => props.removeTag(tag, 'tags')}
                            interactive={true}
                            round={false}
                          >
                            {tag.name || tag}
                          </Tag>
                        ))}
                      </Callout>
                    </Card>
                  </Col>
                </Row>
              </Callout>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button
            disabled={!props.localState.validationsStep.three}
            onClick={props.goToStep4}
            className="bp3-fill BtnLCPrimary"
            rightIcon="arrow-down"
            text="Próximo"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Step3;
