import React from 'react';
import {
  AnchorButton,
  Button,
  Callout,
  Card,
  Checkbox,
  Collapse,
  ControlGroup,
  Divider,
  FormGroup,
  H4,
  HTMLSelect,
  Icon,
  InputGroup,
  Label,
  Popover,
  Position,
} from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import './TabsPanel.scss';
import CompleteIndividualRegistration from './../../components/CompleteIndividualRegistration';
import { letters, minCharacter, numbers, thereNumber, thereSpecialChar, thereUpper } from './../../utilityFunctions';
import ReactDropzone from 'react-dropzone';
import Recaptcha from 'reaptcha';
import AddImage from '../../components/AddImage';

const acceptedFiles = 'image/jpeg, image/png, image/gif, image/mba, image/jpg';

class BusinessPanel extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = null;
    this.state = {
      captchaReady: false,
      renderedCaptcha: false,
    };
  }

  componentDidUpdate(prevState) {
    if (this.state.captchaReady && !this.state.renderedCaptcha) {
      this.setState({ renderedCaptcha: true }, () =>
        this.captcha.renderExplicitly(),
      );
    }
  }

  onLoad = () => {
    this.setState({
      captchaReady: true,
    });
  };

  render() {
    return (
      <>
        <div className="businessPanel">
          <H4>Registro para empresas</H4>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="marginLeft-1em dragDropDeleteButton">
                    <Button onClick={this.props.deleteImage}>
                      <Icon icon="trash" />
                    </Button>
                  </div>
                  <ReactDropzone
                    maxSize={5000000}
                    accept={acceptedFiles}
                    onDrop={files => this.props.onDrop(files)}
                  >
                    {(dropZoneProps) => (
                      <div className="justify-center h-full w-full">
                        {
                          !this.props.enterprise.image ? <AddImage {...dropZoneProps} className="relative z-20" /> :
                            <div className="image-preview">
                              <div className="croppedImageWrapper" id={`image`}>
                                {this.props.enterprise.image && <img src={this.props.enterprise.image} alt={`image`} />}
                              </div>
                            </div>
                        }
                      </div>
                    )}

                  </ReactDropzone>
                  <div className="marginTop-1em"></div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Label>Representante de la empresa:</Label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    helperText={this.props.helperTexts.name}
                    label="Nombre"
                    labelFor="text-input"
                    labelInfo="*"
                  >
                    <InputGroup

                      placeholder="Nombre"
                      id="name"
                      onChange={this.props.changed}
                      onKeyPress={letters}
                      value={this.props.enterprise.name}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    helperText={this.props.helperTexts.last_name}
                    label="Apellido"
                    labelFor="text-input"
                    labelInfo="*"
                  >
                    <InputGroup

                      placeholder="Apellido"
                      onChange={this.props.changed}
                      id="last_name"
                      onKeyPress={letters}
                      value={this.props.enterprise.last_name}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    helperText={this.props.helperTexts.email}
                    label="Correo electrónico"
                    labelFor="text-input"
                    labelInfo="*"
                  >
                    <InputGroup

                      placeholder="Correo"
                      onChange={this.props.changed}
                      id="email"
                      value={this.props.enterprise.email}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    label="Razón social"
                    labelFor="text-input"
                    labelInfo="*"
                  >
                    <InputGroup

                      placeholder="Razón social"
                      id="businessName"
                      onChange={this.props.changed}
                      value={this.props.enterprise.businessName}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    helperText={this.props.helperTexts.dni}
                    label="RIF"
                    labelInfo="*"
                  >
                    <ControlGroup>
                      <HTMLSelect
                        onChange={this.props.changed}
                        id="typeRIF"
                        options={this.props.rifSelect}

                        value={this.props.enterprise.typeRIF}
                      />
                      {/* SELECT V - / J - / G - */}
                      <InputGroup

                        id="dni"
                        onChange={this.props.changed}
                        fill={true}
                        placeholder="RIF"
                        onKeyPress={numbers}
                      />
                    </ControlGroup>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="CardForm">
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <Popover
                          isOpen={true}
                          content={
                            this.props.focusPassword ? (
                              <div className="popOverContent">
                                <Callout
                                  className=""
                                  icon="key"
                                  title="Tu contraseña debe contener"
                                >
                                  <ul>
                                    <li>
                                      {!minCharacter(
                                        this.props.enterprise.password,
                                      ) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos seis caracteres
                                    </li>
                                    <li>
                                      {!thereUpper(
                                        this.props.enterprise.password,
                                      ) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos una mayúscula
                                    </li>
                                    <li>
                                      {!thereNumber(
                                        this.props.enterprise.password,
                                      ) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos un caracter numérica
                                    </li>
                                    <li>
                                      {!thereSpecialChar(
                                        this.props.enterprise.password,
                                      ) ? (
                                        <Icon
                                          className="iconRed"
                                          icon="cross"
                                        />
                                      ) : (
                                        <Icon
                                          className="iconGreen"
                                          icon="tick"
                                        />
                                      )}
                                      Al menos un caracter especial (!"#@?-_")
                                    </li>
                                  </ul>
                                </Callout>
                              </div>
                            ) : null
                          }
                          position={Position.TOP}
                        >
                          <FormGroup
                            label="Contraseña"
                            labelFor="text-input"
                            labelInfo="*"
                          >
                            <InputGroup
                              className="whiteInput"

                              placeholder="Contraseña"
                              value={this.props.enterprise.password}
                              onChange={this.props.changed}
                              id="password"
                              type="password"
                              onFocus={this.props.onFocusPassword}
                              onBlur={this.props.onBlurPassword}
                            />
                          </FormGroup>
                        </Popover>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup
                          helperText={this.props.helperTexts.repeatPassword}
                          label="Confirmar contraseña"
                          labelFor="text-input"
                          labelInfo="*"
                        >
                          <InputGroup
                            className="whiteInput"

                            placeholder="Confirmar contraseña"
                            value={this.props.enterprise.repeatPassword}
                            onChange={this.props.changed}
                            id="repeatPassword"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup label="País" labelFor="text-input" labelInfo="*">
                          <HTMLSelect
                            value={this.props.enterprise.country}
                            id="country"
                            onChange={this.props.changed}
                            options={this.props.countries}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="marginBottom-1em">
                <Button
                  rightIcon="chevron-down"
                  minimal="true"
                  fill="true"

                  onClick={this.props.toForm2}
                >
                  Deseo completar la información de perfil ahora
                </Button>
              </div>
            </Col>
          </Row>

          <Divider className="marginBottom-1em" />
          <Collapse isOpen={this.props.showForm2}>
            <CompleteIndividualRegistration
              data={this.props.enterprise}
              changed={this.props.changed}
              helperText={this.props.helperTexts}
            />
          </Collapse>
          <Divider className="marginBottom-1em" />

          <Row className="marginBottom-1em">
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.termsAceppt}
                label="Términos y Condiciones"
                labelInfo="*"
              >
                <Checkbox
                  id="termsAceppt"
                  onChange={this.props.changed}
                  checked={this.props.enterprise.termsAceppt}
                >
                  Acepto los <strong>Términos y Condiciones</strong>
                </Checkbox>
              </FormGroup>
              <AnchorButton
                text="Leer Términos y Condiciones"
                minimal="false"
                icon="eye-open"
                onClick={() => this.props.onOpenTerms()}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                helperText={this.props.helperTexts.policiesAceppt}
                label="Políticas y Privacidad"
                labelInfo="*"
              >
                <Checkbox
                  id="policiesAceppt"
                  onChange={this.props.changed}
                  checked={this.props.enterprise.policiesAceppt}
                >
                  Acepto las <strong>Políticas y Privacidad</strong>
                </Checkbox>
              </FormGroup>
              <AnchorButton
                text="Leer Políticas y Privacidad"
                minimal="false"
                icon="eye-open"
                onClick={() => this.props.onOpenPolicies()}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Recaptcha
                className="marginTop-1em Recaptcha"
                ref={e => (this.captcha = e)}
                sitekey={this.props.sitekey}
                onLoad={this.onLoad}
                onVerify={this.props.onVerify}
                onError={this.props.onErrorCaptcha}
                explicit
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Button
                className="BtnLCYellow marginTop-1em"
                rightIcon="new-person"

                fill={true}
                disabled={!this.props.formValid}
                onClick={this.props.sendRegistration}
              >
                Registrarme
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BusinessPanel;
