import React, { Component } from 'react';
import { AnchorButton, Callout } from '@blueprintjs/core';
import { connect } from 'react-redux';
import BaseNotificationGet from '../../components/toastNotification/BaseNotificationGet';
import { Link } from 'react-router-dom';
import './headerNotificationsAlerts/HeaderNotificationsAlerts.scss';
import { config } from '../../system/Config';
import axios from 'axios';
import { getHeader } from '../../system/Sesion';
import { handleClosePopover } from '../../utilityFunctions';

class HeaderNotificationsAlerts extends Component {

  markNotificationAsRead(notification_id) {
    try {
      axios.put(
        `${config.URL_API}/notifications/${notification_id}`,
        { read: true },
        { headers: getHeader() },
      );
    } catch (err) {
      throw err;
    }
  }

  render() {
    const notificationsStore = JSON.parse(JSON.stringify(this.props.notifications));
    const { initialLoading, ...notifications } = notificationsStore;
    const data = Object.values(notifications)
      .flat()
      .filter(notification => notification.type_notification_id !== 10)
      .sort((notificationA, notificationB) => {
        const dateA = new Date(notificationA.created_at).getTime();
        const dateB = new Date(notificationB.created_at).getTime();
        return dateA < dateB ? 1 : -1;
      });

    if (data.length === 0) {
      return (
        <div className="HeaderNotificationsAlerts">
          <div className="padding-05em">
            <Callout
              title="Notificaciones"
              className="marginBottom-05em"
            />
            <Callout>No hay Notificaciones</Callout>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="HeaderNotificationsAlerts">
          <div className="padding-05em">
            <Callout className="notification-title" title="Notificaciones" />
            <ul className="noti-shadow">
              {data.map((notification, index) => (
                (index >= 8) ? null :
                  <div
                    key={index}
                    className="notification"
                    style={(notification.read === false) ? { backgroundColor: 'rgba(138, 155, 168, 0.15)' } : null}
                    onClick={() => this.markNotificationAsRead(notification.id)}
                  >
                    <BaseNotificationGet
                      markNotificationAsRead={this.markNotificationAsRead}
                      key={`questionNotification${index}`} value={notification}
                      typeNotification="Notification"
                    />
                  </div>
              ))}
            </ul>
          </div>
        </div>
        {data.length > 0 && (
          <div className="padding-05em">
            <Link
              to="/notifications_page"
              className="button-chats"
              onClick={handleClosePopover}
            >
              <AnchorButton className="purple-text" minimal fill>
                Ver Todas
              </AnchorButton>
            </Link>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    initialLoad: state.chat.loading,
  };
};

export default connect(mapStateToProps)(HeaderNotificationsAlerts);
