import React from 'react';
import CategoriesFather from './CategoriesFather';

const ItemCategory = props => {
  const {value, i} = props;
  return (
    <CategoriesFather 
      value={value} 
      i={i} 
      toSearch={props.toSearch} />
  )
}

export default ItemCategory;