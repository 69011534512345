import React, { Component } from 'react';
import ActivityNewPublication from './activities/ActivityNewPublication';
import ActivitySuccessfulChange from './activities/ActivitySuccessfulChange';
import ActivityThirdPartySharePublication from './activities/ActivityThirdPartySharePublication';
import ActivityShareOwnPublication from './activities/ActivityShareOwnPublication';
import axios from 'axios';
import { connect } from 'react-redux';
import { getHeader } from './../../system/Sesion';
import { config } from './../../system/Config';
import * as actions from './../../store/actions/index';
import { typesActivities } from "const";
import Ads from "common-component/ads/Ads.jsx";
import { Toast } from '../../utility/toast';
import { userDataComplete } from "../detailProduct/aux";
import CompleteUserData from "../detailProduct/CompleteUserData";


class Activities extends Component {

  constructor(props){
    super(props);
    this.elements = []
    this.scrollCounter = -1;
    this.elementsCounter = 0; 
    this.switch = false
    this.state = {
      activities: this.props.activities,
      isShared: false,
      showUserCompleteData: false,
      changeLink: ''

    }
  }


  isVisible(elem){
    let eh = elem.offsetHeight
    let wh = window.outerHeight
    let gt = elem.getBoundingClientRect().top
    return (!(gt < eh*(-1)) && gt < (wh+eh) )
 }

 eachElement(elem){
  let elementIndex = parseInt(elem.id.split("activityWall")[1])
   let newState = this.props.activities[elementIndex];
   if (this.isVisible(elem)) {
     if (newState) {
      if (!newState["visible"]) {
        newState["visible"] = true;
        this.setState(newState);
      }
     }
  }else{
    if (newState & newState.visible) {
      newState["visible"] = false;    
      this.setState(newState);
    }
  } 

 }

 mapCollection(){
   for (const e of this.elements) {
     this.eachElement(e)
   }
 }

  onScrollOne = (element) => {
    if (this.scrollCounter > 6 || this.scrollCounter == -1) {
      this.scrollCounter = 0
      this.switch = true;
      if (this.elementsCounter == 0) {
        this.elements = document.getElementsByClassName("activityWall")
        this.elementsCounter = this.elements.length
        this.mapCollection()
      } else {
        this.mapCollection()
      }
    } else {
      this.scrollCounter++      
    }
  }

  sharePub = (pub_id, name) => {
    if(!this.state.isShared){
      this.state.isShared = true;
      const dataAPI = {
        user_id: this.props.userId,
        publication_id: pub_id,
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude")
      }

      axios.post(`${config.URL_API}/shares`, dataAPI, {headers: getHeader()})
        .then(() => {
          this.props.onGetShares(this.props.userId)
          Toast.show({
            message: <span>Se compartió exitosamente la publicación <b>{name}</b> en el muro</span>
          });
        })
        .catch((error) => {
          throw error;
        })
    }
  }

  distanceEnable(distance,value){
    
    let distanceValue = value.value.distance / 1000;

    if( distanceValue !== NaN && distance >= distanceValue){
      return true;
    }else{
      return false
    }
  }

  componentDidUpdate(){
    if (this.state.activities.length != this.props.activities.length) {
      this.setState({activities: this.props.activities})
    }
  }

  componentDidMount() {
    let element = document.getElementById('activityWall0');
    window.addEventListener('scroll', () => this.onScrollOne(element) );
    this.onScrollOne();
  }

  toChange = (link) => {
    if (userDataComplete(this.props.otherInfoUser)) {
      this.props.history.push(link);
    } else {
      this.setState({ 
        showUserCompleteData: true,
        changeLink: link
      });
    }
  };

  hideModalUserCompleteData = () => {
    this.setState({ showUserCompleteData: false });
  };

  completeDataUserSuccess = () => {
    this.setState({ showUserCompleteData: false });
    this.props.history.push(this.state.changeLink);
  };


  render() {
    let activities = [];
    activities = this.props.activities
    let distance = this.props.distance;
    const modalUserCompleteData = this.state.showUserCompleteData && (
      <CompleteUserData
        data={this.props.otherInfoUser}
        onClose={this.hideModalUserCompleteData}
        success={this.completeDataUserSuccess}
      />
    );


    return (
      <>
        {activities.map((value, i) => (
           <div id={`activityWall${i}`} className={"activityWall"} key={`activityWall${i}`}>
           {value.type === typesActivities.PUBLICATION ? <ActivityNewPublication sharePub={this.sharePub} key={`newPublication-${i}`} isVisible={value.visible} pub={value.value} sponsored={value.sponsored} toChange={this.toChange}/> : null}
           {value.type === typesActivities.CHANGE_SUCCESSFULL ? <ActivitySuccessfulChange key={`fullChange${i}`} tran={value.value} /> : null}
           {value.type === typesActivities.SHARE_PUB_THIRD ? <ActivityThirdPartySharePublication key={`thirdSharePublication-${i}`} pub={value.value} sharePub={this.sharePub}
           isShared={this.state.isShared}/> : null}
           {value.type === typesActivities.SHARE_PUB_OWN ? <ActivityShareOwnPublication sharePub={this.sharePub} key={`ownharePublication-${i}`} pub={value.value} /> : null}
           {(i+1) % 18 == 0 && i > 0 ? <Ads type={this.props.typeFormatsAds} typeAd={this.props.typeAd} /> : null }
         </div>
        ))}
        {modalUserCompleteData}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    otherInfoUser: state.auth.otherInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetShares: (user_id) => dispatch(actions.getShares(user_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
