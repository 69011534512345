import { spaceValidation, addClassElement, removeClassElement, emailValidation, thereNumber, thereSpecialChar, thereUpper } from './../../utilityFunctions';

export const validation = (data, withTouched) => {
  let aux = true;
  let messages = []

  // name validation
  if (!spaceValidation(data.name) || data.name.length <= 1) {
    if (!withTouched || touchedControlUser['name']) {
      addClassElement('name', 'errorInput')
      helperTextsUser.name = 'El nombre no puede ser menor de 2 caracateres'
      messages.push('Nombre')
    }
    aux = false;
  } else {
    helperTextsUser.name = ''
    removeClassElement('name', 'errorInput')
  }

  // lastname validation
  if (!spaceValidation(data.last_name) || data.last_name.length <= 1) {
    if (!withTouched || touchedControlUser['last_name']) {
      addClassElement('last_name', 'errorInput')
      helperTextsUser.last_name = 'El apellido no puede ser menor de 2 caracteres'
      messages.push('Apellido')
    }
    aux = false;
  } else {
    helperTextsUser.last_name = ''
    removeClassElement('last_name', 'errorInput')
  }

  // email validation
  if (!spaceValidation(data.email)) {
    if (!withTouched || touchedControlUser['email']) {
      addClassElement('email', 'errorInput')
      messages.push('Email')
    }
    aux = false 
  } else {
    if (!emailValidation(data.email)) {
      helperTextsUser.email = 'Debe ser un email valido'
      addClassElement('email', 'errorInput')
      aux = false;
    } else {
      helperTextsUser.email = ''
      removeClassElement('email', 'errorInput')
    }
  }

  // dni validation
  // if (!spaceValidation(data.dni) || !spaceValidation(data.typeDNI)) {
  //   if (!withTouched || touchedControlUser['dni']) {
  //     addClassElement('dni', 'errorInput')
  //   }
  //   messages.push('DNI')
  //   aux = false;
  // } 

  // if (spaceValidation(data.dni)) {
  //   if (data.typeDNI == 'V') {
  //     if (data.dni.length < 6 || data.dni.length > 8) {
  //       helperTextsUser.dni = 'Debe ser un numero de cédula de Identidad valido'
  //     } else {
  //       helperTextsUser.dni = ''
  //     }
  //   }
  //   if (data.typeDNI == 'E') {
  //     if (data.dni.length < 8 || data.dni.length > 10) {
  //       helperTextsUser.dni = 'Debe ser un numero de cédula de Identidad valido'
  //     } else {
  //       helperTextsUser.dni = ''
  //     }
  //   }
  // }

  if (spaceValidation(data.password) || spaceValidation(data.confirmation_password)) {
    // password validation
    if (!spaceValidation(data.password)) {
      if (!withTouched || touchedControlUser['password']) {
        addClassElement('password', 'errorInput')
        messages.push('Password')
      }
      aux = false;
    } else {
      removeClassElement('password', 'errorInput')
    }

    if (spaceValidation(data.password)) {
      if (!thereNumber(data.password) || !thereUpper(data.password) || !thereSpecialChar(data.password)) {
        addClassElement('password', 'errorInput')
        aux = false;
      } else {
        removeClassElement('password', 'errorInput')
      }
    }

    // repeat password validation
    if (!spaceValidation(data.confirmation_password)) {
      if (!withTouched || touchedControlUser['confirmation_password']) {
        addClassElement('confirmation_password', 'errorInput')
        messages.push('confirmation_password')
      }
      aux = false;
    } else {
      removeClassElement('confirmation_password', 'errorInput')
    }

    if (spaceValidation(data.password) && spaceValidation(data.confirmation_password)) {
      if (data.password !== data.confirmation_password) {
        addClassElement('password', 'errorInput')
        addClassElement('confirmation_password', 'errorInput')
        helperTextsUser.confirmation_password = 'La Contraseña deben ser iguales'
        messages.push('Las contrasenas no coinciden')
        aux = false;
      } else {
        helperTextsUser.confirmation_password = ''
        removeClassElement('password', 'errorInput')
        removeClassElement('confirmation_password', 'errorInput')
      }
    }

    // vieja contrasena
    // if (!spaceValidation(data.old_password)){
    //   if (!withTouched || touchedControlUser["old_password"]){
    //     addClassElement('old_password', 'errorInput')
    //   }
    //   helperTextsUser.old_password = 'Introduce tu Contraseña actual para poder guardar los cambios'
    //   aux = false;
    // } else {
    //   helperTextsUser.old_password = ''
    //   removeClassElement('old_password', 'errorInput')
    // }
  }

  // fecha de nacimiento  validation
  if (!spaceValidation(data.date_of_birth)) {
    if (!withTouched || touchedControlUser['date_of_birth']) {
      addClassElement('year', 'errorInput')
      addClassElement('month', 'errorInput')
      addClassElement('day', 'errorInput')
    }
    messages.push('Fecha de Nacimiento')
    aux = false;
  } else {
    removeClassElement('year', 'errorInput')
    removeClassElement('month', 'errorInput')
    removeClassElement('day', 'errorInput')
  }

  // gender validation
  if (!spaceValidation(data.gender)) {
    if (!withTouched || touchedControlUser['gender']) {
      addClassElement('genderRadio', 'errorInput')
    }
    messages.push('Genero')
    aux = false;
  } else {
    removeClassElement('genderRadio', 'errorInput')
  }
  return {
    valid: aux,
    messages: messages
  }
}

export const touchedControlUser = {
  name: false,
  last_name: false,
  email: false,
  dni: false,
  password: false,
  confirmation_password: false,
  old_password: false
}

export const helperTextsUser = {
  name: '',
  last_name: '',
  email: '',
  dni: '',
  password: '',
  confirmation_password: '',
  old_password: '',
  termsAceppt: '',
  policiesAceppt: ''
}