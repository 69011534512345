import React, { useState } from 'react';
import {
  Card,
  Elevation,
  Button,
  Collapse,
  Callout,
  AnchorButton,
} from '@blueprintjs/core';
import { Row, Col, Container } from 'react-grid-system';
import './securityAdvice/SecurityAdvice.scss';
import LinksInfoPages from './../components/LinksInfoPages';
import { Link } from 'react-router-dom';

const SecurityAdvice = () => {
  const [collapseOpen, setCollapseOpen] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
  });

  const onSwitchCollapse = (key) => {
    const updatedCollapse = { ...collapseOpen };
    updatedCollapse[key] = !updatedCollapse[key];
    setCollapseOpen(updatedCollapse);
  };
  return (
    <>
      <div className="SecurityAdvice">
        <div className="pt-20">
          <Container>
            <Row>
              <Col xs={12} sm={8} md={8} lg={8}>
                <Card elevation={Elevation.ONE}>
                  <h2 className="margin0 marginBottom-1em">
                    Recomendaciones para tener la mejor experiencia en
                    LoCambio.com
                  </h2>
                  <Button
                    fill={true}
                    className="BtnLCYellow"
                    onClick={() => onSwitchCollapse(1)}
                  >
                    Publicaciones
                  </Button>
                  <Collapse isOpen={collapseOpen[1]} className="mb-2">
                    <div className="mb-2">
                      <Callout>
                        <h3 className="mb-4">Publicaciones</h3>
                        <ul className="list-reset">
                          <li className="mb-4">
                            <p className="mb-0">
                              No compartas información privada como dirección o
                              teléfono. Recuerda, lo que publiques en tu anuncio
                              será información de acceso público.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Utiliza la plataforma o la App para definir el
                              punto de intercambio y mantener cualquier
                              comunicación con interesados. Elige puntos de
                              encuentro públicos y de fácil acceso para la
                              comodidad de los usuarios.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Se detallista en tus publicaciones para evitar
                              cualquier malentendido, el centro de solución de
                              problemas verificará la información en caso de
                              disputa.
                            </p>
                          </li>
                          <li className="mb-0">
                            <p className="mb-0">
                              Utiliza fotos del artículo que estás ofreciendo,
                              no coloques imágenes genéricas o de Internet.
                            </p>
                          </li>
                        </ul>
                      </Callout>
                    </div>
                  </Collapse>
                  <Button
                    fill={true}
                    className="BtnLCYellow"
                    onClick={() => onSwitchCollapse(2)}
                  >
                    Cambios
                  </Button>
                  <Collapse isOpen={collapseOpen[2]} className="mb-2">
                    <div className={'mb-2'}>
                      <Callout>
                        <h3 className="mb-4">Cambios</h3>
                        <ul className="list-reset">
                          <li className="mb-4">
                            <p className="mb-0">
                              Valida la identidad del interesado en cambiar
                              pidiéndole una copia de su documento de identidad.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Utiliza siempre la mensajería instantánea de la
                              plataforma o App.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Asegúrate de cambiar el artículo solo con la
                              persona que hayas hecho el contacto, o con alguien
                              que esté previamente autorizado.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Verifica el funcionamiento y características
                              generales del artículo al momento del cambio. Lo
                              más importante es tu conformidad con lo recibido.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-2">
                              Consejos antes de hacer una propuesta de cambio:
                            </p>
                            <ul className="list-reset">
                              <li className="mb-4">
                                <p className="mb-0">
                                  Revisa las preguntas que han hecho otros
                                  usuarios sobre el artículo.
                                </p>
                              </li>
                              <li className="mb-4">
                                <p className="mb-0">
                                  Despeja cualquier duda que tengas sobre el
                                  artículo con quien lo publicó.
                                </p>
                              </li>
                              <li className="mb-4">
                                <p className="mb-0">
                                  Solicita fotos específicas del artículo a
                                  intercambiar en caso de que la publicación
                                  tenga fotos genéricas.
                                </p>
                              </li>
                              <li className="mb-4">
                                <p className="mb-0">
                                  Revisa el perfil y reputación del creador de
                                  la publicación.
                                </p>
                              </li>
                              <li className="mb-4">
                                <p className="mb-0">
                                  Está alerta ante publicaciones poco realistas.
                                </p>
                              </li>
                            </ul>
                            <li className="mb-0">
                              <p className="mb-0">
                                Finaliza el cambio en la App escaneando el
                                código QR del App del otro usuario en el punto
                                de intercambio.
                              </p>
                            </li>
                          </li>
                        </ul>
                      </Callout>
                    </div>
                  </Collapse>

                  <Button
                    fill={true}
                    className="BtnLCYellow"
                    onClick={() => onSwitchCollapse(3)}
                  >
                    Artículos
                  </Button>
                  <Collapse isOpen={collapseOpen[3]} className="mb-2">
                    <div className="mb-2">
                      <Callout>
                        <h3 className="mb-4">Artículos</h3>
                        <ul className="list-reset">
                          <li className="mb-4">
                            <p className="mb-0">
                              Asegura que tu artículo cuente con las
                              especificaciones, los atributos o accesorios que
                              están en la publicación.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Revisa la lista de Artículos no permitidos en las{' '}
                              <Link to="faq">
                                <AnchorButton
                                  minimal
                                  text="preguntas frecuentes"
                                />
                              </Link>{' '}
                              para evitar que tu Publicación sea rechazado.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Para las publicaciones Premium valida los
                              documentos de propiedad legal sobre el bien antes
                              de realizar cualquier permuta.
                            </p>
                          </li>
                        </ul>
                      </Callout>
                    </div>
                  </Collapse>

                  <Button
                    fill={true}
                    className="BtnLCYellow"
                    onClick={() => onSwitchCollapse(4)}
                  >
                    Usuarios
                  </Button>
                  <Collapse isOpen={collapseOpen[4]}>
                    <div className="mb-2">
                      <Callout>
                        <h3 className="mb-4">Usuarios</h3>
                        <ul className="list-reset">
                          <li className="mb-4">
                            <p className="mb-0">
                              Nunca reveles tu nombre de usuario o contraseña a
                              otras personas.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Utiliza una contraseña complicada que tenga al
                              menos seis (6) caracteres incluyendo números,
                              símbolos y mayúsculas.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              No permitas que la clave se guarde en el navegador
                              de Internet en computadoras de uso público.
                            </p>
                          </li>

                          <li className="mb-4">
                            <p className="mb-0">
                              Si crees que alguien está usando la plataforma o
                              App de forma fraudulenta ve a{' '}
                              <Link to="contactos">
                                <AnchorButton
                                  minimal={true}
                                  text="Contáctanos"
                                />
                              </Link>{' '}
                              y reporta al usuario o publicación.
                            </p>
                          </li>
                          <li className="mb-4">
                            <p className="mb-0">
                              Notifica a las autoridades pertinentes de tu
                              ciudad en caso de haber sido víctima de fraude.
                            </p>
                          </li>
                        </ul>
                      </Callout>
                    </div>
                  </Collapse>
                </Card>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <LinksInfoPages />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SecurityAdvice;
