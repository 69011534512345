import { useTime } from '../../../utilityFunctions';
import { countriesCode, provincesColombia, provincesPeru, provincesVenezuela } from 'const';

// ordena el JSON como lo pide al API y lo envia.
export const transformData = (data, countryUser) => {
  let publication = { ...data.formData };
  let stateValues;
  if (countryUser === countriesCode.VENEZUELA) {
    stateValues = provincesVenezuela;
  } else if (countryUser === countriesCode.PERU) {
    stateValues = provincesPeru;
  } else if (countryUser === countriesCode.COLOMBIA) {
    stateValues = provincesColombia;
  }

  if (publication.locations.length === stateValues.length) {
    publication.locations_all = 'All';
  }

  publication.picture_data = publication.images;
  publication.exchange_interest_category = tagsCategories(publication.interestCategories);
  publication.product_categories_id = data.categorySelected.id;
  publication.use_time = getTextUseTime(data.useTime, publication.use_time);

  if (publication.product_id && publication.product_id !== -1) {
    delete publication.new_product;
  } else {
    delete publication.product_id;
  }
  publication.fields = publication.fields
    .filter(field => field.value !== '')
    .map(field => {
      delete field.label;
      delete field.name;
      return field;
    });

  return publication;
};

const getTextUseTime = (unit, cant) => {
  let result = '';

  switch (unit) {
    case useTime.DAYS:
      result = `${cant} ${parseInt(cant) === 1 ? 'Dia' : 'Dia'}`;
      break;
    case useTime.MONTHS:
      result = `${cant} ${parseInt(cant) === 1 ? 'Mes' : 'Meses'}`;
      break;
    case useTime.YEARS:
      result = `${cant} ${parseInt(cant) === 1 ? 'Año' : 'Años'}`;
      break;
  }
  return result;
};

const tagsCategories = (categories) => {
  return categories.map((ancestorsCategories) => ancestorsCategories.map((category) => category.name).join(('/'))).join('|')
}
