import React from 'react';
import { Tag, Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const ArticlesOfInterest = props => {
  const { articles, tagsHides } = props;
  return (
    <>
      {(articles || []).map((article) => (
        <Tag key={article.id} className={'tagYellow marginRight-05em marginBottom-05'} >{article.name}</Tag>
      ))}
      {tagsHides && <Tag className={'tagYellow marginRight-05em marginBottom-05'} ><Icon icon="more" /></Tag>}
    </>
  )
}

ArticlesOfInterest.propTypes = {
  articles: PropTypes.array.isRequired
}

export default ArticlesOfInterest;
