export default [
  "Amazonas",
  "Anzoátegui",
  "Apure",
  "Aragua",
  "Bolívar",
  "Carabobo",
  "Cojedes",
  "Delta Amacuro",
  "Distrito Capital",
  "Falcón",
  "Guárico",
  "Lara",
  "Mérida",
  "Miranda",
  "Monagas",
  "Nueva Esparta",
  "Portuguesa",
  "Sucre",
  "Trujillo",
  "Vargas",
  "Yaracuy",
  "Zulia"
];
