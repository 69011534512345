import React, { useEffect, useState } from 'react';
import { Alignment, Callout, Card, Switch } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import './configurationsPage/ConfigurationsPage.scss';
import HeaderProfile from '../components/HeaderProfile';
import { useConfigurationPageLogic } from './configurationsPage/ConfigurationsPageLogic';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import axios from 'axios';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import { Toast } from '../utility/toast';

const ConfigurationsPage = ({ user, onRefreshUserData }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    fetchUserInfo(user);
  }, []);

  const fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  const fetchUserInfo = async (user) => {
    try {
      const response = await fetchGetUser(userId);
      let user = { ...response.data.user };
      user.dni = response.data.user.dni.split('-')[1].trim('');
      user.gender = response.data.user.gender === 'Masculino' ? '1' : '0';
      user.old_password = '';
      user.password = '';
      user.confirmation_password = '';
      user.picture_data = null;
      user.picture_data_cover = null;
      if (user.picture_users.length > 0) {
        user.picture_data = 'fromAPI';
      }

      setCurrentUser(user);
      setInitialLoading(false);
    } catch(err) {
      console.log('error', err);
    }
  };

  const isUserActive = (status) => status === 1;

  const toast = {
    message:
      'No se puede desactivar el perfil porque hay transacciones pendientes',
    icon: 'warning-sign',
    intent: 'danger',
  };

  const activateDesactivateProfile = async (active) => {
    if (active == 1) {
      try {
        await axios.put(
          `${config.URL_API}/desactive_account`,
          { id: localStorage.getItem('userId') },
          { headers: getHeader() },
        );
        window.location.reload();
      } catch(err) {
        Toast.show(toast);
      }
    } else {
      await axios.put(
        `${config.URL_API}/activate_account`,
        { id: localStorage.getItem('userId') },
        { headers: getHeader() },
      );
      window.location.reload();
    }
  };

  const { active, notifications_email } = user;
  const { onChangeEmails, notificationEmails } = useConfigurationPageLogic(
    active,
    notifications_email,
    onRefreshUserData,
  );
  return (
    <div className="pt-12 md:pt-0 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6">
      <HeaderProfile
        user={currentUser}
        initialLoading={initialLoading}
        editable={false}
      />
      <div className="ConfigurationsPage mt-8 mb-4 md:mb-32">
        <div className="minCustomWrapper">
          <Card className="mt-4">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Callout className="marginBottom-1em">
                  <h3 className="margin0 marginBottom-1em">E-mails</h3>
                  <div className="mb-8">
                    <Switch
                      large
                      inline
                      className="w-full font-bold"
                      alignIndicator={Alignment.RIGHT}
                      checked={notificationEmails}
                      onChange={onChangeEmails}
                      label={
                        'Deshabilitar las notificaiones por e-mail relacionadas a tus publicaciones LoCambio.com'
                      }
                    >
                      <Callout className="mt-2">
                        <p className="bp3-form-helper-text margin0">
                          Selecciona para no recibir notificaciones vía correo
                          eletrónico relaciondas con tus cambios
                        </p>
                      </Callout>
                    </Switch>
                  </div>
                </Callout>

                <Callout>
                  <h3 className="margin0 marginBottom-1em">
                    Desactivar Cuenta
                  </h3>
                  <Switch
                    large
                    inline
                    className="w-full font-bold"
                    alignIndicator={Alignment.RIGHT}
                    checked={isUserActive(active)}
                    onChange={() => activateDesactivateProfile(active)}
                    label="Desactivar tu cuenta en LoCambio.com"
                  >
                    <Callout className="mt-2">
                      <p className="bp3-form-helper-text margin0">
                        Selecciona para ocultar tu perfil, otros usuarios no
                        encontrarán tu información. No Podrán realizar cambios
                        ni publicaciones.
                      </p>
                    </Callout>
                  </Switch>
                </Callout>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.otherInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRefreshUserData: () => dispatch(actions.checkSesion()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsPage);
