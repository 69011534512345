import React from "react";
import useAdsLogic from "./AdsLogic";
import PropTypes from "prop-types";
import Mrec from "./mrec/mrec";
import HalfPage from "./halfPage/halfpage";
import AdResults from "./adResults/adResults";
import { typeFormatsAds } from "const";
import { typeAds } from "../../const";
import AdWall from "./adWall/adWall";

/**
 * Displays the ad according to the type of format it receives as a parameter.
 * Refreshes the announcement from time to time.
 * @param {String} type, Type of ad (format) to show. See the constant "typeFormatAds".
 * @param {Number} typeAd, Type of ads Positioning or Display.
 */


const Ads = ({ type, typeAd, publication_ids }) => {
  let component = null;
  const { ads, loading, updateView, updateClicked } = useAdsLogic(
    typeAd,
    publication_ids
  );

  if (typeAd === typeAds.display) {
    switch (type) {
      case typeFormatsAds.mrec:
        component = (
          <Mrec
            data={ads}
            onUpdateView={updateView}
            onUpdateClick={updateClicked}
          />
        );
        break;
      case typeFormatsAds.halfPage:
        component = (
          <HalfPage
            data={ads}
            onUpdateView={updateView}
            onUpdateClick={updateClicked}
          />
        );
        break;
      case typeFormatsAds.wall:
        component = (
          <AdWall
            data={ads}
            onUpdateView={updateView}
            onUpdateClick={updateClicked}
          />
        );
        break;
      default:
        component = null;
    }
  } else if (typeAd === typeAds.positioning) {
    component = (
      <AdResults
        data={ads}
        onUpdateView={updateView}
        onUpdateClick={updateClicked}
      />
    );
  }

  return !loading && ads ? component : null;
};

Ads.propTypes = {
  type: PropTypes.string,
  typeAd: PropTypes.string.isRequired,
  publication_ids: PropTypes.string
};

export default Ads;
