import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../system/Config";
import { getHeader } from "../../system/Sesion";

/**
 *
 * @param {Number} typeAd, Type of ads Positioning or Display.
 */
const useAdsLogic = (typeAd, publication_ids) => {
  const TIME_REFRESH = 30000; // Time when the ad will be refreshed.
  let intervalUpdateAds = null; // Update function of ads.
  let indexAds = 0; // Index in the ad array (ad shown)
  let page = 1; // Value for pagination.
  const [data, setData] = useState([]); // Ads.
  const [ads, setAds] = useState(null); // Current ad = the one that is displayed.
  const [loading, setLoading] = useState(true); // Loading data?
  const [totalPages, setTotalPages] = useState(0); // Total pages

  /**
   * Get an ad page.
   */
  const getAds = async () => {
    setLoading(true);

    let query = `page=${page}&type=${typeAd}`;
    if (publication_ids) {
      query = `${query}&publication_ids=${publication_ids.toString()}`;
    }
    try {
      const response = await axios.get(`${config.URL_API}/ads?${query}`, {
        headers: getHeader()
      });

      setData(response.data.ads);
      setTotalPages(response.data.ads);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  };

  /**
   * Set the announcement to be displayed in the status. Every so often update the ad.
   */
  const initAds = () => {
    setAds(data[indexAds]);

    intervalUpdateAds = setInterval(() => {
      ++indexAds;
      if (data[indexAds]) {
        setAds(data[indexAds]);
      } else {
        indexAds = 0;
        if (page + 1 <= totalPages) {
          ++page;
          getAds();
        } else {
          setAds(data[indexAds]);
        }
      }
    }, TIME_REFRESH);
  };

  /**
   * Update the number of ad views.
   * @param {Number} ad_id, Ad Id
   */
  const updateView = async ad_id => {
    try {
      await axios.post(
        `${config.URL_API}/ad_interaction/save_view`,
        { ad_id },
        { headers: getHeader() }
      );
    } catch (err) {
      throw err;
    }
  };

  /**
   * Update the number of ad clicked.
   * @param {Number} ad_id, Ad Id
   */
  const updateClicked = async ad_id => {
    try {
      await axios.post(
        `${config.URL_API}/ad_interaction/save_clicked`,
        { ad_id },
        { headers: getHeader() }
      );
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getAds();

    // At the moment the component will be disassembled.
    return () => {
      clearInterval(intervalUpdateAds);
    };
  }, []);

  useEffect(() => {
    // When the value of the data changes, it calls the script that updates the ads to be displayed.
    if (data && data.length > 0) initAds();
  }, [data]);

  return { ads, loading, updateView, updateClicked };
};

export default useAdsLogic;
