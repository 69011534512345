import React, { Component } from 'react';
import { Card, Elevation, Callout, Button } from "@blueprintjs/core";
import './creditCardList/CreditCardList.scss';
import creditCardVisa from './../../Assets/System/creditCards/VISA-Light.png';
import creditCardMasterCard from './../../Assets/System/creditCards/MasterCard-Light.png';
import creditCardAmex from './../../Assets/System/creditCards/AMERICAN-EXPRESS-LIGHT.png';

class ImageCreditCards extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {type} = this.props;
        switch(type){
            case 'Visa': return (<img src={creditCardVisa} alt="creditCard-visa" />);
            case 'Master Card': return (<img src={creditCardMasterCard} alt="creditCard-masterCard" />);
            case 'American Express': return (<img src={creditCardAmex} alt="creditCard-amex"  />);
          }
    }
}

export default ImageCreditCards;