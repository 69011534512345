import React, { Component } from 'react';
import { config } from '../../system/Config';
import axios from 'axios';
import { getHeader } from '../../system/Sesion';
import './changeProposalsSent/ChangeProposalsSent.scss';
import flechaReceived from './images/flechareceived.png';
import ProposalTransaction from './ProposalTransaction';
import RequestPublication from './RequestPublication';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';
import './changeProposalsSent/ChangeProposalsSent.scss';

class ProposalRequestReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publication: null,
    };
  }

  componentDidMount() {
    this.fetchPublication().then((data) => {
      this.setState({
        publication: data.data,
      });
    });
  }

  fetchPublication() {
    return axios.get(
      `${config.URL_API}/publications/${this.props.proposal.publication}`,
      { headers: getHeader() }
    );
  }

  render() {
    const { publication } = this.state;
    const { indexProposal, suscription, openModal } = this.props;

    if (!publication) {
      return <></>;
    }

    let result = (
      <>
        <div className="flex pb-2 items-center justify-center bg-white">
          <div className="w-2/5 p-1 text-center">
            <RequestPublication
              publication={publication.publication}
              suscription={suscription}
            />
          </div>
          <div className="w-1/5 text-center">
            <img
              src={flechaReceived}
              className="w-full md:w-3/4"
              alt="Recibida"
            />
          </div>
          <div className="w-2/5 py-1 px-1 text-center overflow-y-auto h-cambio">
            {this.props.proposal.transactions?.length > 0 ? (
              <>
                {this.props.proposal.transactions.map((v, i) => (
                  <ScrollToElement key={v} name={v} highlight>
                    <ProposalTransaction
                      urlTransaction={v}
                      indexProposal={indexProposal}
                      openModal={openModal}
                      read={this.props.read}
                      name={v}
                    />
                  </ScrollToElement>
                ))}
              </>
            ) : (
              <div className="NoProposal">No Hay Propuestas</div>
            )}
          </div>
        </div>
      </>
    );
    return result;
  }
}

export default ProposalRequestReceived;
