import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { Callout, Tag, Card, Elevation } from "@blueprintjs/core";
import "./successfulChanges/SuccessfulChanges.scss";
import PublicationItem from "./PublicationItem";
import { orderArrayByDate } from "./../../utilityFunctions";

class SuccessfulChanges extends Component {
  render() {
    const { suscription, status, typesStatus, updating } = this.props;
    let pubs = orderArrayByDate(this.props.pubs, "publish_date");
    return (
      <React.Fragment>
        <div className="SuccessfulChanges">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Callout
                className="marginBottom-1em"
                icon={"info-sign"}
                intent={"primary"}
              >
                <h3>
                  Historial
                  <Tag className="tag marginRight-05em marginLeft-05em">
                    {pubs.length}
                  </Tag>
                </h3>
              </Callout>
            </Col>
          </Row>
          {pubs.map((pub, i) => (
            <PublicationItem
              pub={pub}
              suscription={suscription}
              key={`${i}${pub.id}`}
              status={status}
              typesStatus={typesStatus}
              updating={updating}
            />
          ))}

          {pubs.length === 0 ? (
            <Callout className="noActivityDisplay">
              <h5 className="margin0 marginBottom-05em">
                No hay cambios exitosos aún
              </h5>
            </Callout>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessfulChanges;
