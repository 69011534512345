import React from "react";
import { Card, Elevation, Callout, Tag, Icon } from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";
import SwiperSkeleton from "./SwiperSkeleton";

const ProductSearchListSkeleton = props => (
  <Card elevation={Elevation.ONE} className={"marginBottom-1em"}>
    <div className={"horizontalThumbanil"}>
      <Row>
        <Col xs={12} sm={12} md={3} lg={3}>
          {/* <div className={'positionNewTag'}></div> */}
          <SwiperSkeleton />
        </Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={"InfoProductContainer"}>
            <Callout>
              <div className={"publicationName"}>
                <h1 className={"bp3-skeleton"}></h1>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={"infoDetailPublication"}>
                      <p className={"bp3-form-helper-text bp3-skeleton"}>
                        Disponible para:
                      </p>
                      <Tag className={"tagYellow bp3-skeleton"} >
                        Cambio
                      </Tag>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={"infoDetailPublication"}>
                      <p className={"bp3-form-helper-text bp3-skeleton"}>
                        Precio
                      </p>
                      <Tag className={"tag bp3-skeleton"} >
                        EXAMPLE
                      </Tag>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={"infoDetailPublication"}>
                      <p className={"bp3-form-helper-text bp3-skeleton"}>
                        Se encuentra a
                      </p>
                      <Tag className={"tag bp3-skeleton"} >
                        EXAMPLE
                      </Tag>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={"infoDetailPublication marginBottom-05em"}>
                      <p className={"bp3-form-helper-text bp3-skeleton"}>
                        Condición del artículo
                      </p>
                      <Tag
                        className={"tag marginRight-05em bp3-skeleton"}
                        
                      ></Tag>
                    </div>
                  </Col>
                </Row>
              </div>
            </Callout>
            <Callout className={"marginTop-05em"}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className="headerUserCard">
                    <div className="AvatarContainer bp3-skeleton"></div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}></Col>
              </Row>
            </Callout>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Callout>
            <div className={"interestsForChange"}>
              <div className={"marginBottom-05em"}>
                <h4 className={"marginBottom-1em bp3-skeleton"}>
                  Categorias de interés para Cambio
                </h4>
                <Tag
                  className={
                    "tagYellow marginRight-05em marginBottom-05 bp3-skeleton"
                  }
                  
                >
                  Example
                </Tag>
                <Tag
                  className={
                    "tagYellow marginRight-05em marginBottom-05 bp3-skeleton"
                  }
                  
                >
                  Example
                </Tag>
              </div>

              <h4 className={"marginBottom-1em bp3-skeleton"}>
                Productos de interés para Cambio
              </h4>
              <Tag
                className={
                  "tagYellow marginRight-05em marginBottom-05 bp3-skeleton"
                }
                
              >
                Example
              </Tag>
              <Tag
                className={
                  "tagYellow marginRight-05em marginBottom-05 bp3-skeleton"
                }
                
              >
                Example
              </Tag>
            </div>
          </Callout>
        </Col>
      </Row>
    </div>
  </Card>
);

export default ProductSearchListSkeleton;
