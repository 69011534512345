import React from 'react';
import { Menu } from '@blueprintjs/core';
import Highlighter from 'react-highlight-words';

const ChildrenCategory = props => {
  const { index, categoryDescendants, query } = props;
  return (
    <Menu.Item
      key={index}
      onClick={() => props.toSearch(categoryDescendants.id)}
      text={
        <Highlighter
          highlightClassName="highlightCustom"
          searchWords={[query]}
          autoEscape={true}
          textToHighlight={categoryDescendants.name}
        />
      }
    />
  );
};

export default ChildrenCategory;
