import React, { useState } from 'react';
import { Button, Card, Collapse, Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

const faq = [
  {
    question: '¿Qué es Locambio.com?',
    answer: (
      <p>Es una
        <b>
          aplicación Web gratuita
        </b>
        que ofrece a sus usuarios
        <b>
          intercambiar o canjear
        </b>
        sus bienes, artículos personales, servicios profesionales y habilidades de manera rápida y sencilla. Además permite realizar publicaciones y recibir propuestas
        <b>
          ilimitadas
        </b>.
      </p>
    ),
  },
  {
    question: '¿Cuánto cobra de comisión LoCambio.com?',
    answer: 'Nuestra plataforma es GRATUITA y no contempla cobro ni  comisión por publicar artículos y/o servicios, así como tampoco por los cambios o trueques realizados. Por otro lado, ofrecemos servicios adicionales gratis durante la promoción de lanzamiento (LoCambio Seguro, LoCambio Premium), que permiten mejorar la experiencia dentro y fuera de la plataforma.',
  },
  {
    question: '¿Qué se puede publicar en Locambio.com?',
    answer: (
      <p>
        Con la
        <i>
          Suscripción Básica,
        </i>
        la plataforma permite publicar cualquier artículo como accesorios de vestir, ropa, zapatos, teléfonos, televisores, muebles, neveras, lavadoras, computadoras, juguetes, libros, películas, joyas, maquillaje, etc. Además, en Locambio.com también
        <b>
          se pueden publicar servicios profesionales, oficios y habilidades
        </b>
        de los usuarios. Las posibilidades de canjear o cambiar son infinitas...
        <br />
        <br />
        Con la
        <i>
          Suscripción Premium
        </i>
        los usuarios premium pueden publicar artículos de alto valor como carros, motos, terrenos, oficinas, inmuebles, acciones de club, entre otras.
      </p>
    ),
  },
  {
    question: '¿Cuáles son los requisitos básicos que se necesitan para publicar y canjear?',
    answer: (
      <ul>
        <li> - Ser dueño legítimo del bien y/o tener las habilidades del servicio que se va a publicar.</li>
        <li> - Subir al menos una imagen (de su galería de imágenes formato JPG o PNG) del artículo o servicio para la publicación.</li>
        <li> - Describir detalladamente el objeto o servicio que se está publicando.</li>
        <li> - Definir los bienes y/o servicios de interés que se desean a cambio.</li>
        <li> - Acordar con la otra parte interesada las condiciones del trueque o cambio, así como la ubicación para realizarlo.</li>
      </ul>
    ),
  },
  {
    question: '¿Cómo se negocia y se ponen de acuerdo las partes?',
    answer: (
      <p>
        Se negocia a través del chat de intercambios, este aparece cuando un usuario hace una propuesta de intercambio a otro, permitiendo a los usuarios conocerse, conversar sobre los detalles de sus propuestas y ponerse de acuerdo para concluir el trato.
        <br />
        <br />
        <i>
          Recuerda que en LoCambio.com pueden negociarse objetos a cambio de objetos, servicios, e incluso complementar con dinero para lograr el trueque o cambio.
        </i>
      </p>
    ),
  },
  {
    question: '¿Cómo se pueden encontrar o ver artículos y servicios en la Página?',
    answer: (
      <p>
        Usando la
        <b>Barra de Búsqueda Bidireccional:</b>
        <ul>
          <li> - Busca
            <b>
              quien tiene lo que quieres.
            </b>
          </li>
          <li> - Busca
            <b>
              quien quiere lo que tienes.
            </b>
          </li>
        </ul>
        <br />
        Con ayuda del
        <Link to="/wall">
          Mural de Publicaciones:
        </Link>
        <br />
        Descubre en tiempo real los artículos y servicios cercanos a ti, gracias a la
        <b>
          funcionalidad de geolocalización
        </b>
        con la que cuenta nuestra plataforma.
      </p>
    ),
  },
  {
    question: '¿Es seguro cambiar en LoCambio.com?',
    answer: (
      <p>
        Nuestra plataforma facilita encontrar oportunidades para negociar el trueque o cambio entre usuarios. La condición de los bienes, la calidad de los servicios, así como el lugar para concretar el negocio, dependen de los mismos usuarios.
        <br />
        <br />
        <i>
          Solicita nuestro servicio
          <b>
            LoCambio Seguro
          </b>
          para mayor seguridad al momento de realizar el canje o intercambio
        </i>
      </p>
    ),
  },
  {
    question: '¿Cuál es el propósito de Locambio.com?',
    answer: (
      <ul>
        <li> - Traer el trueque a la era digital, logrando que las personas canjeen o cambien lo que tienen por lo que necesitan o desean, sin el dinero como medio exclusivo de negociación. El dinero puede utilizarse para complementar el trueque o cambio.</li>
        <li> - Ofrecer una plataforma global, con funcionalidades especialmente diseñadas para el intercambio. Pensando en las necesidades de sus usuarios.</li>
        <li> - Promover el ahorro, bienestar y calidad de vida de la comunidad.</li>
        <li> - Fomentar el reciclaje y educar sobre la importancia de poner en circulación bienes en desuso.</li>
        <li> - Reducir el consumismo excesivo apoyando al medio ambiente.</li>
      </ul>
    ),
  },
];

const Faq = () => {
  const [isOpen, setIsOpen] = useState(null);

  const handleOpen = (i) => {
    if (i === isOpen) return setIsOpen(null);
    setIsOpen(i);
  };

  const faqElements = faq.map((element, i) => (
    <div className="mt-2" key={element.question}>
      <Button
        text={element.question}
        className="faq__button"
        fill
        onClick={() => handleOpen(i)}
      >
        <Icon className={`faq__icon ${i === isOpen ? 'faq__icon--active' : ''}`} icon="chevron-down" />
      </Button>
      <Collapse isOpen={i === isOpen}>
        <Card className="faq__content">{element.answer}</Card>
      </Collapse>
    </div>
  ));

  return (
    <div className="faq section--with-padding">
      <div className="faq__cover" />
      <div className="faq__container">
        <p className="faq__title">¿Tienes dudas?</p>
        {faqElements}
      </div>
    </div>
  );
};

export default Faq;
