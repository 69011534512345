import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Callout, AnchorButton } from "@blueprintjs/core";
import './currentBalance/CurrentBalance.scss';
import AddBalance from './AddBalance';
import axios from 'axios';
import { formatPrice, priceToString } from './../../utilityFunctions';
import { config } from './../../system/Config';
import { getHeader } from './../../system/Sesion';
import { validate, touchedControl, helperText } from './currentBalance/validations';

class CurrentBalance extends Component {
  constructor(props){
    super(props);
    this.state = {
      showAddBalance: false,
      user: {},
      balance: '',
      initialLoading: true,
      updating: false,
      formValid: false,
      activatePaypal: false
    };
  }

  componentDidMount(){
    this.getUser();
  }

  fetchGetUser = () => {
    return axios.get(`${config.URL_API}/users/${localStorage.getItem('userId')}`, {headers: getHeader()});
  }

  fetchUpdateBalance = data => {
    return axios.put(`${config.URL_API}/users/${localStorage.getItem('userId')}`, data, {headers: getHeader()});
  }

  getUser = async () => {
    try {
      const response = await this.fetchGetUser();
      this.setUser(response.data.user);
      this.setState({initialLoading: false});
    }catch(err){
      console.error(err)
    }
  }

  setUser = data => {
    this.setState({user: data});
  }

  switchAddBalance = () => {
    this.setState({showAddBalance: !this.state.showAddBalance});
  }

  inputChangeHandler = event => {
    touchedControl[event.target.id] = true;
    this.setState({[event.target.id]: event.target.value !== '' ? priceToString(event.target.value) : event.target.value}, ()=> {
      this.setState({formValid: validate({...this.state}, true)});
    });
  }

  addBalance = async () => {
    if (!validate({...this.state}, true)){
      return;
    }

    this.setState({updating: true, activatePaypal: true});

    const data = {
      balance: parseInt(parseInt(this.state.user.balance) + parseInt(this.state.balance)),
      password_required: false
    };

    this.setState({updating: false});
  };
  
  render(){
    const {showAddBalance, user, initialLoading, balance, formValid, updating, activatePaypal} = this.state;
    const {creditCards} = this.props;

    if (initialLoading){
      return null;
    }

    return (
      <>
        <div className={'CurrentBalance'}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Callout>
                <div className={'CurrentBalanceContent'}>
                  <h1 className={'margin0'}>{localStorage.getItem("currency")} {formatPrice(user.balance)}</h1>
                  <p className={'margin0'}>Saldo</p>
                  {showAddBalance ?
                    <>
                      <AddBalance
                        value={balance}
                        changed={this.inputChangeHandler}
                        addBalance={this.addBalance}
                        helperText={helperText}
                        formValid={formValid}
                        updating={updating}
                        creditCards={creditCards}
                        activatePaypal={activatePaypal}
                      />
                      <AnchorButton 
                        className={'marginTop-1em'} 
                        minimal={true}
                        onClick={this.switchAddBalance}>Cerrar</AnchorButton>
                    </> 
                      :
                      <AnchorButton 
                        className={'marginTop-1em'} 
                        minimal={true}
                        onClick={this.switchAddBalance}>Agregar Saldo</AnchorButton>
                  }
                </div>

                <script src="https://www.paypal.com/sdk/js?client-id=sb"></script>
              <script>paypal.Buttons().render('body');</script>
              </Callout>
              
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default CurrentBalance;
