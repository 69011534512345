import React, { Component } from "react";
import {
  Card,
  Elevation,
  Callout,
  Button,
  Tag,
  Popover,
  Position,
  Icon,
  Divider,
} from "@blueprintjs/core";
import "./activityShareOwnPublication/ActivityShareOwnPublication.scss";
import { Row, Col } from "react-grid-system";
import OwnerInfo from "./activityNewPublication/OwnerInfo";
import ProductSwiper from "../../../components/productSwiper/ProductSwiper";
import Comments from "./../../../components/Comments";
import AvatarUser from "./../../../components/AvatarUser";
import {
  datelocambio,
  distanceFormat,
  isNewPublication,
  typeSuscription,
  productNameUrl,
  formatPrice,
  needOverlay,
} from "./../../../utilityFunctions";
import { config } from "./../../../system/Config";
import { getHeader } from "./../../../system/Sesion";
import Report from "./../../../components/Report";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import OverlayPremium from "./../../../components/OverlayPremium";
import * as actions from "./../../../store/actions";

class ActivityShareOwnPublication extends Component {
  state = {
    toast: { show: false, message: "" },
    publication: null,
    publicationsSavedByUser: null, // la publicacion ha sido guardada por el usuario
    savingPublication: false,
    isOpenReport: false,
    comments: false, // la publicacion tiene comentarios?
  };

  componentDidMount() {
    if (this.props.pub.publication.publications_saveds.length > 0) {
      this.setState({ publicationsSavedByUser: true });
    }
    this.setState({
      comments: this.props.pub.publication.comments_publications.length > 0,
    });
  }

  componentWillReceiveProps() {
    if (this.props.pub.publication.publications_saveds.length > 0) {
      this.setState({ publicationsSavedByUser: true });
    }
    this.setState({
      comments: this.props.pub.publication.comments_publications.length > 0,
    });
  }

  savePublication = () => {
    this.setState({ savingPublication: true });
    const dataAPI = {
      user_id: this.props.userId,
      publication_id: this.props.pub.publication.id,
    };

    axios
      .post(`${config.URL_API}/publications_saveds`, dataAPI, {
        headers: getHeader(),
      })
      .then((data) => {
        let toast = { ...this.state.toast };
        toast.show = true;
        toast.message = "Publicacion guardada";
        this.setState({
          toast: toast,
          publicationsSavedByUser: true,
          savingPublication: false,
        });
        this.hideToast();
        this.props.onGetPublicationsSaved(this.props.userId);
      })
      .catch((error) => {
        this.setState({ savingPublication: false });
        console.log('error', error);
      });
  };

  // el usuario loeguado ha compartido esta publicacion?
  sharedPub = (publication_id) => {
    let aux = false;
    this.props.pubShares.forEach((value) => {
      if (value.publication_id == publication_id) {
        aux = true;
        return;
      }
    });
    return aux;
  };

  switchIsOpenReport = () => {
    this.setState({ isOpenReport: !this.state.isOpenReport });
  };

  setCommentsTrue = () => {
    if (this.state.comments) {
      return;
    }
    this.setState({ comments: true });
  };

  render() {
    return (
      <>
        <div className="ActivityShareOwnPublication">
          <Card elevation={Elevation.ONE} className="marginTop-1em">
            <div
              className={
                "changeDesignLabel gradientBackgroundAnimated marginBottom-1em"
              }
            >
              <h2 className={"margin0"}>
                <Icon className={"margin0 marginRight-05em"} icon={"share"} />
                {`${this.props.pub.user.name} ${this.props.pub.user.last_name}`}{" "}
                 compartió su publicación 
              </h2>
            </div>
            <Divider className={"marginTop-1em marginBottom-1em"} />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="headerUserCard">
                  <div className="AvatarContainer">
                    <Link
                      to={`/profile/detail/${this.props.pub.publication.owner.id}`}
                    >
                      <AvatarUser user={this.props.pub.publication.owner} />
                    </Link>
                  </div>
                  <div className="UserDetailsContainer marginBottom-05em">
                    <div className={"popOverUserInfoContainer"}>
                      <Popover
                        interactionKind={"hover"}
                        position={Position.LEFT_TOP}
                        // isOpen={true}
                        content={
                          <OwnerInfo
                            idUser={this.props.pub.publication.owner.id}
                          />
                        }
                      >
                        <>
                          <h4>
                            {`${this.props.pub.publication.owner.name} ${this.props.pub.publication.owner.last_name}`}
                            <span
                              className={"bp3-form-helper-text marginLeft-05em"}
                            >
                              {datelocambio(this.props.pub.created_at)}
                            </span>
                          </h4>
                          <p className="bp3-form-helper-text">
                            Compartió su publicación {" "}
                            <span>{this.props.pub.publication.name}</span> a
                            <Icon icon="geolocation" />
                            <span>
                              {formatPrice(
                                distanceFormat(
                                  this.props.pub.publication.distance.toString()
                                )
                              )}
                            </span>{" "}
                            en
                            <Icon icon="map-marker" />
                            <span>{this.props.pub.publication.address_1}</span>
                          </p>
                        </>
                      </Popover>
                    </div>
                    {/* <div className="optionsButton">
                      <Popover
                        position={Position.BOTTOM}
                        content={
                          <>
                            <div className="padding-05em">
                              <Button
                                intent="danger"
                                minimal={true}
                                fill={true}
                                onClick={this.switchIsOpenReport}
                              >
                                Denunciar la publicación
                              </Button>
                            </div>
                          </>
                        }
                      >
                        <Button icon="more" minimal={true} />
                      </Popover>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                {this.props.pub.message ? (
                  <div
                    className={
                      "marginBottom-1em gradientBackgroundAnimated OwnMessageShareActivity"
                    }
                  >
                    <h3 className={"margin0"}>
                      <Icon
                        icon={"comment"}
                        className={"marginRight-1em"}
                        iconSize={24}
                      />
                      {this.props.pub.message}
                    </h3>
                  </div>
                ) : null}
                <Callout>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                    <div className="productImageContainer">
                        <figure>
                          <div className="productImage">
                            {needOverlay(
                              this.props.pub.publication.product_category,
                              this.props.suscription
                            ) ? (
                              <OverlayPremium />
                            ) : null}
                            <ProductSwiper
                              pictures={this.props.pub.publication.pictures}
                              needOverlay={needOverlay(
                                this.props.pub.publication.product_category,
                                this.props.suscription
                              )}
                            />
                          </div>
                          <div className="productImageControls bluredPremiumContent">
                            <div>
                              <p>
                                {isNewPublication(
                                  this.props.pub.publication.publish_date
                                ) ? (
                                  <Tag className="tagNew">Nueva</Tag>
                                ) : null}
                              </p>
                            </div>
                            {/* <div><Button icon="chevron-right" /></div>
                            <div><Button icon="chevron-left" /></div> */}
                          </div>
                        </figure>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                    <div className="marginLeft-1em w-1/2">
                        <h1
                          className={
                            "pt-2 sm:pt-4 md:pt-0 lg:pt-0 xl:pt-0 text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl"
                          }
                        >
                          <Link
                            to={`/${productNameUrl(
                              this.props.pub.publication.name
                            )}/${this.props.pub.publication.id}`}
                          >
                            {this.props.pub.publication.name}
                          </Link>
                        </h1>
                        <div className={"mb-2"}>
                          <p className="bp3-form-helper-text">
                            Condición del artículo
                          </p>
                          <p>
                            {this.props.pub.publication.product_status ===
                            "Usado" ? (
                              <Tag
                                className="tag marginRight-05em marginBottom-05em"
                                minimal={true}
                              >
                                Usado
                              </Tag>
                            ) : null}
                            {this.props.pub.publication.product_status ===
                            "Nuevo" ? (
                              <Tag
                                className="tag marginRight-05em marginBottom-05em"
                                minimal={true}
                              >
                                Nuevo
                              </Tag>
                            ) : null}
                            {this.props.pub.publication.product_status ===
                            "Usado" ? (
                              <Tag
                                className="tag marginRight-05em marginBottom-05em"
                                minimal={true}
                              >
                                {this.props.pub.publication.condition}
                              </Tag>
                            ) : null}
                          </p>
                        </div>
                        <div>
                          <p className="bp3-form-helper-text">
                            Interesado/a en cambiar por:
                          </p>
                          <p>
                            {this.props.pub.publication
                              .exchange_interest_category
                              ? this.props.pub.publication.exchange_interest_category
                                  .split(",")
                                  .map((cat, i) => (
                                    <Tag
                                      key={`catexch${i}`}
                                      className="marginRight-05em marginBottom-05em"
                                      minimal={true}
                                    >
                                      {cat.split("/")[0]}{" "}
                                      <Icon icon="chevron-right" />{" "}
                                      {cat.split("/")[1]}{" "}
                                    </Tag>
                                  ))
                              : null}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Callout>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="marginTop-1em">
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          {!this.state.publicationsSavedByUser ? (
                            <Button
                              className="BtnLCPrimaryOutline"
                              minimal={true}
                              fill={true}
                              icon="bookmark"
                              loading={this.state.savingPublication}
                              onClick={this.savePublication}
                            >
                              Guardar
                            </Button>
                          ) : (
                            <Button
                              className="BtnLCPrimaryOutline marginBottom-05em"
                              minimal={true}
                              fill={true}
                              disabled={true}
                              icon="bookmark"
                            >
                              Publicacion guardada
                            </Button>
                          )}
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Button
                            className="BtnLCYellow"
                            fill={true}
                            rightIcon="share"
                            onClick={() =>
                              this.props.sharePub(
                                this.props.pub.publication.id,
                                this.props.pub.publication.name
                              )
                            }
                          >
                            Compartir Publicación ukqwebvhfjlbhef
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Comments
                      isVisible={true}
                      pub={this.props.pub.publication}
                      setCommentsTrue={this.setCommentsTrue}
                    />
                    {!this.state.comments ? (
                      <Callout className={"noActivityDisplay marginBottom-1em"}>
                        <h5 className={"bp3-form-helper-text"}>
                          No hay preguntas aun
                        </h5>
                      </Callout>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>

        <Report
          isOpen={this.state.isOpenReport}
          onClose={this.switchIsOpenReport}
          owner={`${this.props.pub.publication.owner.name} ${this.props.pub.publication.owner.last_name}`}
          name={this.props.pub.publication.name}
          id={this.props.pub.publication.id}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    pubShares: state.auth.pubShares,
    suscription: state.auth.suscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPublicationsSaved: (user_id) =>
      dispatch(actions.getPublicationsSaved(user_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityShareOwnPublication);
