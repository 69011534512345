import axios from 'axios';

import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';
import { CHAT_KINDS } from '../../utility/chat';
import { baseAction } from '../../utilityFunctions';
import { ADD_CHAT, REMOVE_CHAT, SET_CHATS, UPDATE_CHAT } from './actionTypes';

export const setChats = (data) => baseAction(SET_CHATS, data);

// export const addMessage = (data) => baseAction(ADD_MESSAGE, data);

export const addChat = (data) => baseAction(ADD_CHAT, data);

export const updateChat = (data) => baseAction(UPDATE_CHAT, data);

export const removeChat = (data) => baseAction(REMOVE_CHAT, data);

export const getChats = () => {
  return async dispatch => {
    try {
      const responses = await Promise.allSettled([
        axios.get(`${config.URL_API}/chats?type=in_progress`, { headers: getHeader() }),
        axios.get(`${config.URL_API}/chats?type=proposal`, { headers: getHeader() }),
      ]);

      dispatch(setChats({
        [CHAT_KINDS.inProgress]: responses[0].value.data,
        [CHAT_KINDS.proposal]: responses[1].value.data,
      }));
    } catch(err) {
      throw err;
    }
  };
};