import React, { Component } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { AnchorButton, Card, Elevation } from '@blueprintjs/core';
import RankingGraph from './../components/RankingGraph';
import RankingStar from './../components/RankingStar';
import { dateMonthString } from '../utilityFunctions';
import { config } from '../system/Config';
import HeaderProfileSkeleton from './../components/HeaderProfileSkeleton';
import AvatarProfile from './AvatarProfile';
import UserStatsInfo from './UserStatsInfo';
import axios from 'axios';
import { getHeader } from '../system/Sesion';
import './headerProfile/HeaderProfile.scss';
import ModalCrop from './ModalCrop';
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import { Toast } from '../utility/toast';

class HeadarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCropping: false,
      srcAvatar: '',
      file: null,
      imgDeleted: false,
    };
  }

  showAvatarInitials = (user) => {
    return (
      (!user.picture_data && user.picture_data !== 'fromAPI') ||
      user.picture_data === 'imgDeleted' ||
      this.state.imgDeleted
    );
  };

  imgProfile = (user) => user.avatar;

  cancelCrop = () => {
    this.setState({
      cropAvatar: null,
      cropCover: null,
      isCropping: false,
    });
  };

  handleBtnClick() {
    /*Collecting node-element and performing click*/
    this.inputFileRef.current.click();
  }

  // Load selected image
  onSelectFile = (e, typeSrc) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          srcAvatar: reader.result,
          isCropping: true,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (canvas) => {
    const newImage = canvas.toDataURL();
    const data = {
      id_image: this.props.user.picture_users[0]?.id,
      id_user: this.props.user.id,
      picture_data: [newImage],
    };
    this.setState({
      croppedImageUrl: newImage,
      cropAvatar: null,
      cropCover: null,
      isCropping: false,
      imgDeleted: false,
    });
    return axios
    .put(`${config.URL_API}/update_profile_photo`, data, {
      headers: getHeader(),
    })
    .then((response) => {
      const toast = {
        show: true,
        message: 'La imagen de perfil se ha guardado correctamente',
        icon: 'tick',
        intent: 'success',
      };
      Toast.show(toast);

      this.props.onCheckSession();
    })
    .catch((err) => {
      const toast = {
        show: true,
        message: 'Ocurrio un error al guardar la imagen de perfil',
        icon: 'tick',
        intent: 'error',
      };
      Toast.show(toast);
    });
  };

  handleDeleteImage = () => {
    this.setState({ ...this.state, imgDeleted: true, croppedImageUrl: null });
    this.props.deleteImgProfile();
  };

  render() {
    const { user, initialLoading } = this.props;

    if (initialLoading) {
      return <HeaderProfileSkeleton />;
    }

    return (
      <>
        <ModalCrop
          isOpen={this.state.isCropping}
          img={this.state.srcAvatar}
          handleComplete={this.onCropComplete}
          handleCancel={this.cancelCrop}
        />
        <div className="HeadarProfile">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="OverlapContent">
                  <Row>
                    <Col xs={12} sm={6} md={3} lg={3}>
                      <Card
                        elevation={Elevation.ONE}
                        className="AvatarProfileContainer"
                      >
                        <div className="User-Avatar-Name">
                          <AvatarProfile
                            imgProfile={this.imgProfile}
                            user={user}
                            editable={this.props.editable}
                            showAvatarInitials={this.showAvatarInitials}
                            deleteImgProfile={this.handleDeleteImage}
                            handleBtnClick={this.handleBtnClick}
                            onSelectFile={this.onSelectFile}
                            croppedImageUrl={this.state.croppedImageUrl}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={9} lg={9}>
                      <Card elevation={Elevation.ONE}>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Row>
                              <Col xs={12} sm={12} md={6} lg={8}>
                                <div className="AvatarProfileInfo">
                                  <div className="AvatarProfileName">
                                    <h1 className="margin0 marginBottom-05em">{`${user.name} ${user.last_name}`}</h1>
                                    <div className="marginBottom-05em">
                                      <p className="margin0 marginBottom-05em Gilroy600">
                                        Saldo:{' '}
                                        <span className="Gilroy800">
                                          USD {user.balance}
                                        </span>
                                      </p>
                                    </div>
                                    <p className="margin0 marginBottom-05em capitalize Gilroy600">
                                      {user.city}
                                    </p>
                                    <p className="margin0 marginBottom-05em Gilroy600">
                                      <span className="mr-1">
                                        Miembro desde
                                      </span>
                                      {dateMonthString(user.registration_date)}
                                    </p>
                                  </div>
                                </div>
                                <div className="marginTop-1em marginBottom-1em text-center">
                                  <AnchorButton
                                    className="marginBottom-05em marginRight-05em"
                                    minimal
                                    text="Datos Personales"
                                    href="/profile"
                                  />
                                  <AnchorButton
                                    className="marginBottom-05em marginRight-05em"
                                    minimal
                                    text="Mis Publicaciones"
                                    href="/my-publications"
                                  />
                                  <AnchorButton
                                    className="marginBottom-05em marginRight-05em"
                                    minimal
                                    text="Métodos de pago"
                                    href="/payment_method"
                                  />
                                  <AnchorButton
                                    className="marginBottom-05em marginRight-05em"
                                    minimal
                                    text="Preferencias"
                                    href="/configurations"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={4}>
                                <UserStatsInfo user={user} />
                                <div className="mt-0 md:mt-8">
                                  <RankingGraph user={user} />
                                  <RankingStar user={user} />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckSession: () => dispatch(actions.checkSesion()),
  };
};

export default connect(null, mapDispatchToProps)(HeadarProfile);
