import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { Button, Dialog } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import React, { useEffect, useRef, useState } from 'react';

const cropBase = {
  unit: '%',
  width: 90,
  height: 90,
  x: 5,
  y: 5,
  disabled: true,
};

const ModalCrop = (props) => {
  const imgRef = useRef(null);
  const [canvas, setCanvas] = useState();
  const [crop, setCrop] = useState(cropBase);

  useEffect(() => {
    if (props.img) setCrop(cropBase);
  }, [props.img, setCrop]);

  const getCroppedImg = (image, newCrop) => {
    if (!newCrop.width || !newCrop.height) return;
    const newCanvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    newCanvas.width = newCrop.width;
    newCanvas.height = newCrop.height;
    const ctx = newCanvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    newCanvas.width = newCrop.width * pixelRatio;
    newCanvas.height = newCrop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      newCrop.x * scaleX,
      newCrop.y * scaleY,
      newCrop.width * scaleX,
      newCrop.height * scaleY,
      0,
      0,
      newCrop.width,
      newCrop.height,
    );
    setCanvas(newCanvas);
    return newCanvas;
  };

  const onImageLoaded = (img) => {
    imgRef.current = img;
    props.onImageLoaded?.(img);
  };

  const onComplete = (newCrop) => {
    const image = imgRef.current;
    const newCanvas = getCroppedImg(image, newCrop);
    props.onComplete?.(newCanvas);
  };

  const onChange = (newCrop) => {
    setCrop(newCrop);
    props.onChange?.(newCrop);
  };

  const handleComplete = () => {
    props.handleComplete?.(canvas);
  };

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={props.isOpen}
      icon={props.icon}
      onClose={props.handleCancel}
      title={props.title}
    >
      <div className="flex flex-col">
        <ReactCrop
          className="self-center children-without-transition"
          crop={crop}
          src={props.img}
          onImageLoaded={onImageLoaded}
          onComplete={onComplete}
          onChange={onChange}
          minWidth={100}
          minHeight={100}
          keepSelection
        />
        <div className="mb-4">
          <Container>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Button
                  fill
                  icon="undo"
                  className="bp3-fill BtnLCCancel marginTop-1em"
                  onClick={props.handleCancel}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Button
                  fill
                  icon="tick-circle"
                  className="bp3-fill BtnLCPrimary marginTop-1em marginRight-1em"
                  onClick={handleComplete}
                >
                  Listo
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Dialog>
  );
};

ModalCrop.propTypes = {
  img: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  handleCancel: PropTypes.func,
  onImageLoaded: PropTypes.func,
  handleComplete: PropTypes.func,
};

ModalCrop.defaultProps = {
  title: 'Recorte de imagen',
  icon: 'widget',
};

export default ModalCrop;
