import React, { Component } from 'react';
import './searchListResults/SearchListResults.scss';
//import Pagination from "react-js-pagination";

class SearchListPagination extends Component {

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {/* <div className={'paginationSearchList marginTop-1em marginBottom-1em'}>
        </div> */}
        {/* <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.props.totalPages + 10}
          pageRangeDisplayed={5}
          onChange={this.props.handlePageChange}
          prevPageText={''}
          firstPageText={''}
          lastPageText={''}
          nextPageText={''}
          activeClass={''} // aqui la clase para cuando esta activa (es una etiqueta li)
          activeLinkClass={''} // aquia la clase para cuando esta active (etiqueta a)
        /> */}
      </>
    )
  }
}

export default SearchListPagination;
