import React from 'react';
import { Suggest } from '@blueprintjs/select';
import { Checkbox } from '@blueprintjs/core';
import { provincesVenezuela } from 'const';
import PropTypes from 'prop-types';

const items = ['Todos los estados', ...provincesVenezuela];

export default function SelectSuggest({ locationChangeHandler, filterValues }) {
  const handleItemRenderer = (item, { handleClick }) => {
    const value =
      filterValues.location === item ||
      (filterValues.location === '' && item === 'Todos los estados');
    return (
      <Checkbox checked={value} onClick={handleClick}>
				{item}
			</Checkbox>
    );
  };

  const handleInputValueRenderer = (inputValue) => inputValue;

  const handleFilterItems = (query, item, _index, exactMatch) => {
    const title = item.toLowerCase();
    const queryLower = query.toLowerCase();
    if (exactMatch) return title === queryLower;
    return title.indexOf(queryLower) >= 0;
  };

  return (
    <Suggest
      inputValueRenderer={handleInputValueRenderer}
      items={items}
      itemRenderer={handleItemRenderer}
      itemPredicate={handleFilterItems}
      onItemSelect={(value) => locationChangeHandler(value)}
      inputProps={{ placeholder: 'Todos los estados' }}
    />
  );
}

SelectSuggest.propTypes = {
  locationChangeHandler: PropTypes.func.isRequired,
  filterValues: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
};
