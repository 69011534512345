import React, { Component } from 'react';
import { Card, Elevation, Spinner, Alert } from '@blueprintjs/core';
import { Container, Row, Col } from 'react-grid-system';
import './paymentMethodContainer/PaymentMethodContainer.scss';
import CurrentBalance from './paymentMethodContainer/CurrentBalance';
import axios from 'axios';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import HeaderProfile from '../components/HeaderProfile';
import { Toast } from '../utility/toast';

class PaymentMethodContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialLoading: true,
      creditCards: [],
      loading: true,
      alertDelete: false,
      deleting: false,
      idCreditCardToDelete: null,
      creditCardsValidator: false,
    };
  }

  componentWillMount() {
    this.getCreditCards();
    const userId = localStorage.getItem('userId');
    this.getUser(userId);
  }

  fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async (userId) => {
    try {
      const response = await this.fetchGetUser(userId);
      let user = { ...response.data.user };
      user.dni = response.data.user.dni.split('-')[1].trim('');
      user.gender = response.data.user.gender === 'Masculino' ? '1' : '0';
      user.old_password = '';
      user.password = '';
      user.confirmation_password = '';
      user.picture_data = null;
      user.picture_data_cover = null;
      if (user.picture_users.length > 0) {
        user.picture_data = 'fromAPI';
      }

      let dayOfBirth = 1;
      if (user.date_of_birth) {
        const splitDate = user.date_of_birth.split('-');
        const intDay = parseInt(splitDate[2]);
        if (intDay) {
          dayOfBirth = intDay;
        }
      }

      this.setState({
        user: user,
        initialLoading: false,
        typeDNI: response.data.user.dni.split(' ')[0],
        year: user.date_of_birth.split('-')[0],
        month: user.date_of_birth.split('-')[1],
        day: dayOfBirth + '',
      });
    } catch (err) {
      console.log('error', err);
    }
  };

  fetchGetCreditCards = () => {
    return axios.get(
      `${config.URL_API}/credit_card?user_id=${localStorage.getItem('userId')}`,
      { headers: getHeader() }
    );
  };

  fetchDeleteCreditCard = (id) => {
    return axios.post(
      `${config.URL_API}/credit_card/delete/${id}`,
      {},
      { headers: getHeader() }
    );
  };

  getCreditCards = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.fetchGetCreditCards();
      this.setState({ loading: false });
      this.setCreditCards(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  setCreditCards = (data) => {
    this.setState({ creditCards: data, creditCardsValidator: true });
  };

  deleteCreditCard = async () => {
    this.setState({ deleting: true });
    try {
      await this.fetchDeleteCreditCard(this.state.idCreditCardToDelete);
      const toast = {
        message: 'La tarjeta ha sido eliminada exitosamente',
        icon: 'tick',
        intent: 'success',
      };
      Toast.show(toast);
      this.setState({
        deleting: false,
        idCreditCardToDelete: null,
        alertDelete: false,
      });
      this.getCreditCards();
    } catch (err) {
      const toast = {
        message: 'Lo sentimos, hubo un error',
        icon: 'cross',
        intent: 'danger',
      };
      Toast.show(toast);
      this.setState({
        deleting: false,
        idCreditCardToDelete: null,
        alertDelete: false,
      });
    }
  };

  alertDeleteCreditCARD = (id) => {
    this.setState({ alertDelete: true, idCreditCardToDelete: id });
  };

  cancelDeleteCreditCard = () => {
    this.setState({ alertDelete: false });
  };

  render() {
    const { creditCards, alertDelete, deleting, user, initialLoading } =
      this.state;

    return (
      <>
        <div
          className={
            'PaymentMethodContainer pt-12 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6'
          }
        >
          <HeaderProfile
            user={user}
            editable={false}
            initialLoading={initialLoading}
          />
          {/* INCLUIR EL HEADER PROFILE */}
          <div className={'minCustomWrapper'}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card elevation={Elevation.ONE} className={'marginTop-1em'}>
                    <CurrentBalance creditCards={creditCards} />
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Alert
          icon="trash"
          confirmButtonText="Si"
          cancelButtonText="No"
          isOpen={alertDelete}
          onConfirm={this.deleteCreditCard}
          onCancel={this.cancelDeleteCreditCard}
        >
          {deleting ? (
            <>
              <span>Eliminando.....</span>
              <Spinner
                className={'marginTop-1em marginBottom-1em'}
                size={'32'}
              />
            </>
          ) : (
            <p>
              Se borrara esta tarjeta de tu lista
              <br />
              ¿Estás seguro?
            </p>
          )}
        </Alert>
      </>
    );
  }
}

export default PaymentMethodContainer;
