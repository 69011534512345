import React from "react";
import { Card, Elevation, AnchorButton } from "@blueprintjs/core";
import { Row, Col, Container } from "react-grid-system";
import "./contacts/Contacts.scss";
import LinksInfoPages from "./../components/LinksInfoPages";
import { Link } from "react-router-dom";
import ContactForm from "./contacts/contact-form/ContactForm";
import MapContainer from "./contacts/map-container/MapContainer";

export default () => (
  <div className="contacts">
    <div className="pt-16">
      <Container>
        <Row>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Card elevation={Elevation.ONE}>
              <h2 className="margin0 marginBottom-1em">Contáctanos</h2>
              <h3>
                Para aclarar cualquier duda sobre el uso o los servicios de
                LoCambio.com te invitamos a visitar nuestras
                <Link to="faq">
                  <AnchorButton minimal text="preguntas frecuentes" />
                </Link>
              </h3>

              <div className="block md:flex mt-8">
                <div className="w-full md:w-1/2">
                  <MapContainer />
                </div>
                <div className="w-full md:w-1/2">
                  <ContactForm />
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <LinksInfoPages />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);
