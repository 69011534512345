import React, { useState } from 'react';
import { AnchorButton, Callout, NonIdealState, Spinner, Tag, Text } from '@blueprintjs/core';
import './headerNotificationsMessages/HeaderNotificationsMessages.scss';
import { useSelector } from 'react-redux';
import AvatarUser from './../AvatarUser';
import { Link } from 'react-router-dom';
import { checkWithCurrentUser, dateMonthStringShort, handleClosePopover } from '../../utilityFunctions';
import { CHAT_KINDS, CHAT_KINDS_BUTTONS, CHAT_KINDS_URL } from '../../utility/chat';

const HeaderNotificationsMessages = () => {
  const [kind, setKind] = useState(CHAT_KINDS.proposal);
  const { loading, [kind]: chats, unreadChats } = useSelector(({ chat }) => chat);

  if (loading) {
    return (
      <div className="HeaderNotificationsMessages padding-05em">
        <Callout title="Chats de intercambios" />
        <Spinner className="mt-12 mb-12" size="32" />
      </div>
    );
  }

  const message = (message) => {
    if (message.latitude && message.longitude) return '[Dirección]';
    if (message.picture_url) return '[Image]';
    return message.message;
  };

  return (
    <div className="BoxNotificationsMessages">
      <div className="HeaderNotificationsMessages padding-05em">
        <Callout title="Chats de intercambio" />
        <ul className="list-button-wrapper">
          {
            CHAT_KINDS_BUTTONS.map((button) => {
              const unread = unreadChats[button.key] > 10 ? '10+' : unreadChats[button.key];
              return (
                <li
                  key={`notification-button-${button.key}`}
                  className={`list-button ${button.key === kind ? 'list-button--active' : ''}`}
                  onClick={() => setKind(button.key)}
                >
                  <Text tagName="h3">
                    {`${button.label}`}
                    {!!unread && ` (${unread})`}
                  </Text>
                </li>
              );
            })
          }
        </ul>
        <div className="marginTop-05em">
          {
            !chats.length && (
              <Callout className="margin0">
                <NonIdealState icon="chat" title="No hay mensajes" />
              </Callout>
            )
          }
        </div>
        <ul>
          {chats.length > 0
            ? chats.map((chat, i) => {
              const isOwner = checkWithCurrentUser(chat.owner.id);
              const user = isOwner ? chat.interested : chat.owner;

              return (
                <Link
                  key={`messageHeaderNotificationsMessages${i}`}
                  to={`/chats/${chat.id}`}
                  onClick={handleClosePopover}
                >
                  <li>
                    <figure className="interactiveFigure">
                      <div className="headerUserCard">
                        {!!chat.unread_messages && <div className="newMessageIcon" />}
                        <div className="AvatarContainerPopover">
                          <AvatarUser user={user} />
                        </div>
                        <div className="UserDetailsContainer marginBottom-0">
                          <div className="flex flex-row name-row">
                            <h4 className="messager-name">
                              {`${user?.name} ${user?.last_name}`}
                              <MessageUnRead
                                chat={chat}
                              />
                            </h4>
                            <span className="dateTime marginBottom-05em">
                              {dateMonthStringShort(chat.updated_at)}
                            </span>
                          </div>
                          <p className="bp3-text-overflow-ellipsis message-text">
                            {message(chat.last_message)}
                          </p>
                          <p
                            className={`bp3-text-overflow-ellipsis article-text ${isOwner ?
                              'purple-text' :
                              'orange-text'}`}
                          >
                            {chat.transaction.publication.title}
                          </p>
                        </div>
                      </div>
                    </figure>
                  </li>
                </Link>
              );
            })
            : null}
        </ul>
      </div>
      {chats.length > 0 ? (
        <div className={'padding-05em mt-2'}>
          <Link
            className="button-chats"
            to={`/chats?tab=${CHAT_KINDS_URL[kind]}`}
            onClick={handleClosePopover}
          >
            <AnchorButton className="purple-text" minimal fill>
              Ver todos
            </AnchorButton>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

const MessageUnRead = (props) => {
  const { chat } = props;
  const cantUnRead = chat.unread_messages;

  return cantUnRead ? (
    <Tag className="tagNew marginLeft-05em">
      <span className="bubbleAlert">{cantUnRead}</span>
    </Tag>
  ) : null;
};

export default HeaderNotificationsMessages;
