import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "./../../utilityFunctions";

const initialState = {
  token: null,
  userId: null,
  fullName: null,
  suscription: null,
  otherInfo: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  isAuthenticated: false,
  loggued: false,
  sessionVerifed: false,
  followers: null,
  following: null,
  imageProfile: null,
  imageCover: null,
  usersFollowers: [],
  usersFollowing: [],
  publications: [],
  pubShares: [], // publicaciones que este usuario a compartido.
  likes: [], // publicaciones a la que este usuario le ha dado like,
  likesLoaded: false, // los likes ya estan cargados?,
  publicationsSaved: [],
  publicationsSavedLoaded: false,
  avatar: null,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSucces = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    fullName: action.fullName,
    loggued: action.loggued,
    suscription: action.suscription,
    otherInfo: action.otherInfo,
    sessionVerifed: true,
    error: null,
    loading: false,
    isAuthenticated: true,
    imageProfile: action.imageProfile,
    imageCover: action.imageCover,
    publications: action.publications,
    avatar: action.avatar,
  });
};

const setFollows = (state, action) => {
  return updateObject(state, {
    followers: action.followers,
    following: action.following
  });
};

const setUsersFollowrs = (state, action) => {
  return updateObject(state, {
    usersFollowers: action.usersFollowers
  });
};

const setUsersFollowing = (state, action) => {
  return updateObject(state, {
    usersFollowing: action.usersFollowing
  });
};

const setPubShares = (state, action) => {
  return updateObject(state, {
    pubShares: action.pubShares
  });
};

const setLikes = (state, action) => {
  return updateObject(state, {
    likes: action.likes,
    likesLoaded: true
  });
};

const setPublicationsSaved = (state, action) => {
  return updateObject(state, {
    publicationsSaved: action.publicationsSaved,
    publicationsSavedLoaded: true
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    sessionVerifed: true
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    isAuthenticated: false,
    fullName: null,
    sessionVerifed: true
  });
};

const setLoggued = (state, action) => {
  return updateObject(state, {
    loggued: action.value
  });
};

const setSessionVerifed = (state, action) => {
  return updateObject(state, {
    sessionVerifed: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSucces(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_SET_LOGGUED:
      return setLoggued(state, action);
    case actionTypes.SET_FOLLOWS:
      return setFollows(state, action);
    case actionTypes.SET_USERS_FOLLOWERS:
      return setUsersFollowrs(state, action);
    case actionTypes.SET_USERS_FOLLOWING:
      return setUsersFollowing(state, action);
    case actionTypes.SET_PUB_SHARES:
      return setPubShares(state, action);
    case actionTypes.SET_LIKES:
      return setLikes(state, action);
    case actionTypes.SET_SESSION_VERIFED:
      return setSessionVerifed(state, action);
    case actionTypes.SET_PUBLICATIONS_SAVED:
      return setPublicationsSaved(state, action);
    default:
      return state;
  }
};

export default reducer;
