// NotificationPageElement
import React from 'react';
import { dateShort } from '../../utilityFunctions';
import { Divider } from '@blueprintjs/core';
import AvatarUser from '../AvatarUser';
import { typeNotifications } from '../../const';

class NotificationPageElement extends React.Component {

  getUser(notification, value) {
    let tn = notification.type_notification_id;
    if (tn === typeNotifications.new_question.name || tn === typeNotifications.new_response_comment.name) {
      return value.user;
    } else if (
        tn === typeNotifications.rejected_proposal.name || 
        tn === typeNotifications.accepted_proposal.name) {
      return value.publication.owner;
    } else if (
      tn === typeNotifications.exchange_accepted.name ||
      tn === typeNotifications.exchange_rejected.name ||
      tn === typeNotifications.transaction_completed_by_the_system.name) {
      return typeNotifications.exchange_rejected.user(notification);
    } else if (tn === typeNotifications.new_change.name) {
      return value.interested_user;
    } else if (tn === typeNotifications.publication_waiting_approval.name) {
      return typeNotifications.publication_waiting_approval.user(notification);
    }
    return undefined;
  }

  render() {
    const { value, notification } = this.props;
    const { message, title } = this.props.textElements;
    const date = this.props.notification.created_at;
    const user = this.getUser(this.props.notification, value);
    const onClick = typeNotifications[notification.type_notification_id].onClick?.(notification);

    return (
      <>
        <div className={`padding-05em ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
          <div className="headerUserCard">
            <div className="AvatarContainer">
              <AvatarUser user={user} />
            </div>
            <div className="UserDetailsContainer marginBottom-05em">
              <div className="flex justify-between Line1">
                <div className="pr-3 UserName">
                  <h4>{title(notification)}</h4>
                </div>
                <div className="NotDate2">{dateShort(date)}</div>
              </div>
              <p className="bp3-form-helper-text">
                {message(notification)}
              </p>
            </div>
          </div>
        </div>
        <Divider />
      </>
    );
  }
}

export default NotificationPageElement;
