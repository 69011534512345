import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Button, Card, Divider, Elevation, Icon, Tag } from '@blueprintjs/core';
import {
  datelocambio,
  distanceFormat,
  formatPrice,
  getAllCategories,
  needOverlay,
  productNameUrl,
} from '../../utilityFunctions';
import Breadcrumb from '../../breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

export default function ProductDetailSection({
  pub,
  pubInTransaction,
  toChange,
  suscription,
  isAuthenticated,
  userId,
}) {
  const currency =
    pub.price === null
      ? 'A convenir'
      : `${localStorage.getItem('currency')} ${formatPrice(pub.price)}`;
  return (
    <>
      <div className="InfoProductContainer">
        <Card
          elevation={Elevation.ONE}
          className="mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0"
        >
          <div className="publicationName">
            <div className="titleWithButton">
              <div>
                <h1 className="mb-4 sm:mb-4 md:mb-2 lg:mb-2 xl:mb-2">
                  {pub.name}
                </h1>
                <Tag className="marginBottom-1em" minimal>
                  ID: <strong>{pub.id}</strong>
                </Tag>
              </div>
              <div className="buttonContainer">
                <Link to="/adForm">
                  <Button className="blackButton">
                    <Icon className="icon" icon="stop" />
                    Promocionar
                  </Button>
                </Link>
              </div>
            </div>
            <Divider className="marginTop-05em marginBottom-1em" />
            <Row>
              <Col xs={6}>
                <div className="infoDetailPublication">
                  <p className="bp3-form-helper-text mt-2 sm:mb-2">
                    Disponible para
                  </p>
                  <Tag className="tagYellow">Cambio</Tag>
                </div>
              </Col>
              <Col xs={6}>
                <div className="infoDetailPublication">
                  <p className="bp3-form-helper-text mt-2 mb-2">
                    { pub.subCategory === 'product' ? "Valor del articulo" : "Valor del servicio" }
                  </p>
                  <Tag className="tag">
                    <strong>{currency}</strong>
                  </Tag>
                </div>
              </Col>
              <Col xs={6}>
                <div className="infoDetailPublication">
                  <p className="bp3-form-helper-text mt-4 mb-2">
                    Se encuentra a
                  </p>
                  <Tag className="tag">
                    {
                      `${formatPrice(distanceFormat(pub.distance)
                        .split(' km')[0])
                        .toString()} km`
                    }
                  </Tag>
                </div>
              </Col>
              <Col xs={6}>
                <div className="infoDetailPublication">
                  <p className="bp3-form-helper-text mt-4 mb-2">
                    Fecha de creación
                  </p>
                  <Tag className="tag">
                    {datelocambio(pub.publish_date)}
                  </Tag>
                </div>
              </Col>
              {pub.accept_changes &&
                <Col xs={6}>
                  <div className="infoDetailPublication">
                    <p className="bp3-form-helper-text mt-2 mb-2">
                      Esta publicación acepta propuestas de
                    </p>
                    <Tag className="tag">
                      <strong>Cualquier tipo </strong>
                    </Tag>
                  </div>
                </Col>}
            </Row>
          </div>
        </Card>
      </div>
      <Card elevation={Elevation.ONE} className="marginTop-1em">
        <Row>
          <Col xs={6}>
            <div className="infoDetailPublication">
              <p className="bp3-form-helper-text mt-0 mb-2">
                Lugar
              </p>
              <Tag className="tag">{pub.location}</Tag>
            </div>
          </Col>
          {
            pub.publication_type === 'Producto' ? (
              <Col xs={6}>
                <div className="infoDetailPublication">
                  <p className="bp3-form-helper-text mb-2">
                    Condición del artículo
                  </p>
                  {pub.product_status === 'Nuevo' ? (
                    <Tag className="tag">Nuevo</Tag>
                  ) : (
                    <React.Fragment>
                      <Tag className="tag mr-2">Usado</Tag>
                      <Tag
                        className="tag mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0"
                        minimal
                      >
                        {pub.condition}
                      </Tag>
                    </React.Fragment>
                  )}
                </div>
              </Col>
            ) : null
          }
          <Col xs={12}>
            <div className="infoDetailPublication">
              <p className="bp3-form-helper-text mt-4 mb-2">
                Categoria del producto
              </p>
              <Breadcrumb
                className="mb-2 mr-2"
                data={getAllCategories(pub.product_category)}
              />
            </div>
          </Col>
        </Row>
      </Card>
      {pub.publications_fields.length > 0 ? (
        <Card
          elevation={Elevation.ONE}
          className="marginTop-1em marginBottom-1em"
        >
          <Row>
            {pub.publications_fields.map((field, index) => {
              return (
                <Col key={index} xs={6}>
                  <p className="bp3-form-helper-text mb-2">
                    {field.field_category.label}
                  </p>
                  <h4 className="atrrCaracteristicas mb-4">
                    {() => {
                      const fieldText = field
                        .field_category.settings.options
                        ? field.field_category.settings.options.find(
                          (option) =>
                            option &&
                            option.value &&
                            option.value.toString() ===
                            field &&
                            field.value &&
                            field.value.toString(),
                        )
                        : field.value;

                      return (
                        (fieldText && fieldText.text) ||
                        field.value
                      );
                    }}
                  </h4>
                </Col>
              );
            })}
          </Row>
        </Card>
      ) : (
        <br />
      )}
      <Row>
        <Col xs={12}>
          <div className="marginBottom-1em hidden sm:hidden md:block lg:block xl:block">
            {!needOverlay(pub.product_category, suscription) ? (
              <>
                {!pubInTransaction ? (
                  <Button
                    onClick={() =>
                      toChange(
                        `/change/operation/${productNameUrl(
                          pub.name,
                        )}/${pub.id}/${pub.owner.id}/${
                          pub.coordinate.latitude
                        }/${pub.coordinate.longitude}`,
                      )
                    }
                    fill
                    className="BtnLCPrimary"
                    disabled={
                      !isAuthenticated ||
                      pub.owner.id === userId ||
                      pubInTransaction
                    }
                  >
                    Enviar Propuesta
                  </Button>
                ) : (
                  <Button
                    fill
                    className="BtnLCPrimary"
                    disabled
                  >
                    Propuesta Enviada
                  </Button>
                )}
            </>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
}
