import React, { PureComponent } from 'react';
import { RadialBarChart, RadialBar, Legend } from 'recharts';


const style = {
  top: 0,
  left: 350,
  lineHeight: '24px',
};


export default class AgeGraph extends PureComponent {

  constructor(props){
    super(props);
    let data = [];
    for (var key in this.props.age) {
      let sumRange1 = 0;
      let sumRange2 = 0;
      let sumRange3 = 0;
      let sumRange4 = 0;
      let sumRange5 = 0;
      let sumRange6 = 0;
      for(let key2 in this.props.age[key].ages){
        if(key == "18-24"){
          sumRange1 += this.props.age[key].ages[key2];
        }
    
        if(key == "25-29"){
          sumRange2 += this.props.age[key].ages[key2];
        }
    
        if(key == "30-34"){
          sumRange3 += this.props.age[key].ages[key2];
        }
    
        if(key == "35-39"){
          sumRange4 += this.props.age[key].ages[key2];
        }
    
        if(key == "40-49"){
          sumRange5 += this.props.age[key].ages[key2];
        }
    
        if(key == "50+"){
          sumRange6 += this.props.age[key].ages[key2];
        }

      }

      if(key == "18-24"){
        data.push( {
          //name: '18-24', uv: this.props.age[key], fill: '#8884d8'
          name: '18-24', uv: sumRange1, fill: '#8884d8'
        });
      }
  
      if(key == "25-29"){
          data.push({
            name: '25-29', uv: sumRange2, fill: '#83a6ed'
          });
      }
  
      if(key == "30-34"){
          data.push({
            name: '30-34', uv: sumRange3, fill: '#8dd1e1'
          });
      }
  
      if(key == "35-39"){
          data.push({
            name: '35-39', uv: sumRange4, fill: '#82ca9d'
          });
      }
  
      if(key == "40-49"){
          data.push({
            name: '40-49', uv: sumRange5, fill: '#a4de6c'
          });
      }
  
      if(key == "50+"){
          data.push({
            name: '50+', uv: sumRange6, fill: '#d0ed57'
          });
      }

      
    }

    this.state = {
      data: data     
    }
  }
  
  render() {
    const {data} = this.state;
    return (
      <RadialBarChart width={500} height={300} cx={150} cy={150} innerRadius={20} outerRadius={140} barSize={10} data={data}>
        <RadialBar minAngle={15} label={{ position: 'insideStart', fill: '#fff' }} background clockWise dataKey="uv" />
        <Legend iconSize={10} width={120} height={140} layout="vertical" verticalAlign="middle" wrapperStyle={style} />
      </RadialBarChart>
    );
  }
}
