import React from "react";
import { Breadcrumb } from "@blueprintjs/core";
import "./../SearchList.scss";

const Item = props => (
  <p>
    <Breadcrumb text={props.text} />
  </p>
);

const ResumeFilter = props => {
  const { filterValues, disabledPrice, disabledDistance } = props;
  const { filterReset } = filterValues;

  return (
    <>
      <div className={"EstasViendoProductos"}>
        <h4 className={"margin0 marginBottom-05em"}>Filtros de búsqueda:</h4>
        {filterReset ? (
          <div>
            <p>filtra para personalizar los resultados de búsqueda</p>
          </div>
        ) : (
          <>
            {!disabledDistance ? (
              <Item text={`${filterValues.distance} km`} />
            ) : null}
            {filterValues.status === "" ? (
              <Item text={`Articulos nuevos / Articulos usados`} />
            ) : (
              <Item text={`${filterValues.status}s`} />
            )}
            {filterValues.location === "" ? (
              <Item text={`Todo el Pais`} />
            ) : (
              <Item text={filterValues.location} />
            )}
            {filterValues.status === "Usado" &&
            filterValues.condition !== "" ? (
              <Item text={filterValues.condition} />
            ) : null}
            {filterValues.attrCategory.value !== "" ? (
              <Item
                text={`${filterValues.attrCategory.displayValue}: ${filterValues.attrCategory.value}`}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ResumeFilter;
