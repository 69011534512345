import React from "react";
import {
  Card,
  Tag,
  Divider,
  Callout,
  Button,
  Elevation,
} from "@blueprintjs/core";
import { Container, Row, Col } from "react-grid-system";
import { Link, withRouter } from "react-router-dom";
import ProductSwiper from "../../components/productSwiper/ProductSwiper";
import axios from "axios";
import { productNameUrl, needOverlay } from "./../../utilityFunctions";
import { config } from "./../../system/Config";
import { getHeader } from "./../../system/Sesion";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import pointerMap from "./../../Assets/System/pointerMap/pointerMap.png";
import AddressPubInfo from "./../../components/AddressPubInfo";
import "./ChangeOperation.scss";
import ChangeOperationStep3 from "./ChangeOperationStep3";
import Footer from "./../../components/Footer";
import OverlayPremium from "./../../components/OverlayPremium";
import { connect } from "react-redux";
import { Toast } from '../../utility/toast';

class ChangeOperationStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savingTransaction: false,
      changeSuccessfull: false,
      idTransaction: null,
      idChat: null,
    };
    this.executeTransaction = this.executeTransaction.bind(this);
    this.fetchSaveTransaction = this.fetchSaveTransaction.bind(this);
    this.fetchCreateChat = this.fetchCreateChat.bind(this);
  }

  async executeTransaction() {
    this.setState({ savingTransaction: true });

    try {
      let articleTagProposal = null;
      if (this.props.tradeTag !== null) {
        articleTagProposal = this.props.tradeTag;
      } else if (this.props.proposal !== null) {
        articleTagProposal = this.props.proposal;
      }

      const transaction = {
        token: localStorage.getItem("tokenId"),
        publication_id: this.props.id_pub,
        meeting_point: this.props.coordinate.id,
        tags: articleTagProposal,
        publication_interested_id: this.props.publication_interested_id,
        type_transaction: false,
        telocambioSeguro: this.props.pointChange,
        point: this.props.address_1,
        sub_point: this.props.address_2,
      };
      let response = await this.fetchSaveTransaction(transaction);

      const data = {
        transaction_id: response.data.id,
        publication_id: this.props.id_pub,
        token: localStorage.getItem("tokenId"),
      };

      try {
        const chat = await this.fetchCreateChat(data);

        Toast.show({message: 'El cambio ha sido ejecutado con exito'})
        this.setState({
          savingTransaction: false,
          changeSuccessfull: true,
          idTransaction: response.data.id,
          idChat: chat.data.id,
        });
      } catch (err) {
        console.log('error creando el chat');
      }
    } catch (err) {
      console.log('err', err.response);
    }
  }

  fetchSaveTransaction(data) {
    return axios.post(`${config.URL_API}/v1/transactions`, data, {
      headers: getHeader(),
    });
  }

  fetchCreateChat(data) {
    return axios.post(`${config.URL_API}/chats`, data, {
      headers: getHeader(),
    });
  }

  getArticleSelected(tradeTag, pubSelected, propposal) {
    if (tradeTag != null) {
      return tradeTag;
    }

    if (pubSelected != null) {
      return pubSelected.name;
    }

    if (propposal != null) {
      return propposal;
    }
  }

  render() {
    const {
      pictures,
      name,
      tradeTag,
      pointChange,
      address_1,
      address_2,
      coordinate,
      nameOwner,
      product_category,
      pubSelected,
      proposal,
    } = this.props;
    const {
      savingTransaction,
      changeSuccessfull,
      idTransaction,
      idChat,
    } = this.state;
    const position = { lat: coordinate.latitude, lng: coordinate.longitude };
    const pubURL = `/${productNameUrl(this.props.match.params.name)}/${
      this.props.match.params.id
    }`;

    if (!changeSuccessfull) {
      return (
        <>
          <Container>
            <div
              className={
                "ChangeOperationContainer pt-32 pb-32 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6"
              }
            >
              <div className={"marginBottom-1em"}>
                <Button
                  onClick={this.props.backStep}
                  className={"marginRight-1em"}
                  icon={"chevron-left"}
                  minimal={true}
                >
                  <Link to={pubURL}>{this.props.match.params.name}</Link>
                </Button>
                <Button
                  onClick={this.props.backStep}
                  className={"marginRight-1em"}
                  icon={"chevron-left"}
                  minimal={true}
                >
                  Volver
                </Button>
              </div>
              <Card elevation={Elevation.ONE}>
                <Callout
                  title={`Confirma tu propuesta para  cambiar por ${name}`}
                />
                <div className={"marginTop-1em marginBottom-1em"}>
                  <Callout>
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4}>
                        <div className={"SwiperContainer"}>
                          {needOverlay(
                            product_category,
                            this.props.suscription
                          ) ? (
                            <OverlayPremium />
                          ) : null}
                          <ProductSwiper
                            pictures={pictures}
                            needOverlay={needOverlay(
                              product_category,
                              this.props.suscription
                            )}
                          />
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={8}>
                        <Card className={"padding-05em"}>
                          {proposal === null ? 
                          <h3 className={"margin0 marginBottom-1em"}>
                          Propuesta:
                        </h3> : 
                          <h3 className={"margin0 marginBottom-1em"}>
                          Propuesta de cambio:
                        </h3>}
                          <Tag
                            
                            rightIcon={"tick"}
                            className={
                              "tagChecked marginRight-05em marginBottom-05em"
                            }
                          >
                            {this.getArticleSelected(
                              tradeTag,
                              pubSelected,
                              proposal
                            )}
                          </Tag>
                        </Card>
                        <div className={"mt-4"}>
                          <h5 className={"mt-8"}>Atención</h5>
                          <p className={"bp3-form-helper-text mt-2"}>
                            Recuerda que LoCambio.com no se hace responsable por
                            las acciones de terceros que incumplan nuestros
                            términos y condiciones, asimismo, cualquier
                            incumplimiento de los mismos puede conllevar a la
                            suspensión o eliminación de la cuenta en
                            LoCambio.com. Para el uso adecuado de nuestros
                            servicios recomendamos leer{" "}
                            <Link to="/terminos-y-condiciones">
                              los términos y condiciones de LoCambio.com
                            </Link>{" "}
                            y{" "}
                            <Link to="/consejos_de_seguridad">
                              nuestros consejos de seguridad
                            </Link>
                            .
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Callout>
                </div>
                 <Divider className={"marginTop-1em marginBottom-1em"} />
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Callout title={"Confirma el punto de cambio"} />
                  </Col>

                  {pointChange ? (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Callout className={"marginTop-1em"}>
                        <Row>
                          <Col xs={12} sm={4} md={4} lg={4}>
                            <div className={"mapChangePoint"}>
                              <Map
                                className="app mapDetailProduct"
                                {...this.props}
                                google={this.props.google}
                                onClick={this.mapClicked}
                                center={position}
                                zoom={14}
                              >
                                <Marker
                                  icon={{
                                    url: pointerMap,
                                    scaledSize: { width: 64, height: 64 },
                                  }}
                                  position={position}
                                />
                                <InfoWindow onClose={this.onInfoWindowClose}>
                                  <div>
                                    <h1>{address_1}</h1>
                                  </div>
                                </InfoWindow>
                              </Map>
                            </div>
                          </Col>
                          <Col xs={12} sm={8} md={8} lg={8}>
                            <div className={"infoDetailPublication"}>
                              <AddressPubInfo
                                address_1={address_1}
                                address_2={address_2}
                                coordinate={coordinate}
                              />
                            </div>
                            <Divider
                              className={"marginTop-1em marginBottom-1em"}
                            />
                            <h5 className={"margin0 marginBottom-1em"}>
                              Atención
                            </h5>
                            <p className={"bp3-form-helper-text mt-2"}>
                              El punto de cambio lo podrás modificar más adelante en la negociacion por chat privado. Considera la reputación de otros usuarios y siempre elige un sitio público y seguro para realizar el encuentro. Te recomendamos que sigas nuestros <Link to="/consejos_de_seguridad">Consejos de seguridad</Link> para que tu experiencia LoCambio.com sea exitosa.
                            </p>
                          </Col>
                        </Row>
                      </Callout>
                    </Col>
                  ) : (

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Callout className={"marginTop-1em"}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <div className={"infoDetailPublication"}>
                              <p>Punto LoCambio.com Seguro elegido:</p>
                              <p className={"bp3-form-helper-text"}>
                                Dirección
                              </p>
                              <h5>{address_1}</h5>
                              {address_2 ? (
                                <>
                                  <p className={"bp3-form-helper-text"}>
                                    Dirección complementaria
                                  </p>
                                  <h5>{address_2}</h5>
                                </>
                              ) : null}
                              <p className={"bp3-form-helper-text"}>
                                Coordenadas
                              </p>
                              <h5>
                                {`${coordinate.latitude} ${coordinate.longitude}`}{" "}
                              </h5>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <h5>Atención</h5>
                            <p className={"bp3-form-helper-text"}>
                              Recuerda que LoCambio.com no se hace responsable
                              por las acciones de terceros que incumplan
                              nuestros términos y condiciones, asimismo,
                              cualquier incumplimiento de los mismos puede
                              conllevar a la suspensión o eliminación de la
                              cuenta en LoCambio.com. Para el uso adecuado de
                              nuestros servicios recomendamos leer{" "}
                              <Link to="/terminos-y-condiciones">
                                los términos y condiciones de LoCambio.com
                              </Link>{" "}
                              y{" "}
                              <Link to="/consejos_de_seguridad">
                                nuestros consejos de seguridad
                              </Link>
                              .
                            </p>
                          </Col>
                        </Row>
                      </Callout>
                    </Col>
                  )}
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button
                      fill={true}
                      
                      className={"BtnLCYellow marginTop-1em"}
                      loading={savingTransaction}
                      onClick={this.executeTransaction}
                    >
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Footer />
                </Col>
              </Row>
            </div>
          </Container>
        </>
      );
    } else {
      return (
        <ChangeOperationStep3
          nameOwner={nameOwner}
          pubURL={pubURL}
          idChat={idChat}
          product_category={product_category}
          name={name}
          pictures={pictures}
          idTransaction={idTransaction}
        />
      );
    }
  }
}

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
})(withRouter(connect(mapStateToProps, null)(ChangeOperationStep2)));

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

// export default withRouter(connect(mapStateToProps, null)(ChangeOperationStep2));
