import { useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { config } from "../../../system/Config";
import { Toast } from '../../../utility/toast';

export const useContactFormLogic = () => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errorFormValues, setErrorFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  const onChangeFormValue = event => {
    const { name, value } = event.target;
    const updatedFormValues = {...formValues};
    updatedFormValues[name] = value;
    setFormValues(updatedFormValues);
  };

  const validateFormValues = async () => {
    let isValid = true;
    const updatedErrors = { ...errorFormValues };
    for (let key in formValues) {
      try {
        await yup.reach(validationSchema, key).validate(formValues[key]);
        updatedErrors[key] = "";
      } catch (err) {
        isValid = false;
        updatedErrors[key] = (err.errors && err.errors[0]) || "";
      }
    }
    setErrorFormValues(updatedErrors);
    return isValid;
  };

  const onSubmit = async () => {
    if (loading) return;

    const isValid = await validateFormValues();
    if (!isValid) return;

    const data = { ...formValues, last_name: formValues.lastName };
    delete data.lastName;

    setLoading(true);

    try {
      await axios.post(`${config.URL_API}/contact_forms`, data);
      Toast.show({
        message: "Los datos han sido enviados exitosamente",
        intent: "success",
        icon: "tick",
        time: 3000
      });
      setFormValues(initialFormValues);
    } catch (err) {
      Toast.show({
        message: "Lo sentimos, hubo un error.",
        intent: "danger",
        icon: "tick",
        time: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  return { onChangeFormValue, formValues, onSubmit, loading, errorFormValues };
};

const initialFormValues = {
  email: "",
  lastName: "",
  message: "",
  subject: ""
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("El email es requerido")
    .email("El email es invalido"),
  lastName: yup.string().required("El nombre es requerido"),
  message: yup.string().required("El mensaje es requerido"),
  subject: yup.string().required("El asunto es requerido")
});
