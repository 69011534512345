import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalRanking from "./giveRanking/ModalRanking";
//import './../components/giveRanking/giveRanking.scss';

class GiveRanking extends Component {
  
  render(){
    return ( 
      <>
        {this.props.changesUnRanked.map( change => (
          <ModalRanking change={change} changes={this.props.changesUnRanked} />
        ))}
      </>
    )
  }
};

const mapStateToProps = state => {
  return {
    changesUnRanked: state.notifications.allData.changes.trans_interested.unRanked
  }
};

export default connect(mapStateToProps)(GiveRanking);
