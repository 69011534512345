import React from "react";
import HeaderThirdProfile from "./../components/HeaderThirdProfile";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import { getHeader } from "./../system/Sesion";
import { config } from "./../system/Config";
import HeaderProfileSkeleton from "./../components/HeaderProfileSkeleton";

class ThirdProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialLoading: true,
    };
  }

  componentDidMount() {
    const urlUserId = this.props.match.params.id;
    const userId = localStorage.getItem("userId");
    if (urlUserId === userId) {
      this.props.history.push("/profile");
      return;
    }
    this.getUser(urlUserId);
  }

  fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async (userId) => {
    try {
      const response = await this.fetchGetUser(userId);
      this.setUser(response.data.user);
      this.setState({ initialLoading: false });
    } catch (err) {
      console.log('error', err);
    }
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  render() {
    const { user, initialLoading } = this.state;

    if (initialLoading) {
      return <HeaderProfileSkeleton />;
    }

    if (!initialLoading && !user.active) {
      return <Redirect to="/user/inactive/tmp" />;
    }
    return (
      <div className="Profile">
        <HeaderThirdProfile
          user={user}
          updateInfoUser={() => {
            this.getUser(user.id);
          }}
        />
      </div>
    );
  }
}

export default withRouter(ThirdProfile);
