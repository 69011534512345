import React, { Component } from 'react';
import { Button, Callout, Collapse, ControlGroup, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import './comments/Comments.scss';
import axios from 'axios';
import { datelocambio, spaceValidation } from '../utilityFunctions';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withCable from '../hocs/withCable';
import AvatarUser from '../components/AvatarUser';
import { Toast } from '../utility/toast';
import { typeNotifications } from '../const';

class Comments extends Component {
  cableConnection = null;

  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      question: '',
      answers: [],
      allComments: [],
      isOpen: false,
      loadingFirst: false,
      loadingQuestion: false,
      loadingAnswers: [],
      viewAllComents: false,
    };
  }

  TYPE_COMMENT = {
    QUESTION: 'QUESTION',
    ANSWER: 'ANSWER',
  };

  actionCableConnection() {
    this.cableConnection = this.props.cableApp.subscriptions.create(
      { channel: 'CommentChannel', publication_id: this.props.pub.id },
      {
        received: (response) => {
          let comments = [...this.state.comments];
          if (response.father_comment_id === 0) {
            comments.unshift({ father: response, children: null });
          } else {
            comments.forEach((element, index) => {
              if (element.father.id.toString() === response.father_comment_id.toString()) {
                comments[index].children = response;
              }
            });
          }
          this.setState({ comments });
        },
      },
    );
  }

  componentDidMount() {
    this.setState({ loadingFirst: true });
  }

  componentWillReceiveProps() {
    this.getComments();
  }

  getComments = async () => {
    const { data } = await axios.get(
      `${config.URL_API}/find_comments_publications?publication_id=${this.props.pub.id}`,
    );
    this.setState({
      allComments: data,
      loadingFirst: false,
    });
    this.orderedComments(data.reverse());
  };

  orderedComments = (data) => {
    let comments = [];
    let fathers = [];
    data.forEach((comment) => {
      if (comment.father_comment_id === 0) {
        fathers.push(comment);
      }
    });

    fathers.forEach((father) => {
      let children = null;
      data.forEach((comment) => {
        if (comment.father_comment_id === father.id) {
          children = comment;
        }
      });
      comments.push({ father: father, children: children });
    });

    this.setState({ comments: comments });
  };

  inputChangeHandler = (event) =>
    this.setState({ [event.target.id]: event.target.value });

  inputAnswerChangeHandler = (event) => {
    const { answers } = this.state;
    answers[event.target.id] = event.target.value;
    this.setState({ answers });
  };

  addQuestion = () => {
    const dataComment = {
      user_to_id: this.props.userId,
      user_from_id: this.props.pub.owner.id,
      publication_id: this.props.pub.id,
      comment: this.state.question,
      token: this.props.userToken,
      father_comment_id: 0,
    };
    this.setState({ loadingQuestion: true });
    axios
      .post(`${config.URL_API}/comments_publications`, dataComment, {
        headers: getHeader(),
      })
      .then(() => {

        this.showInfoToastSend(typeNotifications.new_question.name);
        this.setState({ question: '', loadingQuestion: false });
        this.props.setCommentsTrue();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loadingQuestion: false });
      });
  };

  addAnswer = (
    father_comment_id,
    idAnswer,
    userToResponse,
    userToResponseId,
  ) => {
    const dataComment = {
      user_to_id: this.props.userId,
      user_from_id: userToResponseId,
      publication_id: this.props.pub.id,
      comment: this.state.answers[idAnswer],
      token: this.props.userToken,
      father_comment_id: father_comment_id,
    };
    let loadingAnswers = this.state.loadingAnswers;
    this.setState({ loadingAnswers });
    axios
      .post(`${config.URL_API}/comments_publications`, dataComment, {
        headers: getHeader(),
      })
      .then((data) => {
        loadingAnswers[idAnswer] = false;
        this.setState({ loadingAnswers });
        this.showInfoToastSend(this.TYPE_COMMENT.ANSWER, userToResponse);
      })
      .catch((error) => {
        loadingAnswers[idAnswer] = false;
        this.setState({ loadingAnswers });
      });
  };

  showInfoToastSend = (typeComment, userToResponse) => {
    let name;
    if (typeComment === typeNotifications.new_question.name) {
      name = `${this.props.pub.owner.name} ${this.props.pub.owner.last_name}`;
    } else if (typeComment === typeNotifications.new_response_comment.name) {
      name = userToResponse;
    }

    const comment = localStorage.userId?.toString() === this.props.pub.owner.id.toString() ? 'respuesta' : 'pregunta';

    Toast.show({
      message: <>Tú {comment} a <b>{name}</b> ha sido enviada.</>,
    });
  };

  sendMailAnswer = (id) =>
    axios.get(`${config.URL_API}/send_mail_new_answer/${id}`, {
      headers: getHeader(),
    });

  switchCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      this.getComments();
    });
  };

  getChildren = (id) => {
    let result = null;
    let comments = [...this.state.allComments];
    comments.forEach((comment) => {
      if (id == comment.father_comment_id) {
        result = comment;
      }
    });
    return result;
  };

  handlerEnterQuestion = (event) => {
    if (event.which === 13 && spaceValidation(this.state.question)) {
      this.addQuestion();
    }
  };

  handlerEnterAnswer = (
    event,
    father_comment_id,
    idAnswer,
    userToResponse,
    userToResponseId,
  ) => {
    if (!this.state.answers[idAnswer]) {
      return;
    }
    if (event.which === 13 && spaceValidation(this.state.answers[idAnswer])) {
      this.addAnswer(
        father_comment_id,
        idAnswer,
        userToResponse,
        userToResponseId,
      );
    }
  };

  switchShowAllComents = () =>
    this.setState({ viewAllComents: !this.state.viewAllComents });

  getImagePicture = () => {
    return {
      picture_users: this.props.pictureData,
      name: this.props.fullName.split(' ')[0],
      last_name: this.props.fullName.split(' ')[1],
    };
  };

  render() {
    if (this.props.isVisible) {
      if (!this.cableConnection) {
        this.actionCableConnection();
      }
    } else {
      if (this.cableConnection) {
        this.cableConnection.unsubscribe();
        this.cableConnection = null;
      }
    }

    const stateComments = [...this.state.comments];
    let comments = [];
    if (this.state.viewAllComents) {
      comments = stateComments;
    } else {
      comments = stateComments.splice(0, 2);
    }

    return (
      <>
        <div className="Comments">
          {!this.props.isVisible ? '000000' : null}
          <div className="toWriteCommentQuestionContainer">

            <h4 className="marginTop-1em marginBottom-1em">
              <Icon className="marginRight-05em" icon="annotation" /> Haz
              una pregunta sobre esta publicación:
            </h4>
            <ControlGroup
              fill
              large
              className="marginBottom-1em"
            >
              <InputGroup
                className="whiteInput InputSize"
                placeholder="Escribir pregunta..."
                value={this.state.question}
                onChange={this.inputChangeHandler}
                id="question"
                onKeyPress={this.handlerEnterQuestion}
                disabled={
                  this.props.pub.owner.id === this.props.userId ||
                  !this.props.isAuthenticated
                }
              />
              <Button
                className="BtnLCYellow btnSizeFix btnPreguntar"
                text="Enviar"
                onClick={this.addQuestion}
                disabled={
                  this.state.question === '' ||
                  this.props.pub.owner.id === this.props.userId ||
                  !this.props.isAuthenticated
                }
                loading={this.state.loadingQuestion}
              />
            </ControlGroup>
            {comments.length > 0 ? (
              this.state.isOpen ? (
                <Button
                  onClick={this.switchCollapse}
                  className="BtnLCPrimaryOutline marginBottom-1em"
                  minimal
                  fill
                  rightIcon="chevron-up"
                  text="Cerrar"
                />
              ) : (
                <Button
                  onClick={this.switchCollapse}
                  className="BtnLCPrimaryOutline marginBottom-1em"
                  fill
                  minimal
                  rightIcon="chevron-down"
                  text="Ver preguntas"
                />
              )
            ) : null}
          </div>

          <Collapse isOpen={this.state.isOpen}>
            <div className="Comments">
              {comments.length > 0 ? (
                <h4 className="margin0 marginTop-1em marginBottom-1em">
                  <Icon className="marginRight-05em" icon="comment" />
                  Preguntas
                </h4>
              ) : null}
              {comments.length > 0 ? (
                <Callout className="CommentsContainer">
                  {comments.length === 0 && this.state.loadingFirst ? (
                    <Spinner
                      className="marginTop-1em marginBottom-1em"
                      size="32"
                    />
                  ) : null}
                  {comments.map((comment, i) => (
                    <div key={`question${i}${this.props.pub.id}`}>
                      <blockquote className="bp3-blockquote marginBottom-1em">
                        <div className="CommentsQuestionContainer">
                          <div className="headerUserCard">
                            <div className="AvatarContainer">
                              <Link
                                to={`/publications/third/${comment.father.user.id}`}
                              >
                                <AvatarUser user={comment.father.user} />
                              </Link>
                            </div>
                            <div className="UserDetailsContainer CommentMessage marginBottom-05em">
                              <h4>
                                <Link
                                  to={`/publications/third/${comment.father.user.id}`}
                                >
                                  {`${comment.father.user.name} ${comment.father.user.last_name} `}
                                </Link>
                                <span className="bp3-form-helper-text marginLeft-05em">
                                  {datelocambio(comment.father.created_at)}
                                </span>
                              </h4>
                              <p className="CommentMessage">
                                {comment.father.comment}
                              </p>
                            </div>
                          </div>
                        </div>

                        {comment.children !== null ? (
                            <div
                              key={`anwer${i}${this.props.pub.id}`}
                              className="CommentsAnswersContainer"
                            >
                              <div className="headerUserCard">
                                <div className="AvatarContainer">
                                  <Link
                                    to={`/publications/third/${comment.children.user.id}`}
                                  >
                                    <AvatarUser user={comment.children.user} />
                                  </Link>
                                </div>
                                <div className="UserDetailsContainer CommentMessage marginBottom-05em">
                                  <h4>
                                    <Link
                                      to={`/publications/third/${comment.children.user.id}`}
                                    >{`${comment.children.user.name} ${comment.children.user.last_name}`}</Link>
                                    <span className="bp3-form-helper-text marginLeft-05em">
                                    {datelocambio(comment.children.created_at)}{' '}
                                  </span>
                                  </h4>
                                  <p className="CommentMessage">
                                    {comment.children.comment}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) :
                          this.props.userId == this.props.pub.owner.id ? (
                            <div className="CommentsAnswersContainer">
                              <div className="headerUserCard">
                                <div className="AvatarContainer">
                                  <AvatarUser user={this.getImagePicture()} />
                                </div>
                                <div className="UserDetailsContainer CommentMessage marginBottom-05em">
                                  <h5>{this.props.fullName}</h5>
                                  <ControlGroup fill>
                                    <InputGroup
                                      className="whiteInput"
                                      placeholder="Responder..."
                                      value={
                                        this.state.answers[
                                          `answer-${this.props.pub.id}-${comment.father.id}`
                                          ]
                                      }
                                      id={`answer-${this.props.pub.id}-${comment.father.id}`}
                                      onChange={this.inputAnswerChangeHandler}
                                      disabled={!this.props.isAuthenticated}
                                      onKeyPress={(event) =>
                                        this.handlerEnterAnswer(
                                          event,
                                          comment.father.id,
                                          `answer-${this.props.pub.id}-${comment.father.id}`,
                                          `${comment.father.user.name} ${comment.father.user.last_name}`,
                                          comment.father.user.id,
                                        )
                                      }
                                    />
                                    <Button
                                      className="BtnLCYellow"
                                      text="Responder"
                                      onClick={() =>
                                        this.addAnswer(
                                          comment.father.id,
                                          `answer-${this.props.pub.id}-${comment.father.id}`,
                                          `${comment.father.user.name} ${comment.father.user.last_name}`,
                                          comment.father.user.id,
                                        )
                                      }
                                      loading={
                                        this.state.loadingAnswers[
                                          `answer-${this.props.pub.id}-${comment.father.id}`
                                          ]
                                      }
                                      disabled={!this.props.isAuthenticated}
                                    />
                                  </ControlGroup>
                                </div>
                              </div>
                            </div>
                          ) : null}
                      </blockquote>
                    </div>
                  ))}
                </Callout>
              ) : <div> 123 123 </div>}
              {this.state.comments.length > 2 ? (
                !this.state.viewAllComents ? (
                  <Button
                    className="BtnLCPrimaryOutline marginTop-05em"
                    text="Ver todas las preguntas anteriores"
                    minimal
                    fill
                    onClick={this.switchShowAllComents}
                  />
                ) : (
                  <Button
                    className="BtnLCPrimaryOutline marginTop-05em"
                    text="Ocultar comentarios"
                    minimal
                    fill
                    icon="chevron-up"
                    onClick={this.switchShowAllComents}
                  />
                )
              ) : null}
            </div>
          </Collapse>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userToken: state.auth.token,
    fullName: state.auth.fullName,
    pictureData: state.auth.imageProfile,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withCable(Comments));
