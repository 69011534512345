import React, { Component } from 'react';
import FormPublication from './newPublication/FormPublication';
import './newPublication/NewPublication.scss';
import Footer from './../components/Footer';
import { Col, Container, Row } from 'react-grid-system';

class NewPublication extends Component {
  
  // esto debe ser un componente responsive para que pueda ser reutilizable en cualquier lugar :)
  state = {
    publication: {
      name: ''
    }
  }
  
   // aqui toda la estructura.
  render() {
    return(
      // aqui va el formulario
      <section className="NewPublicaition">
        <div className="gradientBackground gradientBackgroundAnimated"></div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormPublication/>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </section>
    ) 
  }
}

export default NewPublication;
