import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const ActivePublication = (props) => {
  return (
    <div className="buttonContainer">
      <Button
        className="purpleButton"
        onClick={() => props.activePub(props.idPub)}
        loading={props.updating}
      >
        <Icon className="icon" icon="stop" />
        Desarchivar
      </Button>
    </div>
  );
};

ActivePublication.propTypes = {
  idPub: PropTypes.number.isRequired,
  updating: PropTypes.bool,
  desactivePub: PropTypes.func.isRequired,
};

export default ActivePublication;
