import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setNotifications } from '../store/actions/index';
import HeaderNotificationsAlerts from './headerNavbar/HeaderNotificationsAlerts';
import { Button, Popover, Position, Tooltip, Icon } from '@blueprintjs/core';
import axios from 'axios';
import { getHeader } from '../system/Sesion';
import { config } from '../system/Config';

function NotificationsBubble(props) {
  const [notificationsNotRead, setNotificationsNotRead] = useState(0);

  useEffect(() => {
    axios.get(`${config.URL_API}/notifications`, {
        headers: getHeader(),
      })
      .then(response => props.onSetNotifications(response.data));
  }, []);

  useEffect(() => {
    const { initialLoading, ...notifications } = props.notifications;
    const filteredNotifications = Object.values(notifications)
      .flat()
      .filter(notification => notification.type_notification_id !== 10);
    setNotificationsNotRead(filteredNotifications.filter(notification => !notification.read).length);
  }, [props.notifications]);

  return (
    <Popover
      content={<HeaderNotificationsAlerts notifications={props.notifications} />}
      position={Position.BOTTOM_RIGHT}
      >
      <Tooltip
        content={<p className="margin0">Notificaciones</p>}
        position={Position.BOTTOM}
        >
        <Button className="bp3-minimal" icon={<Icon className="white-text" icon="notifications" iconSize={22} />}>
          {notificationsNotRead !== 0 ? (
            <span className="bubbleAlert">
              {notificationsNotRead}
            </span>
          ) : null}
        </Button>
      </Tooltip>
    </Popover>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onSetNotifications: (data) => dispatch(setNotifications(data)),
  };
};

const mapStateToProps = state => {
  return {
    notifications: state.notification,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NotificationsBubble);
