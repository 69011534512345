import { spaceValidation, addClassElement, removeClassElement, emailValidation, thereNumber, thereSpecialChar, thereUpper } from './../../utilityFunctions';

export const validationEnterprise = (data, withTouched) => {
  let aux = true;
  let messages = []

  // name validation
  if (!spaceValidation(data.name) || data.name.length <= 1) {
    if (!withTouched || touchedControlEnterprise['name']) {
      addClassElement('name', 'errorInput')
      messages.push('Nombre')
      helperTextsEnterprise.name = 'El nombre no puede ser menor de 2 caracateres'
    }
    aux = false;
  } else {
    helperTextsEnterprise.name = ''
    removeClassElement('name', 'errorInput')
  }

  // name validation
  if (!spaceValidation(data.businessName) || data.last_name.length <= 1) {
    if (!withTouched || touchedControlEnterprise['businessName']) {
      addClassElement('businessName', 'errorInput')
      messages.push('Razon Social')
    }
    aux = false;
  } else {
    removeClassElement('businessName', 'errorInput')
  }

  // lastname validation
  if (!spaceValidation(data.last_name)) {
    if (!withTouched || touchedControlEnterprise['last_name']) {
      addClassElement('last_name', 'errorInput')
      messages.push('Apellido')
      helperTextsEnterprise.last_name = 'El apellido no puede ser menor de 2 caracteres'
    }
    aux = false;
  } else {
    helperTextsEnterprise.last_name = ''
    removeClassElement('last_name', 'errorInput')
  }

  // email validation
  if (!spaceValidation(data.email)) {
    if (!withTouched || touchedControlEnterprise['email']) {
      addClassElement('email', 'errorInput')
      messages.push('Email')
    }
    aux = false;
  } else {
    removeClassElement('email', 'errorInput')
  }

  if (spaceValidation(data.email)) {
    if (!emailValidation(data.email)) {
      helperTextsEnterprise.email = 'Debe ser un email valido'
      addClassElement('email', 'errorInput')
      aux = false;
    } else {
      helperTextsEnterprise.email = ''
      removeClassElement('email', 'errorInput')
    }
  }

  // dni validation
  if (!spaceValidation(data.dni)) {
    if (!withTouched || touchedControlEnterprise['dni']) {
      addClassElement('dni', 'errorInput')
      messages.push('RIF')
    }
    aux = false;
  } else {
    removeClassElement('dni', 'errorInput')
  }

  // password validation
  if (!spaceValidation(data.password)) {
    if (!withTouched || touchedControlEnterprise['password']) {
      addClassElement('password', 'errorInput')
      messages.push('Password')
    }
    aux = false;
  } else {
    removeClassElement('password', 'errorInput')
  }

  if (spaceValidation(data.password)) {
    if (!thereNumber(data.password) || !thereUpper(data.password) || !thereSpecialChar(data.password)) {
      addClassElement('password', 'errorInput')
      aux = false;
    } else {
      removeClassElement('password', 'errorInput')
    }
  }

  // repeat password validation
  if (!spaceValidation(data.repeatPassword)) {
    if (!withTouched || touchedControlEnterprise['repeatPassword']) {
      addClassElement('repeatPassword', 'errorInput')
      messages.push('repeatPassword')
    }
    aux = false;
  } else {
    removeClassElement('repeatPassword', 'errorInput')
  }

  if (spaceValidation(data.password) && spaceValidation(data.repeatPassword)) {
    if (data.password !== data.repeatPassword) {
      addClassElement('password', 'errorInput')
      addClassElement('repeatPassword', 'errorInput')
      helperTextsEnterprise.repeatPassword = 'La Contraseña deben ser iguales'
      messages.push('Las contrasenas no coinciden')
      aux = false;
    } else {
      removeClassElement('password', 'errorInput')
      removeClassElement('repeatPassword', 'errorInput')
      helperTextsEnterprise.repeatPassword = ''
    }
  }
  if (!data.termsAceppt) {
    messages.push('Aceptar terminos y condiciones')
    helperTextsEnterprise.termsAceppt = 'Debe aceptar los terminos y condiciones'
    aux = false;
  } else {
    helperTextsEnterprise.termsAceppt = ''
  }
  if (!data.policiesAceppt) {
    messages.push('Aceptar politicas y Privacidad')
    helperTextsEnterprise.policiesAceppt = 'Debe aceptar las politicas y privacidad'
    aux = false;
  } else {
    helperTextsEnterprise.policiesAceppt = ''
  }
  
  return {
    valid: aux,
    messages: messages
  }
}

export const touchedControlEnterprise = {
  name: false,
  last_name: false,
  email: false,
  dni: false,
  businessName: false,
  password: false,
  repeatPassword: false
}

export const helperTextsEnterprise = {
  name: '',
  last_name: '',
  email: '',
  businessName: '',
  dni: '',
  password: '',
  repeatPassword: '',
  termsAceppt: '',
  policiesAceppt: ''
}