import { Tag } from '@blueprintjs/core';
import React, { useCallback, useMemo, useState } from 'react';

import Button from '../../../../components/button';
import Confirmation, { CONFIRMATION_TYPES } from '../confirmation/Confirmation';

const BUTTONS_TYPES = {
  [CONFIRMATION_TYPES.acceptProposal]: {
    text: 'Aceptar',
    className: 'mr-4 ',
    intent: 'success',
    icon: 'tick',
  },
  [CONFIRMATION_TYPES.rejectProposal]: {
    text: 'Rechazar',
    intent: 'danger',
    icon: 'cross',

  },
  [CONFIRMATION_TYPES.cancelProposal]: {
    text: 'Cancelar',
    className: 'mr-4 ',
    intent: 'danger',
    icon: 'cross',
  },
  [CONFIRMATION_TYPES.cancelChange]: {
    text: 'Cancelar',
    intent: 'danger',
    icon: 'cross',
  },
  [CONFIRMATION_TYPES.confirmChange]: {
    text: 'Confirmar',
    className: 'mr-4 ',
    intent: 'success',
    icon: 'tick',
  },
};

const PanelButtons = ({ status, isOwner, transactionId }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');

  const openModal = useCallback((type) => {
    setIsConfirmationOpen(true);
    setConfirmationType(type);
  }, []);

  const buttonsContent = useMemo(() => (
    {
      active: isOwner ?
        [CONFIRMATION_TYPES.acceptProposal, CONFIRMATION_TYPES.rejectProposal] :
        [CONFIRMATION_TYPES.cancelProposal],
      accepted: [CONFIRMATION_TYPES.confirmChange, CONFIRMATION_TYPES.cancelChange],
      confirmed_interested: isOwner ? [CONFIRMATION_TYPES.confirmChange] : [],
      confirmed_owner: isOwner ? [] : [CONFIRMATION_TYPES.confirmChange],
    }
  ), [isOwner]);

  return (
    <section className="panel-buttons">
      <Confirmation
        isOwner={isOwner}
        type={confirmationType}
        isOpen={isConfirmationOpen}
        setIsOpen={setIsConfirmationOpen}
        status={status}
        transactionId={transactionId}
      />
      {
        buttonsContent[status]?.length ?
          buttonsContent[status].map((button) => (
            <Button
              bold
              key={`confirmation-${button}`}
              onClick={() => openModal(button)}
              {...BUTTONS_TYPES[button]}
            />
          )) :
          <Tag className="panel-buttons__waiting">
            Esperando confirmación
          </Tag>
      }
    </section>
  );
};

export default PanelButtons;