import { addClassElement, removeClassElement } from "../../../utilityFunctions";

export const validate = (data, withTouched) => {
  let valid = true;

  if (parseInt(data.balance) < 0){
    if (touchedControl.balance || !withTouched){
      addClassElement('balance', 'errorInput');
      helperText.balance = 'El monto no debe ser menor a 1.000 BsS'
    } else {
      removeClassElement('balance', 'errorInput');
      helperText.balance = '';
    }
    valid = false;
  } else {
    removeClassElement('balance', 'errorInput');
    helperText.balance = '';
  }
  return valid;
}

export const touchedControl = {
  balance: false
}

export const helperText = {
  balance: ''
}