import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './../pages/Login';
import NewPublication from './../pages/NewPublication';
import EditPublication from './../pages/EditPublication';
import Registration from './../pages/Registration';
import DetailProduct from './../pages/DetailProduct';
import SearchList from './../pages/SearchList';
import Logout from './../pages/Logout';
import ForgotMyPassword from './../pages/ForgotMyPassword';
import RecoverPassword from './../pages/RecoverPassword';
import ActivitiesWall from './../pages/ActivitiesWall';
import ConfirmateUser from './../pages/ConfirmateUser';
import Page404 from './../pages/Page404';
import ChangeOperationStep1 from './../pages/ChangeOperationStep1';
import NotificationsPage from './../pages/NotificationsPage';
import AboutLoCambio from './../pages/AboutLoCambio';
import PricesLoCambio from './../pages/PricesLoCambio';
import LoCambioSeguro from './../pages/LoCambioSeguro';
import PublicidadPage from './../pages/PublicidadPage';
import HelpCenter from './../pages/HelpCenter';
import SecurityAdvice from './../pages/SecurityAdvice';
import PrivacyPolicies from './../pages/PrivacyPolicies';
import TermsAndConditions from './../pages/TermsAndConditions';
import Contacts from './../pages/Contacts';
import TransactionCanceled from './../pages/TransactionCanceled';
import ChangeSuccessfull from './../pages/ChangeSuccessfull';
import OwnProfile from './../pages/OwnProfile';
import ThirdProfile from './../pages/ThirdProfile';
import MyPublications from './../pages/MyPublications';
import ConfigurationsPage from './../pages/ConfigurationsPage';
import PaymentMethodContainer from './../pages/PaymentMethodContainer';
import MyPublicationsThird from './../pages//MyPublicationsThird';
import adPositioningForm from '../pages/adPositioningForm.jsx';
import AdsDashboard from '../pages/adDashboard.jsx';
import PublicationCreatedSuccessfully from './../pages/publicationCreatedSuccessfully';
import AdCreatedSuccessfully from './../pages/adCreatedSuccessfully';
import ChangesPage from '../pages/Changes';
import Home from '../pages/home';
import Chat from '../pages/chat';

class Routes extends React.Component {
  render() {
    if (!this.props.sessionVerifed) {
      return null;
    }

    const { isAuthenticated } = this.props;

    return (
      <>
        <Switch>
          <Route path="/login" component={Login} />
          {isAuthenticated && (
            <Route path="/new_publication" exact component={NewPublication} />
          )}
          {!this.props.isAuthenticated && (
            <Route path="/registration" exact component={Registration} />
          )}
          <Route
            path="/publicacion_creada"
            component={PublicationCreatedSuccessfully}
            exact
          />
          <Route
            path="/publicidad_creada"
            component={AdCreatedSuccessfully}
            exact
          />
          <Route path="/home" exact component={Home} />
          <Route
            path="/search_list/:tags/:location/:type/:km/:latitude/:longitude/:sbp/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_price/:location/:type/:km/:latitude/:longitude/:sbp/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_interest/:tag/:location/:latitude/:longitude/:ids/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_by_category/:category/:latitude/:longitude/:query"
            exact
            component={SearchList}
          />
          <Route path="/logout" exact component={Logout} />
          <Route path="/fotgot_password" exact component={ForgotMyPassword} />
          <Route
            path="/recovery_password/:token/:tlc"
            exact
            component={RecoverPassword}
          />
          {isAuthenticated && (
            <Route
              path="/chats/:id?"
              component={Chat}
              exact
            />
          )}
          <Route path="/:name/:id" component={isAuthenticated ? DetailProduct : Login} exact />
          {isAuthenticated && (
            <Route
              path="/change/operation/:name/:id/:id_user/:latitude/:longitude"
              component={ChangeOperationStep1}
              exact
            />
          )}
          {isAuthenticated && (
            <Route path="/wall" component={ActivitiesWall} exact />
          )}
          <Route path="/confirm/user/:token" component={ConfirmateUser} exact />
          {isAuthenticated && (
            <Route path="/requests" component={ChangesPage} exact />
          )}
          {isAuthenticated && (
            <Route path="/profile" component={OwnProfile} exact />
          )}
          <Route path="/profile/third/:id" component={ThirdProfile} exact />
          {isAuthenticated && (
            <Route path="/my-publications" component={MyPublications} exact />
          )}
          {isAuthenticated && (
            <Route path="/ads-dashboard" component={AdsDashboard} exact />
          )}
          {isAuthenticated && (
            <Route
              path="/publications/third/:id"
              component={MyPublicationsThird}
              exact
            />
          )}
          {isAuthenticated && (
            <Route
              path="/transaction_canceled"
              component={TransactionCanceled}
              exact
            />
          )}
          {isAuthenticated && (
            <Route
              path="/configurations"
              component={ConfigurationsPage}
              exact
            />
          )}
          {isAuthenticated && (
            <Route
              path="/change_successfull"
              component={ChangeSuccessfull}
              exact
            />
          )}
          {isAuthenticated && (
            <Route
              path="/payment_method"
              component={PaymentMethodContainer}
              exact
            />
          )}
          {isAuthenticated && (
            <Route path="/adForm" component={adPositioningForm} exact />
          )}
          {isAuthenticated && (
            <Route path="/adForm/edit/:id" component={adPositioningForm} exact />
          )}
          {isAuthenticated && (
            <Route
              path="/edit/publication/:id"
              component={EditPublication}
              exact
            />
          )}
          <Route path="/adDashboard" component={AdsDashboard} exact /> {/* OJO#1 */}
          <Route path="/cambio" component={ChangeSuccessfull} exact />

          {/* Info Pages */}
          <Route path="/about_locambio/" component={AboutLoCambio} exact />
          <Route path="/prices_locambio/" component={PricesLoCambio} exact />
          <Route path="/locambio_seguro/" component={LoCambioSeguro} exact />
          <Route path="/publicidad/" component={PublicidadPage} exact />
          <Route path="/faq/" component={HelpCenter} exact />
          <Route
            path="/consejos_de_seguridad/"
            component={SecurityAdvice}
            exact
          />
          <Route
            path="/politicas-de-privacidad/"
            component={PrivacyPolicies}
            exact
          />
          <Route
            path="/terminos-y-condiciones/"
            component={TermsAndConditions}
            exact
          />
          <Route path="/contactos/" component={Contacts} exact />

          {isAuthenticated && (
            <Route
              path="/notifications_page"
              component={NotificationsPage}
              exact
            />
          )}
          <Route path="/user/inactive/tmp" component={Page404} />

          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </>
    );
  }
}

export default Routes;
