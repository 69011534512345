import React, { useState } from "react";
import {
  Callout,
  FormGroup,
  InputGroup,
  ControlGroup,
  HTMLSelect,
  Divider,
  Button
} from "@blueprintjs/core";
import { Row, Col } from "react-grid-system";
import { numbers } from "./../utilityFunctions";
import {
  countries,
  provincesColombia,
  provincesPeru,
  provincesVenezuela,
  countriesCode
} from "const";

const countriesOptions = countries.map(({ description, value }) => ({
  label: description,
  value
}));
countriesOptions.unshift({ label: "Seleccionar Pais", value: "" });

const renderLabelProvince = value => {
  if (value === countriesCode.VENEZUELA) {
    return "Estado";
  } else if (value === countriesCode.PERU) {
    return "Departamento";
  } else if (value === countriesCode.COLOMBIA) {
    return "Estado";
  }
};

const CompleteIndividualRegistration = props => {
  const [states, setStates] = useState([{ label: `Seleccionar`, value: "" }]);
  const [labelProvince, setLabelProvince] = useState("");

  const onChangeCountry = event => {
    const {
      target: { value }
    } = event;
    let values = [];
    if (value === countriesCode.VENEZUELA) {
      values = provincesVenezuela;
    } else if (value === countriesCode.PERU) {
      values = provincesPeru;
    } else if (value === countriesCode.COLOMBIA) {
      values = provincesColombia;
    }

    const options = values.map(province => ({
      label: province,
      value: province
    }));
    const provinceLabel = renderLabelProvince(value);
    setLabelProvince(provinceLabel);
    options.unshift({
      label: `Seleccionar ${provinceLabel}`,
      value: ""
    });
    setStates(options);
  };
  return (
    <div>
      <Divider className="marginBottom-1em" />
      <Row>
        {!props.hideMessageInformative ? (
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="marginBottom-1em">
              <Callout
                className="marginBottom-1em"
                icon="info-sign"
                title={
                  "Puedes completar la información de tu perfil más tarde o antes de realizar un cambio."
                }
              ></Callout>
            </div>
          </Col>
        ) : null}
        <Col xs={12} sm={12} md={6} lg={6}>
          <FormGroup
            helperText={props.helperText.address}
            label="Dirección"
            labelFor="text-input"
            labelInfo="*"
          >
            <InputGroup
              className={"whiteInput"}
              placeholder="Dirección"
              id="address"
              value={props.data.address}
              onChange={props.changed}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <FormGroup
            helperText={props.helperText.city}
            label="Ciudad"
            labelFor="text-input"
            labelInfo="*"
          >
            <InputGroup
              className={"whiteInput"}
              placeholder="Ciudad"
              id="city"
              value={props.data.city}
              onChange={props.changed}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <FormGroup
            helperText="Helper text with details..."
            label="Código postal"
            helperText={props.helperText.postal_code}
            labelFor="text-input"
            labelInfo="*"
          >
            <InputGroup
              className={"whiteInput"}
              placeholder="Código postal"
              id="postal_code"
              onKeyPress={numbers}
              value={props.data.postal_code}
              onChange={props.changed}
            />
          </FormGroup>
        </Col>
      {/* <Col xs={12} sm={12} md={6} lg={6}>
          <FormGroup label="País" labelFor="text-input" labelInfo="*">
            <HTMLSelect
            value={props.data.country}
              id="country"
              onChange={event => {
                onChangeCountry(event);
                props.changed(event);
              }}
              options={countriesOptions}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <FormGroup
            helperText={props.helperText.state}
            label={labelProvince || "Estado"}
            labelInfo="*"
          >
            <ControlGroup>
              <HTMLSelect
                options={states}

                id="state"
                onChange={props.changed}
                value={props.data.state}
              />
            </ControlGroup>
          </FormGroup>
            </Col> */}
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {props.buttonSend ? (
            <Button
              fill={true}

              className={"BtnLCYellow"}
              onClick={props.send}
              loading={props.saving}
              disabled={!props.formValid}
            >
              Completar registro
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default CompleteIndividualRegistration;
