import React from 'react';
import { Button, Dialog } from '@blueprintjs/core';

class ModalImage extends React.Component {
  render() {
    const { visible } = this.props;
    return (
      <Dialog isOpen={visible}>
        <img src={this.props.src} alt="Modal image"/>
        <div className={'p-4 text-right'}>
          <Button intent={'danger'} icon={'cross'} onClick={this.props.close}>Cerrar</Button>
        </div>
      </Dialog>
    );
  }
}

export default ModalImage;
