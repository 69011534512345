import React, { Component } from 'react';
import { Button, Card, Tab, Tabs } from '@blueprintjs/core';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';
import {
  addClassElement,
  daysInMonth,
  getDateActual,
  getDayActual,
  getMonthActual,
  getYearActual,
  monthsOptions,
  removeClassElement,
  spaceValidation,
} from '../utilityFunctions';
import { config } from '../system/Config';
import IndividualPanel from './registration/IndividualPanel';
import BusinessPanel from './registration/BusinessPanel';
import { Col, Container, Row } from 'react-grid-system';
import {
  helperTextsUser,
  resetTouchedAndTextHelper,
  touchedControlUser,
  validationsUser,
} from './registration/validationsUser';
import {
  helperTextsEnterprise,
  touchedControlEnterprise,
  validationEnterprise,
} from './registration/validationsEnterprise';
import './registration/Registration.scss';
import Footer from './../components/Footer';
import axios from 'axios';
import PoliciesAndPrivacy from './registration/PoliciesAndPrivacy';
import TermsAndCondition from './registration/TermsAndCondition';
import ModalCrop from '../components/ModalCrop';
import { Toast } from '../utility/toast';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.captcha = null;
    this.state = initialState;
  }

  /**
   * It fires when the captcha is ready.
   */
  onLoad = () => this.setState({ captchaReady: true });

  /**
   * It fires when the captcha is verified.
   */
  onVerify = () => this.setState({ verifyCaptcha: true });

  /**
   * It fires when an error occurs in the captcha.
   */
  onErrorCaptcha = () =>
    Toast.show({
      message: 'Por favor verifica tu conexion a Internet',
      intent: 'warning',
      icon: 'error',
    });

  componentDidMount() {
    this.setDatesForm();
  }

  setDatesForm = () => {
    const date_actual = moment(getDateActual())
      .subtract(18, 'year')
      .format('YYYY');
    let years = [];
    years.push({ label: 'Año', value: '', disabled: true, selected: true });
    years.push({ label: date_actual, value: date_actual.toString() });
    for (let i = 0; i < 75; i++) {
      let year = moment(date_actual).subtract(i, 'year').format('YYYY');
      years.push({ label: year, value: year.toString() });
    }
    this.setState({ years: years, months: monthsOptions });
  };

  /**
   * Depending on the active tab, it calls the default registration function.
   * @returns {Function}
   */
  handlerRegistration = () =>
    this.state.navbarTabId === 'individual'
      ? this.registrationUser()
      : this.registrationEnterprise();

  /**
   * Prepares the user registration data to be sent to the server.
   */
  registrationUser = () => {
    const { user, verifyCaptcha } = this.state;
    let validUser = validationsUser(user, false);

    if (validUser.valid) {
      let userAPI = {
        name: user.name,
        last_name: user.last_name,
        email: user.email,
        dni: `${user.typeDNI} - ${user.dni}`,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
        password: user.password,
        address: user.address,
        city: user.city,
        postal_code: user.postal_code,
        state: user.state,
        country: user.country,
      };

      // If I add an image we add the property "picture_data" to the object.
      if (spaceValidation(user.image)) {
        userAPI.picture_data = [user.image];
      }

      // If you have not approved the captcha then we return.
      if (!verifyCaptcha && !process.env.REACT_APP_LOCAL_ENV) {
        Toast.show({
          message: "Debe completar el Captcha",
          intent: "warning",
          icon: "error",
        });
        return;
      }

      if (validUser.valid) {
        ReactPixel.init('317028946379263', {
          autoConfig: true, // set pixel's autoConfig
          debug: false, // enable logs
        });
        ReactPixel.pageView();
        ReactPixel.track('successful_registration', 'Successful registration');
      }

      // Sending the data to the server.
      this.sendDataToApi(userAPI);
    }
  };

  /**
   * Prepares the enterprise registration data to send them to the server.
   */
  registrationEnterprise = () => {
    const { enterprise, verifyCaptcha } = this.state;
    let validEnterprise = validationEnterprise(enterprise, false);

    if (validEnterprise.valid) {
      const enterpriseToSend = {
        name: enterprise.name,
        last_name: enterprise.last_name,
        businessName: enterprise.businessName,
        email: enterprise.email,
        dni: `${enterprise.typeRIF} - ${enterprise.dni}`,
        password: enterprise.password,
        address: enterprise.address,
        city: enterprise.city,
        postal_code: enterprise.postal_code,
        state: enterprise.state,
        country: 'Venezuela',
        admin: false,
      };
      // If I add an image we add the property "picture_data" to the object.
      if (spaceValidation(enterprise.image)) {
        enterpriseToSend.picture_data = [enterprise.image];
      }

      // If you have not approved the captcha then we return.
      if (!verifyCaptcha) {
        Toast.show({
          message: 'Debe completar el Captcha',
          intent: 'warning',
          icon: 'error',
        });
        return;
      }

      // Sending the data to the server.
      this.sendDataToApi(enterpriseToSend);
    }
  };

  /**
   * API request.
   * @param {Object} data
   * @returns {Promise}
   */
  fetchSendData = (data) => axios.post(`${config.URL_API}/users`, data);

  /**
   * Send the registration data to the server.
   * @param {Object} data
   */
  sendDataToApi = async (dataAPI) => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });
    try {
      const request = await this.fetchSendData(dataAPI);
      if (request.status === 201) {
        Toast.show({
          message: "Registro exitoso. Hemos enviado un link de verificación a tu correo",
          intent: "success",
          icon: "saved",
        });
        resetTouchedAndTextHelper();
        this.setState({ user: initialValueUser, day: '', month: '', year: '' });
      } else {
        Toast.show({
          message: 'Lo sentimos, hubo un error',
          intent: 'warning',
          icon: 'error',
        });
      }
    } catch (err) {
      if (err.response.status === 500 && err.response.data) {
        Toast.show({
          message: err.response.data.errors[0],
          intent: 'warning',
          icon: 'error',
        });
      } else {
        Toast.show({
          message: 'Lo sentimos, hubo un error',
          intent: 'warning',
          icon: 'error',
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * Handles the change of the input of the user form and makes some validations.
   * @param {Object} event
   */
  userInputChangeHandler = (event) => {
    let user = { ...this.state.user };
    const identifier = event.target.id; // Unique identifier of the input: is equal to an existing property in the user object of the state.
    user[identifier] =
      event.target.type === 'checkbox' ? !user[identifier] : event.target.value;

    validationsUser(user, true);

    if (touchedControlUser.hasOwnProperty(identifier))
      touchedControlUser[event.target.id] = true;

    /**
     * If the input "repeat password" has the focus then the equality of passwords is validated.
     * @param {String} identifier
     */
    const isFocusRepeatPassword = (identifier) => {
      if (identifier === 'repeatPassword')
        validateEqualityPasswords(
          this.state.user.password,
          this.state.user.repeatPassword
        );
    };

    this.setState({ user }, () => isFocusRepeatPassword(identifier));
  };

  /**
   * Handles the change of the input of the enterprise form and makes some validations.
   * @param {Object} event
   */
  inputEnterpriseChangeHandler = (event) => {
    let { enterprise } = this.state;
    const identifier = event.target.id; // Unique identifier of the input: is equal to an existing property in the user object of the state.
    enterprise[identifier] =
      event.target.type === 'checkbox'
        ? !enterprise[identifier]
        : event.target.value;

    validationEnterprise(enterprise, true);

    if (touchedControlEnterprise.hasOwnProperty(identifier))
      touchedControlEnterprise[event.target.id] = true;

    /**
     * If the input "repeat password" has the focus then the equality of passwords is validated.
     * @param {String} identifier
     */
    let isFocusRepeatPassword = (identifier) => {
      if (identifier === 'repeatPassword')
        validateEqualityPasswords(
          this.state.enterprise.password,
          this.state.enterprise.repeatPassword
        );
    };

    this.setState({ enterprise }, () => isFocusRepeatPassword(identifier));
  };

  /**
   * Manage the change of input values that are NOT within the "user" or "enterprise" property of the state.
   * @param {Object} event
   */
  inputChangeHandler = (event) => {
    const actualDay = getDayActual(); // Current day
    const minYear = getYearActual() - 18; // Minimum year accepted as valid (the user must be of legal age)
    const actualMonth = getMonthActual(); // Current month

    /**
     * Evaluate if a day should be shown as disabled in the Dropdown of days.
     * @param {Number} day
     * @returns {Boolean}
     */
    const disabledDay = (day) => {
      let { year, month } = this.state;
      if (
        parseInt(year) === parseInt(minYear) &&
        parseInt(day) > parseInt(actualDay) &&
        parseInt(month) === parseInt(actualMonth)
      ) {
        return true;
      }
      if (month > actualMonth && year == minYear) {
        return true;
      }
      return false;
    };

    /**
     * Set in the state the days allowed for the form.
     * @param {String} identifier
     */
    let setDaysAllowed = (identifier) => {
      let { year, month, day } = this.state;

      // If the user is manipulating the Dropdown of year or month.
      if (identifier === 'month' || identifier === 'year') {
        // Establishes the allowed months in the state.
        if (spaceValidation(year)) {
          let months = [];
          months.push({
            label: 'Mes',
            value: '',
            disabled: true,
            selected: true,
          });
          for (let i = 1; i < monthsOptions.length; i++) {
            months.push({
              label: monthsOptions[i].label,
              disabled:
                parseInt(year) === parseInt(minYear) &&
                parseInt(monthsOptions[i].value) > parseInt(actualMonth),
              selected: false,
              value: monthsOptions[i].value,
            });
          }
          this.setState({ months });
        }

        // Establishes the days allowed in the state.
        if (spaceValidation(month) && spaceValidation(year)) {
          let days = [
            { label: 'Dia', value: '', disabled: true, selected: true },
          ];

          for (let i = 1; i <= daysInMonth(month, year); i++) {
            days.push({ label: i, value: i, disabled: disabledDay(i) });
          }
          this.setState({ days });
        }
      }

      // If the form is already on the day, month and year then we update the user's date of birth.
      if (
        spaceValidation(year) &&
        spaceValidation(month) &&
        spaceValidation(day)
      ) {
        let { user } = this.state;
        user['date_of_birth'] = `${year}-${month}-${day}`;
        this.setState({ user });
      }
    };

    const identifier = event.target.id; // Unique identifier of the input: is equal to an existing property in the user object of the state.

    this.setState({ [identifier]: event.target.value }, () =>
      setDaysAllowed(identifier)
    );
  };

  onFocusPassword = () => this.setState({ focusPassword: true });

  onBlurPassword = () => this.setState({ focusPassword: false });

  // cuando el crop es completado pinto la imagen en el canvas
  onCropComplete = (canvas) => {
    if (this.state.navbarTabId === 'individual') {
      let user = { ...this.state.user };
      user.image = canvas.toDataURL();
      this.setState({ user, cropping: false, imgCropping: null });
    } else {
      let enterprise = { ...this.state.enterprise };
      enterprise.image = canvas.toDataURL();
      this.setState({ enterprise, cropping: false, imgCropping: null });
    }
  };

  // It fires when the crop is canceled.
  cancelCrop = () => {
    this.setState({ cropping: false, imgCropping: null });
  };

  // Handle for drag and drop
  onDrop = (files) => {
    if (files.length === 1) {
      this.setState({
        cropping: true,
        imgCropping: URL.createObjectURL(files[0]),
        cropCurrent: this.state.crop,
      });
    }
  };

  /**
   * Remove the image of the user or the company.
   * @param {String} typeUser
   */
  deleteImage = (typeUser) => {
    let updateObject = { ...this.state[typeUser] };
    updateObject['image'] = '';
    this.setState({ [typeUser]: updateObject });
  };

  /**
   * Handles the change of the record type tab.
   * @param {String|Number} navbarTabId
   */
  handleTabChange = (navbarTabId) =>
    this.setState({ navbarTabId: navbarTabId, showForm2: false });

  toForm2 = () => this.setState({ showForm2: !this.state.showForm2 });

  /**
   * Returns true or false if the Captchal should be displayed or not. The captcha should only be displayed when the form is complete
   * @returns {Boolean}
   */
  visibleCaptcha = () => {
    let show = false;
    const { navbarTabId, user, enterprise } = this.state;

    switch (navbarTabId) {
      case 'individual':
        if (validationsUser(user, true).valid) {
          show = true;
        }
        break;
      case 'business':
        if (validationEnterprise(enterprise, true).valid) {
          show = true;
        }
        break;
      default:
        return show;
    }
    return show;
  };

  render() {
    const {
      openModalPolicies,
      openModalTerms,
      cropping,
      imgCropping,
      countries,
    } = this.state;

    return (
      <div>
        <ModalCrop
          isOpen={cropping}
          img={imgCropping}
          handleComplete={this.onCropComplete}
          handleCancel={this.cancelCrop}
        />
        {/* <Button onClick={this.resetForm}>resetar</Button> */}
        <section className="Registration">
          <div className="gradientBackground gradientBackgroundAnimated" />
          <div className="registarionContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1 className="textAlignCenter textColorWhite text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl m-8 sm:m-10 md:m-10 lg:m-10 xl:m-10">
                    Completa tu información para registrarte
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <Tabs
                      id="registrationTabs"
                      key="horizontal"
                      animate={true}
                      renderActiveTabPanelOnly={true}
                      onChange={this.handleTabChange}
                      selectedTabId={this.state.navbarTabId}
                    >
                      <Tab
                        id="individual"
                        className="p-2 sm:p-0 md:p-2 lg:p-2 xl:p-2 w-auto sm:w-full md:w-auto lg:w-auto xl:w-auto"
                        title="Registro para personas"
                        panel={
                          <IndividualPanel
                            user={this.state.user}
                            changed={this.userInputChangeHandler}
                            changedGeneral={this.inputChangeHandler}
                            years={this.state.years}
                            months={this.state.months}
                            days={this.state.days}
                            year={this.state.year}
                            month={this.state.month}
                            day={this.state.day}
                            onFocusPassword={this.onFocusPassword}
                            onBlurPassword={this.onBlurPassword}
                            focusPassword={this.state.focusPassword}
                            sendRegistration={this.handlerRegistration}
                            onDrop={this.onDrop}
                            showForm2={this.state.showForm2}
                            toForm2={this.toForm2}
                            loading={this.state.loading}
                            deleteImage={() => this.deleteImage('user')}
                            formValid={
                              validationsUser(this.state.user, true).valid
                            }
                            helperTexts={helperTextsUser}
                            sitekey={config.KEY_CAPTCHA}
                            onVerify={this.onVerify}
                            onError={this.onErrorCaptcha}
                            onOpenPolicies={() =>
                              this.setState({ openModalPolicies: true })
                            }
                            onOpenTerms={() =>
                              this.setState({ openModalTerms: true })
                            }
                            countries={countries}
                          />
                        }
                      />
                      <Tab
                        id="business"
                        title="Registro para empresas"
                        className="p-2 sm:0 md:p-2 lg:p-2 xl:p-2"
                        panel={
                          <BusinessPanel
                            enterprise={this.state.enterprise}
                            onFocusPassword={this.onFocusPassword}
                            onBlurPassword={this.onBlurPassword}
                            focusPassword={this.state.focusPassword}
                            changed={this.inputEnterpriseChangeHandler}
                            rifSelect={this.state.rifSelect}
                            sendRegistration={this.handlerRegistration}
                            changedGeneral={this.inputChangeHandler}
                            onDrop={this.onDrop}
                            showForm2={this.state.showForm2}
                            toForm2={this.toForm2}
                            loading={this.state.loading}
                            deleteImage={() => this.deleteImage('enterprise')}
                            formValid={
                              validationEnterprise(this.state.enterprise, true)
                                .valid
                            }
                            helperTexts={helperTextsEnterprise}
                            sitekey={config.KEY_CAPTCHA}
                            onVerify={this.onVerify}
                            onError={this.onErrorCaptcha}
                            onOpenPolicies={() =>
                              this.setState({ openModalPolicies: true })
                            }
                            onOpenTerms={() =>
                              this.setState({ openModalTerms: true })
                            }
                            countries={countries}
                          />
                        }
                      />
                    </Tabs>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="pl-2 pr-2">
                          <Link to="/home">
                            <Button
                              className="BtnLCPrimaryLink"
                              icon="arrow-left"
                              fill={true}
                            >
                              Volver al Inicio
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Footer />
                </Col>
              </Row>
            </Container>

            <PoliciesAndPrivacy
              isOpen={openModalPolicies}
              onClose={() => this.setState({ openModalPolicies: false })}
            />
            <TermsAndCondition
              isOpen={openModalTerms}
              onClose={() => this.setState({ openModalTerms: false })}
            />
          </div>
        </section>
      </div>
    );
  }
}

const initialValueUser = {
  name: '',
  last_name: '',
  email: '',
  dni: '',
  gender: '',
  date_of_birth: '',
  password: '',
  repeatPassword: '',
  error: '',
  loading: '',
  image: '',
  address: '',
  address_2: '',
  city: '',
  postal_code: '',
  country: '',
  state: '',
  termsAceppt: false,
  policiesAceppt: false,
  typeDNI: 'V',
};

const initialValueEnterprise = {
  name: '',
  last_name: '',
  dni: '',
  password: '',
  repeatPassword: '',
  email: '',
  businessName: '',
  image: '',
  address: '',
  address_2: '',
  city: '',
  postal_code: '',
  country: '',
  state: '',
  termsAceppt: false,
  policiesAceppt: false,
  typeRIF: 'V',
};

// helper
const initialState = {
  user: initialValueUser,
  enterprise: initialValueEnterprise,
  day: '',
  month: '',
  year: '',
  years: [{ label: 'Año', value: '', disabled: true, selected: true }],
  monts: [{ label: 'Mes', value: '', disabled: true, selected: true }],
  days: [{ label: 'Dia', value: '', disabled: true, selected: true }],
  focusPassword: false,
  isOpen: false,
  crop: {
    x: 10,
    y: 10,
    width: 50,
    aspect: 1,
  },
  cropping: false,
  imgCropping: null,
  cropCurrent: null,
  rifSelect: [
    { label: 'V', value: 'V' },
    { label: 'J', value: 'J' },
    { label: 'G', value: 'G' },
  ],
  navbarTabId: 'individual',
  showForm2: false,
  loading: false,
  verifyCaptcha: false,
  captchaReady: false,
  renderedCaptcha: false,
  openModalPolicies: false,
  openModalTerms: false,
  countries: [
    { label: 'Seleccionar', value: '', disabled: true, selected: true },
    { label: 'Colombia', value: 'CO' },
    { label: 'Perú', value: 'PE' },
    { label: 'Venezuela', value: 'VE' },
  ],
};

/**
 * Validate the equality of the passwords and change the class to the input.
 * @param {String} password
 * @param {String} repeatPassword
 */
const validateEqualityPasswords = (password, repeatPassword) => {
  if (password !== repeatPassword) {
    addClassElement('repeatPassword', 'errorInput');
  } else {
    removeClassElement('repeatPassword', 'errorInput');
  }
};

export default Registration;
