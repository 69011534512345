import React from 'react';
import { Callout, FormGroup, InputGroup, Menu } from '@blueprintjs/core';
import Fuse from 'fuse.js';
import { withRouter } from 'react-router-dom';
import ItemCategory from './searchByCategories/ItemCategory';
import { Col, Row } from 'react-grid-system';

class SearchByCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultSearchCategories: [], // aqui van las categorias hijos que se encontraron en la busqueda
      inputSearchCat: ""
    };
  }

  toSearch = category => {
    const { latitude, longitude } = this.props;
    this.props.history.push(
      `/search_list_by_category/${category}/${latitude}/${longitude}/category`
    );
  };

  inputSearchCatChangeHandler = event => {
    this.setState({ inputSearchCat: event.target.value }, () => {
      this.searchCategory(this.state.inputSearchCat);
    });
  };

  searchCategory = value => {
    const { categories } = this.props;
    let allCategories = []; // todas las categorias (padres e hijos)

    // obtengo todas las categorias (padres e hijos)
    categories.forEach(e => {
      allCategories.push(e);
      e.categories.forEach(cat => {
        allCategories.push(cat);
      });
    });
    const options = {
      keys: ["name"],
      threshold: 0
    };
    const fuse = new Fuse(allCategories, options);
    let resultsSearch = fuse.search(value);
    this.setState({ resultSearchCategories: resultsSearch });
  };

  // recibe como parametro el id de una categoria padre
  // retorna true si esa categoria padre tiene a un hijo presente en la busqueda o si la misma esta presente en la busqueda
  isCategoryPresentInSearch = father_id => {
    let aux = false;
    this.state.resultSearchCategories.forEach(category => {
      if (category.father_id === father_id || category.id === father_id) {
        aux = true;
        return;
      }
    });
    return aux;
  };

  render() {
    const { inputSearchCat } = this.state;
    const { categories } = this.props;
    const searchning = inputSearchCat !== ""; // el usuario esta buscando?;
    const categoriesFiltered = categories.filter(category =>
      this.isCategoryPresentInSearch(category.id)
    );
    const printCategories = searchning ? categoriesFiltered : categories;

    return (
      <Menu className={"widthMenu-wrapper"}>
        <Callout
          className="marginBottom-05em"
          title={"Búsqueda por Departamentos"}
        >
          <FormGroup>
            <InputGroup
              value={inputSearchCat}
              onChange={this.inputSearchCatChangeHandler}
              className="marginTop-1em whiteInput"
              placeholder="Búsqueda por Departamentos"
              ref={this.inputSearch}
              autoFocus={true}
            />
          </FormGroup>
        </Callout>
        <Menu.Divider className="marginTop-1em marginBottom-1em" />
        {/* Crear una grilla de 4 row y 4 col donde el primer row (locambio social) es un row col-12 */}
        {!searchning ? (
          <Row>
            {printCategories.map((value, i) => (
              <Col key={`categoryFather${i}`} xs={6} sm={6} md={6} lg={6}>
                <ItemCategory
                  query={inputSearchCat}
                  searchning={searchning}
                  value={value}
                  i={i}
                  toSearch={this.toSearch}
                  filter={this.isCategoryPresentInSearch}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            {printCategories.map((value, i) => (
              <Col key={`categoryFather${i}`} xs={12} sm={12} md={12} lg={12}>
                <ItemCategory
                  query={inputSearchCat}
                  searchning={searchning}
                  value={value}
                  i={i}
                  toSearch={this.toSearch}
                  filter={this.isCategoryPresentInSearch}
                />
              </Col>
            ))}
          </Row>
        )}
      </Menu>
    );
  }
}

export default withRouter(SearchByCategories);
