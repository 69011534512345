import React from "react";
import {
  ControlGroup,
  FormGroup,
  InputGroup,
  Spinner,
  Callout,
  Button
} from "@blueprintjs/core";
import "./addBalance/AddBalance.scss";
import { formatPrice, numbers } from "./../../utilityFunctions";
import CreditCardList from "./CreditCardList";
import PropTypes from "prop-types";
import PayPalButton from "../../components/PaypalButton";
//import { PayPalButton } from "react-paypal-button-v2";

/**
 * Recharge balance.
 * @param {String} value, Value of the input balance.
 * @param {Object} helperText, Values for the help text of the form.
 * @param {Boolean} formValid, The form is valid?
 * @param {Boolean} updating, Is the balance being updated?
 * @param {Array} creditCards, Credit cards.
 * @param {Function} addBalance, It is triggered when the "Reload" or "Pay" button is pressed.
 * @param {Function} changed, Controls the change of form values.
 */
const AddBalance = ({
  value,
  helperText,
  formValid,
  updating,
  creditCards,
  addBalance,
  changed,
  activatePaypal
}) => {
  return (
    <div className={"AddBalance p-4"}>
      <FormGroup helperText={helperText.balance}>
        <ControlGroup fill={true} vertical={false}>
          <InputGroup
            className={"whiteInput"}
            
            placeholder={localStorage.getItem("currency")}
            value={formatPrice(value)}
            onKeyPress={event => {
              numbers(event);
            }}
            name="balance"
            id="balance"
            onChange={event => changed(event)}
          />
          <Button className="BtnLCPrimary" disabled={!formValid} onClick={addBalance} loading={updating} text={'Recargar'} />
        </ControlGroup>
      </FormGroup>
      {!updating ? (
        <>
          <Callout>
          <div className={'p-2'}>
            
            {activatePaypal ? (
              <>
                <h3 className={'mb-2'}>Pago por paypal</h3>
                <PayPalButton
                  amount={value}
                />
              </>
            ): null}
          </div>
        </Callout>
        {/*<Callout>
          <div className={'p-2'}>
            <h3 className={"mb-2"}>Elige la tarjeta para recargar</h3>
            {creditCards.length === 0 ? (
              <Callout className={"noActivityDisplay marginBottom-1em"}>
                <h5 className={"bp3-form-helper-text"}>No hay tarjetas aún</h5>
              </Callout>
            ) : (
                <>
                  <CreditCardList
                    creditCards={creditCards}
                    showPay={true}
                    disabledPay={!formValid}
                    payInProcess={updating}
                    pay={addBalance}
                  />
                </>
              )}
          </div>
        </Callout> */}
        </>
      ) : (
          <Spinner className={"marginTop-1em marginBottom-1em"} size={"32"} />
        )}
    </div>
  );
};

AddBalance.propTypes = {
  helperText: PropTypes.object,
  addBalance: PropTypes.func.isRequired,
  changed: PropTypes.func.isRequired,
  formValid: PropTypes.bool.isRequired,
  updating: PropTypes.bool,
  creditCards: PropTypes.array
};

AddBalance.defaultProps = {
  helperText: {
    balance: ""
  },
  value: "",
  creditCards: []
};

export default AddBalance;
