import React, { Component } from "react";
import {
  Card,
  Elevation,
  Callout,
  Icon,
  Button,
  AnchorButton,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import AdsContent from "./adDashboard/adsContent/adsContent";
//import DetailResultAd from "./adDashboard/detailResultAd/detailResultAd";
import { config } from "./../system/Config";
import { getHeader } from "./../system/Sesion";
import axios from "axios";

class AdsDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actives: [],
      inactives: [],
      activeCampaña: true,
      loadingAds: true,
      userBalance: 0
    };
  }

  componentWillMount() {
    this.initAds();
  }

  initAds = () => {
    this.setState({ loadingAds: true});
    // Get Active Campaign
    axios
      .get(`${config.URL_API}/ad/active_stats?user_id=${localStorage.getItem("userId")}&status=1`, {
        headers: getHeader(),
      })
      .then((response) => {
        this.setState({ loadingAds: false, actives: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
    
    // Get Inactive Campaign
    axios
      .get(`${config.URL_API}/ad/active_stats?user_id=${localStorage.getItem("userId")}&status=0`, {
        headers: getHeader(),
      })
      .then((response) => {
        this.setState({ loadingAds: false, inactives: response.data });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get Balance for Validations
    axios
      .get(`${config.URL_API}/user/get_balance/`, {
        headers: getHeader(),
      })
      .then((response) => {
        this.setState({ userBalance: parseFloat(response.data) });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  

  render() {
    const { activeCampaña, actives, inactives, loadingAds, userBalance } = this.state;
    return (
      <>
        <div className={"pt-32 sm:pt-24 md:pt-16 lg:pt-16 xl:pt-16"}>
          <Container>
            <Row>
              <Col xs={12} sm={6} md={3} lg={3}>
                <Card elevation={Elevation.ONE}>
                  <h3 className={"text-base mb-4"}>Campañas</h3>
                  <div
                    className={"mb-2 bg-grey-lighter btn-arrowAnchor"}
                    onClick={() => this.setState({ activeCampaña: true })}
                  >
                    <AnchorButton fill={true} minimal={true}>
                      <div class="flex items-stretch">
                        <div class="self-center flex-1">
                          <div class="flex justify-between">
                            <div class="flex-grow text-left py-2">Campañas Activas</div>
                            <div class="text-center ml-4 bg-grey-lighter p-2 rounded">
                              <Icon icon={"chevron-right"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </AnchorButton>
                  </div>
                  <div
                    className={"bg-grey-lighter"}
                    onClick={() => this.setState({ activeCampaña: false })}
                  >
                    <AnchorButton fill={true} minimal={true}>
                      <div class="flex items-stretch">
                        <div class="self-center flex-1">
                          <div class="flex justify-between">
                            <div class="flex-grow text-left py-2">Campañas Finalizadas</div>
                            <div class="text-center ml-4 bg-grey-lighter p-2 rounded">
                              <Icon icon={"chevron-right"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </AnchorButton>
                  </div>
                </Card>
              </Col>
              <AdsContent
                loadingAds={loadingAds}
                activeCampaña={activeCampaña}
                actives={actives}
                inactives={inactives}
                userBalance={userBalance}
                initAds={this.initAds}
              />
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default AdsDashboard;