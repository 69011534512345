import React, { Component } from "react";
import { Card, Elevation } from "@blueprintjs/core";
import { Row, Col, Container } from "react-grid-system";
import "./pricesLoCambio/PricesLoCambio.scss";
import Footer from "./../components/Footer";
import LinksInfoPages from "./../components/LinksInfoPages";

class PricesLoCambio extends Component {
  render() {
    return (
      <>
        <div className={"PricesLoCambio"}>
          <div className={"pt-32 sm:pt-12 md:pt-6 lg:pt-6 xl:pt-6"}>
            <Container>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                  <Card elevation={Elevation.ONE}>
                    <h2 className={"margin0 marginBottom-1em"}>
                      Precios LoCambio.com
                    </h2>
                    <p className={"margin0"}>Texto a desarrollar</p>
                  </Card>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <LinksInfoPages />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default PricesLoCambio;
