import React from 'react';
import PropTypes from 'prop-types';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

const settings = {
  modules: [Pagination, Navigation, Autoplay],
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  pagination: { type: 'bullets', clickable: true },
  navigation: true,
  loop: true,
  initialSlide: 0,
};

const Slider = ({ images }) => (
  <div className="slider section">
    <Swiper {...settings}>
      {images.map((image) => (
          <SwiperSlide key={`slider-${image}`}>
            <img alt="main-slider" src={image} />
          </SwiperSlide>
        ),
      )}
    </Swiper>
  </div>
);

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

Slider.defaultProps = {
  images: [
    'https://storage.googleapis.com/locambio-admin/banner/banner-1.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-2.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-3.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-4.jpg',
  ],
};

export default Slider;