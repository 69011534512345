import * as actionTypes from "./actionTypes";

export const setChange = (id) => {
    return {
        type: actionTypes.SET_CHANGE_SUCCESS,
        id: id
    }
}

export const resetChange = () => {
    return {
        type: actionTypes.RESET_CHANGE_SUCCESS
    }
};