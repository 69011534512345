import React from 'react';
import { Icon, Tag } from '@blueprintjs/core';
import Breadcrumb from '../breadcrumb/Breadcrumb';

const CategoriesOfInterest = props => {
  const { categories } = props;
  if (!categories) return null;

  return (
    <React.Fragment>
      {categories.split('|')
        .slice(0, 5)
        .map((cat, i) => (
          <Breadcrumb className="tagYellow mb-2 mr-2" data={cat.split('/')} />
        ))}
      {categories.split(',').length > 5 &&
      <Tag className={'tagYellow marginRight-05em marginBottom-05'}><Icon icon="more" /></Tag>}
    </React.Fragment>
  );
};

export default CategoriesOfInterest;
