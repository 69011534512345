import React from 'react';
import { Button, ButtonGroup, Callout, Divider, InputGroup, Slider } from '@blueprintjs/core';
import ResumeFilter from './searchListFilters/ResumeFilter';
import { calculateSize } from './searchListFilters/aux';
import './searchListFilters/SearchListFilter.scss';
import SelectSuggest from './StateListSuggest';

const SearchListFilters = (props) => {
  const { filterInitialValues, filterValues, publications } = props;
  const stepSizeDistance = calculateSize(
    filterInitialValues.distanceMin + filterInitialValues.distanceMax,
    5,
  );

  // si la distancia mayor es menor que 1km o la distance menor y mayor son iguales no habilitare este filtro.
  const disabledDistance =
    filterInitialValues.distanceMax < 1 ||
    filterInitialValues.distanceMax === filterInitialValues.distanceMin;
  const fieldsCategories = [];
  publications.map((publication) => {
    const { publications_fields } = publication;
    publications_fields.map((field) => {
      const indexFieldCategory = fieldsCategories.findIndex(
        (e) => e.id === field.id,
      );
      if (indexFieldCategory === -1) {
        fieldsCategories.push({
          label: field.field_category.label,
          name: field.field_category.name,
          id: field.id,
          values: field.value,
        });
      } else {
        fieldsCategories[
          indexFieldCategory
          ].values = `${fieldsCategories[indexFieldCategory].values},${field.value}`;
      }
    });
  });
  return (
    <>
			<div className="SearchListFilter">
				<Callout>
					{/* <h4 className={"margin0 marginBottom-05em"}>Filtros de búsqueda</h4> */}
          <Button
            minimal
            fill
            className="BtnLCPrimaryOutline"
            onClick={props.resetFilter}
          >
						Limpiar filtros de búsqueda
					</Button>
				</Callout>
				<Divider className="marginTop-05em marginBottom-05em" />
				<Callout>
					<ResumeFilter
            filterValues={filterValues}
            disabledDistance={disabledDistance}
          />
				</Callout>
				<Divider />
        {!disabledDistance ? (
          <Callout>
						<div className="marginBottom-1em ">
							<>
								<h4 className="margin0 marginBottom-05em">Se encuentra a</h4>
								<Slider
                  disabled={disabledDistance}
                  min={
                    filterInitialValues.distanceMin ===
                    filterInitialValues.distanceMax
                      ? 0
                      : filterInitialValues.distanceMin
                  }
                  max={filterInitialValues.distanceMax}
                  labelStepSize={stepSizeDistance}
                  value={filterValues.distance}
                  onRelease={props.distanceChangeHandler}
                />
							</>
						</div>
					</Callout>
        ) : null}
        <Divider className="marginTop-05em marginBottom-05em" />
				<Callout>
					<div className="marginBottom-1em">
						<ButtonGroup minimal fill vertical>
							<div>
								<h4 className="margin0 marginBottom-05em">
									Condicion del articulo
								</h4>
								<Button
                  className={filterValues.status === 'Nuevo' ? 'current' : null}
                  onClick={() =>
                    props.statusConditionChangeHandler('status', 'Nuevo')
                  }
                >
									Articulos Nuevos
								</Button>
								<Button
                  className={filterValues.status === 'Usado' ? 'current' : null}
                  onClick={() =>
                    props.statusConditionChangeHandler('status', 'Usado')
                  }
                >
									Articulos Usados
								</Button>
							</div>
              {filterValues.status === 'Usado' ? (
                <div className="mt-4">
									<h4 className="margin0 marginBottom-05em">
										Condicion del Articulo
									</h4>
									<Button
                    className={
                      filterValues.condition === 'Excelente' ? 'current' : null
                    }
                    onClick={() =>
                      props.statusConditionChangeHandler(
                        'condition',
                        'Excelente',
                      )
                    }
                  >
										Excelente
									</Button>
									<Button
                    className={
                      filterValues.condition === 'Bueno' ? 'current' : null
                    }
                    onClick={() =>
                      props.statusConditionChangeHandler('condition', 'Bueno')
                    }
                  >
										Bueno
									</Button>
									<Button
                    className={
                      filterValues.condition === 'Con Detalles'
                        ? 'current'
                        : null
                    }
                    onClick={() =>
                      props.statusConditionChangeHandler(
                        'condition',
                        'Con Detalles',
                      )
                    }
                  >
										Con Detalles
									</Button>
									<Button
                    className={
                      filterValues.condition === 'Para Repuesto'
                        ? 'current'
                        : null
                    }
                    onClick={() =>
                      props.statusConditionChangeHandler(
                        'condition',
                        'Para Repuesto',
                      )
                    }
                  >
										Para Respuesto
									</Button>
								</div>
              ) : null}
						</ButtonGroup>
					</div>
				</Callout>
				<Divider className="marginTop-05em marginBottom-05em" />
				<Callout>
					<div className="marginBottom-1em">
						<ButtonGroup minimal fill vertical>
							<h4 className="margin0 marginBottom-05em">Ubicación</h4>
							<SelectSuggest
                filterValues={filterValues}
                locationChangeHandler={props.locationChangeHandler}
              />
						</ButtonGroup>
					</div>
				</Callout>
			</div>
		</>
  );
};

export default SearchListFilters;
