import React from 'react';
import { Button, Callout, Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import PropTypes from 'prop-types';
import axios from 'axios';
import './report/Report.scss';
import { Toast } from '../utility/toast';

class Report extends React.Component {
  state = {
    typeReport: '',
    sendingReport: false,
    reported: false, // el reporte ha sido enviado?
  };

  radioChangeHandler = event =>
    this.setState({ typeReport: event.target.value });

  fetchSendReport = data =>
    axios.post(`${config.URL_API}/reports`, data, {
      headers: getHeader(),
    });

  sendReport = async () => {
    this.setState({ sendingReport: true });

    const data = {
      type_report: this.state.typeReport,
      publication_id: this.props.id,
      token: this.props.token,
      user_id: this.props.userId,
    };

    try {
      await this.fetchSendReport(data);
      Toast.show({
        message: 'El reporte ha sido enviado exitosamente',
        icon: 'tick',
        intent: 'success',
      });
      this.setState({ reported: true, sendingReport: false });
      this.props.onClose();
    } catch(err) {
      Toast.show({
        show: true,
        message: 'Lo sentimos, hubo un error',
        icon: 'cross',
        intent: 'danger',
      });
      this.setState({ sendingReport: false });
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, onClose, name } = this.props;
    const { typeReport, sendingReport, reported } = this.state;
    return (
      <>
        <div className="DangerDialog">
          <Dialog
            className="paddingBottom-0 DangerDialog"
            isOpen={isOpen}
            icon="ban-circle"
            title="Denunciar la publicación"
            onClose={onClose}
          >
            <div className="padding-1em">
              <Callout
                className="marginBottom-1em padding-1em"
                icon="clean"
                intent="warning"
                title={
                  'Ayúdanos a mantener limpia la plataforma haciendo uso de las buenas practicas'
                }
              />
              <Callout title="Denunciar la publicación" intent="danger">
                <RadioGroup
                  className="marginTop-1em"
                  selectedValue={typeReport}
                  onChange={this.radioChangeHandler}
                >
                  <Radio

                    label={`La publicacion ${name} esta como finalizada`}
                    value="0"
                  />
                  <Radio

                    label={`Contenido inapropiado en la publicacion ${name}`}
                    value="2"
                  />
                  <Radio

                    label={`Denunciar la publicacion ${name} como Fraudulenta`}
                    value="3"
                  />
                </RadioGroup>
              </Callout>
              <Button
                fill
                intent="danger"
                className="margin0 marginTop-1em"
                loading={sendingReport}
                onClick={this.sendReport}
                disabled={typeReport === '' || reported}
              >
                Enviar denuncia
              </Button>
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  };
};

Report.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  owner: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Report);
