import React, { Component } from 'react';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import { getHeader } from './../system/Sesion';
import { config } from "./../system/Config";
import * as actions from './../store/actions/index';
import axios from 'axios';
import { Row, Col } from 'react-grid-system';

class FollowAction extends Component {

  state = {
    isFollowing: null // el usuario logueaddo esta siguiendo la usuario de la info?
  }

  componentDidMount() {
    const userId = this.props.userId // usuario de la info (no el que esta logueado)
    let isFollowing = false
    this.props.followingUserLogued.forEach(user => {
      if (user.folowed_user.id === userId) {
        this.setState({ isFollowing: true })
        isFollowing = true
        return
      }
    })

    if (!isFollowing) {
      this.setState({ isFollowing: false })
    }
  }

  // seguir
  follow = () => {
    this.setState({ isFollowing: true })

    const dataAPI = {
      user_folower_id: this.props.userLogguedId,
      user_folowed_id: this.props.userId
    }

    axios.post(`${config.URL_API}/followers?`, dataAPI, { headers: getHeader() })
      .then((data) => {
        this.props.onGetFollow(this.props.userLogguedId)
        this.props.onGetUsersFollow(this.props.userLogguedId)
        this.props.updateInfoUser()
      })
      .catch((error) => {
        this.setState({ isFollowing: false })
      })
  }

  // {user_folower_id: this.props.userLogguedId, user_folowed_id: this.props.userId},
  // dejar de seguir
  unFollow = () => {
    this.setState({ isFollowing: false })

    const dataAPI = {
      user_folower_id: this.props.userLogguedId,
      user_folowed_id: this.props.userId
    }

    axios.delete(`${config.URL_API}/unfollow?user_folower_id=${this.props.userLogguedId}&user_folowed_id=${this.props.userId}`, { headers: getHeader() })
      .then((data) => {
        this.props.onGetFollow(this.props.userLogguedId)
        this.props.onGetUsersFollow(this.props.userLogguedId)
        this.props.updateInfoUser()
      })
      .catch((error) => {
        this.setState({ isFollowing: true })
      })
  }

  render() {
    let content = null;

    if (this.props.userId !== this.props.userLogguedId && this.state.isFollowing !== null) {
      content = (
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {this.state.isFollowing ?
                <Button className={'BtnLCPrimary p-2'} fill={true} icon={'following'} onClick={this.unFollow}>Dejar de seguir</Button>
                :
                <Button className={'BtnLCPrimary'} fill={true} icon={'follower'} onClick={this.follow}>Seguir</Button>
              }
            </Col>
          </Row>
        </>
      )
    }

    return (
      content
    )
  }
}

const mapStateToProps = state => {
  return {
    userLogguedId: state.auth.userId,
    followersUserLogued: state.auth.usersFollowers,
    followingUserLogued: state.auth.usersFollowing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUsersFollow: (user_id) => dispatch(actions.getUsersFollow(user_id)),
    onGetFollow: (user_id) => dispatch(actions.getFollows(user_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowAction);
