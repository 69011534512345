import React from 'react';
import Faq from './components/Faq';
import Video from './components/Video';
import Footer from './components/Footer';
import Slider from './components/Slider';
import Categories from './components/Categories';
import ServicesLC from './components/ServicesLC';

import './home.scss';

const servicesLCProps = {
  type: 'primary',
  title: {
    normal: 'Pregunta por los servicios de',
    highlight: 'LoCambio.com',
  },
  services: [
    {
      status: 'preview',
      title: 'Cambia artículos de alto valor',
      description:
        'Cambia artículos como vehículos e inmuebles, y recibe beneficios como: Contrato Digital, Asesoría Legal y mucho más',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-1.jpg',
      route: '',
    },
    {
      status: 'unavailable',
      title: 'Cambia seguro',
      description:
        'Usa nuestras instalaciones para cambiar de manera fácil, cómoda y segura',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-2.jpg',
      route: '',
    },
    {
      status: 'unavailable',
      title: 'Inspira confianza',
      description:
        'Solicita una verificación de identidad para aumentar la confianza en los usuarios interesados en contactarte',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-3.jpg',
      route: '',
    },
  ],
};

const servicesProps = {
  type: 'primary',
  title: {
    normal: 'Categorias de servicios',
    highlight: 'recomendadas',
  },
  categories: [
    {
      name: 'Mensajero',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-1.jpg',
    },
    {
      name: 'Consulta Medica a domicilio',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-2.jpg',
    },
    {
      name: 'Asesoría mercantil',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-3.jpg',
    },
    {
      name: 'Terapia de parejas',
      image:
        'https://storage.googleapis.com/locambio-admin/categories/category-4.jpg',
    },
  ],
};

const Home = () => {
  return (
    <div className="home">
      <Slider />
      <ServicesLC {...servicesLCProps} />
      <Categories />
      <Categories {...servicesProps} />
      <Video />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
