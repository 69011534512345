import React from "react";
import { Tab, Tabs, Tag } from "@blueprintjs/core";

const MyPublicationsSkeleton = (props) => {
  return (
    <div className={"customWrapper"}>
      <div className={"MyPublications"}>
        <Tabs
          id="TabsExample"
          vertical={true}
          selectedTabId="ActivatedPublications"
        >
          <Tab
            id="ActivatedPublications"
            panel={
              <div
                style={{ width: "100%", height: "100%" }}
                className="bp3-skeleton"
              ></div>
            }
          >
            <p
              className={
                "margin0 marginTop-05em marginBottom-05em bp3-skeleton"
              }
            >
              <Tag minimal={true} className={"tagYellow marginRight-1em"}>
                XX
              </Tag>
              Activas
            </p>
          </Tab>

          <Tab
            id="DesactivatedPublications"
            title=""
            panel={
              <div
                style={{ width: "100%", height: "100%" }}
                className="bp3-skeleton"
              ></div>
            }
          >
            <p
              className={
                "margin0 marginTop-05em marginBottom-05em bp3-skeleton"
              }
            >
              <Tag minimal={true} className={"tagYellow marginRight-1em"}>
                XX
              </Tag>
              Inactivas
            </p>
          </Tab>

          <Tab
            id="SuccessfulChanges"
            panel={
              <div
                style={{ width: "100%", height: "100%" }}
                className="bp3-skeleton"
              ></div>
            }
          >
            <p
              className={
                "margin0 marginTop-05em marginBottom-05em bp3-skeleton"
              }
            >
              <Tag minimal={true} className={"tagYellow marginRight-1em"}>
                XX
              </Tag>
              Finalizadas
            </p>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default MyPublicationsSkeleton;
