import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import { initialValue } from '../utilityFunctions';
import './headerProfile/HeaderProfile.scss';

class AvatarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
    };

    this.inputFileRef = React.createRef();
    this.onBtnClick = this.props.handleBtnClick.bind(this);
  }

  render() {
    const { user, croppedImageUrl, editable } = this.props;

    return (
      <>
        <figure>
          {!croppedImageUrl ? (
            <>
              <div
                className={
                  editable ? 'initialsName' : 'initialsName noBlur'
                }
                style={{
                  display: this.props.showAvatarInitials(user)
                    ? 'flex'
                    : 'none',
                }}
              >
                <p className="margin0">
                  <span>{user.name ? initialValue(user.name) : null}</span>
                  <span>
                    {user.last_name ? initialValue(user.last_name) : null}
                  </span>
                </p>
              </div>
              <div className="avatarContainer">
                <div className="dragDropDisclaimerContenainer">
                  <img
                    id="user-profile-image"
                    alt="profile"
                    src={user.avatar}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="dragDropDisclaimerContenainer">
                <img id="user-profile-image" src={croppedImageUrl} />
              </div>
            </>
          )}
          {editable && (
            <div className="editButton">
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={this.inputFileRef}
                onChange={(e) => {
                  this.props.onSelectFile(e, 'avatar');
                }}
              />
              <Button icon="edit" onClick={this.onBtnClick} />
              <Button
                minimal
                intent="danger"
                onClick={this.props.deleteImgProfile}
                icon="trash"
              />
            </div>
          )}
        </figure>
      </>
    );
  }
}

AvatarProfile.defaultProps = {
  editable: true,
};

export default AvatarProfile;
