import { spaceValidation } from "../../utilityFunctions";

export const userDataComplete = user => {
  let complete = true;
  if (!spaceValidation(user.address)) {
    complete = false;
  } else if (!spaceValidation(user.city)) {
    complete = false;
  } else if (!spaceValidation(user.postal_code)) {
    complete = false;
  } else if (!spaceValidation(user.state)) {
    complete = false;
  }
  return complete;
}