import * as actionTypes from './actionTypes';

export const setPosition = (latitude, longitude) => {
  localStorage.setItem('latitude', latitude)
  localStorage.setItem('longitude', longitude)
  return {
    type: actionTypes.SET_POSITION,
    latitude: latitude,
    longitude: longitude
  }
}

export const getPosition = () => {
  return dispatch => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
    dispatch(setPosition("10.518528", "-66.9597696"))
    function showPosition(position) {
      dispatch(setPosition(position.coords.latitude, position.coords.longitude))
    }
  }
}

export const checkPosition = () => {
  return dispatch => {
    if (localStorage.getItem('latitude') && localStorage.getItem('longitude')) {
      dispatch(setPosition(localStorage.getItem('latitude'), localStorage.getItem('longitude')))
    }
  }
}
