import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Card, Elevation } from '@blueprintjs/core';
import UserStatsInfo from './UserStatsInfo';
import RankingGraph from './../components/RankingGraph';
import RankingStar from './../components/RankingStar';
import { dateMonthString, initialValue } from './../utilityFunctions';
import FollowAction from './../components/FollowAction';
import './headerThirdProfile/HeaderThirdProfile.scss';

const HeaderThirdProfile = (props) => {
  const { user } = props;
  let publicationsActive = [];

  user.publication_owners.forEach((item) => {
    if (item.publication_status == "Activo") {
      publicationsActive.push(item);
    }
  });

  return (
    <div className={"HeaderThirdProfile"}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={"OverlapContent"}>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <div className="backgroundImageAvatar">
                    <figure>
                      <div className="backgroundImage">
                        {/* <div id={`image-cover-user`}>
                            <img
                              id={`user-cover-image`}
                              src={
                                user.picture_covers.length > 0
                                  ? `${config.URL_IMAGE}/covers/${user.picture_covers[0].id}/original/${user.picture_covers[0].picture_file_name}`
                                  : null
                              }
                              alt=""
                            />
                          </div> */}
                      </div>
                    </figure>
                  </div>
                  {/* <div className={"gradientCoverImageProfile"}></div> */}

                  <Card
                    elevation={Elevation.ONE}
                    className={"AvatarProfileContainer -mt-12"}
                  >
                    <div className="User-Avatar-Name">
                      <figure>
                        <div className="avatarContainer">
                          <div
                            className="dragDropDisclaimerContenainer"
                            style={{
                              display:
                                user.picture_users.length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <img
                              src={
                                user.picture_users.length > 0
                                  ? user.picture_users[0]?.url
                                  : null
                              }
                            />
                          </div>
                          <div
                            className="AvatarInitials"
                            style={{
                              display:
                                user.picture_users.length === 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p>
                              <span>
                                {user.name ? initialValue(user.name) : null}
                              </span>
                              <span>
                                {user.last_name
                                  ? initialValue(user.last_name)
                                  : null}
                              </span>
                            </p>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                  <Card
                    elevation={Elevation.ONE}
                    className={"mt-3 AvatarProfileInfoContainer"}
                  >
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={8}>
                            <div className={"AvatarProfileInfo"}>
                              <div className={"AvatarProfileName"}>
                                <h1 className="margin0 marginBottom-05em">{`${user.name} ${user.last_name}`}</h1>
                                <p className="margin0 marginBottom-05em capitalize Gilroy600">
                                  {user.city}
                                </p>
                                <p className="margin0 Gilroy600">
                                  Se unió el{" "}
                                  {dateMonthString(user.registration_date)}
                                </p>
                                <div className="md:mt-8">
                                  <Row>
                                    <Col xs={12} sm={12} md={12} lg={8}>
                                      <div className="marginTop-1em">
                                        <FollowAction
                                          updateInfoUser={props.updateInfoUser}
                                          userId={user.id}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={4}>
                            <div className="mt-4 md:mt-8">
                              <UserStatsInfo user={user} />
                              <RankingGraph user={user} />
                              <RankingStar user={user} />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderThirdProfile;
