import React from 'react';
import { Button, Card, Dialog, Divider, FormGroup, NumericInput } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import ProductSwiper from '../../../components/productSwiper/ProductSwiper';
import axios from 'axios';
import { getHeader } from '../../../system/Sesion';
import { config } from '../../../system/Config';

/**
 * @param {Object} data, Ad Data
 * @param {Function} onViewResult, It is triggered when the "see results" button is pressed.
 */

class ItemAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      dialogShow: '',
      userBalance: props.userBalance,
      balance: parseFloat(props.data.balance),
      validateBalance: false,
    };

  }

  handleOpen = (show) => this.setState({ isOpen: true, dialogShow: show });

  handleClose = () => this.setState({ isOpen: false });

  deactivateCampaign = () => {
    const data = {
      id: this.props.data.id,
    };

    axios.post(`${config.URL_API}/ads/finalize_ad`, data, { headers: getHeader() })
      .then(
        (response) => {
          this.handleClose();
          this.props.initAds();
        },
      );
  };

  editCampaign = () => {
    const data = { new_balance: this.state.balance };
    axios.put(`${config.URL_API}/ads/${this.props.data.id}`, data, { headers: getHeader() })
      .then(
        (response) => {
          this.handleClose();
          this.props.initAds();
        },
      );
  };

  handleValueChange = async (value) => {
    this.setState({ balance: value });
    const valid = !(value > this.state.userBalance || value <= 0);

    if (valid) {
      this.setState({ validateBalance: false });
    } else {
      this.setState({ validateBalance: true });
    }
  };

  render() {
    const { data } = this.props;
    const { dialogShow, validateBalance } = this.state;
    return (
      <div>
        <Card className={'mb-1'}>
          <Divider>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2>Campaña de: {data.publication_name}</h2>
              </Col>
            </Row>
            <div className="mt-2 mb-2">
              <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ProductSwiper
                      pictures={data.pictures}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    {
                      data.status === 'Activa' &&
                      <div>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <Button
                              icon="edit"
                              onClick={() => this.handleOpen('edit')}
                              className="mt-2 min-w-8 bp3-intent-success"
                            >Editar</Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <Button
                              icon="delete"
                              onClick={() => this.handleOpen('delete')}
                              className="mt-2 min-w-8 bp3-intent-danger"
                            >Finalizar</Button>
                          </Col>
                        </Row>
                      </div>
                    }
                  </Col>
              </Row>
            </div>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <p className={'bp3-form-helper-text'}>
                  Fecha de Inicio:
                </p>
              </Col>
              <Col>
                <h3>
                  {data.start_date}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <p className={'bp3-form-helper-text'}>
                  Fecha de Culminacion:
                </p>
              </Col>
              <Col>
                <h3>
                  {data.end_date}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <p className={'bp3-form-helper-text'}>
                  Balance Consumido:
                </p>
              </Col>
              <Col>
                <h3>
                  {data.consumed} $
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <p className={'bp3-form-helper-text'}>
                  Balance Restante:
                </p>
              </Col>
              <Col>
                <h3>
                  {data.balance} $
                </h3>
              </Col>
            </Row>
          </Divider>
        </Card>
        <Dialog
          title={dialogShow === 'edit' ? 'Editar Campaña' : 'Desactivar Campaña'}
          onClose={this.handleClose}
          {...this.state}
        >
          {dialogShow === 'edit' ?
            <div className="margin-1em">
              <FormGroup
                label="Balance"
                helperText="Agregar o Disminuir Balance de la Campaña"
              >
                <NumericInput placeholder="Ejemplo: 0.7" onValueChange={this.handleValueChange} fill={true} />
              </FormGroup>
              <div className="bp3-dialog-footer marginBottom-1em">
                <div className="bp3-dialog-footer-actions">
                  <button
                    type="button"
                    onClick={this.editCampaign}
                    className="bp3-button"
                    disabled={validateBalance}
                  >Confirmar</button>
                  <button type="button" onClick={this.handleClose} className="bp3-button">Cancelar</button>
                </div>
              </div>
            </div>
            :
            <>
            <div className="bp3-dialog-body">
              Esta seguro que desea cancelar la Campaña de Publicacion?
            </div>
            <div className="bp3-dialog-footer marginBottom-1em">
              <div className="bp3-dialog-footer-actions">
                <button type="button" onClick={this.deactivateCampaign} className="bp3-button">Confirmar</button>
                <button type="button" onClick={this.handleClose} className="bp3-button">Cancelar</button>
              </div>
            </div>
            </>
          }
          
        </Dialog>
      </div>
    );
  };
};

export default ItemAds;
