import React, { useMemo, useState } from 'react';
import { Dialog, Icon, Text } from '@blueprintjs/core';

import Button from '../../../../components/button';
import { rankUser, updateTransaction } from '../../ChatAPI';

import './Confirmation.scss';
import Rating from 'react-rating';

export const CONFIRMATION_TYPES = {
  acceptProposal: 'acceptProposal',
  rejectProposal: 'rejectProposal',
  cancelProposal: 'cancelProposal',
  confirmChange: 'confirmChange',
  cancelChange: 'cancelChange',
};

const Confirmation = ({ type, isOpen, setIsOpen, transactionId, status, isOwner }) => {
  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState(0);

  const contentConfirmation = useMemo(() => ({
    [CONFIRMATION_TYPES.acceptProposal]: {
      status: 'accepted',
      title: '¿Deseas aceptar la propuesta?',
      body: 'Afirmas haber llegado a un acuerdo',
    },
    [CONFIRMATION_TYPES.rejectProposal]: {
      status: 'rejected',
      title: '¿Deseas rechazar la propuesta?',
      body: 'Si rechazas se eliminará la transacción',
    },
    [CONFIRMATION_TYPES.cancelProposal]: {
      status: 'rejected',
      title: '¿Deseas cancelar la propuesta?',
      body: 'Si cancelas se eliminará la transacción',
    },
    [CONFIRMATION_TYPES.cancelChange]: {
      status: status === 'confirmed_owner' || status === 'confirmed_interested' ?
        isOwner ?
          'claim_owner' :
          'claim_interested' :
        'rejected',
      title: '¿Deseas cancelar el cambio?',
      body: 'Si cancelas se eliminará la transacción',
    },
    [CONFIRMATION_TYPES.confirmChange]: {
      status: status === 'accepted' ?
        isOwner ?
          'confirmed_owner' :
          'confirmed_interested' :
        'complete',
      title: '¿Confirmas haber cambiado existosamente?',
      body: 'Califica como fue tu experiencia con el cambio realizado',
    },
  }), [isOwner, status]);

  if (!type || !setIsOpen) return null;

  const content = contentConfirmation[CONFIRMATION_TYPES[type]];
  const handleConfirmation = async () => {
    setLoading(true);
    const data = {
      status: content.status,
    };
    if (type === CONFIRMATION_TYPES.confirmChange) {
      const rankingData = {
        token: localStorage.getItem('tokenId'),
        transaction_id: transactionId,
        rank: ranking,
      };
      await rankUser(rankingData);
    }
    await updateTransaction(transactionId, data);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <Dialog className="confirmation" isOpen={loading || isOpen} onClose={() => setIsOpen(false)}>
      <header className="confirmation__header">
        <Text
          tagName="h2"
          title={content.title}
        >
          {content.title}
        </Text>
      </header>
      <section className="confirmation__body">
        <p>{content.body}</p>
        {
          type === CONFIRMATION_TYPES.confirmChange && (
            <Rating
              initialRating={ranking}
              stop={5}
              onClick={setRanking}
              emptySymbol={<Icon iconSize="32" icon="star-empty" />}
              fullSymbol={<Icon iconSize="32" icon="star" />}
            />
          )
        }
      </section>
      <footer className="confirmation__footer">
        <Button
          bold
          minWidth
          text="Si"
          className="mr-6"
          intent="success"
          onClick={handleConfirmation}
          loading={loading}
        />
        <Button
          bold
          minWidth
          text="No"
          intent="danger"
          onClick={() => setIsOpen(false)}
          loading={loading}
        />
      </footer>
    </Dialog>
  );
};

export default Confirmation;