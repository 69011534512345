import React, { Component } from 'react';
import FormPublication from './newPublication/FormPublication';
import "./newPublication/NewPublication.scss";
import Footer from './../components/Footer';
import { Container, Row, Col } from 'react-grid-system';

class EditPublication extends Component {
  render() {
    return(
      <section className="NewPublicaition">
        <div className="gradientBackground gradientBackgroundAnimated"></div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormPublication edit={true} />
            </Col>
            <div className='FooterContainer'>
              <Footer />
            </div>

          </Row>
        </Container>
      </section>
    ) 
  }
}

export default EditPublication;
