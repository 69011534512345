import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { config } from "./../../../system/Config";
import pointerMap from "./../../../Assets/System/pointerMap/pointerMap.png";
import { useEffect } from "react";
import { useState } from "react";
import { Callout, Tooltip, Position } from "@blueprintjs/core";
import FacebookIcon from "./../../../Assets/System/icons-social/facebookIcon.svg";
import InstagramIcon from "./../../../Assets/System/icons-social/instagramIcon.svg";
// import YoutubeIcon from "./../../../Assets/System/icons-social/youtubeIcon.svg";

const MapContainer = props => {
  const [position, setPosition] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setPosition({
        lat: "10.481121190305075",
        lng: "-66.84438407151495"
      });
    }, 1000);
  }, []);
  return (
    <div className={"w-full mb-4 md:mb-0"}>
      <div className={"mr-0 md:mr-2 relative"}>
        <div className={"block"}>
          <div className={"w-full h-48 mb-2"}>
            <Map
              disabled={true}
              readOnly={true}
              {...props}
              google={props.google}
              onClick={() => {}}
              center={position}
              zoom={14}
            >
              <Marker
                readOnly={true}
                disabled={true}
                icon={{
                  url: pointerMap,
                  scaledSize: { width: 64, height: 64 }
                }}
                position={position}
              />
            </Map>
          </div>
        </div>
      </div>
      <div className={"block"}>
        <div className={"w-full"}>
          <Callout className={"mb-2"}>
            <p className={"mb-2"}>
              <strong>Dirección:</strong>
            </p>
            <p className={"mb-0"}>
              Av. Principal del Cafetal, Qta la Mora
              <br />
              Urb. Santa Marta
              <br />
              Caracas - Venezuela
            </p>
          </Callout>
        </div>
        <Callout className={"mb-2"}>
          <div className={"w-full"}>
            <p className={"mb-2"}>
              <strong>Teléfono:</strong>
            </p>
            <div className={"mb-4"}>
              <Tooltip content="Llamar" position={Position.TOP_LEFT}>
                <a href="tel:+582129919135">+58 212 991 91 35</a>
              </Tooltip>
            </div>
          </div>
          <div className={"w-full"}>
            <p className={"mb-2"}>
              <strong>E-mail:</strong>
            </p>
            <div className={"mb-0"}>
              <Tooltip content="Escribir e-mail" position={Position.TOP_LEFT}>
                <a href="mailto:contacto@locambio.com">contacto@locambio.com</a>
              </Tooltip>
            </div>
          </div>
        </Callout>
        <Callout>
          <div className={"w-full"}>
            <p className={"mb-2"}>
              <strong>Redes Sociales:</strong>
            </p>
          </div>
          <div className={"flex mb-2"}>
            <div className={"w-6 h-6 mt-2 mr-4"}>
              <Tooltip content="Visitar Fabebook" position={Position.TOP_LEFT}>
                <a
                  href="https://www.facebook.com/locambioLATAM/"
                  target="_blank"
                >
                  <img
                    className={"w-6 h-6"}
                    src={FacebookIcon}
                    alt={"logo Facebook"}
                  />
                </a>
              </Tooltip>
            </div>
            <div className={"w-6 h-6 mt-2"}>
              <Tooltip content="Visitar Instagram" position={Position.TOP_LEFT}>
                <a
                  href="https://www.instagram.com/locambiolatam/"
                  target="_blank"
                >
                  <img
                    className={"w-6 h-6"}
                    src={InstagramIcon}
                    alt={"logo Facebook"}
                  />
                </a>
              </Tooltip>
            </div>
          </div>
        </Callout>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: null
})(MapContainer);
