import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';



const COLORS = ['#0088FE', '#FF0080'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class GenderGraph extends PureComponent {

  
  constructor(props){
    super(props);

    this.state = {
      data: [
        { name: 'Hombres', value: this.props.gender.Masculino},
        { name: 'Mujeres', value: this.props.gender.Femenino },
      ]
    }
  }
  
  render() {
    const {data} = this.state;
    const masculino = this.props.gender.Masculino;
    const femenino = this.props.gender.Femenino;
    return (
      <>
        <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
      
      <div style={{backgroundColor: "#0088FE", color: "#fff"}}>Hombres: {masculino}</div>
      <div style={{backgroundColor: "#FF0080", color: "#fff"}}>Mujeres: {femenino}</div>
      </>
    );
  }
}
