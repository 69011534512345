import React from "react";
import { rankingUserText, rankingUserStars } from "./../utilityFunctions";
import { Icon } from "@blueprintjs/core";
import "./../components/utility.css";

const RankingUserStar = (props) => {
  const user = props.user;

  const RankNumber = props.user.reputation;

  const starRanking = rankingUserStars(user.registration_date, user.reputation);
  for (let i = 0; i < starRanking.lengthArrayStar.length; i++) {
    starRanking.lengthArrayStar[i] = i;
  }
  for (let i = 0; i < starRanking.lengthArrayStarEmpty.length; i++) {
    starRanking.lengthArrayStarEmpty[i] = i;
  }

  let rankingData = function isUserRanked() {
    if (RankNumber <= 0) {
      return (
        <p className="bp3-form-helper-text margin0 marginBottom-05em pt-2 newUserText">
          Nuevo Usuario
          <span> </span>
          <Icon icon={"star-empty"}></Icon>
        </p>
      );
    } else {
      return null;
    }
  };

  return (
    (rankingData() == null && (
      <>
        <p className={"mt-3 RankBlock"}>
          <span className={"margin0 marginLeft-05em rankingPorcentage"}>
            {RankNumber.toFixed(1)}
          </span>
          <span> </span>
          <Icon className="IconGold" icon={"star"} />
        </p>
        {/*<p className="bp3-form-helper-text margin0 marginBottom-05em">
          {rankingUserText(user.registration_date, user.reputation)}
        </p>*/}
      </>
    )) ||
    rankingData()
  );
};

export default RankingUserStar;
