import React, { Component } from 'react';
import { Button, Callout, Card, Elevation, Icon, Popover, Position, Tag } from '@blueprintjs/core';
import { ClearFix, Col, Row } from 'react-grid-system';
import ProductSwiper from '../../../components/productSwiper/ProductSwiper';
import Comments from '../../../components/Comments';
import {
  datelocambio,
  distanceFormat,
  formatPrice,
  isNewPublication,
  needOverlay,
  productNameUrl,
} from '../../../utilityFunctions';
import { config } from '../../../system/Config';
import { getHeader } from '../../../system/Sesion';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import OverlayPremium from '../../../components/OverlayPremium';
import * as actions from '../../../store/actions';
import '../../myPublications/PublicationItem';
import './activityNewPublication/ActivityNewPublication.scss';
import { Toast } from '../../../utility/toast';

class ActivityNewPublication extends Component {
  state = {
    publication: null,
    publicationsSavedByUser: false, // la publicacion ha sido guardada por el usuario,
    savePublication: false,
    isOpenReport: false,
    comments: false, // la publicacion tiene comentarios?
    firstImpresion: true,
  };

  componentDidMount() {
    this.setState({ firstImpresion: false });
  }

  sponsoredInteractions = (id, click, ...rest) => {
    let response = false;
    if (rest[0]) {
      let elementTaget = rest[0].target.className.split(' ');
      response = [
        'swiper-pagination-bullet',
        'swiper-button-next',
        'swiper-button-prev',
      ].some((el) => elementTaget.includes(el));
    }
    if (!id || response) return;
    let endPoint = click ? 'click_counter' : 'print_counter';
    const dataAPI = {
      user_id: this.props.userId,
      ad_id: id,
      latitude: localStorage.getItem('latitude'),
      longitude: localStorage.getItem('longitude'),
    };

    axios
      .post(`${config.URL_API}/v1/ads/${endPoint}`, dataAPI, {
        headers: getHeader(),
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  savePublication = () => {
    this.setState({ savingPublication: true });
    const dataAPI = {
      user_id: this.props.userId,
      publication_id: this.props.pub.id,
    };

    axios
      .post(`${config.URL_API}/publications_saveds`, dataAPI, {
        headers: getHeader(),
      })
      .then(() => {
        this.setState({
          publicationsSavedByUser: true,
          savingPublication: false,
        });
        Toast.show({ message: 'Publicación guardada' });
        this.props.onGetPublicationsSaved(this.props.userId);
      })
      .catch(() => {
        this.setState({ savingPublication: false });
      });
  };

  // el usuario loeguado ha compartido esta publicacion?
  sharedPub = (publication_id) => {
    let aux = false;
    this.props.pubShares.forEach((value) => {
      if (value.publication_id == publication_id) {
        aux = true;
        return;
      }
    });
    return aux;
  };

  switchIsOpenReport = () => {
    this.setState({ isOpenReport: !this.state.isOpenReport });
  };

  setCommentsTrue = () => {
    if (this.state.comments) {
      return;
    }
    this.setState({ comments: true });
  };

  render() {
    if (this.props.sponsored && this.state.firstImpresion) {
      this.sponsoredInteractions(this.props.sponsored.id, false);
    }

    const serviceLocation = this.props.pub.location;
    const publicationAddress1 = this.props.pub.address_1;
    const publicationAddress = publicationAddress1.split(',');
    const productLocation = publicationAddress[publicationAddress.length - 3];
    const { toChange, pub } = this.props;
    const owner_id = pub.owner.id;

    let content = null;
    content = (
      <>
        <div className="ActivityNewPublication">
          <Card elevation={Elevation.ONE} className="marginTop-1em">
            {isNewPublication(this.props.pub.publish_date) ? (
              <>
                <div className="changeDesignLabel gradientBackgroundAnimated marginBottom-1em">
                  <h2 className="margin0">
                    <Icon className="margin0 marginRight-05em" icon={'star'} />
                    Nueva publicación
                  </h2>
                </div>
              </>
            ) : null}
            <Row className="PubContainer" style={{ display: 'contents' }}>
              {this.props.sponsored && (
                <h4>
                  <span className="absolute text-xs text-grey-dark ml-3 mt-3 w-1/2">
                    Publicación&nbsp;Patrocinada
                  </span>
                </h4>
              )}
              <Callout>
                <div className="flex">
                  <div className="relative w-1/2 h-auto">
                    {needOverlay(
                      this.props.pub.product_category,
                      this.props.suscription,
                    ) ? (
                      <OverlayPremium />
                    ) : null}
                    <div
                      className="h-full"
                      onClick={(ev) => {
                        if (this.props.sponsored !== undefined) {
                          this.sponsoredInteractions(
                            this.props.sponsored.id,
                            true,
                            ev,
                          );
                          // llamar al api impresiones
                        }
                      }}
                    >
                      <ProductSwiper
                        pictures={this.props.pub.pictures}
                        needOverlay={false}
                        isWall={true}
                        urlPub={`/${productNameUrl(this.props.pub.title)}/${
                          this.props.pub.id
                        }`}
                      />
                    </div>
                  </div>
                  <div className="relative pl-4 w-1/2	Half2">
                    <div className="FechaPub">
                      <h4>
                        <span className="float-right text-xs text-grey-dark mb-0 -mt-4">
                          {datelocambio(this.props.pub.publish_date)}
                        </span>
                      </h4>
                    </div>
                    <div>
                      <div className="publicationDetailsContainer marginLeft-1em">
                        <h1 className="pt-2 sm:pt-0 md:pt-0 lg:pt-0 xl:pt-0 text-base pub-title">
                          <div
                            className="break-words"
                            onClick={(ev) => {
                              if (this.props.sponsored !== undefined) {
                                this.sponsoredInteractions(
                                  this.props.sponsored.id,
                                  true,
                                  ev,
                                );
                                // llamar al api impresiones
                              }
                            }}
                          >
                            {
                              <Link
                                to={`/${productNameUrl(this.props.pub.title)}/${
                                  this.props.pub.id
                                }`}
                              >
                                {this.props.pub.title}
                              </Link>
                            }
                          </div>
                        </h1>
                        <div className="mb-2">
                          <p>
                            {this.props.pub.condition ? (
                              <>
                                <Tag
                                  className="tag marginRight-05em marginBottom-05em"
                                  minimal={true}
                                >
                                  Usado
                                </Tag>
                                <Tag
                                  className="tag marginRight-05em marginBottom-05em"
                                  minimal={true}
                                >
                                  {this.props.pub.condition}
                                </Tag>
                              </>
                            ) : (
                              <Tag
                                className="tag marginRight-05em marginBottom-05em"
                                minimal={true}
                              >
                                {this.props.pub.publication_type === '0'
                                  ? 'Servicio'
                                  : 'Nuevo'}
                              </Tag>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="headerUserCard">
                        <br></br>
                        <br></br>
                        <div className="UserDetailsContainer marginBottom-05em">
                          <div className="popOverUserInfoContainer">
                            <Popover
                              interactionKind={'hover'}
                              position={Position.LEFT_TOP}
                              content={
                                this.props.pub.owner &&
                                `${this.props.pub.name} ${this.props.pub.last_name}`
                              }
                            >
                              <>
                                <p className="bp3-form-helper-text">
                                  Publicación a
                                  <Icon icon="geolocation" />
                                  <span>
                                    {formatPrice(
                                      distanceFormat(
                                        this.props.pub.dist_metros + '',
                                      ),
                                    )}
                                  </span>
                                  en
                                </p>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Tag
                                    className="marginBottom-1em -ml-4 tagLocation"
                                    large={true}
                                    icon="map-marker"
                                  >
                                    {this.props.pub.publication_type ===
                                    'Producto' ? (
                                      <div className="mr-2 text-xs">
                                        {productLocation
                                          ? productLocation
                                          : publicationAddress1}
                                      </div>
                                    ) : (
                                      <div className="mr-2 text-xs">
                                        {serviceLocation}
                                      </div>
                                    )}
                                  </Tag>
                                </Col>
                              </>
                            </Popover>
                            <div>
                              <h4>
                                {`${this.props.pub.name} ${this.props.pub.last_name}`}
                                {/* {`${this.props.pub.owner.name} ${this.props.pub.owner.last_name}`} */}
                                {this.props.pub.suscription !==
                                'Premium' ? null : (
                                  <Tag className="tagPremium marginBottom-05em marginLeft-05em">
                                    Premium
                                  </Tag>
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className={ClearFix}></div>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          <div className="marginBottom-1em">
                            {!this.state.publicationsSavedByUser ? (
                              <Button
                                className="BtnLCPrimaryOutline"
                                minimal={true}
                                fill={true}
                                icon="bookmark"
                                loading={this.state.savingPublication}
                                onClick={this.savePublication}
                              >
                                Guardar
                              </Button>
                            ) : (
                              <Button
                                className="BtnLCPrimaryOutline marginBottom-05em"
                                minimal={true}
                                fill={true}
                                disabled={true}
                                icon="bookmark"
                              >
                                Publicacion guardada
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          <Button 
                            fill={true} 
                            disabled = {this.props.userId === pub.owner.id}
                            className={"BtnLCPrimary"} 
                            onClick={() => toChange(`/change/operation/${productNameUrl(pub.title)}/${pub.id}/${owner_id}/${pub.coordinate.latitude}/${pub.coordinate.longitude}`)}
                          >
                            Propuesta
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Callout>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Comments
                      isVisible={this.props.isVisible}
                      pub={this.props.pub}
                      setCommentsTrue={this.setCommentsTrue}
                    />
                    {this.setCommentsTrue ? (
                      <Callout className="noActivityDisplay marginBottom-1em">
                        <h5 className="bp3-form-helper-text">
                          No hay preguntas aun
                        </h5>
                      </Callout>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );

    return <>{content}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    pubShares: state.auth.pubShares,
    suscription: state.auth.suscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPublicationsSaved: (user_id) =>
      dispatch(actions.getPublicationsSaved(user_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityNewPublication);
