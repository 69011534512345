import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

const settings = {
  modules: [Navigation],
  navigation: true,
  initialSlide: 0,
  slidesPerView: 1.5,
  spaceBetween: 20,
  centeredSlides: true,
};

const Categories = ({ categories, title, type }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const { normal, highlight } = title;

  const allCategories = categories.map((category) => {
    const content = (
      <div
        className="categories__category"
        key={`category-${category.name}`}
      >
				<img
          className="categories__image"
          src={category.image}
          alt={category.name}
        />
				<p className="categories__name">
					{category.name}
				</p>
			</div>
    );

    if (isNotMobile) return content;
    return <SwiperSlide key={`category-slide-${category.name}`}>{content}</SwiperSlide>;
  });

  return (
    <div className="categories section section--with-max-width">
			<p className="section__title">
        <span>{normal || title} </span>
        <span className={`section__title--${type} section__title--bolder`}>{highlight}</span>
      </p>
      {
        isNotMobile ?
          <div className="categories__container">{allCategories}</div> :
          <Swiper {...settings}>{allCategories}</Swiper>
      }
		</div>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      normal: PropTypes.string.isRequired,
      highlight: PropTypes.string,
    }),
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'accent']),
};

Categories.defaultProps = {
  categories: [
    {
      name: 'Electrónica, Audio y Video',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-1.jpg',
    },
    {
      name: 'Vehículos y repuestos',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-2.jpg',
    },
    {
      name: 'Electrodomésticos',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-3.jpg',
    },
    {
      name: 'Deportes y Fitness',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-4.jpg',
    },
    {
      name: 'Hogar y Muebles',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-5.jpg',
    },
    {
      name: 'Estética y Belleza',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-6.jpg',
    },
    {
      name: 'Ropas y Accesorios',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-7.jpg',
    },
    {
      name: 'Bebés',
      image: 'https://storage.googleapis.com/locambio-admin/categories/category-8.jpg',
    },
  ],
  title: {
    normal: 'Categorías de articulos',
    highlight: 'recomendadas',
  },
  type: 'accent',
};

export default Categories;
