import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Button, Dialog } from '@blueprintjs/core';
import { Link, withRouter } from 'react-router-dom';
import AvatarUser from '../../components/AvatarUser';
import { getHeader } from '../../system/Sesion';
import { datelocambio } from '../../utilityFunctions';
import { config } from '../../system/Config';
import RequestPublication from './RequestPublication';
import flechasChange from './images/flechas.png';
import ProposalRequestReceived from './ProposalRequestReceived';
import RankingUserStar from '../../components/RankingStar';
import { setCurrentTransaction, setScrollTo } from '../../store/actions';

class ChangesTab extends Component {
  valueAwaits;

  constructor(props) {
    super(props);
    this.state = {
      transaction: null,
      status: null,
      is_owner: null,
      profile: null,
      openawait: false,
    };
  }

  componentDidMount() {
    this.fetchTransaccion().then(async (data) => {
      let isOwner = data.data.interested_id !== this.props.userId;
      let profile = null;
      if (!isOwner) {
        profile = (await this.fetchUserProfile(data.data.publication.owner_id))
          .data;
      } else {
        try {
          let awaitsData = (await this.fetchAwaits()).data;
          this.valueAwaits = {
            publication: this.props.value.publication,
            transactions: awaitsData.transactions,
          };
        } catch (e) {}
      }
      this.setState(
        {
          transaction: data.data,
          status: data.data.status,
          is_owner: isOwner,
          profile,
        },
        () => {
          if (this.props.currentTransaction === this.props.name) {
            this.props.setScrollTo(this.props.currentTransaction);
            this.props.setCurrentTransaction('');
          }
        }
      );
    });
  }

  fetchTransaccion() {
    return axios.get(
      `${config.URL_API}/transactions/${this.props.name}`,
      { headers: getHeader() }
    );
  }

  fetchUserProfile(idProfile) {
    return axios.get(`${config.URL_API}/users/${idProfile}`, {
      headers: getHeader(),
    });
  }

  fetchAwaits() {
    return axios.get(
      `${config.URL_API}/v1/requests?awaits_for=${this.props.value.publication_id}`,
      { headers: getHeader() }
    );
  }

  profile() {
    const { transaction, profile } = this.state;

    let profileUser = profile;
    if (!profile) {
      profileUser = { user: transaction.interested_user };
      profileUser.user.id = transaction.interested_id;
    }

    return (
      <div className="headerUserCard flex-col md:flex-row text-left">
        <div className="AvatarContainer">
          <Link to={`/publications/third/${profileUser.user.id}`}>
            <AvatarUser user={profileUser.user} />
          </Link>
        </div>
        <div className="w-full md:px-1 marginBottom-05em">
          <div className="flex">
            <h4 className="ProfileName">
              {`${profileUser.user.name} ${profileUser.user.last_name}`}
            </h4>
            <RankingUserStar user={profileUser.user} />
            <Link
              to={`/chats/${transaction.chat_id}?scroll=true`}
              className="text-white"
            >
              <Button
                className="ml-2"
                intent="primary"
                style={{ marginTop: '10px' }}
              >
                CHAT
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  switchDialogAwait = () => {
    this.setState({
      openawait: !this.state.openawait,
    });
  };

  render() {
    const { transaction, status, is_owner, profile, openawait } = this.state;
    const { indexProposal, suscription, openModal, openRankModal } = this.props;

    let result = <></>;
    if (!transaction) {
      return result;
    }
    let divPub = (
      <>
        <RequestPublication
          publication={transaction.publication}
          suscription={suscription}
        />
      </>
    );
    let divInterested = (
      <>
        <RequestPublication
          transaction={transaction}
          publication_interested={transaction.publication_interested}
          tags={transaction.tags}
          suscription={suscription}
        />
      </>
    );

    switch (status) {
      case 'accepted':
        result = (
          <div className="container mx-auto relative mb-3 PubBox">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              CAMBIO EN PROCESO
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center OwnPubOnChange">
                  {is_owner ? (
                    <>
                      {divPub}
                      <div className="acceptedText">
                        ¿Ya cambiaste con{' '}
                        {`${transaction.interested_user.name} ${transaction.interested_user.last_name}`}
                        ?
                        {this.valueAwaits ? (
                          <>
                            <Button
                              minimal
                              icon="time"
                              style={{
                                textAlign: 'center',
                                'margin-left': '5px',
                                textDecoration: 'underline',
                              }}
                              onClick={this.switchDialogAwait}
                            >
                              Otras Propuestas
                            </Button>
                            <Dialog
                              autoFocus
                              enforceFocus
                              canEscapeKeyClose
                              canOutsideClickClose
                              icon="info-sign"
                              maxWidth="false"
                              isOpen={openawait}
                              style={{ width: '70%' }}
                              title="Propuestas en Espera"
                              onClose={this.switchDialogAwait}
                            >
                              <ProposalRequestReceived
                                proposal={this.valueAwaits}
                                suscription={this.props.suscription}
                                indexProposal="1"
                                openModal={this.openModal}
                                read={true}
                              />
                            </Dialog>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      {divInterested}
                      <div className="acceptedText">
                        ¿Ya cambiaste con{' '}
                        {`${profile.user.name} ${profile.user.last_name}`}?
                      </div>
                    </>
                  )}
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 md:px-1 StdButton">
                      <Button
                        style={{
                          textAlign: 'center',
                        }}
                        rightIcon="small-tick"
                        fill
                        className="mt-2 mb-1"
                        intent="success"
                        onClick={() =>
                          openRankModal(transaction, is_owner, profile)
                        }
                      >
                        Confirmar Cambio
                      </Button>
                    </div>
                    <div className="w-full md:w-1/2 md:px-1">
                      <Button
                        style={{
                          textAlign: 'center',
                        }}
                        rightIcon="cross"
                        fill
                        className="mt-2 mb-1"
                        intent="danger"
                        onClick={() =>
                          openModal(transaction.id, transaction, 'cancel')
                        }
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="w-1/5 text-center">
                  <img
                    src={flechasChange}
                    className="w-full md:w-1/2"
                    alt="Cambio"
                    style={{ maxWidth: '150px' }}
                  />
                </div>
                <div className="w-2/5 p-1 text-center">
                  {!is_owner ? divPub : divInterested}
                  {this.profile()}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 'confirmed_owner':
      case 'confirmed_interested':
        result = (
          <div className="container mx-auto relative mb-3">
            <div className="text-center bg-grey-light p-1 shadow-md text-grey-darker Header1">
              CAMBIO POR CONFIRMAR
            </div>
            <div className="container mx-auto relative bg-white overflow-y-auto">
              <div className="text-right absolute pin-r Date">
                {datelocambio(transaction.updated_at)}
              </div>
              <div className="flex content-center items-center Box2 h-full">
                <div className="w-2/5 p-1 text-center">
                  {is_owner ? (
                    <>
                      {divPub}
                      <div className="acceptedText">
                        {`${transaction.interested_user.name} ${transaction.interested_user.last_name}`}{' '}
                        nos dijo que cambio exitosamente contigo
                      </div>
                    </>
                  ) : (
                    <>
                      {divInterested}
                      <div className="acceptedText">
                        {`${profile.user.name} ${profile.user.last_name}`} nos
                        dijo que cambio exitosamente contigo
                      </div>
                    </>
                  )}
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 md:px-1 StdButton">
                      <Button
                        rightIcon="small-tick"
                        fill
                        className="mt-2 mb-1"
                        intent="success"
                        onClick={() =>
                          openRankModal(transaction, is_owner, profile)
                        }
                      >
                        Confirmar cambio
                      </Button>
                    </div>
                    <div className="w-full md:w-1/2 px-1">
                      <Button
                        rightIcon="cross"
                        fill
                        className="my-1"
                        intent="danger"
                      >
                        Tengo un problema
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="w-1/5 text-center">
                  <img
                    src={flechasChange}
                    className="w-full md:w-1/2"
                    alt="Cambio"
                    style={{ maxWidth: '150px' }}
                  />
                </div>
                <div className="w-2/5 p-1 text-center">
                  {!is_owner ? divPub : divInterested}
                  {this.profile()}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        result = (
          <div className="container mx-auto">No se encontro estatus</div>
        );
    }
    return result;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentTransaction: (currentTransaction) =>
    dispatch(setCurrentTransaction(currentTransaction)),
  setScrollTo: (scrollTo) => dispatch(setScrollTo(scrollTo)),
});

export default withRouter(connect(null, mapDispatchToProps)(ChangesTab));
