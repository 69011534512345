import { useState } from 'react';
import axios from 'axios';
import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';
import { Toast } from '../../utility/toast';

/**
 * @param {Number} initialValueHidePerfil
 * @param {Boolean} initialValueNotificationsEmail
 * @param {Function} onRefreshUserData, Refresh user data in the store
 */
const useConfigurationPageLogic = (
  initialValueHidePerfil,
  initialValueNotificationsEmail,
  onRefreshUserData,
) => {
  const [hidePerfil, setHidePerfil] = useState(
    !Boolean(initialValueHidePerfil),
  );
  const [notificationEmails, setNotificationEmails] = useState(
    !initialValueNotificationsEmail,
  );
  const [updatingHidePerfil, setUpdatingHidePerfil] = useState(false);
  const [updatingNotificationsEmail, setUpdatingNotificationsEmail] = useState(
    false,
  );

  const onChangeHidePerfil = async event => {
    if (updatingHidePerfil) return;
    const value = event.target.checked;
    setUpdatingHidePerfil(true);
    if (!value) {
      try {
        await axios.put(
          `${config.URL_API}/active_account`,
          { id: localStorage.getItem('userId') },
          { headers: getHeader() },
        );
        Toast.show({
          message: 'Tu perfil esta visible nuevamente',
          intent: 'success',
        });
        onRefreshUserData();
      } catch (err) {
        Toast.show({ message: 'Lo sentimos hubo un error', intent: 'danger' });
      } finally {
        setUpdatingHidePerfil(false);
      }
    } else {
      try {
        await axios.put(
          `${config.URL_API}/desactive_account`,
          { id: localStorage.getItem('userId') },
          { headers: getHeader() },
        );
        Toast.show({
          message: 'Tu perfil esta oculto por ahora',
          intent: 'warning',
        });
        onRefreshUserData();
      } catch (err) {
        const intent = 'danger';
        if (err.response.status === 502) {
          Toast.show({
            message: 'Para ocultar tu perfil debes terminar los cambios pendientes',
            intent,
          });
        } else {
          Toast.show({ message: 'Lo sentimos hubo un error', intent });
        }
      } finally {
        setUpdatingHidePerfil(false);
      }
    }
    setHidePerfil(value);
  };

  const onChangeEmails = async event => {
    if (updatingNotificationsEmail) return;
    const value = event.target.checked;
    setNotificationEmails(value);
    setUpdatingNotificationsEmail(true);

    try {
      await axios.put(
        `${config.URL_API}/users/${localStorage.getItem('userId')}`,
        { notifications_email: !value },
        { headers: getHeader() },
      );

      const message = value
        ? 'Has deshabilitado las notificaciones de correo'
        : 'Has habilitado las notificaciones de correo';
      Toast.show({ message });
      onRefreshUserData();
    } catch (err) {
      Toast.show({ message: 'Lo sentimos hubo un error', intent: 'danger' });
    } finally {
      setUpdatingNotificationsEmail(false);
    }
  };

  return {
    hidePerfil,
    onChangeHidePerfil,
    onChangeEmails,
    notificationEmails,
  };
};

export { useConfigurationPageLogic };
