import React from 'react';
import { Button } from '@blueprintjs/core';
import { productNameUrl } from '../../utilityFunctions';

export default function ProducDetailsMobileActions({
  pubInTransaction,
  pub,
  toChange,
  isAuthenticated,
  userId,
  handlerVisibiltyUserDetailPanel,
}) {
  return (
    <div className="mb-2">
      {
        !pubInTransaction ? (
          <Button
            onClick={() =>
              toChange(
                `/change/operation/${productNameUrl(
                  pub.name
                )}/${pub.id}/${pub.owner.id}/${pub.coordinate.latitude
                }/${pub.coordinate.longitude}`
              )
            }
            fill
            className="BtnLCPrimary"
            disabled={
              !isAuthenticated ||
              pub.owner.id === userId ||
              pubInTransaction
            }
          >
            Enviar Propuesta
          </Button>
        ) : (
          <Button
            fill
            disabled
            className="BtnLCPrimary"
          >
            Cambio pendiente
          </Button>
        )
      }
      <Button
        onClick={handlerVisibiltyUserDetailPanel}
        fill
        minimal
        className="BtnLCYellow mt-2"
      >
        Ver Información de la publicación
      </Button>
    </div>
  )
}
