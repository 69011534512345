import React from "react";
import { Callout, Tag } from "@blueprintjs/core";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { filter } from "./notificationsPage/filter";
import BaseNotificationGet from '../components/toastNotification/BaseNotificationGet.js'
import "./notificationsPage/NotificationsPage.scss";
import Footer from "./../components/Footer";
import { config } from "../system/Config";
import axios from "axios";
import { getHeader } from "../system/Sesion";

class NotificationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filterValues: {
        date: "",
        type_notification: ""
      }
    };
  }

  formatDateOnlyDate = date => {
    let frg = date.split("T");
    let formatDate = frg[0].replace(/-/g,"/"); //SE REMPLAZA LOS - POR /
    let frg2 = formatDate.split("/");
    return frg2[2] + "/" + frg2[1] + "/" + frg2[0];
  };

  filterChangeHandler = (value, name) => {
    const filterValues = { ...this.state.filterValues };
    filterValues[name] = value;
    this.setState({ filterValues: filterValues }, () => {
      this.forceUpdate();
    });
  };

  filterData = data => {
    return filter(data, this.state.filterValues);
  };

  translateValueNotificationToSpanish = value => {
    switch (value) {
      case "":
        return "Todos";
      case this.TYPE_NOTIFICATION.CHANGE:
        return "Solo cambios";
      case this.TYPE_NOTIFICATION.CHAT:
        return "Solo mensajes";
      case this.TYPE_NOTIFICATION.COMMENT:
        return "Solo comentarios";
      case this.TYPE_NOTIFICATION.PROPOSAL_CHANGE:
        return "Solo propuestas de cambio";
      case this.TYPE_NOTIFICATION.SHARE:
        return "Solo notificaciones de compartir";
      case this.TYPE_NOTIFICATION.GOOD_DEAL:
        return "Solo notificaciones de buen negocio";
    }
  };

  markNotificationAsRead(notification_id) {
    try {
      axios.put(
        `${config.URL_API}/notifications/${notification_id}`,
        { read: true },
        { headers: getHeader() }
      );
    } catch (err) {
      throw err;
    }
  }

  render() {
    const notifications = JSON.parse(JSON.stringify(this.props.notifications));
    const {
      commentsQuestion,
      commentsAnswer,
      newProposalChange,
      acceptedProposalChange,
      rejectedPropsalChange,
      newChange,
      changeRequestFinalize,
      changeRejected,
      changeFinalized,
      newMessage,
      share,
      ToThirdPartyHaveOwnerInterest,
      ToOwnerArticleHeWant,
      ToOwnerThirdPartyWantArticle,
      ToThirdPartyWantOwnerArticle,
      ToOwnerSameInterest,
      ToThirdPartySameInterest,
      TransactionCompletedByTheSystem,
      PublicationWaitingApproval,
    } = notifications;

    const data = commentsQuestion
      .concat(
        commentsAnswer,
        newProposalChange,
        acceptedProposalChange,
        rejectedPropsalChange,
        newChange,
        changeRequestFinalize,
        changeRejected,
        changeFinalized,
        newMessage,
        share,
        ToThirdPartyHaveOwnerInterest,
        ToOwnerArticleHeWant,
        ToOwnerThirdPartyWantArticle,
        ToThirdPartyWantOwnerArticle,
        ToOwnerSameInterest,
        ToThirdPartySameInterest,
        TransactionCompletedByTheSystem,
        PublicationWaitingApproval,
      )
      .filter((notification) => notification.type_notification_id !== 10)
      .sort((notificationA, notificationB) => {
        let dateA = new Date(notificationA.created_at).getTime();
        let dateB = new Date(notificationB.created_at).getTime();
        return dateA < dateB ? 1 : -1;
      });

    const notificationsUnRead = data.filter(notification => !notification.read).length;

    return (
      <>
        <div
          className={"NotificationsPage pt-20 sm:pt-24 md:pt-24 lg:pt-16 xl:pt-16"}
        >
          <div className={"customWrapper"}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}/>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Callout className={"marginBottom-05em"}>
                  <h3 className={"margin0"}>Notificaciones</h3>
                </Callout>
                {notificationsUnRead > 0 && (
                  <Callout
                    icon={"issue"}
                    className={"marginBottom-05em"}
                    intent={"primary"}
                  >
                    <h3 className={"margin0"}>
                      Tienes{" "}
                      <Tag  className={"tag"}>
                        {notificationsUnRead}
                      </Tag>{" "}
                      {notificationsUnRead > 1
                        ? "notificaciones por ver"
                        : "notificación por ver"}
                    </h3>
                  </Callout>
                )}

                {data.map((notification, i) => (
                  <div key={i} style={(notification.read === false) ? {backgroundColor: "rgba(138, 155, 168, 0.15)"} : null}
                  onClick= {()=>{this.markNotificationAsRead(notification.id)}}
                  >
                  <BaseNotificationGet markToastAsView={null} key={`notification${i}`} value={notification} typeNotification={"Page"} />
                  </div>
                ))}

                {data.length === 0 ? (
                  <Callout className={"noActivityDisplay marginBottom-1em"}>
                    <h5 className={"bp3-form-helper-text"}>
                      Sin Notificaciones
                    </h5>
                  </Callout>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={12} lg={3}/>
            </Row>
          </div>
        </div>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Footer />
              <div className="whiteSpace"/>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    subscription: state.auth.suscription,
    notifications: state.notification
  };
};

export default connect(mapStateToProps)(NotificationsPage);
