import React from 'react';
import { Menu } from '@blueprintjs/core';
import ChildrenCategory from './ChildrenCategory';

const CategoriesFather = props => {
  const { value, i } = props;
  return (
    <React.Fragment key={i}>
      <Menu.Item text={value.name}>
        {value.categories?.map((category, index) => (
          category.categories?.length ?
            <CategoriesFather
              key={`${category.name}${index}`}
              value={category}
              i={index}
              toSearch={props.toSearch}
            /> :
            <ChildrenCategory
              key={`${category.name}${index}`}
              index={`${i}-${index}`}
              categoryDescendants={category}
              toSearch={props.toSearch}
            />
        ))}
      </Menu.Item>
    </React.Fragment>
  );
};

export default CategoriesFather;
