import axios from "axios";
import { getHeader } from '../../system/Sesion';
import { config } from '../../system/Config';

import * as actionTypes from "./actionTypes";

export const setTags = tags => {
  return {
    type: actionTypes.SET_TAGS,
    tags: tags
  };
};

export const setTradeTags = tradeTags => {
  return {
    type: actionTypes.SET_TRADE_TAGS,
    tradeTags: tradeTags
  };
};

export const getTags = () => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/autocomplete_tag`, { headers: getHeader() })
      .then(data => {
        dispatch(setTags(data.data.tags));
      })
      .catch(error => {
        console.log('tags', error);
      });
  };
};

export const getTradeTags = () => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/trade_tags_pub`)
      .then(data => {
        dispatch(setTradeTags(data.data));
      })
      .catch(error => {
        console.log('tradeTags', error);
      });
  };
};
