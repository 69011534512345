import { useDispatch } from 'react-redux';
import React, { memo, useState } from 'react';
import { Collapse, Icon, Text } from '@blueprintjs/core';

import Images from '../images';
import PanelButtons from '../panelButtons';
import AvatarUser from '../../../../components/AvatarUser';

import { DRAWERS } from '../../../../utility/drawers';
import { updateDrawer } from '../../../../store/actions';
import { checkWithCurrentUser, datelocambio } from '../../../../utilityFunctions';

const PanelHeader = ({ chat }) => {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(true);

  const publication = chat.transaction.publication;
  const interests = chat.transaction.tags || chat.transaction.publication_interested.title;
  const isOwner = checkWithCurrentUser(chat.owner.id);
  const user = isOwner ? chat.interested : chat.owner;
  const fullName = `${user?.name} ${user?.last_name}`;

  const handleToggle = () => {
    dispatch(updateDrawer({ [DRAWERS.chats]: true }));
  };

  return (
    <header className="panel-header">
      <section className="panel-header__navigation">
        <Icon
          className="chat__icon panel-header__icon"
          icon="arrow-left"
          iconSize={15}
          onClick={handleToggle}
        />
        <AvatarUser user={user} background />
        <Text
          ellipsize
          tagName="h4"
          title={fullName}
        >
          {fullName}
        </Text>
        <Icon
          className={`chat__icon panel-header__icon ${showContent ? '' : 'panel-header__icon--down'}`}
          icon="chevron-up"
          iconSize={20}
          onClick={() => setShowContent(!showContent)}
        />
      </section>
      <Collapse isOpen={showContent}>
        <section className="panel-header__content-wrapper">
          <div className="panel-header__image-wrapper">
            <Images images={publication.pictures} />
          </div>
          <section className="panel-header__content">
            <section className="panel-header__info">
              <Text
                ellipsize
                tagName="h2"
                title={publication.title}
                className="panel-header__title panel-header__title--accent"
              >
                {isOwner ? 'Quiere' : 'Quieres'}
                <span className="panel-header__title--sub-title">
                    {` ${publication.title}`}
                  </span>
              </Text>
              <Text className="panel-header__date">
                <time>
                  {datelocambio(chat.transaction.created_at)}
                </time>
              </Text>
            </section>
            <Text
              ellipsize
              tagName="h2"
              title={interests}
              className="panel-header__title panel-header__title--primary"
            >
              {isOwner ? 'Ofrece' : 'Ofreces'}
              <span className="panel-header__title--sub-title">
                  {` ${interests}`}
                </span>
            </Text>
          </section>
          <PanelButtons
            status={chat.transaction.status}
            isOwner={isOwner}
            transactionId={chat.transaction.id}
          />
        </section>
      </Collapse>
    </header>
  );
};

const areEqual = ({ chat: prevChat }, { chat: nextChat }) => {
  return (
    prevChat.id === nextChat.id &&
    prevChat.transaction.status === nextChat.transaction.status
  );
};

export default memo(PanelHeader, areEqual);