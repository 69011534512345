// devuelve los header para una peticion que requiere autorizacion
export const getHeader = () => {
    const header = {
        'access-token': localStorage.getItem('access-token'),
        'client': localStorage.getItem('client'),
        'uid': localStorage.getItem('uid'),
        'expiry': localStorage.getItem('expiry'),
        'token-type': localStorage.getItem('token-type')
    }
    return header;
}

// guarda en local storage los valores necesarios para el header de autorizacion
export const setHeader = xhr => {
    localStorage.setItem('access-token', xhr['access-token'])
    localStorage.setItem('client', xhr['client'])
    localStorage.setItem('uid', xhr['uid'])
    localStorage.setItem('expiry', xhr['expiry'])
    localStorage.setItem('token-type', xhr['token-type'])
    localStorage.setItem('sesionValid', true)
}