import React from "react";
import { Button } from "@blueprintjs/core";
import ReactCrop from "react-image-crop-old";
import { Container, Row, Col } from "react-grid-system";

const modalCropOld = props => (
  <div>
    <ReactCrop
      crop={props.crop}
      src={props.img}
      onImageLoaded={props.onImageLoaded}
      onComplete={props.onComplete}
      onChange={props.onChange}
      style={{ width: props.width, height: props.height}}
    />
    <div className={"mb-4"}>
      <Container>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Button
              icon="undo"
              
              fill={true}
              className="bp3-fill BtnLCCancel marginTop-1em"
              onClick={props.cancelCrop}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Button
              icon="tick-circle"
              
              fill={true}
              className="bp3-fill BtnLCPrimary marginTop-1em marginRight-1em"
              onClick={props.isCropped}
            >
              Listo
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default modalCropOld;
