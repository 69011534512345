import moment from 'moment';
import { getDateActual, DATE_FILTER_VALUES } from './../../utilityFunctions';

export const filter = (data, filterValues) => {
  const filteredData = data.filter( value => 
    byDate(value, filterValues.date) &&
    byTypeNotification(value, filterValues.type_notification)
  );
  return filteredData;
}

export const byTypeNotification = (value, typeFilter) => {
  if (typeFilter === ''){
    return true;
  }
  return value.type === typeFilter;
}

export const byDate = (value, typeFilter) => {
  const dateActual = moment(getDateActual()).format("YYYY-MM-DD"); 

  let today = () => {
    if (moment(value.date).format("YYYY-MM-DD") == dateActual) {
      return true;
    } else {return false}
  }

  let yesterday = () => {
    if ( moment(value.date).format("YYYY-MM-DD") == moment(dateActual).subtract(1, 'days').format("YYYY-MM-DD") ) {
      return true; 
    } else {return false}
  }

  let beforeYesterday = () => {
    let dateFilter = moment(dateActual).subtract(2, 'days').format("YYYY-MM-DD")
    if ( moment(value.date).format("YYYY-MM-DD") > dateFilter && moment(value.date).format("YYYY-MM-DD") < dateActual ) {
      return true;
    } else {return false}
  }

  let sevenDays = () => {
    let dateFilter = moment(dateActual).subtract(7, 'days').format("YYYY-MM-DD")
    if ( moment(value.date).format("YYYY-MM-DD") > dateFilter && moment(value.date).format("YYYY-MM-DD") < dateActual ) {
      return true;
    } else {return false}
  }

  let thirtyDays = () => {
    let dateFilter = moment(dateActual).subtract(30, 'days').format("YYYY-MM-DD")
    if ( moment(value.date).format("YYYY-MM-DD") > dateFilter && moment(value.date).format("YYYY-MM-DD") < dateActual ) {
      return true;
    } else {return false}
  }

  let thereeMonths = () => {
    let dateFilter = moment(dateActual).subtract(3, 'months').format("YYYY-MM-DD")
    if ( moment(value.date).format("YYYY-MM-DD") > dateFilter && moment(value.date).format("YYYY-MM-DD") < dateActual ) {
      return true;
    } else {return false}
  }

  switch(typeFilter) {
    case DATE_FILTER_VALUES.TODAY: return today();
    case DATE_FILTER_VALUES.YESTERDAY: return yesterday();
    case DATE_FILTER_VALUES.BEFORE_YESTERDAY: return beforeYesterday();
    case DATE_FILTER_VALUES.SEVEN_DAYS: return sevenDays();
    case DATE_FILTER_VALUES.THIRTY_DAYS: return thirtyDays();
    case DATE_FILTER_VALUES.THREE_MONTHS: return thereeMonths();
    case '': return true;
  }
}