import React, { useEffect } from "react";
import { Button, Callout } from "@blueprintjs/core";
import "./adWall.scss";
import { config } from "../../../system/Config";
import { callToActionAds, segmentationAds } from "const";
import PropTypes from "prop-types";

/**
 * Ad HalfPage Format.
 * @param {Object} data, Ad values.
 * @param {Function} onUpdateView, It runs when the ad is viewed or pressed or the ad is seen.
 * @param {Function} onUpdateClick, It runs when ad is pressed.
 */
const AdWall = ({ data, onUpdateView, onUpdateClick }) => {
  const { name, text, call_to_action, ads_pictures, url } = data;
  const picture = ads_pictures.find(item => item.type_format === WALL);

  useEffect(() => {
    if (!picture) return;
    const { id, segmentation } = data;
    if (segmentation === segmentationAds.impresion) onUpdateView(id);
  }, [data]);

  if (!picture) return null;

  const onClicked = () => {
    const { id, segmentation } = data;
    if (segmentation === segmentationAds.click) onUpdateClick(id);
  };

  return (
    <>
      <Callout className={"mt-4 mb-4"}>
        <div className={'mb-2'}>
          <p className={"text-xs text-grey-dark mb-2"}>Publicidad</p>
        </div>
        <div className={"adWall"}>
          <div className={"adImage rounded-t"}>
            <img
              className={"rounded-t"}
              src={`${config.URL_IMAGE}/ads/${picture.id}/${picture.picture_file_name}`}
            />
          </div>
          <div className={"w-full bg-white rounded-b p-2"}>
            <div className={"flex"}>
              <div className={"w-full sm:w-full md:w-2/3 lg:w-2/3 xl:w-2/3"}>
                <h5 className={"text-sm mb-0 flex-center"}>{name}</h5>
                <p className={"text-xs text-grey-darker mb-0 flex-center"}>
                  {text}
                </p>
              </div>
              <div className={"w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3"}>
                <div className={"text-right flex-right"}>
                  <Button
                    className={"BtnLCPrimary text-center"}
                    minimal={true}
                    onClick={() => {
                      onClicked();
                      window.open(url, "_blank");
                    }}
                  >
                    {callToActionAds[call_to_action]}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Callout>
    </>
  );
};

const WALL = 1;

AdWall.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdateView: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired
};

export default AdWall;
