import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Callout, Card, Elevation, Icon } from '@blueprintjs/core';

const PublicationCreatedSuccessfully = () => (
  <>
    <div className="cardContainer">
      <div className="p-12">
        <Card elevation={Elevation.ONE} className="marginBottom-1em">
          <Callout>
            <div className="p-12">
              <p className="text-center text-green mb-8">
                <Icon iconSize="48" icon="tick-circle" />
              </p>
              <h3 className="text-center text-green-darker mb-0">
                Publicación creada exitosamente
              </h3>
            </div>
          </Callout>
          <div className="md:flex">
            <div className="w-full md:w-1/2 md:mr-1">
              <Link to="/my-publications">
                <Button className="BtnLCPrimary marginTop-05em" fill>
                  Ir a Mis Publicaciones
                </Button>
              </Link>
            </div>
            <div className="w-full md:w-1/2 md:ml-1">
              <Link to="wall">
                <Button className="BtnLCPrimary marginTop-05em" fill>
                  Ir al mural
                </Button>
              </Link>
            </div>
            <div className="w-full md:w-1/2  md:ml-2">
              <Link to="/adForm">
                <Button className="BtnLCPrimary marginTop-05em" fill>
                  Promocionar
                </Button>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </>
);

export default PublicationCreatedSuccessfully;
