import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import { Link, withRouter } from 'react-router-dom';

import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';
import flechasSend from './images/flechassend.png';
import AvatarUser from '../../components/AvatarUser';
import { datelocambio } from '../../utilityFunctions';
import RequestPublication from './RequestPublication';
import './changeProposalsSent/ChangeProposalsSent.scss';
import RankingUserStar from '../../components/RankingStar';
import { setCurrentTransaction, setScrollTo } from '../../store/actions';

class ProposalRequestSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: null,
      status: null,
      profile: null,
    };
  }

  componentDidMount() {
    this.fetchTransaccion()
      .then(async (data) => {
        let profileData = await this.fetchUserProfile(
          data.data.publication.owner_id,
        );
        this.setState({
          transaction: data.data,
          status: data.data.status,
          profile: profileData.data,
        }, () => {
          if (this.props.currentTransaction === this.props.name) {
            this.props.setScrollTo(this.props.currentTransaction);
            this.props.setCurrentTransaction('');
          }
        });
      });
  }

  fetchTransaccion() {
    let { transactions } = this.props.value;
    return axios.get(`${config.URL_API}/transactions/${transactions}`, {
      headers: getHeader(),
    });
  }

  fetchUserProfile(idProfile) {
    return axios.get(`${config.URL_API}/users/${idProfile}`, {
      headers: getHeader(),
    });
  }

  render() {
    const { transaction, profile } = this.state;
    const { indexProposal, suscription, openModal } = this.props;

    let result = <></>;
    if (!transaction) {
      return result;
    }
    result = (
      <div className="container mx-auto relative bg-white overflow-y-auto">
        <div className="text-right absolute pin-r shadow-inner Date">
          {datelocambio(transaction.updated_at)}
        </div>
        <div className="flex content-center items-center Box2 mt-5 md:mt-0 PropSendPubBox">
          <div className="w-2/5 p-1 -mt-6 text-center">
            <RequestPublication
              transaction={transaction}
              publication_interested={transaction.publication_interested}
              tags={transaction.tags}
              suscription={suscription}
            />
            <div className="flex">
              <div className="w-full md:w-1/2 md:px-3 BtnCancel">
                <Button
                  rightIcon="cross"
                  fill
                  className="my-1 text-center"
                  intent="danger"
                  onClick={() => openModal(transaction.id, transaction, 'cancel')}
                >
                  Cancelar Propuesta
                </Button>
              </div>
            </div>
          </div>
          <div className="w-1/5 text-center">
            <img src={flechasSend} className="w-full md:w-3/4" alt="Enviada" />
          </div>
          <div className="w-2/5 p-1 text-center">
              <RequestPublication
              transaction={transaction}
              publication={transaction.publication}
              suscription={suscription}
            />
            <div className="headerUserCard flex-col md:flex-row text-left -mt-2 UserCardPropSend">
              <div className="AvatarContainer_PropSend" style={{ marginRight: '0' }}>
                <Link to={`/publications/third/${profile.user.id}`}>
                  <AvatarUser user={profile.user} />
                </Link>
              </div>
              <div className="flex items-center w-full md:px-1 marginBottom-05em UserName">
                <h4>
                  {`${profile.user.name} ${profile.user.last_name}`}
                </h4>
                <RankingUserStar user={profile.user} />
                <Link
                  to={`/chats/${transaction.chat_id}?scroll=true`}
                  className="text-white ChatButton"
                >
                  <Button className="ml-2" intent="primary">
                    CHAT
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return result;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentTransaction: (currentTransaction) => dispatch(setCurrentTransaction(currentTransaction)),
  setScrollTo: (scrollTo) => dispatch(setScrollTo(scrollTo)),
});

export default withRouter(connect(null, mapDispatchToProps)(ProposalRequestSend));
